import React from "react";

// Customizable Area Start
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  CardMedia,
  Grid,
  Chip
} from "@material-ui/core";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import StarIcon from "@material-ui/icons/Star";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOnOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";

import "../../blocks/dashboard/assets/css/Search.css";
interface P {
  cards: unknown[],
  loading: boolean,
  message: boolean,
  hire: boolean,
  cardLoading:any,
  handleMessage: (id: number) => void,
  handleAvailableNow?: () => void,
  handleFavourite: () => void,
  handleInvite?: () => void,
  handleHire: () => void
  hireList?: boolean
}

const CardList = ({ cards = [], loading = false, cardLoading,message, hire, handleMessage, handleAvailableNow, handleFavourite, handleInvite, handleHire, hireList = false }: P) => {
  return (
    <Grid item xs={12} sm={12} data-test-id='cardListId'>
      {cards.map((card: any, index: number) => (
        <Card
          key={index}
          style={{
            marginBottom: "40px",
            borderRadius: "10px",
            border: "1px solid #E4E6E8",
            boxShadow: 'none'
          }}
        >
          <CardContent>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} sm={12} md={12} lg={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={2}>
                    <div className="profileAvatar">
                      <CardMedia
                        className="avatarActive"
                        component="img"
                        alt={card?.attributes?.name}
                        image={card?.attributes?.photo[0]?.url || 'https://via.placeholder.com/300'}
                        title={card?.attributes?.name}
                      />

                      <div className="freelancer-search-user-online" />

                    </div>
                  </Grid>

                  <Grid item xs={10} sm={10} md={10} lg={10}>
                    <div>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs id="freelancer-search-name-grid">
                          <div className="freelancer-search-name">
                            {card?.attributes.first_name} {card?.attributes.last_name}
                          </div>
                        </Grid>
                        {handleAvailableNow && (<Grid item>
                          <Button
                            data-test-id="availableButtonId"
                            variant="contained"
                            color="primary"
                            className="freelancer-search-primary-button avb-btn"
                            onClick={handleAvailableNow}
                            style={{
                              borderRadius: "20px",
                              backgroundColor: "#FFF",
                              color: "#00A7A2",
                              border: "1px solid #00A7A2",
                              height: "30px",
                              fontSize: "12px !important",
                              fontWeight: "bold",
                              boxShadow: 'none'
                            }}
                          >
                            <FlashOnIcon style={{ color: "#00A7A2" }} />
                            <span>Available Now</span>
                          </Button>
                        </Grid>)}
                      </Grid>
                      <div className="skillSetCard">
                        <div className="skillSet">
                          <span>Graphic Designer</span>
                          <span>Package Designer</span>
                          <span>Logo Designer</span>
                        </div>
                        <div className="freelancer-search-location">
                          <span>
                            <LocationOnOutlinedIcon
                              style={{ fontSize: "1rem" }}
                            />
                            {card?.attributes.city}, {card?.attributes.country}
                          </span>
                        </div>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="freelancer-search-ratings"
                        >
                          {[...Array(5)].map((_, index) => (
                            <StarIcon
                              key={index}
                              style={{
                                color:
                                  index < Math.floor(card?.averageRating || 4)
                                    ? "#FFC107"
                                    : "#C4C4C4",
                                fontSize: "1rem",
                                marginRight: "2px"
                              }}
                            />
                          ))}
                          <span style={{ fontSize: "12px" }}>
                            4/5 (99 Ratings)
                          </span>
                        </Typography>
                      </div>
                      <div
                        style={{
                          margin: "30px 0",
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap"
                        }}
                      >
                        <div>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                              display: "block",
                              textAlign: "left",
                              marginRight: "10px",
                              fontSize: "12px"
                            }}
                          >
                            Total Bid Amount
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                              display: "block",
                              textAlign: "left",
                              fontWeight: "bold",
                              marginRight: "20px",
                              color: "black",
                              fontSize: "16px"
                            }}
                          >
                            $20/hr
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                              display: "block",
                              textAlign: "left",
                              marginRight: "10px",
                              fontSize: "12px"
                            }}
                          >
                            Total Amount of Time
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                              display: "block",
                              textAlign: "left",
                              fontWeight: "bold",
                              marginRight: "20px",
                              color: "black",
                              fontSize: "16px"
                            }}
                          >
                            {card?.attributes.totalTime}

                            {"300"}
                            {" Hours"}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                              display: "block",
                              textAlign: "left",
                              marginRight: "10px",
                              fontSize: "12px"
                            }}
                          >
                            Total Experience
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{
                              display: "block",
                              textAlign: "left",
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "16px"
                            }}
                          >
                            {card?.attributes.experience_in_years}{4}
                            {" Years"}
                          </Typography>
                        </div>
                      </div>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          {/* {card?.attributes.skills.map(
                            (skill: string, skillIndex: number) => (
                              <Chip
                                className="freelancer-search-chip"
                                key={skillIndex}
                                label={skill}
                              />
                            )
                          )} */}
                          <Chip
                            className="freelancer-search-chip"
                            key={0}
                            label={"Adobe Photoshop"}
                          />
                          <Chip
                            className="freelancer-search-chip"
                            key={1}
                            label={"Logo Design"}
                          />
                          <Chip
                            className="freelancer-search-chip"
                            key={2}
                            label={"Branding"}
                          />
                          <Chip
                            className="freelancer-search-chip"
                            key={3}
                            label={"Adobe Illustrator"}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <CardActions className="freelancer-search-card-action">
                  <Button
                    data-test-id="favoriteButtonId"
                    className="freelancer-search-primary-button"
                    variant="contained"
                    color="primary"
                    onClick={handleFavourite}
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "#F2F2F2",
                      minWidth: "0",
                      padding: "10px"
                    }}
                  >
                    <FavoriteBorderOutlinedIcon
                      style={{ color: "#000000" }}
                    />
                  </Button>

                  {!hireList && (<>
                    <Button
                      data-test-id="messageButtonId"
                      variant="contained"
                      color="primary"
                      className="freelancer-search-primary-button"
                      onClick={() => { handleMessage(card?.attributes.id) }}
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#F2F2F2",
                        minWidth: "0",
                        padding: "10px"
                      }}
                      disabled={cardLoading[card?.attributes.id]}
                    >
                      {/* {console.log(cardLoading[card?.attributes.id])} */}

                      {!cardLoading[card?.attributes.id]? <ForumOutlinedIcon style={{ color: "#000000" }} />
                        : <CircularProgress style={{ width: '25px', height: '25px' }} />}

                    </Button>

                    {!hire ? <Button
                      data-test-id="hireButtonId"
                      className="freelancer-search-secondary-button"
                      variant="contained"
                      color="primary"
                      onClick={handleHire}
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#00A7A2",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        color: "#0000",
                        height: "45px",
                        width: "85px"
                      }}
                    >
                      Hire
                    </Button> : <Button
                      data-test-id="hireButtonId"
                      variant="contained"
                      color="primary"
                      className="freelancer-search-primary-button"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: "#F2F2F2",
                        minWidth: "0",
                        padding: "10px"
                      }}
                      disabled={true}
                    >
                      <CircularProgress style={{ width: '25px', height: '25px' }} />
                    </Button>}
                  </>)}

                  {hireList && (
                    <>
                      <Button
                        data-test-id="messageButtonId"
                        variant="contained"
                        color="primary"
                        className="freelancer-search-proposal-success-secondary-button"
                        onClick={() => { handleMessage(card?.attributes.id) }}
                        style={{
                          borderRadius: "25px",
                          backgroundColor: "#F2F2F2",
                          minWidth: "0",
                          padding: "10px",
                          color: '#000000',
                          width: '146px',
                          height: '46px',
                        }}
                        disabled={message}
                      >
                        Message
                      </Button>
                      <Button
                        data-test-id="hireButtonId"
                        className="freelancer-search-proposal-success-primary-button"
                        variant="contained"
                        color="primary"
                        onClick={handleHire}
                        style={{
                          borderRadius: "25px",
                          backgroundColor: "#00A7A2",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          color: "#ffffff",
                          width: '146px',
                          height: '46px',
                        }}
                      >
                        Hire
                      </Button>
                    </>
                  )
                  }

                  {handleInvite && <Button
                    data-test-id="inviteButtonId"
                    className="freelancer-search-primary-button"
                    variant="contained"
                    color="primary"
                    onClick={handleInvite}
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#00A7A2",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      color: "#ffff",
                      height: "45px",
                      width: "85px"
                    }}
                  >
                    Invite
                  </Button>}
                </CardActions>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Grid>
  )
}

export default CardList;