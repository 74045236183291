export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const lancerScopeLogo = require("../assets/lancerscope_logo.svg");
export const convertToFreelancerIcon = require("../assets/convert_to_freelancer_icon.svg");
export const messageIcon = require("../assets/message_icon.svg");
export const notificationsIcon = require("../assets/notification_icon.svg");
export const helpIcon = require("../assets/help_icon.svg");
export const hourlyRateIcon = require("../assets/hourly_rate.svg");
export const basicDetailsIcon = require("../assets/basic_details_icon.svg");
export const personalDetailsIcon = require("../assets/personal_details_icon.svg");
export const deleteIcon = require("../assets/delete_icon.svg");
export const activeAccountDetailsIcon = require("../assets/active_account_details_icon.svg");
export const activeHoursIcon = require("../assets/active_ours_icon.svg");
export const activePersonalInfoIcon = require("../assets/active_personal_icon.svg");
export const activeBasicInfoIcon = require("../assets/active_basic_info.svg");
export const accountDetailsIcon = require("../assets/account_details_icon.svg");
export const editIcon = require("../assets/edit_icon.svg");
export const sponsorPersonalIcon = require("../assets/sponsor_personal_info.svg");
export const activeSponsorPersonalIcon = require("../assets/active_sponsor_details.svg");
export const activeSponsorBillingDetails = require("../assets/active_sponser_billing_details.svg");
export const activeSponsorCompanyDetailsIcon = require("../assets/active_sponsor_company_details.svg");
export const SponsorCompanyDetailsIcon = require("../assets/sponsor_company_details.png");
export const bhimUpiIcon = require("../assets/bhim_upi_icon.svg");
export const gpayUpiIcon = require("../assets/gpay_icon.svg");
export const phonepeUpiIcon = require("../assets/phonepe_logo_icon.svg");
export const paytmUpiIcon = require("../assets/paytm_icon.svg");
export const crediCardIcon = require("../assets/credit_card_icon.svg");
export const payPalIcon = require("../assets/paypal_icon.svg");
export const download = require("../assets/download.png");
export const countryCodes = require("../assets/countryCodes.json");
export const profileLancer = require("../assets/profilelancer.png");
