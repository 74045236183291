import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import { getStorageData } from "../../../framework/src/Utilities";

interface IFile {
  name: string;
  size: string;
}

interface IMessage {
  id: number;
  msgStatus: string;
  message: string;
}

interface Milestone {
  id: string;
  type: string;
  attributes: {
    id: number;
    milestone_title: string;
    due_date: number;
    deposite_amount: string;
    milestone_status: string;
    project_id: number;
    milestone_description: string;
    progress_percentage: number;
    created_at: string;
    updated_at: string;
    documents: IFile[];
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAddnewMilestoneBtnClicked: boolean;
  currentUser: IProfile | null;
  authToken: string;
  addNewMilestoneTitle: string;
  addNewMilestoneDescription: string;
  addNewMilestoneDueDate: string;
  addNewMilestoneAmount: string;
  projectCategoryId: null | number;
  mileStonetemplates: any;
  isEditButtonClicked: boolean;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CreateNewMilestoneController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllTemplateMilestonesId: string = "";
  createNewMilestoneId: string = "";
  revisionMilestoneId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.LoginUserMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isAddnewMilestoneBtnClicked: false,
      currentUser: null,
      authToken: "",
      addNewMilestoneTitle: "",
      addNewMilestoneDescription: "",
      addNewMilestoneDueDate: "",
      addNewMilestoneAmount: "",
      projectCategoryId: null,
      mileStonetemplates: [],
      isEditButtonClicked: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.setState({ currentUser: user });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIds = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responsesJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responsesJson && !responsesJson.errors) {
        if (apiRequestCallIds === this.getAllTemplateMilestonesId) {
          this.setState({ mileStonetemplates: responsesJson.data });
        }
      } else if (responsesJson?.errors) {
        alert(responsesJson.errors)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const token = await getStorageData("loginSuccessToken");
    this.setState({ authToken: token });
    const projectCategoryId = await getStorageData("projectCategoryId");
    this.setState({ projectCategoryId: projectCategoryId }, () => {
      this.getAllTemplateMilestones();
    });
  }

  // Tabs handler

  handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState({ ...this.state, [name]: value });
  };

  addNewMilestone = () => {
    this.setState({
      isAddnewMilestoneBtnClicked: !this.state.isAddnewMilestoneBtnClicked,
    });
  };

  getAllTemplateMilestones = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };
    const getAllTemplateMileStonesRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllTemplateMilestonesId = getAllTemplateMileStonesRequest.messageId;

    getAllTemplateMileStonesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTemplateMilestones}=${this.state.projectCategoryId}`
    );
    getAllTemplateMileStonesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getAllTemplateMileStonesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(
      getAllTemplateMileStonesRequest.id,
      getAllTemplateMileStonesRequest
    );
  };

  createNewMileStone = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const httpBody = {
      milestone_title: this.state.addNewMilestoneTitle,
      milestone_description: this.state.addNewMilestoneDescription,
      due_date: this.state.addNewMilestoneDueDate,
      deposite_amount: this.state.addNewMilestoneAmount,
    };
    const createMilestoneRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewMilestoneId = createMilestoneRequest.messageId;

    createMilestoneRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewMilestoneEndpoint
    );
    createMilestoneRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    createMilestoneRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    createMilestoneRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(
      createMilestoneRequest.id,
      createMilestoneRequest
    );
  };

  editMilestone = (milestone: any) => {
    this.setState({
      addNewMilestoneTitle: milestone.attributes.milestone_title,
      addNewMilestoneDescription: milestone.attributes.milestone_description,
      addNewMilestoneDueDate: milestone.attributes.days,
      addNewMilestoneAmount: milestone.attributes.cost,
      isAddnewMilestoneBtnClicked: true,
      isEditButtonClicked: true,
    });
  };
  // Customizable Area End
}
