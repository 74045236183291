import React from "react";

// Customizable Area Start
import Profilesetupboth from "./Profilesetupboth.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

import ProfilesetupController, { Props } from "./ProfilesetupController.web";
import FreelancerProfileStudent from "./FreelancerProfileStudent.web";
import FreelancerProfileCorporate from "./FreelancerProfileCorporate.web";
import FreelancerProfieIndividual from "./FreelancerProfieIndividual.web";
import Sponser from "./Sponser.web";
import Studentboth from "./Studentboth.web";
import Corporateboth from "./Corporateboth.web";

export default class Profilesetup extends ProfilesetupController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    const userRole=this.state.profileRole;
    const freelancer=userRole.freelancer;
    const sponsor=userRole.sponsor;
    const subRole=userRole.subRole;
    let component: React.ReactElement = <></>;

    switch (true) {
      case (freelancer && sponsor && subRole==="individual"):
        component = <Profilesetupboth navigation={this.props.navigation} id={""} />;
        break;
      case (freelancer && sponsor && subRole==="student"):
        component = <Studentboth navigation={this.props.navigation} id={""} />;
        break;
      case (freelancer && sponsor && subRole==="corporate"):
        component = <Corporateboth navigation={this.props.navigation} id={""} />;
        break;
      case (freelancer && !sponsor && subRole==="individual"):
        component = (
          <FreelancerProfieIndividual navigation={this.props.navigation} id={""} />
        );
        break;
      case (freelancer && !sponsor && subRole==="student"):
        component = <FreelancerProfileStudent navigation={this.props.navigation} id={""} />;
        break;
      case (freelancer && !sponsor && subRole==="corporate"):
        component = (
          <FreelancerProfileCorporate navigation={this.props.navigation} id={""} />
        );
        break;
      case (!freelancer && sponsor):
        component = <Sponser navigation={this.props.navigation} id={""} />;
        break;
      default:
        break;
    }
    return (
      // Customizable Area Start
      <>{component}</>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End