export const profilePhoto = require('../assets/profle_photo.svg')
export const messageIcon = require('../assets/settings_icon.svg')
export const shareIcon = require('../assets/share_icon.svg')
export const wallet = require('../assets/wallet.svg')
export const reciept = require('../assets/receipt.svg')
export const note = require('../assets/note.svg')
export const download = require('../assets/download.svg')
export const shareFile = require('../assets/share_file.svg')
export const calender = require('../assets/calendar.svg')
export const document = require('../assets/document.svg')
export const revisionIcon = require('../assets/revision_icon.svg')
export const profileIcon = require('../assets/profile_img.svg')
export const milestoneCreatedIcon = require('../assets/milestone_icon.svg')
export const messageBlueIcon = require('../assets/message_icon.png')
export const chatIcon = require("../assets/chat.png")
export const deleteFile = require("../assets/deleteFileIcon.png")