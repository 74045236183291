// Customizable Area Start
import React, { useContext } from "react";
import { IProfile } from "./Roles.types";
interface AuthContextType {
  user: IProfile|null;
}
const defaultContext: AuthContextType = {
   user:null
};

export const AuthContext = React.createContext<AuthContextType>(defaultContext);

export function useAuth() {
  return useContext(AuthContext);
}

// Customizable Area End