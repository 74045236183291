import React from "react";
import {
    Box,
    Button,
    Typography,

    // Customizable Area Start
    Grid, TextField, Checkbox, Divider,
    Modal,
    withStyles,
    Theme,
    // Customizable Area End

} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import LinkedinSignin from "../../../components/src/auth/LinkedinSignin.web";
import GoogleSignin from "../../../components/src/auth/GoogleSignin.web";
import FacebookSignin from "../../../components/src/auth/FacebookSignin.web";
import TermsAndServiceModal from "../../../components/src/TermsAndServiceModal.web";
const Linkdin = require("../assets/Linkdin.png")
const FaceBook = require("../assets/Fb.png")
const logo = require("../assets/logo.jpeg")
const contact = require("../assets/Help and Support.png")


const theme = createTheme({
    palette: {
    },
});
const labelStyle = {
    color: '#8083A3',
};
const inputStyle = {
    height: "35px",
    width: "100%",
};
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

// Customizable Area End
import SignUpController, {
    Props,
} from "./SignUpController.web";

export class SignUp extends SignUpController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const {signupLoading,errors,termsAndService,facebookSignupLoading,visiblePassword}=this.state;
        const{classes}=this.props;
        return (
            // Customizable Area Start
            <>
             <div style={{ paddingBottom: "35px" }}>
                <Box tabIndex={-1}  display='flex' justifyContent='end' style={{marginTop:'6px',marginRight:'10px'}}>
                        <img style={{height:'5vh'}} src={contact} alt="" />
                        <a style={{marginTop:'5px',marginLeft:'5px' , color:'#8083A3', textDecoration:'none'}}
                            href="#">Help and Support
                        </a>
                    </Box>
                    <Grid>
                        <Box tabIndex={-1} py={4} style={{ textAlign: "center" }}>
                             <img style={webStyle.logo_img} src={logo} alt="" />
                        </Box>
                        <Box tabIndex={-1}
                            boxShadow={1}
                            display="flex"
                            maxWidth={450}
                            maxHeight={880}
                            borderRadius="18px"
                            border=" 1px solid #E4E6E8"
                            flexDirection="column"
                            justifyContent="center"
                            margin="auto">
                            <Typography align="center" 
                            style={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "20px",
                                paddingTop: "20px",
                                paddingBottom: "20px"
                            }}>Sign Up</Typography>  
                            <Divider />
                            <Box paddingX={3}>
                                <Grid container direction="column" >
                                    <Grid tabIndex={-1} style={webStyle.facebookLinkedin_mainbox}>
                                        <FacebookSignin
                                            submitHandler={this.handleFacebookResponse}
                                            facebookLoginLoading={facebookSignupLoading}
                                            buttonText="Sign up with Facebook"
                                        />
                                     </Grid>
                                    <Grid tabIndex={-1} style={webStyle.linkedin_mainbox} >
                                        <LinkedinSignin
                                                errorHandler={this.handleLinkedinLoginError}
                                                submitHandler={this.handleLinkedinLoginResponse}
                                                buttonText="Sign up with Linkedin"
                                                loading={this.state.linkedinSignupLoading}
                                            />
                                    </Grid> 
                                    <Grid tabIndex={-1} style={webStyle.google_mainbox} >
                                        <GoogleSignin
                                                errorHandler={this.onGoogleSignupError}
                                                submitHandler={this.onGoogleSignupSuccess}
                                                buttonText="Sign up with Google"
                                                loading={this.state.googleSignupLoading}
                                            />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                            <Box
                                margin="auto"
                                paddingX={3}
                            >
                                <TextField
                                    InputProps={{
                                        style: inputStyle,
                                    }}
                                    autoFocus={true}
                                    tabIndex = {0}
                                    data-test-id="txtInputEmail"
                                    fullWidth
                                    value={this.state.email}
                                    onChange={(e: any) => {
                                        this.setState({ email: e.target.value,emailblank:false,Email_invalid:'' })
                                    }}
                                    type="email"
                                    margin="normal"
                                    id="email"
                                    label="Email"
                                    placeholder="Enter your email"
                                    name="email"
                                    InputLabelProps={{ style: labelStyle }}
                                    helperText={errors?.account}
                                    error={errors?.account?true:false}
                                >
                                </TextField>
                                {this.state.emailblank && <p>Please enter your email</p>} 
                                {this.state.Email_invalid }

                                <TextField
                                    InputProps={{
                                        style: inputStyle,
                                        endAdornment:(
                                            <InputAdornment position="end" style={{marginBottom:"10px"}}>
                                              <IconButton
                                              tabIndex={-1}
                                                data-testid="password-visible"
                                                onClick={this.handleChangePasswordVisibility}
                                                style={{paddingBottom:"10px"}}
                                              >
                                                {visiblePassword?.password ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    tabIndex = {1}
                                    fullWidth
                                    value={this.state.password}
                                    onChange={this.handlePasswordChange}
                                    data-test-id="txtInputPassword"
                                    size="small"
                                    margin="normal"
                                    id="password"
                                    type={visiblePassword.password?"text":"password"}
                                    label="Password"
                                    placeholder="Enter your password"
                                    name="password"
                                    helperText={errors?.password}
                                    error={errors?.password?true:false}
                                    InputLabelProps={{ style: labelStyle }}
                                >
                                </TextField>
                                 <TextField
                                     InputProps={{
                                        style: inputStyle,
                                        endAdornment:(
                                            <InputAdornment position="end">
                                              <IconButton
                                              tabIndex={-1}
                                                data-testid="confirm-password-visible"
                                                onClick={this.handleChangeConfirmPasswordVisibility}
                                                style={{paddingBottom:"22px"}}
                                              >
                                                {visiblePassword?.confirmPassword ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    tabIndex = {2}
                                    data-test-id="txtInputConfirmPasswor"
                                    fullWidth
                                    value={this.state.c_password}
                                    onChange={(e: any) => {
                                        this.setState({ c_password: e.target.value,confirmpassworderror:false,errorMsg:'' })
                                    }}
                                    size="small"
                                    margin="normal"
                                    type={visiblePassword.confirmPassword?"text":"password"}
                                    label="Confirm Password"
                                    placeholder="Confirm your password"
                                    name="password"
                                    helperText={errors?.password_confirmation}
                                    error={errors?.password_confirmation?true:false}
                                    InputLabelProps={{ style: labelStyle }}
                                ></TextField>
                                <Box display="flex">
                                    <div tabIndex={3}>
                                    <Checkbox  
                                     data-testid="termsandConditions" 
                                     checked={termsAndService}
                                      onChange={(e)=>this.handleTermsAndConditionChange(e.target.checked)} 
                                      style={webStyle.check_box} {...label} size="small"  color="default" 
                                      />
                                    </div>
                                    <Typography tabIndex={-1} style={webStyle.terms_condition}>By signing up, you agree to the
                                        <span style={{ color: "#ec952d",cursor:'pointer' }}
                                        onClick={ ()=>this.handleCallModal("Terms of Service" )
                                        }
                                        > Terms of Service, </span>
                                         <span style={{ color: "#ec952d",cursor:'pointer' }}
                                        onClick={()=>this.handleCallModal("User Agreement")}
                                        > User Agreement</span>
                                        , including Cookie Use and Receiving notifications
                                    </Typography>
                                </Box>
                                {
                                    errors?.terms && <Typography 
                                    component={"p"}
                                    style={{color:"#f44336",fontSize:"0.75rem"}}>{errors?.terms}</Typography>
                                }
                                <div tabIndex = {4}>
                                <Button 
                                    data-testid="signup"
                                style={{
                                    borderRadius: "20px",
                                    marginBottom: "30px",
                                    marginTop: "20px",
                                    color: "white",
                                    padding: "0px",
                                    height: "38px",
                                    width: "100%",
                                   fontWeight:"bold",
                                    textTransform:"none",
                                    overflow:'hidden',
                                    backgroundColor: "#ec952d"
                                }}
                                    fullWidth
                                    id="btn"
                                    type="submit"
                                    variant="contained"
                                    disableFocusRipple
                                    onClick={this.handleValidate}
                                >
                                       {signupLoading?<CircularProgress size={24} />:"Create Account"}
                                   </Button>
                                   </div>
                                </Box>
                            <Box justifyContent="center" alignItems="center" style={webStyle.p1_box}>
                                <Typography tabIndex={-1} style={{
                                    marginTop: "21px",
                                    textAlign:"center",
                                    color: "#8083A3",
                                    fontSize: "14px",
                                    fontWeight: "normal"
                                }}>
                                    Already have an account?
                                    <span
                                        data-test-id="signInbutton"
                                        style={{
                                            fontWeight: 'bold',
                                            marginLeft: '5px',
                                            cursor: "pointer",
                                        }}
                                        onClick={this.SignInonclick}
                                    >Sign In
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                 </div>
                 {/* -------------------------------------------- modal verify email ------------------------------------------------------------- */}
                 <TermsAndServiceModal
                    open={this.state.openTermsAndServiceModal}
                    description={this.state.termsOfServiceDesc}
                    loading={this.state.modalLoading}
                    setOpen={this.handleCloseTermsAndServiceModal}
                    title={this.state.termsOfServiceTitle}
                />
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    p_color: {
        color: "#EC952D"
    },

    logo_img: {
        height: "35px",
        width: "145.15px",
    },

    facebookLinkedin_mainbox: {
        paddingBottom: "10px",
        paddingTop: "30px"
    },
    facebookLinkedin_subbox: {
        height: "38px",
        borderRadius: "23px",
        border: "1px solid #E4E6E8",
        padding: "6px",
        justifyContent: "space-around"
    },
    facebook_img: {
        marginLeft: "-20px",
        marginTop: "5px",
        height: "16px",
        paddingTop: "4px"
    },
    linkedin_img: {
        marginLeft: "-20px",
        marginTop: "7px",
        height: "14px",
        paddingTop: "5px"
    },

    linkedin_mainbox: {
        paddingBottom: "10px"
    }, 
    google_mainbox: {
        paddingBottom: "30px"
    },
    check_box: {
        height: '20px',
        padding: '0',
        margin: '14px 7px 0 0',
        overflow:'hidden'
    },

    terms_condition: {
        marginTop: "15px",
        marginBottom: "0px",
        color: "#646464",
        fontSize: "12px"
    },
    
    p1_box: {
        cursor: "hover",
        height: "60px",
        backgroundColor: " #F5F5FA",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
    },
};

export const styles = (theme: Theme) => ({
        modalStyle: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: '400px',
        border: 'none',
        focus: 'none',
        borderRadius: '20px',
        paddingTop: '2px',
        paddingLeft: '4px',
        paddingRight: '4px',
        paddingBottom: '3px',
        backgroundColor: '#ffffff',
        [theme.breakpoints.down('xs')]: {
                    width: '80%',
                    
                }
      },
      modalBox:{ 
        padding: '1em',
         marginTop: '20px', 
         display: 'flex',
          alignItems: 'center', 
          justifyContent: 'center', 
          flexDirection: 'column' as const, 
        },
      modalTextTitle: {
        color: '#171725',
        fontFamily: "inter",
        fontSize: '26px',
        fontWeight: 600,
        lineHeight: '24px',
    
      },
      modalTextSubTitle: {
        color: '#92929d',
        fontFamily: "inter",
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        marginTop: '20px'
      },
      modalIconButtonStyle:{ 
        marginTop: '-260px',
         marginLeft: '440px',
         [theme.breakpoints.down('xs')]: {
            marginTop: '-370px',
            marginLeft: '300px',
          }
        },
      modalIconButtonBoxStyle:{ 
        paddingTop: '2px',
       alignText: 'center',
        borderRadius: '50px',
         color: '#fff',
          backgroundColor: '#EC952D', 
          width: '24px',
           height: '24px'
         },

});

export default withStyles(styles)(SignUp);
// Customizable Area End



