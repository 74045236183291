Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.paymentMethodEndpoint = "stripe_payments/payment_methods";
exports.paymentEndpoint = "stripe_payments/payments";
exports.confirmPaymentEndpoint = "stripe_payments/payments/confirm";
exports.getPaymentMethodsMethod = "GET";
exports.createPaymentMethodMethod = "POST";
exports.createPaymentMethod = "POST";
exports.confirmPaymentMethod = "POST";

exports.orderId = "Order ID";
exports.loading = "Loading...";
exports.noPaymentMethods = "No payment methods found.";
exports.loginToSeePaymentMethods = "Please login to see payment methods.";
exports.addPaymentMethod = "Add new payment method";
exports.completePayment = "Complete Payment";
exports.cardNumberLabel = "Card Number";
exports.cardNumberPlaceholder = "Enter your credit card number";
exports.expiryDateLabel = "Expiry Date";
exports.expiryDatePlaceholder = "MM / YYYY";
exports.cvcLabel = "CVC";
exports.cvcPlaceholder = "XXX";
exports.cancelText = "Cancel";
exports.submitText = "Submit";


exports.stripePayment = "Stripe Payment";
exports.successMessage = "Your payment was successful!"
exports.ok = "OK";
exports.stripePayments = "Stripe Integration";

exports.headerTxt="My Earnings";
exports.backTxt="Back";
exports.withdrawalBtnTxt="Withdraw Available Funds"
exports.walletTitle1= "Available Funds"
exports.walletTitle2= "Pending Funds"
exports.walletTitle3= "In Progress"
exports.walletTitle4= "In Review"
exports.paymentTxtTitle="Payment History"
exports.cardBoxTitleTxt1="Date"
exports.cardBoxTitleTxt2="Details"
exports.cardBoxTitleTxt3="Amount"
exports.cardBoxTitleTxt4="Status"
exports.showMoreBtn="Show More"
exports.headerWithdrawalTxt="Select Payment Method"
exports.savedBankTxt="Saved Bank Accounts"
exports.newBankAccTxt="New Bank Account"
exports.saveCardBank="SBI"
exports.SavedCardNo="2029****8878"
exports.paymentAmtLabel="Enter Amount"
exports.paymentAmtPlaceholder="Total Amount :"
exports.btnWithdraw="Withdraw Securely"
exports.taxInfoStart=`You'll be charged up to a`
exports.taxInfoCenter="5% marketplace fee."
exports.taxInfoEnd=` Taxes may also apply.`
exports.verificationSuccessTitle = "ID Verification Successful!"
exports.withdrawalSuccessTitle = "Fund Withdrawal Successful!"
exports.withdrawalFailTitle = "Fund Withdrawal Failed"
exports.verificationTxt="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
exports.goToEarningsBtn = "Go to My Earnings"
exports.retryWithdrawalBtn = "Retry Withdrawal"
exports.proceedWithdrawalBtn = "Proceed to Withdrawal"
exports.accountNumber = "Account Number"
exports.verifyAccountNumber = "Verify Account Number"
exports.bankName = "Bank"
exports.ifscCode = "IFSC"
exports.accountNumberPlaceholder = "Enter your account number"
exports.verifyAccountNumberPlaceholder = "Re-enter your Account Number"
exports.bankNamePlaceholder = "Select your bank"
exports.ifscCodePlaceholder = "Enter IFSC code"
exports.cancelBtnLabel = "Cancel"
exports.submitBtnLabel = "Submit"
exports.totalAmtTxt="Total Amount:"
exports.dollar="$"
exports.verificationHeader="Identity Verification"
exports.guidelineOne="Make sure photo is not blurry"
exports.guidelineTwo="Include border area around your ID and all four corners should be visible"
exports.guidelineThree="The image should not be manipulated in any way"
exports.uploadAnotherIdBtn="Upload Different ID"
exports.uploadYourIdTxt="Upload Your ID"

// Customizable Area End
