import React, { Component } from "react";
// Customizable Area Start

// Customizable Area End


export default class MyBidsLancer extends Component{
  render() {
    return (
      <></>
    );
  }
}



















