import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BasicDetailsCustomizable from "./BasicDetailsCustomizable.web";
import { PersonalInfoPageStyles } from "./PersonalInfoPage.web";
import { HourlyRatePageStyles } from "./HourlyRatePage.web";
import { AccountDetailsStyle } from "./AccountDetails.web";
import { FreelancerCorporateStyles } from "./FreelancerCorporateDetails.web";

import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import {
  hourlyRateIcon,
  personalDetailsIcon,
  basicDetailsIcon,
  activeBasicInfoIcon,
  activePersonalInfoIcon,
  activeHoursIcon,
  activeAccountDetailsIcon,
  accountDetailsIcon,
} from "./assets";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

let routeItemsArray = [
  {
    img: basicDetailsIcon,
    name: "Basic Info",
    route: "basicInfo",
    activeImg: activeBasicInfoIcon,
  },
  {
    img: personalDetailsIcon,
    name: "Personal Details",
    route: "personalDetails",
    activeImg: activePersonalInfoIcon,
  },
  {
    img: hourlyRateIcon,
    name: "Hourly Rate",
    route: "hourlyRate",
    activeImg: activeHoursIcon,
  },
  {
    img: accountDetailsIcon,
    name: "Account Details",
    route: "accountDetails",
    activeImg: activeAccountDetailsIcon,
  },
];
// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
} from "./Customisableuserprofiles2Controller";

export default class Customisableuserprofiles2 extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetailsViewComponent() {
    const componentMapping: Record<string, JSX.Element> = {
      basicInfo: <BasicDetailsCustomizable typeOfRole="Freelancer" />,
      personalDetails: this.getProfileRole.toLowerCase().includes("corporate")?  <FreelancerCorporateStyles/> : <PersonalInfoPageStyles />,
      hourlyRate: <HourlyRatePageStyles />,
      accountDetails: <AccountDetailsStyle />,
    };

    const { renderDetailsView } = this.state;
    if (componentMapping.hasOwnProperty(renderDetailsView)) {
      return componentMapping[renderDetailsView];
    }

    return null;
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <FreelancerHeader navigation={this.props.navigation} history={undefined} location={undefined} />
        <Container maxWidth="lg">
          <Typography variant="h5" className={classes.myProfileHeading}>
            {configJSON.myProfileTxt}
          </Typography>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={4}
              className={classes.sideRoutingBlock}
            >
              <Box>
                {routeItemsArray.map((item) => {
                  return (
                    <Box
                      className={classes.sideNavigationItem}
                      key={item.name}
                      onClick={() =>
                        this.navigateToSpecificDetailsPage(item.route)
                      }
                      data-testid={`navigateToSpecificPage-${item.route}`}
                    >
                      <Box className={classes.singleRouteBlock}>
                        <img
                          src={
                            this.state.renderDetailsView === item.route
                              ? item.activeImg
                              : item.img
                          }
                          alt={item.name}
                          className={classes.routeIcons}
                        />
                        <Typography
                          variant="h6"
                          className={
                            this.state.renderDetailsView === item.route
                              ? `${classes.textLabelLink} ${classes.activeSpecificBlock}`
                              : classes.textLabelLink
                          }
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <ChevronRightIcon
                        className={
                          this.state.renderDetailsView === item.route &&
                           classes.activeSpecificBlock
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              {this.renderDetailsViewComponent()}
            </Grid>
          </Grid>
        </Container>
        <DashboardFooter navigation={undefined} history={undefined} location={undefined} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle: any = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  singleRouteBlock: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center"
    
  },
  routeIcons: {
    margin: "0 10px 0",
    width: "20px",
    height: "20px"
  },
  inputRoundedEl: {
    ".MuiOutlinedInput-root": {
      borderRadius: "30px",
    },
  },
  customSelectEl: {
    ".MuiSelect-root": {
      height: "35px",
      paddingTop: "2px",
      paddingBottom: "2px",
      outline: "none",
    },
  },
  MuiInputBaseRootEl: {
    height: "30px",
  },
  navlinks: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "12px",
    color: "#8083a3",
    textDecoration: "none",
    margin: "5px",
  },
  convertSponsorTexts: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "10px",
    textAlign: "center",
    color: "#222222",
  },
  switchToSponsorStyle: {
    border: "1px solid #00a7a2",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "68px",
    height: "55px",
    margin: "10px",
  },
  switchToSponsorStyleIcon: {
    height: "20px",
    width: "20px",
  },
  userEmail: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#8083a3",
  },
  navbarIcons: {
    height: "22px",
    width: "22px",
  },
  myProfileHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "28px",
    color: "#222222",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  textLabelLink: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    color: "#222222",
    fontFamily: "Inter",
  },
  sideRoutingBlock: {
    padding: "20px",
    background: "rgba(245, 245, 250, 0.698039)",
    border: "1px solid #e4e6e8",
    borderRadius: "14px",
    height: "13rem",
    marginTop: "10",
    boxShadow: "inset 0px 0px 28px -3px rgba(0,0,0,0.1)",
  },
  errorText: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "12px",
    color: "#d90000",
  },
  sideNavigationItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 5px",
    cursor: "pointer",
  },
  activeSpecificBlock: {
    color: "#EC952D",
  },
};
export const Customisableuserprofiles2Styles = withStyles(webStyle)(
  Customisableuserprofiles2
);
// Customizable Area End
