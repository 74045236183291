import React from "react";

import ReactFacebookLogin from "react-facebook-login";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FaceBookImg } from "../../../blocks/social-media-account-registration/src/assets";

interface Props {
  buttonText?: string;
  submitHandler: any;
  facebookLoginLoading: boolean;
}

const FacebookSignin: React.FC<Props> = ({
  buttonText = "Sign up with facebook",
  submitHandler,
  facebookLoginLoading,
}) => {
  return (
    <ReactFacebookLogin
      containerStyle={{
        position: "relative",
        height: "30px",
        marginBottom: "40px",
      }}
      buttonStyle={{
        width:"100%",
        background:"white",
        borderRadius:"25px",
        position:"relative",
        border:"1px solid rgb(228, 230, 232)",
        color:"rgb(128, 131, 163)",
        textTransform:"none",
        fontSize:"14px",
        fontFamily:"inter"
    }}
      appId="270612552597660"
      autoLoad={false}
      fields="name,email,picture"
      icon={
        <>
          <img
            style={{ position: "absolute", top: "17px", left: "26px" }}
            src={FaceBookImg}
          />
          {facebookLoginLoading && (
            <div style={{ position: "absolute", top: "15px", right: "23px" }}>
              <CircularProgress size={24} />
            </div>
          )}
        </>
      }
      textButton={buttonText}
      callback={submitHandler}
    />
  );
};

export default FacebookSignin;
