import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?: any;
  typeOfRole?: string;
  classes?: any;
}
export interface S {
  // Customizable Area Start
  cardData: any;
  redirect: any;
  renderDetailsView: string;
  response: boolean;
  projectCards: any[];
  visibleCards: number;
  activeTab: number;
  filterActiveTab: number;
  projectLoading: boolean; // Add the projectLoading flag
  totalCards: number;
  searchQuery: string;
  availableNow: boolean;
  favourite: boolean;
  message: boolean;
  hire: boolean;
  invite: boolean;
  filter: boolean;
  back: boolean;
  pageNumber: number;
  authtoken: any;
  error: any;
  tabLables: {
    tabFilterLabels: string[];
    tabCardsLabels: string[];
  };
  filterTabValue: string;
  filterOptions: any;
  projectBidSelectedOption: {
    categoriesOptions: any[];
    specialitiesOptions: any[];
    languageOptions: any[];
    skillsOptions: any[];
    locationOptions: any[];
    experienceOptions: string;
  };
  displayFilterSortCard: boolean;

  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class SearchBidController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  bidProjectListCallID: any;
  bidProjectListFilterID: any;
  bidProjectListMessageCallID: any;
  bidProjectSearchListCallID: any;
  projectFilterListCallID: any;
  bidProjectFilterCategoryListCallID: any;
  bidProjectSkillsCategoryListCallID: any;
  bidProjectLanguageCategoryListCallID: any;
  bidProjectSpecialitiesCategoryListCallID: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      // Customizable Area Start
      renderDetailsView: "browseAllProject",
      response: false,
      redirect: null,
      cardData: "",
      projectCards: [],
      visibleCards: 1,
      activeTab: 0,
      filterActiveTab: 0,
      projectLoading: true,
      totalCards: 4,
      searchQuery: "",
      availableNow: false,
      favourite: false,
      message: false,
      hire: false,
      invite: false,
      filter: false,
      back: false,
      pageNumber: 1,
      authtoken: window.localStorage.getItem("loginSuccessToken"),
      error: "",
      displayFilterSortCard: false,
      tabLables: {
        tabFilterLabels: [
          "Newest First",
          "Oldest First",
          "Highest Budget First",
          "Lowest Budget First",
          "Most Bids First",
          "Least Bids First",
        ],
        tabCardsLabels: [
          "All",
          "Bid open Projects",
          "Fixed Price Projects",
          "Non-Bid Projects",
        ],
      },
      filterTabValue: "",
      filterOptions: {
        categoriesOptions: [],
        specialitiesOptions: [
          "specialitiesOptions-1",
          "specialitiesOptions-2",
          "specialitiesOptions-3",
        ],
        languageOptions: [
          "langulageOptions-1",
          "langulageOptions-2",
          "langulageOptions-3",
        ],
        skillsOptions: [],
        locationOptions: ["India", "USA", "Europe"],
        __experienceOptions: ["Begginer", "Intemediate", "Expert"],
      },
      projectBidSelectedOption: {
        categoriesOptions: [],
        specialitiesOptions: [],
        languageOptions: [],
        skillsOptions: [],
        locationOptions: [],
        experienceOptions: "",
      },

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    this.projectBidListAPI(this.state.pageNumber);

    // Customizable Area End
  }
  handleResponseWithoutErrors(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.bidProjectListCallID:
        this.handleProjectListResponse(responseJson);
        break;
      case this.bidProjectSearchListCallID:
        this.handleProjectListSearchResponse(responseJson);
        break;
      case this.bidProjectLanguageCategoryListCallID:
        this.handleProjectLanguageCategoryListResponse(responseJson);
        break;
      case this.bidProjectListFilterID:
        this.handleProjectListFilterResponse(responseJson);
        break;
      case this.bidProjectListMessageCallID:
        this.handleProjectListMessageResponse();
        break;
      case this.bidProjectFilterCategoryListCallID:
        this.handleProjectFilterCategoryListResponse(responseJson);
        break;
      case this.bidProjectSkillsCategoryListCallID:
        this.handleProjectSkillsCategoryListResponse(responseJson);
        break;
      case this.bidProjectSpecialitiesCategoryListCallID:
        this.handleProjectSpecialitiesCategoryListResponse(responseJson);
        break;
    }
  }
  handleProjectListResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        // projectCards: [...responseJson.data],
        projectCards: responseJson.data,
        projectLoading: false,
        response: true,
        totalCards: responseJson?.meta?.total || 0,
      });
    } else {
      this.setState({ projectLoading: false });
    }
  };
  handleProjectListFilterResponse = (responseJson: any) => {
    this.handleProjectListResponse(responseJson);
  };
  handleProjectLanguageCategoryListResponse = (responseJson: any) => {
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        languageOptions: responseJson,
      },
    }));
  };
  handleProjectListMessageResponse = () => {
    this.props.navigation.navigate("SearchSuccessWeb");
    this.setState({
      projectLoading: false,
      response: true,
    });
  };
  handleProjectFilterCategoryListResponse = (responseJson: any) => {
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        categoriesOptions: responseJson,
      },
    }));
  };
  handleProjectSkillsCategoryListResponse = (responseJson: any) => {
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        skillsOptions: responseJson,
      },
    }));
  };
  handleProjectSpecialitiesCategoryListResponse = (responseJson: any) => {
    this.setState((prevState) => ({
      filterOptions: {
        ...prevState.filterOptions,
        specialitiesOptions: responseJson,
      },
    }));
  };
  handleProjectListSearchResponse = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        projectCards: responseJson.data,
        projectLoading: false,
        response: true,
      });
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        this.handleResponseWithoutErrors(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  navigateToSpecificDetailsPage = (view: string) => {
    this.setState({ renderDetailsView: view, pageNumber: 1 }, () => {
      if (view === "browseAllProject") {
        this.projectBidListAPI(this.state.pageNumber);
      }
    });
  };

  projectBidListAPI = (pageNumber: number) => {
    this.setState({
      projectLoading: true,
    });
    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/search_algorithms/freelancer_sorting_projects?page_number=${pageNumber}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getProjectFilterCategoryListAPI = () => {
    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectFilterCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/search_algorithms/fetch_category`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };
  getProjectSkillsCategoryListAPI = () => {
    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectSkillsCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/search_algorithms/fetch_skills`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getProjectSpecialitiesCategoryListAPI = () => {
    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectSpecialitiesCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/search_algorithms/fetch_specialist`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getProjectLanguageCategoryListAPI = () => {
    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectLanguageCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/search_algorithms/fetch_language`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  sendProjectBidFilterApi = (
    pageNumber: number,
    filters: {
      project_category?: string[];
      speciality?: string[];
      skills?: string;
      language?: string[];
      location?: string[];
      experience?: string;
      filterTabValue?: string;
    }
  ) => {
    const {
      project_category,
      speciality,
      skills,
      language,
      location,
      experience,
      filterTabValue,
    } = filters;

    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectListFilterID = getValidationsMsg.messageId;

    let queryParamsBid = [];

    if (project_category !== undefined && project_category.length > 0) {
      queryParamsBid.push(
        `project_category[]=${project_category.join("&project_category[]=")}`
      );
    }
    if (speciality !== undefined && speciality.length > 0) {
      queryParamsBid.push(`speciality[]=${speciality.join("&speciality[]=")}`);
    }
    if (skills !== undefined) {
      queryParamsBid.push(`skills=${skills}`);
    }
    if (language !== undefined && language.length > 0) {
      queryParamsBid.push(
        `language_of_converse[]=${language.join("&language_of_converse[]=")}`
      );
    }
    if (location !== undefined && location.length > 0) {
      queryParamsBid.push(`country[]=${location.join("&country[]=")}`);
    }
    if (experience) {
      queryParamsBid.push(`experience=${experience}`);
    }
    if (filterTabValue !== undefined) {
      if (filterTabValue.toLowerCase() === "newest first") {
        queryParamsBid.push(`order=desc`);
      } else if (filterTabValue.toLowerCase() === "oldest first") {
        queryParamsBid.push(`order=asc`);
      }
    }

    const queryStringBid = queryParamsBid.join("&");

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/search_algorithms/filters_project?${queryStringBid}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  sendProjectSearchListAPI = (searchQuery: string) => {
    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.bidProjectSearchListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/search_algorithms/search_project?search=${searchQuery}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleProjectBidLoadMore = () => {
    const { projectLoading, pageNumber, filter, searchQuery } = this.state;

    if (!projectLoading) {
      this.setState(
        { projectLoading: true, pageNumber: pageNumber + 1 },
        () => {
          this.projectBidListAPI(this.state.pageNumber);
        }
      );
    }
  };
  handleProjectBidSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ searchQuery: event.target.value }, () => {});
  };
  handleProjectBidSearchClick = () => {
    this.sendProjectSearchListAPI(this.state.searchQuery);
    this.setState({ projectCards: [], projectLoading: true }, () => {});
  };
  handleClick = (cardData: any) => {
    this.setState({ redirect: cardData });
  };
  handleProjectBidClearSearch = () => {
    this.setState(
      { searchQuery: "", projectCards: [], projectLoading: true },
      () => {
        this.projectBidListAPI(this.state.pageNumber);
      }
    );
  };
  handleProjectBidTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
    tabId: number
  ) => {
    if (tabId == 0) {
      this.setState(
        {
          filterActiveTab: newValue,
          filterTabValue: this.state.tabLables.tabFilterLabels[newValue],
        },
        () => {}
      );
    }
    if (tabId == 1) {
      this.setState({
        projectLoading: true,
        activeTab: newValue,
        projectCards: [],
      });
      this.projectBidListAPI(this.state.pageNumber);
    }
  };

  handleProjectBidOptionsChange = (
    selectedOptions: React.ChangeEvent<{ value: unknown }>,
    fieldName: string
  ) => {
    this.setState((prevState) => {
      return {
        projectBidSelectedOption: {
          ...prevState.projectBidSelectedOption,
          [fieldName]: selectedOptions.target.value,
        },
      };
    });
  };

  handleProjectApplyClick = () => {
    this.setState({ projectLoading: true, projectCards: [] }, () => {
      this.sendProjectBidFilterApi(this.state.pageNumber, {
        project_category: this.state.projectBidSelectedOption.categoriesOptions,
        speciality: this.state.projectBidSelectedOption.specialitiesOptions,
        skills: this.state.projectBidSelectedOption.skillsOptions[0],
        language: this.state.projectBidSelectedOption.languageOptions,
        location: this.state.projectBidSelectedOption.locationOptions,
        experience: this.state.projectBidSelectedOption.experienceOptions,
        filterTabValue: this.state.filterTabValue,
      });
    });
  };

  handleClearSelectedOptions = () => {
    this.setState({
      projectBidSelectedOption: {
        categoriesOptions: [],
        specialitiesOptions: [],
        languageOptions: [],
        skillsOptions: [],
        locationOptions: [],
        experienceOptions: "",
      },
    });
  };

  handleFilterSortCard = () => {
    this.getProjectFilterCategoryListAPI();
    this.getProjectSkillsCategoryListAPI();
    this.getProjectLanguageCategoryListAPI();
    this.getProjectSpecialitiesCategoryListAPI();
    this.setState({ displayFilterSortCard: !this.state.displayFilterSortCard });
  };
  // Customizable Area End
}
