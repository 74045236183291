import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import FullTeamVisibilityController, {
  Props,
  configJSON,
} from "./FullTeamVisibilityController";
import { Department, Organisation, Team, TeamMember } from "./IFullteamVisibility";

export default class FullTeamVisibility extends FullTeamVisibilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  OrgChart = ({data}:{data:Organisation[]}) => {
    
  
    const renderDep = (department:Department) => {
      const { id, name, team } = department.attributes;
      return (
        <div key={id}>
          <h2>Department: {name}</h2>
          {team.data.map((team) => renderTeam(team))}
        </div>
      );
    };
  
    const renderTeam = (team:Team) => {
      const { id, name, team_member } = team.attributes;
      return (
        <div key={id} style={{margin:8}}>
          <h3>Team: {name}</h3>
          {team_member.map((member:TeamMember) => renderMember(member))}
        </div>
      );
    };
  
    const renderMember = (member:TeamMember) => {
      const { id, role_type ,first_name , last_name } = member;
      return (
        <div key={id} style={{margin:16,padding:8,border:'1px solid black'}}>
          <p>Name: {first_name + '' + last_name}</p>
          <p>Role: {role_type}</p>
        </div>
      );
    };
  
    return (
      <div style={{textAlign:'left',width:'90%'}}>{data.length !== 0 && data[0].attributes.department.data.map((department:Department) => renderDep(department))}</div>
    );
  };
  

  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container style={{ width: '70%'}}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h3">Organisation Name</Typography>
            <Typography variant="h4">Member List</Typography>
            <Box sx={{width:'100%'}}>
              <Typography variant="h4">Filters</Typography>
              <div style={{display:"flex",gap:'10px'}}>
              {this.state.teamList && 
              <div>
              <label>
                Team:
                <select value={
                  this.state.filterData.team_name
                } onChange={(event)=>this.handleValueChange('team',event.target.value)}>

                  <option value={undefined}>All</option>
                  {this.state.teamList.map((item)=><option key={item} value={item}>{item}</option>)}
                </select>
              </label>
            </div>}
              {this.state.roleList && 
              <div>
              <label>
                Role:
                <select value={
                  this.state.filterData.role_type
                } onChange={(event)=>this.handleValueChange('role',event.target.value)}>

                  <option value={undefined}>All</option>
                  {this.state.roleList.map((item)=><option key={item} value={item}>{item}</option>)}
                </select>
              </label>
            </div>}
              {this.state.regionList && 
              <div>
              <label>
                Region:
                <select value={
                  this.state.filterData.region
                } onChange={(event)=>this.handleValueChange('region',event.target.value)}>

                  <option value={undefined}>All</option>
                  {this.state.regionList.map((item)=><option key={item} value={item}>{item}</option>)}
                </select>
              </label>
            </div>}
             
              <Button style={webStyle.applyBtn}
              data-test-id="applyBtn"
              onClick={()=>
                this.filter(this.state.filterData)
              }>Apply</Button>
              </div>
            </Box>
            <TableContainer component={Paper}>
              <Table style={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Region</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Team</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(this.state.newList.length !== 0?this.state.newList:this.state.memberList).map((teamMember:TeamMember) => (
                    <TableRow key={teamMember.id}>
                      <TableCell component="th" scope="row">
                        {teamMember.first_name +''+ teamMember.last_name}
                      </TableCell>
                      <TableCell align="center">{teamMember.region}</TableCell>
                      <TableCell align="center">{teamMember.role_type}</TableCell>
                      <TableCell align="center">{teamMember.team_name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
                <Button style={webStyle.buttonStyle} data-test-id = 'fullTeamBtn'
                onClick = {this.toggleOrgChart}>
                  {!this.state.showOrgChart?'Show Full Team':'Hide FUll Time'}
                </Button>
          </Box>
          {this.state.showOrgChart && <this.OrgChart data={this.state.orgData}/>}
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    height: "45px",
    width:'250px',
    margin: "20px 10px",
    backgroundColor: "rgb(98, 0, 238)",
    color: '#fff'
  },
  applyBtn:{
    height:'23px',
    backgroundColor: "rgb(98, 0, 238)",
    color: '#fff'
  }
};
// Customizable Area End