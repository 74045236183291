import React from "react";
// Customizable Area Start
import "../assets/CSS/index.css";
import Sponsorlistingcontroller, {
    Props
} from "./Sponsorlistingcontroller.web";
import ProjectDisplay from "../../../components/src/ProjectDisplay";
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";
// Customizable Area End


export default class Activeproject extends Sponsorlistingcontroller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    
    render() {
        // Customizable Area Start
        return (
            // Customizable Area Start
            <div data-id="abc">
                <ProjectFilterWeb
                   onSearch={this.searchProjectHandler}
                   onCancelFilter={this.cancelFilterProjectHandler}
                   data-testid="active_search"
                   onFilter={this.filterProjectHandler}
                   activeTabColor="#00A7A2"
                />
                <ProjectDisplay
                    data-testid= "paginationTestID"
                    loading={this.state.loading}
                    projectList={this.state.projectdata}
                    tabDataList={this.state.chipNames}
                    isShowMore={this.state.totalProjectsCount > this.state.projectdata?.length}
                    loadMore={this.handleProjectLoadMore}
                    navigate={this.props.navigation?.navigate}
                    onTabChange={this.handleChipClick}
                    type = {this.props.name}
                    shareProject={this.handleShareProject}  
                    onEdit={this.getSingleProjectInfoData}
                    copyURL={this.handleCopyClick}     
                />

            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area End
