import React from "react";

import {
  // Customizable Area Start
  Container,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Button
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  AddCircleOutline as AddCircleOutlineIcon
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { BlockComponent } from "../../../framework/src/BlockComponent";

const languageLevelJson = [
  {
    id: 1,
    title: "Beginner",
    value: "beginner"
  },
  {
    id: 2,
    title: "Intermediate",
    value: "intermediate"
  },
  {
    id: 3,
    title: "Proficient",
    value: "proficient"
  }
];

interface IProps {
  onComponentChange: (view: string) => void;
  onComponentBackChange: (view:string) => void;
  projectSkills: Skill[];
  filterProjectData:Skill[];
  handleSelectSkills: (
    event: React.ChangeEvent<{}>,
    value: ISearchValue[],
    selectedSkill:any,
  ) => void;
  selectedSkills: ISearchValue[];
  onChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => void;
  languageItems: Language[];
  language: string;
  languageLevel: string;
  formErrors: IFormErrors;
  deleteSelectedSkill: (id: string) => void;
  addOtherSkills : (skill:any) => void
  otherSkills:ISearchValue[]
}

interface S {}

interface SS {
  id
  : any;
}

// Customizable Area End

import {
  IFormErrors,
  ISearchValue,
  Language,
  Skill,
  configJSON,
} from "./ProjecttemplatesController";

export default class ProjectSkillsDetailsPage extends BlockComponent<
  IProps,
  SS,
  S
> {
  constructor(props: IProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={"lg"} style={webStyle.mainContainer}>
        <FormControl fullWidth style={webStyle.formControlStyle}>
          <FormHelperText style={webStyle.labelStyle} component="p">
            {configJSON.addSkillsLabelTxt}
          </FormHelperText>
          
          <Autocomplete
            multiple
            disableClearable={true}
            options={this.props.projectSkills.map((item) => ({
              id: item.id,
              name: item.attributes.name,
              label: item.attributes.name,
            })).filter((e: any)=>!this.props.selectedSkills.map((selectedSkill: any)=>selectedSkill.name).includes(e.name))}
            id={`project_input_selectedSkills`}
            getOptionLabel={(skill) => skill.name}
            getOptionSelected={(option, value) =>
              option.id === value.id && option.name === value.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                id={`project_input_selectedSkills`}
                placeholder={configJSON.searchSkillsPlaceholderTxt}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={webStyle.searchIconStyle} />
                    </InputAdornment>
                  ),
                  style: {
                    ...webStyle.placeholderStyle,
                  },
                }}
              />
            )}
            value={this.props.selectedSkills}
            style={webStyle.searchFieldStyle}
            onChange={(event, value) =>{
              const selectedSkill = value[value.length-1]
              this.props.handleSelectSkills(event, value,selectedSkill)
        }}
          />
          {this.props.formErrors && this.props.formErrors["selectedSkills"] && (
            <Typography style={webStyle.errorStyle}>
              {this.props.formErrors["selectedSkills"]}
            </Typography>
          )}
        </FormControl>
        <Box>
          <Typography style={webStyle.subHeadingStyle}>
            {configJSON.selectedSkillsLabelTxt}
          </Typography>
          <Box style={{...webStyle.skillsConatinerStyle,flexWrap:"wrap"}}>
            {this.props.selectedSkills.map((skill) => {
              return (
                <Typography style={webStyle.singleSkillStyle} key={skill.id}>
                  {skill.name}
                  <ClearIcon
                    style={webStyle.deleteIconStyle}
                    onClick={this.props.deleteSelectedSkill.bind(this,skill.id)}
                  />
                </Typography>
              );
            })}
          </Box>
        </Box>
        <Box component="hr" />
        <Box>
          {
            this.props.filterProjectData?.length>0 && (
              <Typography style={webStyle.otherSkillsHeadingStyle}>
                 {configJSON.otherSkillsLabelTxt}
              </Typography>
            )
          }
          <Box style={{...webStyle.skillsConatinerStyle,flexWrap:"wrap"}}>
            {this.props.filterProjectData.filter(el1=>!this.props.selectedSkills.map(elif=>elif.name).includes(el1.attributes.name)).map((skill) => {
              return (
                <Typography
                  style={webStyle.singleOtherSkillStyle}
                  key={skill.id}
                  onClick={this.props.addOtherSkills.bind(this,skill.attributes)}
                  data-test-id = 'addOtherSkills'
                >
                  {skill.attributes.name}
                  <AddCircleOutlineIcon style={webStyle.addIconStyle} />
                </Typography>
              );
            })}
          </Box>
          <Box component="hr" />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth style={webStyle.formControlStyle}>
              <FormHelperText style={webStyle.labelStyle} component="p">
                {configJSON.languageLabelTxt}
              </FormHelperText>
              <Select
                value={this.props.language}
                id={`project_input_language`}
                data-testid="renderone"
                data-test-id="txtInput"
                style={webStyle.selectItemValuestyle}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    horizontal: 'left',
                    vertical: 'top',
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      width: 250,
                      maxHeight: 300,
                    },
                  },
                }}
                onChange={(event) =>
                  this.props.onChange(
                    event as React.ChangeEvent<
                      HTMLInputElement | HTMLSelectElement
                    >
                  )
                }
                renderValue={
                  this.props.language !== ""
                    ? undefined
                    : () => (
                        <Typography style={webStyle.placeholderStyle}>
                          {configJSON.languagePlaceholderTxt}
                        </Typography>
                      )
                }
                displayEmpty
                name="language"
              >
                {this.props.languageItems.map((language) => {
                  return (
                    <MenuItem
                      key={language.id}
                      value={language.name}
                      style={webStyle.selectItemValuestyle}
                    >
                      {language.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {this.props.formErrors && this.props.formErrors["language"] && (
                <Typography style={webStyle.errorStyle}>
                  {this.props.formErrors["language"]}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth style={webStyle.formControlStyle}>
              <FormHelperText style={webStyle.labelStyle} component="p">
                {configJSON.languageLevelLabelTxt}
              </FormHelperText>
              <Select
                value={this.props.languageLevel}
                id={`project_input_languageLevel`}
                data-test-id="btnAddExample"
                style={webStyle.selectItemValuestyle}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    horizontal: 'left',
                    vertical: 'top',
                  },
                  getContentAnchorEl: null,
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      width: 250,
                    },
                  },
                }}
                onChange={(event) =>
                  this.props.onChange(
                    event as React.ChangeEvent<
                      HTMLInputElement | HTMLSelectElement
                    >
                  )
                }
                renderValue={
                  this.props.languageLevel !== ""
                    ? undefined
                    : () => (
                        <Typography style={webStyle.placeholderStyle}>
                          {configJSON.languageLevelPlaceholderTxt}
                        </Typography>
                      )
                }
                displayEmpty
                name="languageLevel"
              >
                {languageLevelJson.map((level) => {
                  return (
                    <MenuItem
                      key={level.id}
                      value={level.value}
                      style={webStyle.selectItemValuestyle}
                    >
                      {level.title}
                    </MenuItem>
                  );
                })}
              </Select>
              {this.props.formErrors &&
                this.props.formErrors["languageLevel"] && (
                  <Typography style={webStyle.errorStyle}>
                    {this.props.formErrors["languageLevel"]}
                  </Typography>
                )}
            </FormControl>
          </Grid>
        </Grid>
        <Box style={webStyle.btnContainerStyle}>
          <Button
            id="btn1"
            style={webStyle.backBtnStyle}
            onClick={this.props.onComponentBackChange.bind(this,"title")}
          >
            {configJSON.backBtnTxt}
          </Button>
          <Button
            style={webStyle.continueBtnSyle}
            id="btn2"
            onClick={this.props.onComponentChange.bind(this,"scope")}
          >
            {configJSON.continueBtnTxt}
          </Button>
        </Box>
      </Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    padding: 20
  },
  labelStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700
  },
  formControlStyle: {
    margin: "15px 0px"
  },
  searchFieldStyle: {
    color: "#8083A3",
    fontSize: 14,
    fontFamily: "Inter",
    fontWeight: 400
  },
  skillsConatinerStyle: {
    display: "flex",
    alignItems: "center",
    columnGap: 10,
    margin: "25 0"
  },
  singleSkillStyle: {
    borderRadius: 30,
    background: "#00A7A2",
    padding: "10 20",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: 10,
    fontWeight: 500,
    marginTop: 10,
    alignItems: "center",
    display: "flex"
  },
  deleteIconStyle: {
    width: 14,
    height: 14,
    marginLeft: 10
  },
  subHeadingStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    marginTop: 10
  },
  placeholderStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400
  },
  btnContainerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 40,
    columnGap: 10,
    marginBottom: 20
  },
  backBtnStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35"
  },
  continueBtnSyle: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35"
  },
  otherSkillsHeadingStyle: {
    fontSize: "14px",
    color: "#8083A3",
    fontFamily: "Inter",
    fontWeight: 700,
    marginTop: 30
  },
  singleOtherSkillStyle: {
    borderRadius: 30,
    background: "#F6F7F7",
    padding: "10 20",
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    marginTop: 10,
    alignItems: "center",
    display: "flex"
  },
  addIconStyle: {
    width: 14,
    height: 14,
    color: "#8083A3",
    marginLeft: 15
  },
  searchIconStyle: {
    width: 18,
    height: 18,
    color: "#8083A3"
  },
  errorStyle: {
    paddingTop:10,
    color: "#eb020a",
    fontSize:"14px"
  },
  selectItemValuestyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
};
// Customizable Area End
