import React from "react";

// Customizable Area Start
import "../assets/css/Accountinfo.css";
import { lancerlogo, searchicon } from "./assets";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Formik, Field, Form } from "formik";
import { Button } from "@material-ui/core";
import Select from 'react-select'
// Customizable Area End

// Customizable Area Start

// Customizable Area End

import ProfilesetupController, { Props } from "./ProfilesetupController.web";
import ProfileSetupHeader from "../../../components/src/profile-setup/ProfileSetupHeader.web";
import CorporateForm from "../../../components/src/profile-setup/CorporateForm.web";
import ProfileSetupSteper from "../../../components/src/profile-setup/ProfileSetupSteper.web";

export default class FreelancerProfileCorporate extends ProfilesetupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { company_logo, skill_ids, hobby_ids } = this.state
    return (
      // Customizable Area Start
      <div className="main-wrapper corporate_freelancer">
       <ProfileSetupHeader
          email={this.state.loggedInUserEmail}
          onLogout={this.sendLogoutMessage}
       />

        <div className="site-wrapper corporate_freelancer">
          <div className="setup-account-block corporate_freelancer">
            <div className="inner-block corporate_freelancer">
              <a href="#">
                <img src={lancerlogo} alt="logo" />
              </a>
              <h3>
                Welcome to the Lancerscape Portal. Quickly setup your account to
                get started.
              </h3>
            </div>
            <ProfileSetupSteper
              activeBasicDetails={true}
              activeProfileSetup={true}
            />
          </div>

          <div className="choose-profile-block corporate_freelancer">
            <div className="profile-heading corporate_freelancer">
              <h6>Fill in the details(s) selected by you</h6>
            </div>
            <div className="profile-heading corporate_freelancer">
              <b>Freelancer Profile (Corporate)</b>
            </div>
            <div className="form-block corporate_freelancer">
               <CorporateForm
                    industryList={this.state.industryList}
                    freelancingExperienceList={this.state.freelancingExperienceList}
                     submitHandler={this.handleCorporateFormSubmitOnly}
                      company_logo={this.state.company_logo}
                      handleChangeCompanyLogo={this.setCompanyLogo}
                      cancelHandler={this.handleGoBack}
                      submitLoading={this.state.submitLoading}
                      countryList={this.state.countryDataList}
                      countryLoading={this.state.getCountryLoading}
                      getStateList={this.getStateDataList}
                      stateList={this.state.stateDataList}
                      stateLoading={this.state.getStateLoading}
                      skills={this.state.skillList}
                      categories={this.state.categoriesList}
                      cityList={this.state.cityDataList}
                      getCityLoading={this.state.getCityLoading}
                      getCityList={this.getCityDataList}
                    />
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
