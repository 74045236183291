import React from "react";
// Customizable Area Start
import {
    Grid,
    Typography,
    Box,
    Avatar,
    Button,
    FormControl,
    RadioGroup,
    Divider,
    Tooltip

} from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
import { ThemeProvider,createTheme,withStyles} from "@material-ui/core/styles";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import {info_circle, leftArrow} from "./assets"
import "./MyBid.css";
export const hireTheme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
      overrides: {
        MuiTooltip: {
          tooltip: {
            fontSize: "12px",
            fontWeight: 500,
            color: "#fff",
            backgroundColor: "#222222",
            padding:'16px',
            border:'none'
          },
          arrow: {
           color: "#222222",
          }
        }
      }
    
});


// Customizable Area End

import PayAndHireController, {
    Props, configJSON
} from "./PayAndHireController.web";
import { getSponsorTaxAmountElement } from "../../../components/src/utill/getMultiCurrencyHtml";
import KnowYourCustomerKycVerification from "../../knowyourcustomerkycverification/src/KnowYourCustomerKycVerification.web";


export class PayAndHire extends PayAndHireController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start


      getProjectTotalCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean,param?:string) => {
        return (
            <>
                {freelancerCost?.currency_symbol} {param ? freelancerCost?.total_project_cost : freelancerCost?.total_project_cost_with_tax} {"   "}
                {sponsorCost?.currency != freelancerCost?.currency &&
                    <>
                        <span  id="2" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost.currency_symbol}{param ? sponsorCost.total_project_cost : sponsorCost.total_project_cost_with_tax})</span>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000}  title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
                            <img  id="payandhire" style={{height: "13px",  width: "13px" , marginLeft:"5px"}} src={info_circle} alt="info"  />
                        </Tooltip>
                    </>
                }
                {hourlyRated && " /hr"}
            </>
        )
    } 

    getTotalProjectCostElement = (freelancerCost: any, sponsorCost: any, rate: boolean) => {
      return (
          <>
              {freelancerCost?.currency_symbol} {rate ? freelancerCost?.total_project_cost : freelancerCost?.total_project_cost_with_tax} {"   "}
              {sponsorCost?.currency != freelancerCost?.currency &&
                  <>
                      <span  id="111" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost.currency_symbol}{rate ? sponsorCost.total_project_cost : sponsorCost.total_project_cost_with_tax})</span>
                      <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
                          <img  id="payandhire" style={{height: "13px",  width: "13px" , marginLeft:"5px"}} src={info_circle} alt="info"  />
                      </Tooltip>
                  </>
              }
          </>
      )
  } 

    getProjectMilestoneCostElement = (freelancerCostCurrency: any, sponsorCost: any, hourlyRated: boolean,param?:string) => {
        return (
            <>
                {freelancerCostCurrency?.currency_symbol} {param ? freelancerCostCurrency?.first_milestone_cost:freelancerCostCurrency?.total_first_milestone_cost_with_tax} {"   "}
                {sponsorCost?.currency != freelancerCostCurrency?.currency &&
                    <>
                        <span style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost?.currency_symbol}{param ? sponsorCost?.first_milestone_cost:sponsorCost?.total_first_milestone_cost_with_tax})</span>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${freelancerCostCurrency.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
                            <img id="payhire" style={{ height: "13px", width: "13px", marginLeft: "5px" }} src={info_circle} alt="info" />
                        </Tooltip></>}
                {hourlyRated && " /hr"}
            </>
        )
    } 

      renderBackFunction = () => {
        const{auctionBidData}=this.state;
        return (
            <Box className="personal_details_container">
                <Box className="sendAnOffer_header_wrapper">
                    <Box
                        className="Negotiate_Bid newBid_container"
                        onClick={this.handleGoBackButton}
                        data-test-id = "back_btn"
                    >
                        <img data-test-id = 'back_arrow' src={leftArrow} alt="image" style={{ paddingRight: "10px" }} />
                        <span>{configJSON.btnBackTxt}</span>
                    </Box>
                    <span className="SendAnOffer_container">{configJSON.hireTxt}{auctionBidData?.attributes.profile_name}</span>
                </Box>
               
            </Box>
        );
    };

    renderFreelancerHeader = () => {
        const{classes}=this.props;
        const{auctionBidData}=this.state;
        return <Box className={classes.personalDetailsWrapper}>
                <Box className={classes.frelancerDetails}>
                  <Box style={{
                      display:"flex",
                      alignItems:"center",
                      justifyContent:"space-between",
                      width:"100%"
                  }}>
                  <Typography className={classes.projectName}>{auctionBidData?.attributes.project_name}</Typography>
                        <div>
                           <KnowYourCustomerKycVerification
                              currentUser={this.state.currentUser}
                              navigation={this.props.navigation}
                           />
                        </div>
                  </Box>
                <Grid container>
                                <Grid item xs={12} className={classes.projectDetailsGrid}>
           
                                    <Grid container className={classes.projectDetailsAvatarBox}>
                                        <Grid item sm={3} xs={12}>
                                          <Avatar src={auctionBidData?.attributes?.photo[0]?.url ? auctionBidData?.attributes?.photo[0]?.url : ""} className={classes.projectDetailsAvatar}/>
                                        </Grid>
                                        <Grid item sm={9} xs={12}>
                                          <Box className={classes.projectDetailsNameBox}>
                                            <Typography className={classes.projectDetailsLancerName}>{auctionBidData?.attributes.profile_name}</Typography>
                                            <Typography className={classes.skillsStyle}>
                                                {auctionBidData?.attributes?.profile_skills?.map((item: string, index: number) => {
                                                let skill;
                                                if (auctionBidData?.attributes.profile_skills.length != index + 1) {
                                                    skill = item + " | "
                                                }
                                                else {
                                                    skill = item
                                                }
                                                return ( skill)
                                            })}
                                            </Typography>
                                          </Box>
                                        </Grid>

                                    </Grid> 
                                </Grid>
                            </Grid>
                </Box>
            </Box>
     
    };
    renderFreelancerContent = () => {
        const{classes}=this.props;
        const{getProjectCostLoading,escrowAmount,projectCost,payProjectLoading}=this.state;
        const convertedCost=projectCost?.currency_conversion;
        const freelancerConvertedCost=convertedCost?.freelancer;
        const sponsorConvertedCost=convertedCost?.sponsor;
        return <Box className={classes.personalDetailsWrapper}>
                <Typography className={classes.escrowTxtStyle}>{configJSON.escrowTxt}</Typography>
                <Box className={classes.escrowAmtDetails }>
                {getProjectCostLoading && <Box>
                      <CircularProgress size={18}/>
                  </Box>}

                <Typography className={classes.projectDetailsLancerName}>{configJSON.totalAmtTxt}</Typography>     
                <Typography className={classes.projectAmtStyle}>
                {escrowAmount== "full_project" && this.getTotalProjectCostElement(freelancerConvertedCost,sponsorConvertedCost,this.state.auctionBidData?.attributes?.project?.budget_type == "hourly_price")} 
                {escrowAmount== "first_milestone" && this.getProjectMilestoneCostElement(freelancerConvertedCost,sponsorConvertedCost,false)} 
                </Typography>
                </Box>
                <Box className={classes.respaySection}>
                         <FormControl component="fieldset" style={{width:"100%", maxWidth:"350px"}}>
                            <RadioGroup  name="payment_option" >
                                {
                                    escrowAmount==="full_project" ? (
                                      <Box>
                                          {
                                              projectCost?.taxForTotalProject?.map((item)=>(
                                                  <Box className={classes.taxItemContainer}>
                                                      <Typography className={classes.taxItemText}>{item?.name}({item.cost})</Typography>
                                                      <Typography className={classes.taxItemText}>
                                                        {
                                                          getSponsorTaxAmountElement(
                                                            {
                                                              sponsor:{
                                                                  currency_symbol:sponsorConvertedCost.currency_symbol,
                                                                  amount:item.total_cost,
                                                                  currency:sponsorConvertedCost.currency,
                                                                },
                                                                freelancer:item?.lanceer_currency,
                                                            }
                                                          )
                                                        }
                                                      </Typography>
                                                  </Box>
                                              ))
                                          }
                                          <Divider/>
                                              <Box className={classes.taxItemContainer}>
                                                  <Typography className={classes.taxItemText}>Project Cost</Typography>
                                                  <Typography className={classes.taxItemText}>{this.getProjectTotalCostElement(freelancerConvertedCost,sponsorConvertedCost,false,"true")}</Typography>
                                              </Box>
                                              <Divider/>
                                              <Box className={classes.taxItemContainer}>
                                                  <Typography className={classes.taxItemText}>Total Cost</Typography>
                                                  <Typography className={classes.taxItemText}>{this.getProjectTotalCostElement(freelancerConvertedCost,sponsorConvertedCost,false)}</Typography>
                                              </Box>
                                      </Box>
                                    ):(
                                      <Box >
                                        {
                                            projectCost?.taxForFirstMilestone?.map((item)=>(
                                                <Box className={classes.taxItemContainer}>
                                                    <Typography className={classes.taxItemText}>{item?.name}({item.cost})</Typography>
                                                    <Typography className={classes.taxItemText}>
                                                      {getSponsorTaxAmountElement({
                                                            sponsor:{
                                                                currency:sponsorConvertedCost.currency,
                                                                amount:item.total_cost,
                                                                currency_symbol:sponsorConvertedCost.currency_symbol,
                                                            },
                                                            freelancer:item?.lanceer_currency,
                                                        })}
                                                    </Typography>
                                                </Box>
                                            ))
                                        }
                                        <Divider/>
                                          <Box className={classes.taxItemContainer}>
                                              <Typography className={classes.taxItemText}>Milestone Cost</Typography>
                                              <Typography className={classes.taxItemText}>{this.getProjectMilestoneCostElement(freelancerConvertedCost,sponsorConvertedCost,false,"true")}</Typography>
                                          </Box>
                                          <Divider/>
                                          <Box className={classes.taxItemContainer}>
                                              <Typography className={classes.taxItemText}>Total Cost</Typography>
                                              <Typography className={classes.taxItemText}>{this.getProjectMilestoneCostElement(freelancerConvertedCost,sponsorConvertedCost,false)}</Typography>
                                          </Box>
                                    </Box>
                                    )
                                }
                                
                               
                            </RadioGroup>
                            </FormControl>
                </Box>

                <Box className="resBtnWidth">
                  <Box className={classes.buttonBox}>
                  <Button onClick={this.handlePayProject}  disableFocusRipple variant="contained" className={classes.buttonPay} >{payProjectLoading?<CircularProgress size={18}/>:configJSON.btnPay}</Button>
                  </Box>
                </Box>

           </Box>
      
    };
     
       // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (

            // Customizable Area Start

             <ThemeProvider theme={hireTheme}>
             <Sponserheader navigation={this.props.navigation} id={""} route={"projects"} />
                <Box className="body_wrapper"> 
                {this.renderBackFunction()}
                {this.renderFreelancerHeader()}
                {this.renderFreelancerContent()}

                
                </Box>

                <Sponserfooter navigation={this.props.navigation} />
                </ThemeProvider>

            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const styles = () => ({
    personalDetailsWrapper: {
        border: '1px solid #e4e6e8',
        borderRadius: '16px',
        margin: '20px 0',
        padding: '20px 25px',
        display: "flex",
        flexDirection:'column' as const,
        flexWrap: "wrap" as const,
        justifyContent: "space-between",
        alignItems: 'flex-start',
        backgroundColor: '#F8F7FC',
      },

      frelancerDetails :{
        display: "flex",
        flexWrap: "wrap" as const,
        justifyContent: "space-between",
        alignItems: 'flex-start',
      },
      taxItemContainer:{
        maxWidth:"350px",
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
     },
     taxItemText:{
        color:"#8083a3",
        fontSize:"12px"
     },
      escrowAmtDetails :{
        display: "flex",
        flexWrap: "wrap" as const,
        alignItems: 'center',
        marginTop:'1vw'
      },

      escrowTxtStyle:{
        color: '#333333',
        fontSize: '20px',
        fontWeight: 500,
      },

   
projectDetailsGrid:{
  padding: '1.5vw',
},

  projectDetailsName:{
    color: '#222222',
    fontSize: '20px',
    fontWeight: 700,
  },
  projectDetailsAvatar:{
    width: '50px',
    height: '50px',
  },
  projectDetailsLancerName:{
    color: '#333333',
    fontSize: '16px',
    fontWeight: 600,
  },
  projectAmtStyle:{
    color: '#333333',
    fontSize: '20px',
    fontWeight: 700,
    marginLeft:'10px'
  },
  taxAmtStyle:{
    color: '#8083a3',
    fontSize: '12px',
    fontWeight: 300,
    marginTop:'20px'
  },
  skillsStyle:{
    color: '#8083a3',
    fontSize: '14px',
    fontWeight: 400,
  },
  projectName:{
    color: '#333333',
    fontSize: '24px',
    fontWeight: 600,
  },

  projectDetailsNameBox:{
    display:'flex', 
    flexDirection: 'column' as const, 
    paddingRight:'2vw',
    paddingLeft:'1vw',
  },

   buttonBox:{
    display:'flex',
    margin: '2vw 0',
  },

  buttonPay:{
    color: '#fff',
    fontSize:'12px',
    fontWeight: 700,
    width: '168px',
    height: '42px',
    borderRadius: '20px',
    backgroundColor: '#00a7a2',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    '&:hover': {
        backgroundColor: '#00a7a2',
      },
},
taxPercentStyle:{
  color: '#222',
  fontSize: '12px',
  fontWeight: 500,
  margin:'20px 5px 0 5px',
},
respaySection: {
  width: "100%",
}
 
});

export default withStyles(styles)(PayAndHire);


  // Customizable Area End