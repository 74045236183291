// Customizable Area Start
import React from "react";
import ApprovalController, { Props } from "./AuthProviderController.web";
import { AuthContext } from "./AuthContext.web";
import FullPageBackdropLoader from "../../../components/src/utill/FullPageBackdropLoader.web";
import LogoutModal from "../../../components/src/utill/ActionModal.web";

class AuthProvider extends ApprovalController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { hangLoader, logoutModal, sesstionTimeoutModal,switchAccountModal,currentProfile,showUnAuthorizeModal,showDeactivateModal,isOffline,showActivateModal,showDeleteModal, showAdminDeactiveModal} = this.state;
    const switchToText=currentProfile.toLowerCase().includes("freelancer")?"Sponsor":"Freelancer"
   
    return (
      <AuthContext.Provider
        value={{
          user: this.state.currentUser,
        }}
      >
        {this.props.children}
        <FullPageBackdropLoader open={hangLoader} />
        <LogoutModal
          key="logout_modal"
          open={logoutModal}
          setOpen={this.handleLogoutModalOpen}
          message="Are you sure you want to logout?"
          title="Logout"
          actionHandler={this.handleLogout}
        />
        <LogoutModal
          key="sesstion_timeout_modal"
          open={sesstionTimeoutModal}
          setOpen={() => {}}
          message="You have to login"
          title="Session timeout"
          positiveButtonText="Login"
          actionHandler={this.handleLogout}
          showCancelButton={false}
        />
        <LogoutModal
          key="switchAccountModal"
          open={switchAccountModal}
          setOpen={this.openSwithAccountModal}
          message={`Are you sure you want to switch to ${switchToText}?`}
          title="Switch Account"
          positiveButtonText="Yes"
          actionHandler={this.switchAccount}
        />  
        <LogoutModal
          key="unAuthorizeModal"
          open={showUnAuthorizeModal}
          setOpen={()=>{}}
          title={`Unauthorized User`}
          message="You are not authorized to visit this page"
          positiveButtonText={`Go to Dashboard`}
          actionHandler={this.navigateToActualDashboard}
          showCancelButton={false}
        />
        <LogoutModal
          key="userDeactivated"
          open={showDeactivateModal}
          setOpen={()=>{}}
          title={`Account Blocked!`}
          message="Your account is blocked, please connect to Administrator!"
          positiveButtonText={`Login`}
          actionHandler={this.handleNavigateToLogin}
          showCancelButton={false}
        />
        <LogoutModal
          key="userActivated"
          open={showActivateModal}
          setOpen={()=>{}}
          title={`Account Activation`}
          message="Your account is successfully created. Please activate your account using the activation link sent on your registered Email ID."
          positiveButtonText={`Login`}
          actionHandler={this.handleNavigateToLogin}
          showCancelButton={false}
        />
        <LogoutModal
          key="userActivated"
          open={showDeleteModal}
          setOpen={()=>{}}
          title={`Account Deleted`}
          message="Your Account has been Deleted"
          positiveButtonText={`Create New Account `}
          actionHandler={this.handleNavigateToSignUp}
          showCancelButton={false}
        />
        <LogoutModal
          key="adminDeactivated"
          open={showAdminDeactiveModal}
          setOpen={()=>{}}
          title={`Account Deactivated`}
          message="Your account is deactivated. Please login and activate your account using the activation link which will be sent on your registered Email ID."
          positiveButtonText={`Log In`}
          actionHandler={this.handleNavigateToLogin}
          showCancelButton={false}
        />
        <LogoutModal
          data-testid="offline_modal"
          key="offline_modal"
          open={isOffline}
          setOpen={this.handleOfflineOpen}
          title={`You are offline!`}
          message="Currently you are offline.Change your status to online if you want"
          positiveButtonText={`Go online`}
          actionHandler={this.handleRecheckInternetConnection}
          showCancelButton={true}

        />
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
// Customizable Area End
