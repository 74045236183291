import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

  // Customizable Area Start
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData } from "framework/src/Utilities";
import { IProjectData } from "../../dashboard/src/types";
import { ICustomMilestone } from "../../projecttemplates/src/ProjecttemplatesController";
export const configJSON = require("./config");
import {toast} from 'react-toastify';

interface DisputeDoc {
  blob_id: number;
  created_at: string;
  name: string;
  size: number;
  url: string;
}
  // Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  classes?:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
 
  // Customizable Area Start
  loading: boolean;
  projectLoading: boolean;
  projectData: IProjectData | null;
  milestoneData: ICustomMilestone | null;
  disputeCategories: { id: number, name: string, created_at: string, updated_at: string }[];
  disputeCategory: string;
  disputeDescription: string;
  disputeAmount: number | null;
  disputeDocs: File[];
  submitLoader:boolean;
  // Customizable Area End

}

interface SS { }

export default class DisputeRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProjectDetailsAPICallId:string="";
  getMilestoneCostApiCallId:string="";
  payMilestoneApiCallId:string="";
  getDisputeCategoriesAPICallId: string = "";
  projectId: string = "";
  milestoneId: string = "";
  raiseDisputeAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area Start
      // Customizable Area End
    ]);

    this.state = {
        // Customizable Area Start
        loading: true,
        projectLoading: false,
        projectData: null,
        milestoneData: null,
        disputeCategories: [],
        disputeCategory: "",
        disputeDescription: "",
        disputeAmount: null,
        disputeDocs: [],
        submitLoader:false,
        // Customizable Area End
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    // Customizable Area Start
    
    if (responseJson && !responseJson.errors) {

      if (apiRequestCallId === this.getProjectDetailsAPICallId) {
        this.handleProjectDetailsResponse(responseJson)

      }

      if (apiRequestCallId === this.getDisputeCategoriesAPICallId) {
        this.setState({ disputeCategories: responseJson })

      }
      if (apiRequestCallId === this.raiseDisputeAPICallId) {
        this.props.navigation.goBack()
      }
    }
     runEngine.debugLog(responseJson);
     runEngine.debugLog(apiRequestCallId);
    // Customizable Area End

    }
  }
// Customizable Area Start
async componentDidMount() {
  this.projectId = this.props.navigation.getParam('projectId')
  this.milestoneId = this.props.navigation.getParam('milestoneId')
  this.getProjectDetails();
  this.getDisputeCategories()


}

convertFileName = (name: string) => {
  if (name.length > 12) {
    const dotIndex = name.lastIndexOf('.');
    if (dotIndex !== -1) {
      const firstEight = name.substring(0, 8);
      const hiddenPart = '*'.repeat(4);
      const extension = name.substring(dotIndex);
      return `${firstEight}${hiddenPart}${extension}`;
    } else {
      return name;
    }
  } else {
    return name;
  }
}

onConvertFileSize=(bytes:number)=>{
  
  if (bytes >= 1024 * 1024) {
   return (`(${(bytes / (1024 * 1024)).toFixed(2)}MB)`);
 } else {
   return (`(${(bytes / 1024).toFixed(2)}KB)`);
 }
}

handleDeleteUploadedFile = (ind:number)=>{
this.state.disputeDocs.splice(ind,1)
this.setState({disputeDocs:[...this.state.disputeDocs]})
}

handleFileDrop = (acceptedFiles: any) => {
  if (acceptedFiles) {
    let sizeExceedsLimit = false;
    let newDocs :any= [...this.state.disputeDocs]; 

    acceptedFiles.forEach((doc: {
      blob_id: number,
      created_at: string,
      name: string,
      size: number,
      url: string
    }) => {

        if (doc.size > 15 * 1024 * 1024) { 
         sizeExceedsLimit = true;
       } 
      else {
        newDocs.push(doc); 
      }
    });

    if (sizeExceedsLimit) {
      toast.error(configJSON.errorTxtMaxUpload);
      
    } 
    else {
      this.setState({ disputeDocs: newDocs }); 
    }
 }

}

handleAmount = (event:any)=>{

  const inputValue = event.target.value;
  const regex = /^\d{0,8}(\.\d{0,2})?$/;

    if (regex.test(inputValue)) {
      this.setState({ disputeAmount: inputValue });
    }

}


handleProjectDetailsResponse = (responseJson: any) => {
  const allMilestones = responseJson.data.attributes.project_milestones
  let milestoneData: ICustomMilestone | null = null
  allMilestones.forEach((milestone: any) => {
    if (milestone.id === +(this.milestoneId)) {
      milestoneData = { ...milestone }
    }
  })
  this.setState({ loading: false, projectData: responseJson.data, milestoneData: milestoneData })

}

getProjectDetails = async () => {
  this.setState({
    projectLoading: true
  })
  const token = await getStorageData("loginSuccessToken");
  const header = {
    token: token
  };
  const requestMessage = CreateRestApiMessage({
    header: header,
    apiUrl: `${configJSON.projectDetailsAPIEndpoint}/${this.projectId}`,
    body: null,
    method: "GET"
  })

  this.getProjectDetailsAPICallId = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

getDisputeCategories = async () => {

  const token = await getStorageData("loginSuccessToken")
  const header = {
    token: token
  };
  const requestMessage = CreateRestApiMessage({
    header: header,
    apiUrl: configJSON.getDisputeCategoriesAPIEndpoint,
    body: null,
    method: "GET"
  })

  this.getDisputeCategoriesAPICallId = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleSubmitDispute = (values:any)=>{
  if(this.milestoneId){
    this.handleSubmitMilestoneDispute(values)
  }
  else{
    this.handleSubmitProjectDispute(values)
  }
}

handleSubmitProjectDispute = async (values: any) => {
  this.setState({submitLoader:true})

  const token = await getStorageData("loginSuccessToken")
  const header = {
    token: token
  };
  const projectId = this.props.navigation.getParam("projectId");
  let formdata = new FormData();

  formdata.append('project[dispute_description]', values.description)
  formdata.append('project[dispute_amount]', values.disputeAmount)
  formdata.append('project[dispute_category]', values.category)
  this.state.disputeDocs.forEach((doc)=>{
    formdata.append("project[raise_images][]",doc)
  })

  const requestMessage = CreateRestApiMessage({
    header: header,
    apiUrl: `${configJSON.raiseDisputeAPIEndpoint}?id=${projectId}`,
    body: formdata,
    method: "POST"
  })
  this.raiseDisputeAPICallId = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

handleSubmitMilestoneDispute = async (values: any) => {
  this.setState({submitLoader:true})

  const token = await getStorageData("loginSuccessToken")
  const header = {
    token: token
  };
  const milestoneId = this.props.navigation.getParam("milestoneId");
  let formdata = new FormData();

  formdata.append('raise_dispute[dispute_description]', values.description)
  formdata.append('raise_dispute[dispute_amount]', values.disputeAmount)
  formdata.append('raise_dispute[dispute_category]', values.category)
  formdata.append('raise_dispute[project_milestone_id]',milestoneId )
  this.state.disputeDocs.forEach((doc)=>{
    formdata.append("raise_dispute[raise_images][]",doc)
  })

  const requestMessage = CreateRestApiMessage({
    header: header,
    apiUrl: configJSON.raiseDisputeForMilestoneAPIENdpoint,
    body: formdata,
    method: "POST"
  })
  this.raiseDisputeAPICallId = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);

}

  // Customizable Area End
}