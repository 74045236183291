import React from "react";
import {
    // Customizable Area Start
    Box,
    Typography,
    WithStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {withStyles} from "@material-ui/core/styles";
// Customizable Area End

export interface Props extends WithStyles{

    // Customizable Area Start
    onHandleBack:() => void,
    backArrow:string;
    backTxt:string;
    headerTxt:string;
    // Customizable Area End
  }
  
interface S {
    // Customizable Area Start

   // Customizable Area End
  }

export class BackNavigation extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
 
    };
  }

   render() {
    const{classes,backArrow, backTxt,headerTxt,onHandleBack}=this.props;

    return <Box className={classes.boxButtonStyle} 
    data-testid = "back_to_withdrawalpage_btn"
    onClick={onHandleBack}>
            <Box className={classes.btnBack}>
          <img src={backArrow} className={classes.btnArrowStyle} />
              {backTxt}
            </Box> 
            <Typography className={classes.headerText}>{headerTxt}</Typography>
   </Box>
   
  
  }
}
 
// Customizable Area Start
export const styles = () => ({

  btnArrowStyle:{
    width: '20px',
    height: '20px',
    marginRight:'0.5vw',
  },
  btnBack:{
    width: '100px',
    height: '46px',
    borderRadius: '23px',
    backgroundColor: '#f2f2f2',
    display:'flex',
    justifyContent:'center',
     alignItems:'center',
     cursor:'pointer',
     '&:hover': {
      backgroundColor: '#ededed',
    },
  },
  boxButtonStyle:{
    display:'flex',
    justifyContent:'center',
     alignItems:'center',
     flexWrap: "wrap" as const,
  },
  
  headerText:{ 
    color: '#222222',
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: 700,
    margin:'2vw 1vw',
  },    
    
  })

    
    export default withStyles(styles)(BackNavigation);
  // Customizable Area End