import React from "react";
// Customizable Area Start
import {
  Dialog,DialogTitle,DialogContent,DialogActions,Button,Box,Typography
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
// Customizable Area End

import KnowYourCustomerKycVerificationController, {
  Props,
} from "./KnowYourCustomerKycVerificationController";
import { Formik } from "formik";
const initialValues = {
  firstNameDefault: "",
  lastNameDefault: "",
  middleNameDefault: "",
  emailStateDefault: "",
  nationalityDefault: "",
  dateOfBirth: "",
  phoneNumber: ""
}

export default class KnowYourCustomerKycVerification extends KnowYourCustomerKycVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getVerificationStatusComponent=()=>{
    const {getVerificationStatusLoading}=this.state;
    const statusProp=this.getStatusProperties();
    return(
        <Typography variant="h6" style={{fontSize:"18px"}}>
          Verification Status: {getVerificationStatusLoading ? <CircularProgress size={24}/>: (
               <span style={{
                color:statusProp.color,
                fontWeight:"bolder",
                marginLeft:"10px",
                background:`${statusProp.color}30`,
                padding:"10px 20px",
                borderRadius:"10px"
              }}>
              {statusProp?.label}
            </span>
          )}
      </Typography>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {openVerificationModal,sendVerificationStatusLoading,visibleSentRequestBtn}=this.state;
    const themeColor=this.getCurrentUserTheme();
    const verificationComponent=this.getVerificationStatusComponent();
    return (
      <Box>
          {verificationComponent}
         <Dialog
          fullWidth
          maxWidth="xs"
          PaperProps={{ style: { borderRadius: 16 } }}
          open={openVerificationModal}
        >
          <DialogTitle
            id="max-width-dialog-title"
            style={{ paddingBottom: 0 }}
          >
            <strong>Verify Your Identity</strong>
          </DialogTitle>
          <DialogContent>
          {verificationComponent}
          </DialogContent>
          <DialogActions style={{ margin: "15px" }}>
            {
              visibleSentRequestBtn && (
                <Button
                  data-testid="verifyButton"
                  onClick={this.sendVerificationRequest}
                  variant="contained"
                  disabled={sendVerificationStatusLoading}
                  disableElevation
                  style={{
                    borderRadius: "9px",
                    height: "40px",
                    width: "100%",
                    background: themeColor,
                    fontWeight: 600,
                    textTransform: "capitalize",
                  }}
                >
                  {
                    sendVerificationStatusLoading?<CircularProgress size={20}/>:"Verify"
                  }
                  
              </Button>
              )
            }
          </DialogActions>
        </Dialog>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  
};
// Customizable Area End
