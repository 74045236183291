import React, { Component } from "react";
import { Box,Button } from "@material-ui/core";
import { accept_bid } from "./assets";
import "./MyBid.css";
import PaymentResponseController,{Props} from "./AuctionBiddingPaymentController.web"
import Sponserheader  from "../../dashboard/src/Sponserheader.web";
import FullPageBackdropLoader from "../../../components/src/utill/FullPageBackdropLoader.web";
import FreelancerHeader  from "../../dashboard/src/FreelancerHeader.web";
export default class OfferSendSuccess extends PaymentResponseController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
    render() {
        // Customizable Area Start
        const {loader,currentUser}=this.state;
        // Customizable Area End
        return (
            <div>
                {
                    currentUser && this.isSponsor() ? 
                     (<Sponserheader navigation={this.props.navigation} id={""} route={"projects"} />):
                     (<FreelancerHeader navigation={this.props.navigation} id={""} route={"projects"}/>)
                }
           
            {
                loader? <FullPageBackdropLoader open={loader}/>:(
                    <Box className="offer_Send_success_container">
                    <img src={accept_bid} alt="accept" className="accept_bid" />
                    <h1>Congratulations</h1>
                    <h4>{this.getPaymentSuccessTitle()}</h4>
                    <h6 dangerouslySetInnerHTML={{__html:this.getPaymentSuccessDescription()}}>
                    </h6>
                    <style>
                        {`
                            @media(max-width: 490px) {
                                .resInvoicepage {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;
                                    align-items: center;
                                }
                            }
                        `}
                    </style>
                    {
                        this.isShowActionButtons() && (
                            <Box className="resInvoicepage">
                                <button onClick={this.handleDownloadFile} className="save_as_draft fixed_width">Download & View Invoice</button>
                                <button data-testid="viewProjectBtn" onClick={this.navigateToProjectPage}  className="btn-continue fixed_width">Go to My Projects</button>
                            </Box>
                        )
                    }
                        {
                        this.isPaymentForCertificate() && (
                            <Box>
                                <Button 
                                    data-testid="navigateToCertificatePage"
                                    onClick={this.navigateToViewCertificate}
                                     style={{
                                        background:"#f2f2f2",
                                        borderRadius:"20px",
                                        padding:"10px 20px",
                                        textTransform:"capitalize",
                                        fontWeight:"bold"
                                     }}         
                                    >
                                    View Certificate
                                </Button>
                            </Box>
                        )
                    }

                   
                </Box>
                )
            }
          
            </div>
        );
    }
}
