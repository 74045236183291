import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  InputLabel,
  TextField,
  Select, MenuItem
} from "@material-ui/core";
import { createTheme, withStyles, createStyles } from "@material-ui/core/styles";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import CircularProgress from "@material-ui/core/CircularProgress";

// Customizable Area End

import ContactusController, { Props } from "./ContactusController";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    support_category_id: Yup.string().required('Support category is required'),
  });


  color = () => { return this.state?.currentUser?.currentState?.toLowerCase()?.includes("sponsor") ? "#00A7A2" : "rgb(236, 149, 45)" }
  IsSponsor = () => { return this.state?.currentUser?.currentState?.toLowerCase()?.includes("sponsor") ? true : false }
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
    { !this.state.IsLoading  && <>
      {this.IsSponsor() ?  <Sponserheader navigation={this.props.navigation} id={""} route={"Contactus"} /> : 
       <FreelancerHeader
            navigation={this.props.navigation}
            history={undefined}
            location={undefined}
            id={""} route={"contactUs"}
          />
      }
      
       <div className={this.props.classes.formContainerTop}>
          <div className={this.props.classes.formContainer} style={{marginTop:'10px'}}>
            <Formik
              enableReinitialize={true}
              data-testid = 'formik'
              initialValues= {
                this.state.initialvalue
              }
              validationSchema={this.validationSchema}
              // onSubmit={(value:any)=>{this.ContactFormSubmit(value)}}
              onSubmit={(values, { resetForm }) => {
                this.ContactFormSubmit(values);
                resetForm()
              }}
            >
              {({ setFieldValue}) => (
            <Form translate={undefined} className={this.props.classes.form}>
            <Box className={this.props.classes.headerBox} style={{ backgroundColor: `${this.color()}` }}>
              <h6>Contact Us</h6>
            </Box>
            <div className={this.props.classes.maincontainer}>
              <Field
                name="email"
                type="text"
                as={TextField}
                label="Email"
                placeholder="Enter your email"
                className={this.props.classes.inputField}
                fullWidth
              />
              <ErrorMessage className={this.props.classes.error} name="email" component="div" />
          
              <Field
                name="name"
                type="text"
                as={TextField}
                label="Name"
                placeholder="Enter your name"
                className={this.props.classes.inputField}
                fullWidth
              />
              <ErrorMessage className={this.props.classes.error} name="name" component="div" />
          
              <InputLabel className={this.props.classes.inputlable}>Mobile Number</InputLabel>
              <Box className={this.props.classes.verifyInputandBtnConatiner}>
                <PhoneInput
                  inputStyle={{
                    width: '100%',
                    border: "none",
                    borderRadius: "0px",
                    borderBottom: "1px solid #8083A3",
                    font: "inherit",
                    color: '#8083a3',
                    fontFamily: "Inter",
                  fontWeight: 400,
                  fontSize: "14px",
                    
                  }}
                  data-test-id="contactUsPhone"
                  value={this.state.number.phone}
                  onChange={(phone: any) => { setFieldValue("phone_number", phone); this.setState({ number: { phone: phone , state: this.state.number.state} }) }}
                  country={this.state.number?.country}
                />
              </Box>
              <Field
                name="description"
                type="text"
                as={TextField}
                label="Description"
                placeholder="Enter description"
                className={this.props.classes.inputField}
                fullWidth
              />
              <ErrorMessage className={this.props.classes.error} name="description" component="div" />
          
              <InputLabel id="support-category-id-label" className={this.props.classes.inputlable}>Support Category ID</InputLabel>
              <Select
                labelId="support-category-id-label"
                id="support-category-id"
                name="support_category_id"
                data-test-id="categoryselect"
                className={this.props.classes.inputField3}
                fullWidth
                value={this.state.number.state}
                onChange={(event: any) => {
                  setFieldValue("support_category_id", event.target.value);
                  this.setState({
                    number: {
                      state: event.target.value,
                      phone : this.state.number.phone,
                    }
                  })
                }}
              >
                {this.state?.SuppportCategory?.map((value: any, index: number) => {
                  return (
                    <MenuItem value={value?.id} key={index}>{value?.category_name}</MenuItem>
                  )
                })}
              </Select>
              <ErrorMessage className={this.props.classes.error} name="support_category_id" component="div" />
            </div>
            <Button
              className={this.props.classes.saveButton}
              style={{ backgroundColor: `${this.color()}` }}
              type="submit"
              data-test-id="submitbutton"
            >
              {this.state.isLoading ? <CircularProgress size={20}/>:"Submit"}
            </Button>
            </Form>
              )}
            </Formik>
          </div>
       </div>
        <div className={this.props.classes.footer}>
          {
            this.IsSponsor() ? 
            <Sponserfooter navigation={undefined} id={""} />
            :
            <DashboardFooter history={undefined} location={undefined} param={"browse-all"} />
          }
        </div>
     </>}
     </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const modalStyle = () => createStyles({
  verifyInputandBtnConatiner: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    maxWidth: 700,
    marginBottom: "10px"
  },
  formContainerTop : {
    flexGrow: 1,
    display: "flex",
    alignItems: "center"
  },
  formContainer: {
    maxWidth: '400px',
    margin: '0 auto',
    border: '1px solid #E4E6E8',
    borderRadius: '14px',
    backgroundColor: '#FFFFFF',
    width: "100%",
    "@media only screen and (max-width: 400px)":{
      maxWidth: '300px',
    }
  },
  error: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: '12px',
    color: '#d90000',
    marginBottom: "5px"
  },
  footer: {
    maxWidth: '1200px',
    // margin: 'auto',
  },
  maincontainer: {
    padding: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  saveButton: {
    minWidth: '140px',
    borderRadius: '24px',
    background: "#00A7A2",
    padding: '9px 30px',
    color: '#FFFFFF',
    fontSize: '14px',
    margin: '0 15px',
    textTransform: 'none' as const,
    "&:hover": {
      color: "#FFFFFF",
      background: "#00A7A2",
    },
    outline: 'none',
  },
  inputField: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#8083a3",
    "&::placeholder": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      color: "#8083a3",
    },
    '& .MuiInputLabel-shrink': {
      color: '#8083a3',
      fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    },
    '& .MuiInputLabel-animated':{
      color: '#8083a3',
      fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    },
    '& MuiInput-underline .MuiInput-underline:after': {
      border : 'none'
    },
    '& .MuiInputBase-input' : {
      padding: "6px 0 0px !important",
      color: '#8083a3',
      fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    },
    marginBottom: "10px"
  },
  inputField3: {
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Inter",
    color: "#8083a3",
    '& .MuiInputLabel-shrink': {
      color: '#8083a3',
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "14px",
    },
    "&::placeholder": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      color: "#8083a3",
    },
    '& .MuiInputLabel-animated':{
      fontWeight: 700,
      color: '#8083a3',
      fontFamily: "Inter",
    fontSize: "14px",
  },
  marginBottom: "10px",
    '& .MuiInputBase-input' : {
      padding: "6px 0 6px !important",
      color: '#8083a3',
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
    },
    '& MuiInput-underline .MuiInput-underline:after': {
      border : 'none'
    },
  },
  inputField2: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#8083a3",
    "&::placeholder": {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "14px",
      color: "#8083a3",
    },
    '& .MuiInputLabel-shrink': {
      color: '#8083a3',
      fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    },
    '& MuiInput-underline .MuiInput-underline:after': {
      border : 'none'
    },
    '& .MuiInputBase-input' : {
      padding: "6px 0 0px !important",
      color: '#8083a3',
      fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    },
    marginBottom: "10px"
  },
  inputlable: {
    color: '#8083a3',
    fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "14px",
    font: "inherit",
    padding: "6px 0 7px",
    margin: 0,
    height: '1.1876em',
  },
  headerBox: {
    textAlign: 'center',
    padding: '30px',
    borderBottom: '1px solid #E4E6E8',
    borderRadius: '14px 14px 0 0',
    color: '#f2f2f2',
  },
});

export const ContactUsStyle = withStyles(modalStyle)(Contactus);
// Customizable Area End
