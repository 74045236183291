import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    Divider,
    
} from "@material-ui/core";
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import GenericTab from '../../../components/src/GenericTab';
import "../assets/css/Search.css";
import { configJSON } from "./SearchBidController.web";
// Customizable Area End
interface Props {
    response: boolean
    pageNumber: number;
    projectLoading: any;
    totalCards: number;
    tabLables: {

        tabFilterLabels: string[],
        tabCardsLabels: string[]
    };
    projectCards: any;
    activeTab: any;
    handleProjectBidTabChange: any;
    navigate: any;
    handleProjectBidLoadMore: any;
}


const BrowseAllProjectLancer: React.FC<Props> = ({
    projectLoading,
    totalCards,
    tabLables,
    projectCards,
    activeTab,
    handleProjectBidTabChange,
    navigate,
    handleProjectBidLoadMore }) => {

    const isShowMoreEnable=totalCards>projectCards?.length;
    return (
        // Customizable Area Start
        <>
         <Box>
                <Box style={webStyle.projectDetailsTab}>
                    <GenericTab
                        className={"alltabs"}
                        activeTabColor={"#EC952D"}
                        tabLabels={tabLables?.tabCardsLabels}
                        activeTab={activeTab}
                        handleTabChange={(e, val) => handleProjectBidTabChange(e, val, 1)}
                    />
                </Box>
                {
                    projectCards?.length===0 && (
                    <Box sx={{mt:4}}>
                        <Typography>{configJSON.loadingMsg}</Typography>
                    </Box>
                    )
                }
               

                {projectCards?.map((card: any, index: number) => (
                    <Box
                        key={card?.id}
                        mb={3}
                        style={webStyle.projectDescriptionBox}
                    >
                        <Box style={webStyle.projectDescriptionBoxContainer} >
                            <Box style={webStyle.projectHeader}>
                                <Box>
                                    <Typography style={webStyle.cardProjectName}>{card.attributes.project_name}</Typography>
                                    <Box style={webStyle.projectSubInfo}>
                                        <Typography style={webStyle.projectSubInfoType}>{configJSON.fixedPriceTxt}</Typography>
                                        <Typography style={webStyle.projectSubInfoType}>{configJSON.estimatedTimeTxt}</Typography>
                                        <Typography style={webStyle.projectSubInfoType}>{card.attributes.posted_at}</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <MoreHorizIcon />
                                </Box>
                            </Box>
                            <Box style={webStyle.category}>
                                {card?.attributes?.skills.map((skill: any) => (
                                    <Box style={webStyle.categoryBox}>{skill.name}</Box>
                                ))}
                            </Box>
                            <Box style={webStyle.projectDescriptionText}>
                                <Typography style={webStyle.projectDescriptionTextDesign}>{card.attributes.description}</Typography>
                            </Box>
                            <Box style={webStyle.projectDetails}>
                                <Box >
                                    <Typography style={webStyle.projectSubDetails}>{configJSON.estimatedBudgetTxt}</Typography>
                                    <Typography style={webStyle.projectSub_subDetails}>{card.attributes.estimated_budget}</Typography>
                                </Box>
                                <Box>
                                    <Typography style={webStyle.projectSubDetails}>{configJSON.durationTxt}</Typography>
                                    <Typography style={webStyle.projectSub_subDetails}>{card.attributes.select_duration_type}</Typography>
                                </Box>
                                <Box>
                                    <Typography style={webStyle.projectSubDetails}>{configJSON.experienceTxt}</Typography>
                                    <Typography style={webStyle.projectSub_subDetails}>{card.attributes.expected_experience}</Typography>
                                </Box>
                                <Box>
                                    <Typography style={webStyle.projectSubDetails}>{configJSON.languageTxt}Language</Typography>
                                    <Typography style={webStyle.projectSub_subDetails}>{card.attributes.language_of_converse}</Typography>
                                </Box>
                                <Box>
                                    <Typography style={webStyle.projectSubDetails}>{configJSON.currentBidTxt}</Typography>
                                    <Typography style={webStyle.projectSub_subDetails}>{configJSON.currentBidTxtData}</Typography>
                                </Box>
                            </Box>

                        </Box>
                        <Divider />
                        <Box style={webStyle.showSimilarContainer}>
                            <Box display="flex" alignItems="center" justifyContent="start">
                                <EmojiObjectsOutlinedIcon style={webStyle.bulbIcon} />
                                <Typography style={webStyle.showSmiliarText}>{configJSON.showSimilarTxt}</Typography>
                            </Box>
                            <Box style={webStyle.bidTheProjects}>
                                <button style={webStyle.likeIconDesign}><ThumbDownAltOutlinedIcon style={webStyle.iconDesign} /></button>
                                <button style={webStyle.favouriteIcon}><FavoriteBorderOutlinedIcon style={webStyle.iconDesign} /></button>
                                <button style={webStyle.bidOnProjectText} >{configJSON.bidOnThisProjectTxt}</button>
                            </Box>
                        </Box>
                    </Box>
                ))}
                <Box style={webStyle.loadMoreBtn}>
                    {projectLoading &&  <Box><CircularProgress /></Box>}               

                    {isShowMoreEnable && !projectLoading && (
                        <button
                            style={webStyle.showMoreBtn}
                            onClick={handleProjectBidLoadMore}
                            hidden={projectLoading}
                        >
                           {configJSON.showMoreTxt}
                            <ArrowForwardIosIcon style={webStyle.forwardIcon} />
                        </button>
                    )}
                </Box>
            </Box>
        </>
        // Customizable Area End
    );
}

export default BrowseAllProjectLancer;
// Customizable Area Start
const webStyle = {
    root: {
        display: "flex",
        justifyContent: "space-evenly"
    },
    forwardIcon:{
        fontSize: '14px', 
    },
    iconDesign:{
        width: "16px"
    },
    bidOnProjectText: {
        border: "none",
        padding: '9px 35px',
        height: "36px",
        backgroundColor: "#EC952D",
        cursor: "pointer",
        color: "white",
        borderRadius: "18px",
        fontSize: "12px",
        fontWeight: "bold"
    } as const,
    favouriteIcon: {
        border: '1px solid #ddd',
        height: '36px',
        width: ' 36px',
        cursor: 'pointer',
        borderRadius: '50%', background: '#fff'
    },
    showMoreBtn: {
        color: '#000',
        borderRadius: '26px',
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 18px',
        background: '#F2F2F2',
        height: '40px',
        width: '151px',
        border: 'none',
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: 'center',
        cursor:"pointer"
    },
    likeIconDesign: {
        border: '1px solid #ddd',
        height: '36px',
        width: ' 36px',
        borderRadius: '50%',
        cursor: 'pointer',
        background: '#fff',
    },
    showSimilarContainer: {
        padding: '20px 30px',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    projectSub_subDetails: {
        fontSize: '18px',
        color: '#000',
        fontWeight: 600
    },
    projectSubDetails: {
        color: '8083A3',
        fontSize: '12px'
    },
    projectDescriptionTextDesign: {
        fontSize: '14px',
        color: '#000',
        fontWeight: 500
    },
    projectSubInfoType: {
        color: '#8083A3',
        fontSize: '12px',
    },
    projectDescriptionBoxContainer: {
        padding: '18px 24px 16px 30px'
    },
    projectDescriptionBox: {
        borderRadius: '15px',
        marginBottom: '24px',
        border: '1px solid rgb(228, 230, 232)',
        
    },
    cardProjectName: {
        fontSize: '18px',
        fontWeight: 600,
        color: "#000000"
    },
    projectHeader: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: 'center',
    },
    projectSubInfo: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
        gap: '20px',
    },
    category: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: 'center',
        marginTop: '17px',
        flexFlow: 'wrap',
        gap: ' 10px',
        maxWidth: '600px',
    },
    categoryBox: {
        background: '#E4E6E8',
        color: '#8083A3',
        padding: '6px 20px',
        borderRadius: '20px',
    },
    projectDescriptionText: {
        marginTop: '17px',
        marginRight: "35px"
    },
    projectDetails: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: 'center',
        marginTop: '17px',
        marginRight: "120px",
    },
    bidTheProjects: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        justifyContent: 'end',
    },
    loadMoreBtn: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '300px',
        justifyContent: 'center',
        margin: 'auto',
    } as const,
    projectDetailsTab: {
        margin:"24px 0px"
    },
    showSmiliarText: {
        fontSize: '14px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        color: "#000",
        marginLeft: "0px"
    },
    bulbIcon: {
        height: "19px",
        marginRight: "14px",
        color: "black",

    }
}

// Customizable Area End



















