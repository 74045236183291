import React from "react";
// Customizable Area Start
import {
    Grid,
    Box,
    Typography,
    TextField,
    Button,
    Container,
    InputLabel,
    InputAdornment,
    MenuItem,
    Select,
    CircularProgress,
    CardMedia,
} from "@material-ui/core";
import DisputeRequestController, { Props, configJSON } from './DisputeRequestController.web';
import { withStyles,ThemeProvider, createTheme } from "@material-ui/core/styles";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import FullPageBackdropLoader from "../../../components/src/utill/FullPageBackdropLoader.web";
import Dropzone from "react-dropzone";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import moment from "moment";
import { document, deleteFile } from "./assets"
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";


export const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
    overrides: {
      MuiSelect: {
        selectMenu: {
          minHeight: '24px',
          borderBottom: 'none',
        }, 
        select: {
            display:'flex',
            alignItems:"center"
           },
        },
        MuiListItem: {
          root: {
            fontFamily: "Inter",
            color: "#8083a3",
            fontSize: "12px",
            fontWeight:400,
          },
          },
  MuiInput: {
    underline: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      position: 'relative',
      '&:before': {
        position: 'none',
        border:'none',
        content:'none',
        transition: 'none',
      },
    },
  },
 MuiInputBase: {
    input: {
      fontFamily: "Inter",
      color: "#8083A3",
      fontSize: "14px",
      fontWeight:400,
    },
  },
}
});
// Customizable Area End

export class DisputeRequest extends DisputeRequestController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    validationSchema = Yup.object().shape({
        category: Yup.string().required(configJSON.categoryValidationTxt),
        disputeAmount: Yup.number().nullable().required(configJSON.amountValidationTxt),
        description: Yup.string().required(configJSON.descriptionValidationTxt),
    });

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { loading, projectData, milestoneData, disputeCategory, disputeAmount,
            disputeDescription, disputeDocs, submitLoader } = this.state
        const formattedDueDate = moment(milestoneData?.due_date).format('YYYY-MM-DD')
        const { classes } = this.props
        const currentUserDetails = {
            projectTitle: projectData?.attributes?.project_name, projectDueDate: formattedDueDate,
            projectDescription: projectData?.attributes.description, milestoneTitle: milestoneData?.title,
            milestoneDueDate: formattedDueDate, milestoneDescription: milestoneData?.description,
            milestoneAmount: milestoneData?.total_cost, category: disputeCategory, disputeAmount: disputeAmount,
            description: disputeDescription
        }

        // Customizable Area End
        return (

            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Sponserheader navigation={this.props.navigation} id={""} route={"Contactus"} />
                {loading ? <FullPageBackdropLoader open={loading} /> : (
                    <Container maxWidth="md">
                        <Box style={{flexWrap: "wrap"}} className={classes.header}>
                            <Button
                                data-testid="backBtn"
                                onClick={() => window.history.back()}
                                className={classes.backButton}
                                variant="contained"
                                startIcon={<KeyboardBackspaceIcon />}
                            >
                                Back
                            </Button>
                            <Typography className={classes.headingText}>Request for Dispute </Typography>
                        </Box>
                        <Box className={classes.topContainer}>
                            <Formik
                                enableReinitialize={true}
                                initialValues={currentUserDetails}
                                onSubmit={(values, { resetForm }) => {
                                    this.handleSubmitDispute(values)
                                    resetForm()
                                }}

                                validationSchema={this.validationSchema}
                                data-testid='formik'
                            >
                                {({ resetForm, values, setFieldValue }) => (
                                    <Form translate={undefined}>
                                        <Box className="basic-details-main-container">
                                            <Box className="row form-block" flexDirection={"column"}>
                                                <Box pb={2}>
                                                    <Typography style={{ fontWeight: 700, }}>Project Details</Typography>
                                                </Box>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6} className="field-group">
                                                        <InputLabel htmlFor="firstName">
                                                            Title
                                                        </InputLabel>
                                                        <Field
                                                            id="projectTitle"
                                                            name="projectTitle"
                                                            fullWidth
                                                            data-testid="firstName"
                                                            className={classes.fieldsStyle}
                                                            disabled={true}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} className="field-group">
                                                        <InputLabel htmlFor="projectDueDate">
                                                            Due Date
                                                        </InputLabel>
                                                        <Field
                                                            id="projectDueDate"
                                                            name="projectDueDate"
                                                            type="date"
                                                            fullWidth
                                                            className={classes.inputFieldStyle}
                                                            placeholder="Select your date of birth"
                                                            data-testid="dateofBirth"
                                                            max={new Date().toISOString().split('T')[0]}
                                                            disabled={true}
                                                        />

                                                    </Grid>

                                                    <Grid item xs={12} className="field-group">
                                                        <InputLabel htmlFor="projectDescription">
                                                            Description
                                                        </InputLabel>
                                                        <TextField
                                                            minRows={2}
                                                            multiline
                                                            name="projectDescription"
                                                            InputProps={{
                                                                classes: {
                                                                    input: classes.placeholderStyle,
                                                                    root: classes.inputFieldStyle,
                                                                },
                                                            }}
                                                            value={values.projectDescription}
                                                            id="projectDescription"
                                                            fullWidth
                                                            className={classes.inputFieldStyle}
                                                        />

                                                    </Grid>
                                                </Grid>
                                                {this.milestoneId && (
                                                    <>
                                                        <Box pt={3} pb={2}>
                                                            <Typography style={{ fontWeight: 700 }}>Milestone Details</Typography>
                                                        </Box>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} sm={6} className="field-group">
                                                                <InputLabel htmlFor="milestoneTitle">
                                                                    Title
                                                                </InputLabel>
                                                                <Field
                                                                    name="milestoneTitle"
                                                                    id="milestoneTitle"
                                                                    fullWidth
                                                                    data-testid="firstName"
                                                                    className={classes.fieldsStyle}
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} className="field-group">
                                                                <InputLabel htmlFor="milestoneDueDate">
                                                                    Due Date
                                                                </InputLabel>
                                                                <Field
                                                                    id="milestoneDueDate"
                                                                    name="milestoneDueDate"
                                                                    type="date"
                                                                    fullWidth
                                                                    className={classes.inputFieldStyle}
                                                                    placeholder="Select your date of birth"
                                                                    data-testid="dateofBirth"
                                                                    max={new Date().toISOString().split('T')[0]}
                                                                    disabled={true}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} className="field-group">
                                                                <InputLabel htmlFor="milestoneDescription">
                                                                    Description
                                                                </InputLabel>
                                                                <TextField
                                                                    minRows={2}
                                                                    multiline
                                                                    name="milestoneDescription"
                                                                    InputProps={{
                                                                        classes: {
                                                                            input: classes.placeholderStyle,
                                                                            root: classes.inputFieldStyle,
                                                                        },
                                                                    }}
                                                                    value={values.milestoneDescription}
                                                                    id="milestoneDescription"
                                                                    fullWidth
                                                                    className={classes.inputFieldStyle}
                                                                />

                                                            </Grid>
                                                            <Grid item xs={12} className="field-group">
                                                                <InputLabel htmlFor="milestoneAmount">
                                                                    Amount
                                                                </InputLabel>
                                                                <TextField
                                                                    type="number"
                                                                    id="milestoneAmount"
                                                                    name="milestoneAmount"
                                                                    data-test-id="test1"
                                                                    value={values.milestoneAmount}
                                                                    className={classes.inputFieldStyle}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start" style={{ fontSize: "1rem", marginBottom: "2px" }}>
                                                                                {projectData?.attributes?.currency_conversion?.sponsor?.currency_symbol}
                                                                            </InputAdornment>
                                                                        ),
                                                                        classes: {
                                                                            root: classes.inputFieldStyle
                                                                        }
                                                                    }
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                                <Box pt={3} pb={2}>
                                                    <Typography style={{ fontWeight: 700 }}>{configJSON.disputeDetailsHeading}</Typography>
                                                </Box>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={6} className="field-group">
                                                        <InputLabel htmlFor="disputeCategory">{configJSON.category}</InputLabel>
                                                        <Select
                                                            labelId="disputeCategory"
                                                            id="disputeCategory"
                                                            name="disputeCategory"
                                                            value={values.category}
                                                            fullWidth
                                                            displayEmpty
                                                            MenuProps={{
                                                                anchorOrigin: {
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                },
                                                                transformOrigin: {
                                                                    vertical: 'top',
                                                                    horizontal: 'left',
                                                                },
                                                                getContentAnchorEl: null,
                                                            }}
                                                            onChange={(event: any) => this.setState({ disputeCategory: event.target.value })}
                                                            data-test-id="disputeCategory"
                                                        >
                                                            <MenuItem className={classes.menuFirstCategoryStyle} 
                                                                value={configJSON.menuDefaultTxt}> 
                                                                <span className={classes.categoryMenuStyle} > 
                                                                {configJSON.categoryPlaceHolderTxt}
                                                                </span>
                                                            </MenuItem>
                                                            {this.state.disputeCategories.map((value: {name:string}, index: number) => {
                                                                return (
                                                                    <MenuItem value={value.name} key={index}>{value.name}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                        <ErrorMessage className={classes.error} name="category" component="div" />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} className="field-group">
                                                        <InputLabel htmlFor="disputeAmount">
                                                            {configJSON.disputedAmount}
                                                        </InputLabel>
                                                        <TextField
                                                            id="disputeAmount"
                                                            name="disputeAmount"
                                                            type="number"
                                                            data-test-id="disputeAmount"
                                                            placeholder={"Enter Amount"}
                                                            className={classes.inputFieldStyle}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start" style={{ fontSize: "1rem", marginBottom: "2px" }}>
                                                                          {projectData?.attributes?.currency_conversion?.sponsor?.currency_symbol}
                                                                    </InputAdornment>
                                                                ),
                                                                classes: {
                                                                    root: classes.inputFieldStyle,
                                                                    input: classes.placeholderStyle
                                                                }
                                                            }}
                                                            onChange={(event) => this.handleAmount(event)}
                                                            value={values.disputeAmount}
                                                        />
                                                        <ErrorMessage className={classes.error} name="disputeAmount" component="div" />
                                                    </Grid>
                                                    <Grid item xs={12} className="field-group">
                                                        <InputLabel htmlFor="disputeDescription">
                                                            {configJSON.disputeDiscriptionLabel}
                                                        </InputLabel>
                                                        <TextField
                                                            id="disputeDescription"
                                                            name="disputeDescription"
                                                            value={values.description}
                                                            placeholder={"Enter Description"}
                                                            onChange={(event) => this.setState({ disputeDescription: event.target.value })}
                                                            fullWidth
                                                            multiline
                                                            minRows={2}
                                                            data-test-id="disputeDescription"
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.inputFieldStyle,
                                                                    input: classes.placeholderStyle
                                                                }

                                                            }}
                                                        />
                                                        <ErrorMessage className={classes.error} name="description" component="div" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Dropzone
                                                            data-testid="dropzone"
                                                            onDrop={this.handleFileDrop}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                    <div {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <Box
                                                                            className={classes.dropZoneStyle}
                                                                        >
                                                                            <Typography
                                                                                className={classes.uploadFileTxt}
                                                                            >
                                                                                Upload File
                                                                            </Typography>
                                                                            <Typography
                                                                                className={classes.maxFileSizeTxt}
                                                                            >
                                                                                Max file size: 15MB
                                                                            </Typography>
                                                                        </Box>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>

                                                    </Grid>
                                                    <Grid item sm={12}
                                                        container
                                                        spacing={3}
                                                        className="container">
                                                        {disputeDocs.map((doc: any, ind: number) => (
                                                            <Grid item xs={12} sm={6} md={6} key={doc.lastModified}
                                                            >
                                                                <Box className={classes.downloadItemBorder}>
                                                                    <Box className={classes.downloadItemBox}>
                                                                        <img className={classes.milestoneGridImage} src={document} />
                                                                        <Typography className={classes.downloadfileName}>{this.convertFileName(doc.name)}</Typography>
                                                                        <Typography className={classes.downloadfileSize}>{this.onConvertFileSize(doc.size)}</Typography>
                                                                    </Box>
                                                                    <Box className={classes.iconsBox}>

                                                                        <CardMedia
                                                                            data-testId="deleteUploadedFile"
                                                                            component="img"
                                                                            className={classes.deleteIconStyle1}
                                                                            image={deleteFile}
                                                                            onClick={this.handleDeleteUploadedFile.bind(this, ind)}
                                                                        />
                                                                    </Box>

                                                                </Box>
                                                            </Grid>
                                                        ))
                                                        }
                                                    </Grid>

                                                    <Grid item sm={12}>
                                                        <Box
                                                            className={`${classes.sendContainerStyle} ${classes.btnContainerStyle}`}
                                                            pt={2} paddingBottom={2}
                                                        >
                                                            <Button
                                                                className={classes.onHoldButtonStyle}
                                                                onClick={() => this.props.navigation.goBack()}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                className={classes.closeButtonSyle}
                                                                type="submit"
                                                                data-test-id="submitbutton"
                                                                disabled={submitLoader}
                                                            >
                                                                {submitLoader ? <CircularProgress size={20} style={{ color: "grey" }} /> : "Send"}
                                                            </Button>
                                                        </Box>
                                                    </Grid>

                                                </Grid>



                                            </Box>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Box>
                    </Container>
                )}
                <Sponserfooter navigation={this.props.navigation} id={""} />
            </ThemeProvider>

            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const styles = () => ({
    topContainer: {
        borderRadius: 14,
        border: "1px solid #E4E6E8",
        padding: "20px 25px",
        "& .form-block": {
            padding: 0,
        },
        "@media(max-width: 480px)": {
            padding: "20px 10px",
        },
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: "23px",
        marginBottom: "24px",
        padding: "14px 0 0 0px",
    }as const ,
    backButton: {
        boxShadow: "none",
        borderRadius: "20px",
        padding: "10px 20px",
        textTransform: "capitalize",
    } as const,
    headingText: {
        fontWeight: "bolder",
        fontSize: "28px",
        color: "black",
    } as const,
    inputFieldStyle: {
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: '14px',
        color: "#8083A3",
        width:"100%",
        "& .MuiInputBase-input": {
            padding: "0",
        },
        "& .MuiInput-underline:after": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
        },
        "& .MuiInput-underline:before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)"
        },

    },
    fieldsStyle: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        fontWeight: 400,
        color: "#8083A3",
    },
    dropZoneStyle: {
        borderRadius: "10px",
        border: "1.5px dashed #00A7A2",
        background:
            "linear-gradient(0deg, rgba(0, 167, 162, 0.04) 0%, rgba(0, 167, 162, 0.04) 100%), #FFF",
        padding: "20px",
        margin: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column" as "column",
        rowGap: 5,
    },
    uploadFileTxt: {
        color: "#222222",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 700,
    },
    maxFileSizeTxt: {
        color: "#8083A3",
        fontSize: "10px",
        fontFamily: "Inter",
        fontWeight: 500,
    },
    sendContainerStyle: {
        display: "flex",
        justifyContent: "flex-end",
    },
    onHoldButtonStyle: {
        borderRadius: 23,
        background: "#F2F2F2",
        color: "#222",
        textAlign: "center" as "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        padding: "5 35",
        marginRight:'10px'
    },
    closeButtonSyle: {
        borderRadius: 23,
        backgroundColor: "#00A7A2",
        color: "#fff",
        textAlign: "center" as "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        padding: "5 35",
        "&:hover": {
            backgroundColor: "#00A7A2",
        },
    },
    error: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: '12px',
        color: '#d90000',
        marginBottom: "5px"
    },
    placeholderStyle: {
        fontWeight: 400,
        '&::placeholder': {
            color: '#8083a3',
        },
    },
    downloadItemBorder: {
        marginBottom: '1vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding:'5px 16px',
        borderRadius: '10px',
        border: '1px solid #e4e6e8',
        backgroundColor: '#ffffff',
    },
    downloadItemBox: {
        display: 'flex',
        alignItems: 'center',
    },
    milestoneGridImage1: {
        width: "20px",
        marginRight: "1vw",
        height: "21px",
    },
    downloadfileName: {
        color: '#222222',
        fontSize: '12px',
        fontWeight: 600,
        marginRight: '0.5vw',
    },
    downloadfileSize: {
        color: 'rgba(128,131,163,0.76)',
        fontSize: '10px',
        fontWeight: 500,
        marginTop:'3px'
    },
    iconsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '0 0.5vw'
    },
    deleteIconStyle1: {
        borderRadius: "50%",
        border: "1px solid rgba(228, 230, 232, 0.60)",
        background: "#FFF",
        cursor: 'pointer',
    },
    milestoneGridImage: {
        width: "20px",
        height: "21px",
        marginRight: "1vw",
    },

      categoryMenuStyle:{ 
        fontFamily: "Inter",
        fontWeight: 400,
        fontSize: "14px",
        color: "#8083A3",
      },
      menuFirstCategoryStyle:{
         display: "none" 
      },
});

export default withStyles(styles)(DisputeRequest);

// Customizable Area End
