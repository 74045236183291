// Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import CreateRestApiMessage from "../../../../components/src/CreateRestApiMessage.web";
import { IProfile } from "../../../rolesandpermissions/src/Roles.types";
import { IBidData, INegotiation } from "./bid.types";
export const configJSON = require("../config");
export const PROJECT_PER_PAGE = 5;
export interface Props {
  navigation: any;
  id: string;
  param: "browse-all" | "completed" | "progress" | "my-bid" | "onhold";
}
interface S {
  bidList: IBidData[];
  bidLoading: boolean;
  totalBids: number;
  currentPage: number;
  bidSearchText: string;
  bidQueryString: string;
  currentUser: IProfile|null;
  showMore:boolean;
}

interface SS { }

export default class FreelancerMyBidController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllBidsApiCallId: string = "";
  getSearchBidsApiCallId: string = "";
  getAllFilteredBidsApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ]);

    this.state = {
      bidList: [],
      bidLoading: false,
      totalBids: 0,
      currentPage: 1,
      bidSearchText: "",
      bidQueryString: "",
      currentUser: null,
      showMore:true
    };
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const loginUserInfo = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.setState({ currentUser: loginUserInfo },this.fetchProjects);
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const jsonResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getAllBidsApiCallId) {
        this.handleGetAllBidsPayload(jsonResponse);
      }
    }
  }

  handleGetAllBidsPayload = (responseJson: any) => {
    if (responseJson && responseJson?.bids?.data?.length>0) {
      this.setState((prev) => {
        let newProjectList: any[] = [];
        if (prev.currentPage === 1) {
          newProjectList = responseJson?.bids?.data;
        } else {
          newProjectList = [...prev.bidList, ...responseJson.bids.data];
        }
        return {
          bidList: newProjectList,
          bidLoading: false,
          totalBids: responseJson?.pagination?.total_data || 0,
        };
      });
    } else {
      this.setState({ bidLoading: false });
    }

  };

  async componentDidMount() {
    this.sendLoginUserMessage();
  }
  fetchProjects=()=>{
    const {currentUser}=this.state;
    if(currentUser && currentUser?.id){
      this.prepareFinalQuery();
    }
  }
  sendLoginUserMessage=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
  }
  bidSearchHandler = (searchText: string) => {
    this.setState({ currentPage: 1, bidSearchText: searchText }, () => {
      this.prepareFinalQuery();
    });
  };
  bidFilterHandler = (query: string, selectedOptions: any) => {
    this.setState({ currentPage: 1, bidQueryString: query }, () => {
      this.prepareFinalQuery();
    });
  };
  bidCancelFilterHandler = () => {
    this.setState({ currentPage: 1, bidQueryString: "" }, () => {
      this.prepareFinalQuery();
    });
  };
  prepareFinalQuery = () => {
    const {currentUser}=this.state;
    let baseQuery: string[] = [`profile_id=${currentUser?.id}`];
    const { bidSearchText, bidQueryString, currentPage } = this.state;
    baseQuery.push(`current_page=${currentPage}`);
    baseQuery.push(`per_page=${PROJECT_PER_PAGE}`);
    if (bidSearchText) {
      baseQuery.push(`search=${bidSearchText}`);
    }
    if (bidQueryString) {
      baseQuery.push(bidQueryString);
      baseQuery.push(`filter=true`)
    }
    const newQueryText = baseQuery.join("&");
    this.getAllBidList(newQueryText);
  };

  handleProjectLoadMore = () => {
    const { bidLoading } = this.state;
    if (!bidLoading) {
      this.setState(
        (prev) => ({ currentPage: prev.currentPage + 1 }),
        () => {
          this.prepareFinalQuery();
        }
      );
    }
  };

  getNegotiationsConversion=(negotiations:INegotiation[])=>{
    if(negotiations.length>0){
        let status="";
        const lastNegotiations=negotiations[0];
        let negotiations_sender="";
        if(lastNegotiations.submitter==="freelancer"){
          status="Sent to Sponsor";
          negotiations_sender="New Quote from You";
        }else{
          status="Received from Sponsor";
          negotiations_sender="New Quote by Client";
        }
        if(lastNegotiations.status==="rejected"){
          status="Rejected"
        }
        return{
          status,
          currency_conversion:lastNegotiations.currency_conversion,
          negotiations_sender
        }
    }else{
      return {
        status:"Sent to Sponsor",
        currency_conversion:null,
        negotiations_sender:""
      };
    }

  }
  getAllBidList = (query: string) => {
    this.setState({
      bidLoading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getMyBidsApiUrl}?${query}`,
      body: null,
      method: "GET",
    });

    this.getAllBidsApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };


  navigateToBidProjectDetails=(id:string)=>{
      this.props?.navigation?.navigate("QuoteProjectDetails",{projectId:id})
  }

  handleProjectDetailNavigation = (id:string) => {
    this.props.navigation.navigate("QuoteDetails", { bidId:id});
  };
}

// Customizable Area End
