import React from "react";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { SposorCompanyDetailsStyles } from "./SposorCompanyDetails.web";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { SponsorBillingDetailsStyles } from "./SponsorBillingDetails.web";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import {
  hourlyRateIcon,
  sponsorPersonalIcon,
  activeSponsorCompanyDetailsIcon,
  activeSponsorPersonalIcon,
  SponsorCompanyDetailsIcon,
  activeSponsorBillingDetails
} from "./assets";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

let routeArrayItems = [
  {
    img: sponsorPersonalIcon,
    name: "Personal Info",
    route: "basicInfo",
    activeImg: activeSponsorPersonalIcon,
  },
  {
    img: SponsorCompanyDetailsIcon,
    name: "Company Details",
    route: "companyDetails",
    activeImg: activeSponsorCompanyDetailsIcon,
  },
  // {
  //   img: hourlyRateIcon,
  //   name: "Billing & Payments",
  //   route: "billingAndPayments",
  //   activeImg: activeSponsorBillingDetails,
  // },

];

type ContentMapping = {
  [key: string]: JSX.Element | null;
};
// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
} from "./Customisableuserprofiles2Controller";
import BasicDetailsCustomizable from "./BasicDetailsCustomizable.web";

export default class SponsorCustomisableuserprofiles extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetailsViewComponent() {
    const componentMapping:ContentMapping = {
      basicInfo: <BasicDetailsCustomizable typeOfRole="Sponsor" />,
      companyDetails: <SposorCompanyDetailsStyles />,
      // billingAndPayments: <SponsorBillingDetailsStyles />,
    };
  
    const { renderDetailsView } = this.state;
    return componentMapping[renderDetailsView] || null;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {classes} = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Sponserheader navigation={this.props.navigation} id={""} route={""} />
        <Container maxWidth="lg">
          <Typography variant="h5" className={classes.ProfileHeading}>
            {configJSON.sponserHeading}
          </Typography>
          <style>
            {`
              @media(max-width:959px) {
                .sponserProfileBlock {
                  padding: 12px 0 !important;
                }
                .resSideRoutingBlock  {
                  height: 8rem !important;
                }
              }
            `}
          </style>
          <Grid container spacing={3} >
            <Grid item xs={12} sm={12} md={3} className="resSideRoutingBlock" style={webStyle.sideRoutingBlocks}>
              <Box>
                {routeArrayItems.map((item) => {
                  return (
                    <Box
                      className={classes.sideNavigationItems}
                      key={item.name}
                      onClick={() => this.navigateToSpecificDetailsPage(item.route)}
                      data-testid = {`navigateToSpecificPage-${item.route}`}
                    >
                      <Box className={classes.singleRouteBlockEl}>
                        <img
                          src={
                            this.state.renderDetailsView === item.route
                              ? item.activeImg
                              : item.img
                          }
                          alt={item.name}
                          className={classes.routeIcons}
                        />
                        <Typography
                          variant="h6"
                          className={
                            this.state.renderDetailsView === item.route
                              ? `${classes.textLabelLinkStyle} ${classes.activeSpecificBlocks}`
                              : classes.textLabelLinkStyle
                          }
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <ChevronRightIcon
                        className={
                          this.state.renderDetailsView === item.route &&
                          classes.activeSpecificIcons
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9} className="sponserProfileBlock">
            {this.renderDetailsViewComponent()}
            </Grid>
          </Grid>
        </Container>
        <Sponserfooter navigation={undefined} id={""} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyle: any = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  singleRouteBlockEl: {
    display: "flex",
    justifyContent: "space-between",
  },
  routeIcons: {
    margin: "0 10px 0",
  },
  inputRounded: {
    '.MuiOutlinedInput-root': {
      borderRadius: '30px',
    },
  },
  customSelect: {
    '.MuiSelect-root': {
      height: '35px',
      paddingTop: '2px',
      paddingBottom: '2px',
      outline: 'none',
    },
  },
  MuiInputBaseRoot: {
    height: '30px',
  },
  navlink: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '12px',
    color: '#8083a3',
    textDecoration: 'none',
    margin: '5px',
  },
  convertSponsorText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '10px',
    textAlign: 'center',
    color: '#222222',
  },
  switchToSponsor: {
    border: '1px solid #00a7a2',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '68px',
    height: '55px',
    margin: '10px',
  },
  switchToSponsorIcon: {
    height: '20px',
    width: '20px',
  },
  userEmail: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#8083a3',
  },
  navbarIcons: {
    height: '22px',
    width: '22px',
  },
  ProfileHeading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '28px',
    color: '#222222',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  textLabelLinkStyle: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    color: '#222222',
    fontFamily: 'Inter',
  },
  sideRoutingBlocks: {
    padding: '20px',
    background: 'rgba(245, 245, 250, 0.698039)',
    border: '1px solid #e4e6e8',
    borderRadius: '14px',
    height: '10rem',
    marginTop: '10',
    boxShadow: "inset 0px 0px 28px -3px rgba(0,0,0,0.1)"
  },
  errorText: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: '12px',
    color: '#d90000',
  },
  sideNavigationItems: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 5px',
    cursor: 'pointer'
  },
  activeSpecificBlocks:{
    color:"#00A7A2"
  },
  activeSpecificIcons:{
    color:"#00A7A2"
  }
};
export const SponsorCustomisableuserprofilesStyles = withStyles(webStyle)(
  SponsorCustomisableuserprofiles
);
// Customizable Area End
