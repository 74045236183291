import React from "react";
import {
  // Customizable Area Start

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

// Customizable Area End

export interface Props {

  // Customizable Area Start
  timezoneData?: string;
  color?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  localTime: string;
 // Customizable Area End
}

let intervalId: NodeJS.Timeout | number;
class ProjectLocalTime extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      localTime: '',
    };
  }

  componentDidMount() {

    this.updateLocalTime();
    intervalId = setInterval(this.updateLocalTime, 1000);
  }

  componentWillUnmount() {
    clearInterval(intervalId);
  }

  updateLocalTime = () => {
    const timeZone =  this.props.timezoneData

    const localCityTime = this.getLocalTime(timeZone);
    const formattedTime = this.formatTime(localCityTime);
    this.setState({ localTime: formattedTime });
  };

  getLocalTime = (timeZone: any) => {
    const now = new Date();
    const options: any = {
      timeZone,
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    return now.toLocaleTimeString('en-US', options)
  };

  formatTime = (time: string) => {
    const [hours, minutes, seconds] = time.split(':');
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return formattedTime;
  };

   render() {
    return (
       <span style={{color:`${ this.props.color ? this.props.color :'#8083a3'}`,fontSize: '12px', fontWeight: 400}}>{this.state.localTime}</span>
       )
  }
}

// Customizable Area Start

export default ProjectLocalTime;
// Customizable Area End