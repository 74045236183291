import React from "react";

// Customizable Area Start
import {
    globe,
    lancerlogo, usaimg,
} from "./assets";
import {  Grid, Box, Typography, Link, } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import "../assets/css/dashboard.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// Customizable Area End

// Customizable Area Start
import FreelancerDashboardController from "./FreelancerDashboardController.web";
import TermsAndServiceModal from "../../../components/src/TermsAndServiceModal.web";
// Customizable Area End

import { BlockComponent } from "../../../framework/src/BlockComponent";
interface Props {
    navigation: any;
    id?: string;
}

interface S { }

interface SS { }
export default class Sponserfooter extends FreelancerDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.currentPage = "Sponser";
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const filteredSectionSponser = Array.isArray(this.state.userFooterData) && this.state.userFooterData.length > 0 ? this.state.userFooterData : [];

        return (
            // Customizable Area Start
            <div>
                <style>
            {`
                .footerLeftSectionSponser{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .footerRightSectionSponser{
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    gap: 10px;
                }
                @media(max-width: 600px) {
                    .footerLeftSectionSponser, .footerRightSectionSponser{
                        justify-content: center;
                    }
                }
            
            `}
           </style>
                <Grid container justifyContent="center">
                    {
                        filteredSectionSponser.length > 0 ?
                        (
                            <Grid
                        item
                        xs={11}
                        style={{
                            margin: "52px auto"
                        }}
                    >
                        <Grid container justifyContent="space-around" spacing={3}>
                            <Grid item xs={12} md={3}>
                                <Grid item>
                                    <Box mt={1}>
                                        <img
                                            style={{ height: "41px", marginTop: "-31px", cursor: "pointer" }}
                                            src={lancerlogo}
                                            alt=""
                                            onClick={this.navigateToHomePage}

                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container  spacing={2}>
                                
                                {filteredSectionSponser.map((sectionsponser:any) => (
                        
                                    <Grid xs={12} sm={6} md={3} item key={sectionsponser.id}>

                                    <Typography
                                    style={{
                                        color: "#000000",
                                        fontFamily: "inter",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                    }}
                                    >{sectionsponser.title}
                                    </Typography>
                                
                                    {sectionsponser.sub_sections.length > 0 ? (
                                        
                                        <ul>
                                            {sectionsponser.sub_sections.map((subSectionSponser:any) => (
                                            <li className="text-center" style={webStyle.link_text_sponser} key={subSectionSponser.id}>
                                                <a style={webStyle.link_text_sponser} href={subSectionSponser.url} target="_blank">{subSectionSponser.header}</a>
                                            </li>
                                            ))}
                                        </ul>
                                    
                                    ) : ""}
                                    </Grid>
                                ))}
                                </Grid>
                            </Grid>
                        
                        </Grid>
                            </Grid>
                        ) : ""
                    }
                    
                
                </Grid>
                <Divider />
                <Grid container xs={11} style={{margin: "0 auto"}}>
                    <Grid item xs={12}>
                        <Grid container xs= {12} >
                            <Grid item xs={12} sm={6} 
                            className="footerLeftSectionSponser">
                                <span onClick={()=>this.getTermOfServiceInfoFooter("Privacy Policy", "")}
                                 style={{ fontFamily: "inter", fontSize: "14px", cursor:"pointer" }}
                                 data-test-id="termsInfoSponser">
                                    Privacy Policy
                                </span>
                            </Grid>
                            <Grid item xs={12} sm={6}
                            className="footerRightSectionSponser">
                                <img
                                style={webStyle.userFlag}
                                    
                                    src={globe}
                                    alt="country Flag"
                                />
                                <p 
                                    style={{
                                        fontFamily: "inter",
                                        fontSize: "14px",
                                        color: "black",
                                        display: 'flex',
                                        padding: "10px 0",
                                        marginBottom: "0"
                                    }}
                                >
                                    {this.state.userCountry}
                                </p>
                                <p
                                style={webStyle.userCurrency}
                                   
                                >
                                    Currency - {this.state.userCurrency}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Box justifyContent="center" display="flex" pt={3} pb={3.5}>
                    <p style={webStyle.userRights} >
                        @2021 All rights reserved
                    </p>
                </Box>
                <TermsAndServiceModal
                    open={this.state.termsAndServiceFooter?.open}
                    description={this.state.termsAndServiceFooter?.description}
                    loading={this.state.termsAndServiceFooter?.loading}
                    setOpen={this.handleCloseTermsAndServiceModal}
                    title={this.state.termsAndServiceFooter?.title}
                />
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    link_text_sponser: {
        fontSize: "14px",
        fontFamily: "inter",
        marginTop: "20px",
        color: "#8083A3"
    },
    userFlag : {
        width: "20px",
        height: "20px",
    },
    userCurrency : {
        fontFamily: "inter",
        fontSize: "14px",
        color: "black",
        display: 'flex',
        padding: "20px 0",
        marginBottom:"0"
    },
    userRights : {
        fontFamily: "inter", 
        fontSize: "14px"
    },
    
};
// Customizable Area End
