import React from 'react'

import { Typography, Box, Divider, Avatar ,Tooltip, Grid} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import { configJSON } from '../../blocks/dashboard/src/freelancer-projects/FreelancerBrowseProjectController.web'
import { IProjectData } from '../../blocks/dashboard/src/types';
import { Favorite, FavoriteBorderOutlined } from "@material-ui/icons";
import { Link } from 'react-router-dom'
import { info_circle } from '../../blocks/auctionbidding/src/assets';
interface Props {
  card: IProjectData;
  onSubmitBidClick?: any;
  showActionBar?: boolean;
  onBodyClick?: any;
  fav?: any;
  onShowSimilar?: (projectId: string) => void;
  showHoriz?: boolean
}

const ProjectCard: React.FC<Props> = ({ fav, card, onSubmitBidClick, showActionBar = true, onBodyClick, onShowSimilar, showHoriz = true }) => {


  const handleSubmitBidClick = () => {
    if (onSubmitBidClick) {
      onSubmitBidClick(card?.id)
    }
  }
  const handleBodyClick = () => {
    if (onBodyClick) {
      onBodyClick(card?.id)
    }
  }
  const handleFavoriteClick = () => {
    if (fav) {
      fav(card.id);
    }
  };

  const handleShowSimilar = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (onShowSimilar) {
      onShowSimilar(card?.id)
    }
  }

  const estimatedBudgetWithConversionInfoDisplay = (freelancerConversionData: {currency: string, currency_symbol: string, amount: number}, sponsorConversionData: {currency: string, currency_symbol: string, amount: number}, hourlyRated: boolean) => {
    return (
      <>
        {sponsorConversionData?.currency_symbol} {sponsorConversionData?.amount} {"   "}
        {  sponsorConversionData?.currency != freelancerConversionData?.currency &&
          <>
            <span style={{ color: "#EC952D", fontWeight: 400, fontSize: "12px" }} >({freelancerConversionData?.currency_symbol}{freelancerConversionData?.amount})</span>
            <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${sponsorConversionData?.currency}) into (${freelancerConversionData?.currency}) based on today's conversion rate`}>
              <img style={{ height: "13px", width: "13px", marginLeft: "5px" }} src={info_circle} alt="info" />
            </Tooltip></> }
        {hourlyRated && " /hr"}
      </>
    )
  } 
  const conversionRate=card.attributes?.currency_conversion;
  const freelancerconversionRate=conversionRate?.freelancer;
  const sponsorconversionRate=conversionRate?.sponsor;

  
  const estimatedBudget = estimatedBudgetWithConversionInfoDisplay(freelancerconversionRate,sponsorconversionRate,card?.attributes?.budget_type === "hourly_price")
  const projectType = card?.attributes?.budget_type === "hourly_price" ? "Hourly Rated" : "Fixed Price";
  const eastimatedTime = card?.attributes?.EST_time || 0;
  const location = `${card?.attributes?.sponsor_profile.city} , ${card?.attributes?.sponsor_profile.country} `
  return (
    <Box mb={3} style={webStyle.projectDescriptionBox}>
      <Box onClick={handleBodyClick} style={webStyle.projectDescriptionBoxContainer}>
        <Box style={webStyle.fb_projectHeader}>
            <Grid container style={{justifyContent: "space-between"}}>
              <Grid item xs={12} sm="auto">
                <Typography style={webStyle.cardProjectName}>
                  {card.attributes.project_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container  style={webStyle.fb_projectSubInfo}>
              <Grid item style={webStyle.projecctHeaderDetails}>
                <Typography style={webStyle.projectSubInfoType}>
                  {projectType}
                </Typography>
              </Grid>
              <Grid item style={webStyle.projecctHeaderDetails}>
                <span style={{ color: 'gray', marginRight: "5px" }}> • </span>
                <Typography style={webStyle.projectSubInfoType}>
                  {configJSON.estimatedTimeTxtLabel} {eastimatedTime}
                </Typography>
              </Grid>
              <Grid item style={webStyle.projecctHeaderDetails}>
                <span style={{ color: 'gray',  marginRight: "5px"  }}> • </span>
                <Typography style={webStyle.projectSubInfoType}>
                  {card.attributes.posted_at}
                </Typography>
              </Grid>
            </Grid>
        </Box>
        <Divider />
        <Box style={webStyle.fb_projectHeader}>
          <Typography style={webStyle.sponsorText}>Sponsor</Typography>
          <Box style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid container spacing={2} style={{width:"90%"}}>
                <Grid item style={{ display: "flex", alignItems: 'center', padding: "5px" }}>
                  <Avatar src={card?.attributes?.profile_image != null ? card?.attributes?.profile_image[0]?.url : ""}
                    style={webStyle.progressAvatar} />
                  <Typography style={webStyle.avatarText}>{card.attributes.profile_full_name}</Typography>
                </Grid>
                <Grid item style={{ display:"flex", alignItems:"center", }}>
                  ({location})
                </Grid>
              </Grid>
          </Box>
        </Box>
        <Divider />
        <Box style={webStyle.fb_category}>
          {card?.attributes?.skills.map((skill: any) => (
            <Box style={webStyle.fb_categoryBox}>{skill.name}</Box>
          ))}
        </Box>
        <Box style={webStyle.fb_projectDescriptionText}>
          <Typography style={webStyle.projectDescriptionTextDesign}>
            {card.attributes.description}
          </Typography>
        </Box>
        <Box style={webStyle.projectDetails}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={3}>
              <Box>
                <Typography style={webStyle.projectSubDetails}>
                  {configJSON.estimatedBudgetTxt}
                </Typography>
                <Typography style={webStyle.projectSub_subDetails}>
                  {estimatedBudget}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Box>
                <Typography style={webStyle.projectSubDetails}>
                  {configJSON.durationTxt}
                </Typography>
                <Typography style={webStyle.projectSub_subDetails}>
                  {card?.attributes?.project_size} {" "} {card.attributes.select_duration_type}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Box>
                <Typography style={webStyle.projectSubDetails}>
                  {configJSON.experienceTxt}
                </Typography>
                <Typography  style={webStyle.projectSub_subDetails}>
                  {card.attributes.expected_experience} years
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={2} >
              <Box>
                <Typography style={webStyle.projectSubDetails}>
                  {configJSON.languageTxt}
                </Typography>
                <Typography style={webStyle.projectSub_subDetails}>
                  {card.attributes.language_of_converse}
                </Typography>
              </Box>
            </Grid>
            {
              card?.attributes?.number_of_bidders>0 && 
             <Grid item xs={6} sm={6} md={2}>
              <Box>
                <Typography style={webStyle.projectSubDetails}>
                  {configJSON.currentBidTxt}
                </Typography>
                <Typography style={webStyle.projectSub_subDetails}>
                  {card?.attributes?.number_of_bidders - card?.attributes?.number_of_propsals}
                </Typography>
              </Box>
            </Grid>
            }
          </Grid>
        </Box>
      </Box>
      <style>
        {`
          @media(max-width: 767px) {
            .respadding {
              padding: 20px 10px !important;
            }
          }
          @media(max-width: 599px) {
            .resBidProject {
              justify-content: start !important;
            }
          }
          @media(max-width: 479px) {
            .resShowSimilarContainer {
              flex-direction: column;
            }
            .resBidTheProject {
              max-width: 100%;
              flex-wrap: nowrap;
            }
          }
        
        `}
      </style>
      {
        showActionBar && (
          <>
            <Divider />
            <Grid container className='resShowSimilarContainer' style={webStyle.showSimilarContainer}>
              <Grid item xs="auto" sm={6} onClick={handleShowSimilar} style={{ cursor: "pointer", visibility: onShowSimilar ? "visible" : "hidden", display: "flex", alignItems:"center", marginBottom:"10px", justifyContent: "flex-start"}} >
                <EmojiObjectsOutlinedIcon style={webStyle.bulbIcon} />
                <Typography style={webStyle.showSmiliarText}>
                  {configJSON.showSimilarTxt}
                </Typography>
              </Grid>
              <Grid item xs="auto" sm={6} className='resBidTheProject' style={webStyle.bidTheProjects} >
               
                <button style={webStyle.favouriteIcon}
                  onClick={handleFavoriteClick}
                >
                  {card.attributes.is_favourite_project ?
                    <Favorite style={webStyle.iconDesign} /> : <FavoriteBorderOutlined style={webStyle.iconDesign} />
                  }
                </button>
                {
                  !card?.attributes?.submitted_bid_type && card?.attributes?.bid_status !== 1 && (
                    <button onClick={handleSubmitBidClick} style={webStyle.bidOnProjectText} >
                      {/* <Typography  style={{color:'white'}}> */}
                        {configJSON.bidOnThisProjectTxt} 
                      {/* </Typography> */}
                    </button>
                  )
                }
                {
                  !card?.attributes?.submitted_bid_type && card?.attributes?.bid_status === 1 && (
                    <button onClick={handleSubmitBidClick} style={webStyle.bidOnProjectText}>
                      <Typography style={{color:'white'}}>
                        Submit Proposal
                      </Typography>
                    </button>
                  )
                }
                {
                  card?.attributes?.bid_id && (
                    <Link to={`/QuoteDetails/${card?.attributes?.bid_id}`}>
                      <button style={webStyle.bidOnProjectText}>
                        
                          {(card?.attributes?.submitted_bid_type === "proposal" && card?.attributes?.bid_status === 1) ? "View Proposal" : "View Quote"}
                        
                      </button>
                    </Link>
                  )
                }
              </Grid>
            </Grid>
          </>
        )
      }

    </Box>
  );
}

export default ProjectCard;

const webStyle = {
  root: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  avatarBoxContainer: {
    display: "flex",
    flexDirection: 'column' as const,
    padding: "15px",
    gap: "10px"
  },
  sponsorText: {
    fontSize: "11x",
    fontWeight: 400,
    color: "#8083a3",
    fontFamily: 'Inter',
    padding: "5px 0px"
  },
  progressAvatar: {
    height: '30px',
    width: '30px',
  },
  avatarText: {
    fontSize: "16x",
    fontWeight: 700,
    color: "#000",
    fontFamily: 'Inter',
    marginLeft: '1vw',
  },
  messageGreyBox: {
    width: '82px',
    height: '46px',
    borderRadius: '23px',
    backgroundColor: '#E4E6E8',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'hidden' as const,
  },
  messageImg: {
    height: '19px',
    width: '19px'
  },
  messageImgText: {
    fontSize: "14x",
    fontWeight: 700,
    color: "#222",
    fontFamily: 'Inter',
    marginLeft: '8px',
  },
  iconDesign: {
    width: "16px",
  },
  bidOnProjectText: {
    border: "none",
    backgroundColor: "#EC952D",
    cursor: "pointer",
    color: "white",
    padding: "9px 10px",
    borderRadius: "18px",
    fontWeight: "bold",
  } as const,
  favouriteIcon: {
    border: "1px solid #ddd",
    height: "36px",
    width: " 36px",
    cursor: "pointer",
    borderRadius: "50%",
    background: "#fff",
  },
  likeIconDesign: {
    border: "1px solid #ddd",
    height: "36px",
    width: " 36px",
    borderRadius: "50%",
    cursor: "pointer",
    background: "#fff",
  },
  showSimilarContainer: {
    padding: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  } as const,
  projectSub_subDetails: {
    fontSize: "16px",
    color: "#222",
    fontWeight: 700,
    fontFamily: 'Inter',
  },
  projectSubDetails: {
    color: "8083A3",
    fontSize: "12px",
    fontFamily: 'Inter',
  },
  projectDescriptionTextDesign: {
    fontSize: "14px",
    color: "#222",
    fontWeight: 400,
    fontFamily: 'Inter',
    whiteSpace: 'pre-wrap' as const,
    wordBreak: 'break-word' as const,
  },
  projectSubInfoType: {
    color: "#8083A3",
    fontSize: "12px",
    marginRight: "5px",
  },
  projectDescriptionBoxContainer: {
    padding:"5px",
    // borderRadius: "15px",
    // border: '1px solid #e4e6e8'
  },
  projectDescriptionBox: {
    borderRadius: "15px",
    marginBottom: "24px",
    border: "1px solid rgb(228, 230, 232)",
    cursor: 'pointer',
  },
  cardProjectName: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#000000",
    whiteSpace: "normal", 
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: 'break-all'
  }as const,
  fb_projectHeader: {
    witdh:"100%",
    alignItems: "center",
    padding: "15px",
  },
  fb_projectSubInfo: {
    display: "flex",
    alignItems: "center",
    marginTop: "12px",
    cursor: "default"
  },
  fb_category: {
    display: "flex",
    alignItems: "center",
    marginTop: "1vw",
    padding: '1vw',
    flexWrap: 'wrap' as const,
  },
  fb_categoryBox: {
    background: "#E4E6E8",
    color: "#8083A3",
    padding: "6px 20px",
    borderRadius: "20px",
    margin: "10px"
  },
  fb_projectDescriptionText: {
    padding: "1vw 2vw"

  },
  projectDetails: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    padding: '1vw 2vw',
  },
  bidTheProjects: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "10px",
    justifyContent: "flex-end",
    flexWrap: "wrap" as const,
  },


  showSmiliarText: {
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    color: "#000",
    marginLeft: "0px",
  },
  bulbIcon: {
    height: "19px",
    marginRight: "14px",
    color: "black",
  },
  projecctHeaderDetails: {
    display: "flex", 
    alignItems: "center", 
  },
  projecctHeaderDetailsMarginRight: {
    display: "flex", 
    alignItems: "center", 
    marginRight: "5px",
  }
};


