import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    favCards: any[];
    ratingsLoading: boolean;
    keepShowMore: boolean;
    currentPage: number;
    totalReviews: number;
    nextPage: number | null;
    // Customizable Area End
}
export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}
export default class ReviewsController extends BlockComponent<
    Props, S, SS
> {

    // Customizable Area Start
    getRatingsAPICallId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);
        // Customizable Area Start

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
        ];

        this.state = {
            // Customizable Area Start
            favCards: [],
            ratingsLoading: true,
            keepShowMore: false,
            currentPage: 1,
            totalReviews: 0,
            nextPage: null,

            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    async componentDidMount() {
        // Customizable Area Start
        this.getRatingData()
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            runEngine.debugLog("API Message Recived", message);

            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.getRatingsAPICallId) {
                    this.setState({
                        nextPage: responseJson?.pagination?.next_page,
                        ratingsLoading: false,
                        favCards: [...this.state.favCards, ...responseJson.reviews.data],
                        totalReviews: responseJson?.pagination?.total_data || 0
                    })

                }

            }
        }

        // Customizable Area End
    }
    // Customizable Area Start
    handleProjectShowMore = () => {
        this.setState(
            (prev) => ({ currentPage: prev.currentPage + 1, ratingsLoading: true }),
            () => {
                this.getRatingData()
            }
        );
    };

    getRatingData = async () => {

        const header = {
            token: await getStorageData("loginSuccessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRatingsAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getRatingAndReviewsAPIEndpoint}?current_page=${this.state.currentPage}&per_page=2&rated_from=sponsor`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);


    }

    // Customizable Area End
}