import React from 'react';
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IconButton, Typography } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import DescriptionIcon from '@material-ui/icons/Description';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chat_docs_container: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    chat_doc_image_container: {
      height: "auto",
      maxWidth: "400px",
      borderRadius: "10px",
      padding: "10px",
      position: "relative",
      background: "white",
    },
    chat_doc_image_download: {
      position: "absolute",
      right: "10px",
      top: "10px",
      zIndex: 100,
    },
    chat_doc_img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "10px",
    },
    chat_doc_file: {
      width: "100%",
      height: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "white",
      borderRadius: "10px",
    },
    chat_doc_file_left: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      paddingLeft: "5px",
    },
    chat_doc_file_right: {},
  })
);

const getFileSizeInMB=(fileSize:number)=>{
  return `${(fileSize / (1024 * 1024)).toFixed(2)} MB`
}
const isThisFileAnImage=(isLocalImage:boolean,file:any)=>{
  if(isLocalImage && !file?.blob_id){
   return URL.createObjectURL(file);
  }else{
   return file?.url?.url;
  }
}
interface Props{
    file:any;
    isLocalImage:boolean;
}
const ChatFileViewer:React.FC<Props>=({
    file,
    isLocalImage
})=>{
    const [isImageFile,setIsImageFile]=React.useState(false);
    const classes=useStyles();

    React.useEffect(()=>{
        if(isLocalImage){
            setIsImageFile(file?.type?.includes("image"));
          }else if(!isLocalImage && file?.url?.url){
            const checkImage=async()=>{
                try {
                    const response = await fetch(file?.url?.url);
                    const blob = await response.blob();
                    setIsImageFile(blob.type.startsWith('image'));
                  } catch (error) {
                  }
              }
            checkImage()
        }
            
    },[])

  const handleDownloadFile = async (url: string, fileName: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(fileUrl);
  };

   
    
  const fileName=isLocalImage?file?.name:file?.url?.name;
  const fileLink=isThisFileAnImage(isLocalImage,file);
    if (isImageFile) {
      return (
        <div className={classes.chat_doc_image_container}>
          <div className={classes.chat_doc_image_download}>
            <IconButton onClick={()=>handleDownloadFile(fileLink,fileName)}>
              <GetAppIcon />
            </IconButton>
          </div>
          <img
            className={classes.chat_doc_img}
            src={fileLink}
            alt="messageImage"
          />
        </div>
      );
    }

    return (
      <div className={classes.chat_doc_file}>
        <div className={classes.chat_doc_file_left}>
          <DescriptionIcon style={{fontSize:"30px"}}/>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              alignItems:"start"
            }}
          >
            <Typography style={{ fontSize: "12px", textAlign: "left", }}>
              {isLocalImage?file?.name:file?.url?.name.slice(0,10)}
            </Typography>
            <Typography style={{ fontSize: "10px" }}>
              {
                getFileSizeInMB(isLocalImage?file?.size:file?.url?.size)
              }
            </Typography>
          </div>
        </div>
        <div className={classes.chat_doc_file_right}>
          <IconButton onClick={()=>handleDownloadFile(fileLink,fileName)}>
            <GetAppIcon />
          </IconButton>
        </div>
      </div>
    );
}


export default ChatFileViewer;