Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.GETApiMethod = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.POSTAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "KnowYourCustomerKycVerification";
exports.labelBodyText = "KnowYourCustomerKycVerification Body";
exports.getonfidoApi="https://api.onfido.com/v3/sdk_token";
exports.DIGIO_SDK_SRC="https://app.digio.in/sdk/v8/digio.js";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End