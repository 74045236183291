// Customizable Area Start
import React from "react";
import FreelancerBrowseProjectsController, {
  Props,
  configJSON,
} from "./FreelancerBrowseProjectController.web";
import {  Box ,Paper} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import GenericTab from "../../../../components/src/GenericTab";
import ProjectFilter from "./ProjectFilter.web";
import ProjectCard from "../../../../components/src/ProjectCard.web";

const tabs = [
  "All",
  "Quote open Projects",
  "Non-Quote Projects",
];

class FreelancerBrowseProject extends FreelancerBrowseProjectsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      projectList,
      projectLoading,
      totalProjects,
      activeTab,
    } = this.state;
    console.log("Total Projects",totalProjects)
    const isShowMoreEnable = totalProjects > projectList?.length;
    return (
      <Box>
        <ProjectFilter
          onSearch={this.searchHandler}
          onFilter={this.filterHandler}
          onCancelFilter={this.cancelFilterHandler}
          isUserFreelancer={true}
        />
        <style>
          {`
            .tabscroll button {
              margin-bottom: 10px;
            }
          `}
        </style>
        <Box style={webStyle.projectDetailsTab}>
          <GenericTab
            className="alltabs"
            activeTabColor={"#EC952D"}
            tabLabels={tabs}
            activeTab={activeTab}
            handleTabChange={this.handleTabChange}
          />
        </Box>
        {!projectLoading && projectList?.length===0 && (
          <Paper className="border-block p-0 projectloading-main" > 
           <div  className='projectloading'> <h2>No Project Found</h2></div>
          </Paper>
        )}

        {projectList?.map((card: any, index: number) => (

          <ProjectCard 
            key={card?.id}
            card={card}
            onSubmitBidClick={this.navigateToSubmitBidPage}
            onBodyClick={this.navigateToBidProjectDetails} 
            fav={this.addFavouritesProject}
            onShowSimilar={this.onShowSimilar}
            />
        ))}
        <Box style={webStyle.loadMoreBtn}>
          {projectLoading && (
            <Box>
              <CircularProgress />
            </Box>
          )}

          {isShowMoreEnable && !projectLoading && (
            <button
              data-testid="loadMore"
              style={webStyle.showMoreBtn}
              onClick={this.handleProjectLoadMore}
              hidden={projectLoading}
            >
              {configJSON.showMoreTxt}
              <ArrowForwardIosIcon style={webStyle.forwardIcon} />
            </button>
          )}
        </Box>
      </Box>
    );
  }
}

export default FreelancerBrowseProject;

export const webStyle = {
  showMoreBtn: {
    color: "#000",
    borderRadius: "26px",
    fontSize: "14px",
    fontWeight: 600,
    padding: "12px 18px",
    background: "#F2F2F2",
    height: "40px",
    width: "134px",
    border: "none",
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  forwardIcon: {
    fontSize: "14px",
  },
  loadMoreBtn: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: "10rem",
    justifyContent: "center",
    margin: "auto",
  } as const,
  projectDetailsTab: {
    margin: "24px 0px",
    paddingBottom: "10px"
  },
  getstart_btnbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "37px",
    width: "1103px",
    margin: "40px auto",
    backgroundColor: "#feecd6",
    borderRadius: "18px",
    border: "1px solid #EC952D",
    color: "#8083A3",
    position: "relative"
  } as const,
};

// Customizable Area End
