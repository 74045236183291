Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.facebookButtonText = "Sign up with Facebook";
exports.googleButtonText = "Sign up with Google";
exports.signUpButtonText = "Sign up with email and password";
exports.orText = "or";
exports.signUpBodyText = "Value proposition: why users should sign up.";
exports.signUpTitleText = "Sign up";
exports.changepassvalidation = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/
exports.passwordRegPattern= /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\_*])(?=.{8,})/

exports.contentTypeApi = "application/json";
exports.apiGetMethodType = "GET";
exports.termsContentApiCallUrl="account_block/terms_of_services?title=User Agreements";
exports.msgDisplay = "Password must be 8 or more characters, should contain uppercase and lowercase characters, at least one digit and one special character."
exports.getUserRoleApiUrl="bx_block_roles_permissions/roles"
exports.getCountryListApiUrl="bx_block_profile/profiles/get_countries"
exports.getStateListApiUrl="bx_block_profile/profiles/get_states"
exports.getCityListApiUrl="bx_block_profile/profiles/get_cities"
exports.getTimeZoneListApiUrl="bx_block_profile/profiles/time_zones"
exports.getCurrentUserApiUrl="bx_block_profile/profiles/profile_current_email"
exports.getEducationApiUrl="bx_block_profile/profiles/education_list"
exports.getUniversityListApiUrl="bx_block_profile/profiles/university_college_list"
exports.createUniversityApiUrl="bx_block_profile/profiles/create_university"
exports.getFieldOfStudyApiUrl="bx_block_profile/profiles/field_of_study_list"
exports.getIndustryListApiUrl="bx_block_profile/industries"
exports.getProjectCategoryApiUrl="bx_block_profile/projects/get_project_categories"
exports.getSkillListApiUrl="bx_block_profile/skills/get_skills"
exports.getFreelancingExperienceApiUrl="bx_block_auctionbidding/freelancer_experiences"
exports.getCurrencyListingApiUrl="bx_block_profile/profiles/get_currency_listing?enable=true";
exports.getGoogleProfileApiEndpoint="https://www.googleapis.com/oauth2/v1/userinfo?alt=json";
exports.getLinkedinProfileApiEndpoint=`https://api.linkedin.com/v1/people/~:(id,first-name,email-address,last-name,headline,picture-url,industry,summary,specialties,positions:(id,title,summary,start-date,end-date,is-current,company:(id,name,type,size,industry,ticker)),educations:(id,school-name,field-of-study,start-date,end-date,degree,activities,notes),associations,interests,num-recommenders,date-of-birth,publications:(id,title,publisher:(name),authors:(id,name),date,url,summary),patents:(id,title,summary,number,status:(id,name),office:(name),inventors:(id,name),date,url),languages:(id,language:(name),proficiency:(level,name)),skills:(id,skill:(name)),certifications:(id,name,authority:(name),number,start-date,end-date),courses:(id,name,number),recommendations-received:(id,recommendation-type,recommendation-text,recommender),honors-awards,three-current-positions,three-past-positions,volunteer)?format=json`;

// Customizable Area End