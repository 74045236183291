export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const productImg = require("../assets/shoes.png");
export const confirmImage = require("../assets/confirm.png");
export const user = require("../assets/user.png");
export const notification = require("../assets/notification.png");
export const SearchIcon = require("../assets/SearchIcon.png");
export const Filter = require("../assets/filter.png");
export const projectInProgress = require("../assets/projectInProgress.png");
export const graterthen = require("../assets/graterthen.png");
export const completedProject = require("../assets/completedProject.png");
export const cancel_btn = require("../assets/cancel_btn.png");
export const leftArrow = require("../assets/leftArrow.png");
export const location = require("../assets/location.png");
export const calander = require("../assets/calander.png");
export const wallet = require("../assets/wallet.png");
export const UploadImg = require("../assets/UploadImg.png");
export const download = require("../assets/download.png");
export const Well = require("../assets/Well.png");
export const heart_blank = require("../assets/heart_blank.png");
export const heart_black = require("../assets/heart_black.png");
export const message = require("../assets/message.png");
export const rightArrow = require("../assets/rightArrow.png");
export const userProfileImage = require("../assets/userImage.png");
export const accept_bid = require("../assets/accept_bid.png");
export const image_close = require("../assets/image_close.png");
export const addIcon = require("../assets/addIcon.png");
export const dateIcon = require("../assets/dateIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const importFile = require("../assets/importFile.png");
export const info_circle = require("../assets/info-circle.png");
export const button_delete = require("../assets/button_delete.png");
export const pencil = require("../assets/pencil.png");
export const images = require("../assets/images.jpeg");
export const notFoundImg=require("../assets/not-found.svg")
