// Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {}

interface SS {}

export default class FreelancerProjectsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ]);

    this.state = {};
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
  }

  getParamName = () => {
    return this.props.navigation.getParam("type") ? this.props.navigation.getParam("type"): "browse-all";
  };
}

// Customizable Area End
