import React from 'react';
import {Field, ErrorMessage} from 'formik';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomSelect from './CustomSelect.web';
import {MenuItem} from '@material-ui/core'
import InputError from '../../../blocks/social-media-account-registration/src/InputError.web';
import { error } from 'console';

interface Props{
    stateList:string[];
    setFieldValue:any;
    stateLoading:boolean;
    fetchCities:(stateName:string)=>void;
    country:string;
    value:string;
    tabIndex?:number;
    error:string;
}
const StateTextInput:React.FC<Props> = ({stateList,setFieldValue,stateLoading,fetchCities,country,value,error,tabIndex}) => {
    return (
      <>
        {
          country?.trim() && stateList?.length>0 ?(
                <>
                  <CustomSelect
                          value={value}
                          onChange={(e:any)=>{
                            setFieldValue("state",e.target.value);
                            fetchCities(e.target.value)
                            setFieldValue("city","");
                          }}
                          label="State*"
                          renderItem={(item,props)=>(
                              <MenuItem
                                key={item}
                                value={item}
                                {...props}
                              >
                              {item}
                            </MenuItem>
                          )}
                          name="state"
                          id="state"
                          tabIndex={tabIndex}
                          data={stateList}
                          // error={error}
                          placeholder="Select your state"
                          disabled={!country?.trim()}
                        />
                        {!value && (
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="error-class"
                          />
                        )}
                </>
          ):(
            <div className="form-group">
              <label htmlFor="state">State*</label>
              <Field
                test-id="state"
                id="state"
                disabled={!country?.trim()}
                name="state"
                placeholder="Enter your State"
                tabIndex={10}
                onChange={(e:any)=>{
                  setFieldValue("state",e.target.value);
                  setFieldValue("city","");
                  }}
                />
                 <InputError errors={error?{state:error}:{}} name="state" />
                 
            </div>
          )
        }
      
      </>
      
      );
}
 
export default StateTextInput;