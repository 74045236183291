import * as React from "react";

const FormSaver: React.FC<any> = ({ children, saveFieldName,saveInfo, ...rest }) => {
  React.useEffect(() => {
    if(saveInfo){
        localStorage.setItem(saveFieldName, JSON.stringify(rest.values));
    }
  }, [rest?.values,saveInfo]);

  return <>{children(rest)}</>;
};

export default FormSaver;