import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {Organisation, Team, TeamMember, TeamMemberData} from './IFullteamVisibility'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  teamList:string[];
  memberList:TeamMember[];
  departmentList:string[];
  orgData : Organisation[];
  roleList:string[];
  regionList:string[];
  filterData:{
    role_type:string|undefined;
    region:string|undefined;
    team_name:string|undefined;
  };
  showOrgChart:boolean;
  showFilter:boolean;
  newList:TeamMember[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FullTeamVisibilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  memberListMessageId:string=''
  teamListMessageId:string = ''
  orgMessageId:string=''
  departmentListMessageId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showFilter:false,
      memberList:[],
      teamList:[],
      departmentList:[],
      showOrgChart:false,
      orgData:[],
      roleList:[],
      regionList:[],
      filterData:{
        role_type:undefined,
        region:undefined,
        team_name:undefined,
      },
      newList:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
        if(responseJson && responseJson.data){
          if(this.teamListMessageId === apiRequestCallId){
            this.setState({teamList:this.getAllNames(responseJson.data)})
          }
        
          else if(this.memberListMessageId === apiRequestCallId){
            this.setState({
              memberList:this.appendAll(responseJson.data),
              roleList:this.getAllData(this.appendAll(responseJson.data),true),
              regionList:this.getAllData(this.appendAll(responseJson.data),false)
            })
          }

            else if(this.departmentListMessageId === apiRequestCallId){
                this.setState({
                  departmentList:this.getAllNames(responseJson.data)
                })
            }
          
          else if (this.orgMessageId === apiRequestCallId){
            this.setState({orgData:responseJson.data})
          }
        }
        else if (errorReponse){
          this.showAlert("ERROR",'Unable to fetch data')
        }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  
  toggleOrgChart =()=>{
    this.setState({showOrgChart:!this.state.showOrgChart})
  }
  toggleFilter = ()=>{
    this.setState({showFilter:!this.state.showFilter})
  }

  handleValueChange = (type:'role'|'region'|'team',value:string)=>{
    if(type==='role') this.setState({filterData:{...this.state.filterData,role_type:value}})
    else if(type === 'region') this.setState({filterData:{...this.state.filterData,region:value}})
    else this.setState({filterData:{...this.state.filterData,team_name:value}})
  }

  

  apiCall = async (method:string, endPoint:string) => {

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  appendAll=(data:TeamMemberData[])=>{
    let newArray:TeamMember[] = [];
    data.forEach((item) => {
      item.attributes.member_details.forEach((detail:TeamMember) => {
        newArray.push(detail);
      });
    });
    return newArray;
  }

  getAllNames=(data:Team[])=>{
    let allNames = data.map((item:Team)=>item.attributes.name)
    return allNames
  }

  getAllData=(data:TeamMember[],isRole:boolean)=>{
    
    let newArr
    if(isRole) newArr = data.map((item:TeamMember)=>item.role_type) 
    else {
      newArr = data.map((item:TeamMember)=>item.region)
    }
    newArr = Array.from(new Set(newArr))
    return newArr
  }

  filter = (data:{role_type:string|undefined,region:string|undefined,team_name:string|undefined}) =>{
    let filteredArr = this.state.memberList.filter((item) => {
      return (
        (data.role_type == undefined || item.role_type === data.role_type) &&
        (data.region == undefined || item.region === data.region) &&
        (data.team_name == undefined || item.team_name === data.team_name)
      );
    });
    this.setState({newList:filteredArr})
  }

  getMemberList = async() =>{
    this.memberListMessageId = await this.apiCall(configJSON.GET_Method,configJSON.MemberListEndpoint)
  }

  getTeamList = async() =>{
    this.teamListMessageId = await this.apiCall(configJSON.GET_Method,configJSON.TeamListEndpoint)
  }

  getDepartmentList = async() =>{
    this.departmentListMessageId = await this.apiCall(configJSON.GET_Method,configJSON.DepartmentListEndpoint)
  }

  getOrgData =async()=>{
    this.orgMessageId =  await this.apiCall(configJSON.GET_Method,configJSON.OrgDataEndpoint)
  }


  async componentDidMount() {
    await this.getMemberList()
    await this.getTeamList()
    await this.getDepartmentList()
    await this.getOrgData()
    
  }

  // Customizable Area End
}