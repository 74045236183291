import React from 'react'

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Link } from 'react-router-dom';

const linkStyle={
    textDecoration:"none"
}
interface Props{
    activeAccountType?:boolean;
    activeBasicDetails?:boolean;
    activeProfileSetup?:boolean;
}
const ProfileSetupSteper:React.FC<Props>=({activeBasicDetails=false,activeProfileSetup=false,activeAccountType=true})=>{

    return(
        <div className="step-block">
              <ul>
                <Link style={linkStyle} to={activeAccountType?"/Accountinfo":"#"}>
                    <li className={activeAccountType?"active":"step-text"}>
                         Account Type <ChevronRightIcon />
                    </li>
                </Link>
                <Link style={linkStyle} to={activeBasicDetails?"/Basicdetails":"#"}>
                    <li className={activeBasicDetails?"active":"step-text"}>
                      Basic Details <ChevronRightIcon />
                     </li>
                </Link>
                <Link style={linkStyle} to={activeProfileSetup?"/Profilesetup":"#"}>
                    <li className={activeProfileSetup?"active":"step-text"}>
                        Profile Setup 
                    </li>
                </Link>
              </ul>
        </div>
    )
}
export default ProfileSetupSteper;