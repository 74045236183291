import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?: any;


}
export interface S {
  // Customizable Area Start
  cards: any[];
  visibleCards: number;
  activeTab: number;
  filterActiveTab: number;
  loading: boolean; 
  totalCards: number;
  searchQuery: string;
  availableNow: boolean;
  favourite: boolean;
  message: boolean;
  hire: boolean;
  invite: boolean;
  filter: boolean;
  back: boolean;
  pageNumber: number;
  authtoken: any;
  error: any;
  filterTabValue: string;
  filterOptions: any;
  cardLoading:{}
  freeLancerListSelectedOption: {
    skills: any[],
    country: any[],
    experience_in_years: any,

  };
  displayFilterSortCard: boolean;
  tabLables: {
    tabFilterLabels: string[]
  };

  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class SearchController extends BlockComponent<
  Props, S, SS
> {

  // Customizable Area Start
  freeLancerListCallID: any;
  sendMesageCallID: any;
  sendFilterCallID: any;
  freeLancerSkillsCategoryListCallID:any;
  freeLancerSearchListCallID:any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      // Customizable Area Start

      cards: [],
      visibleCards: 4,
      activeTab: 0,
      filterActiveTab: 0,
      loading: true,
      totalCards: 4,
      searchQuery: "",
      availableNow: false,
      favourite: false,
      message: false,
      hire: false,
      invite: false,
      filter: false,
      back: false,
      pageNumber: 1,
      authtoken: window.localStorage.getItem("loginSuccessToken"),
      error: '',
      filterTabValue: '',
      cardLoading:{},
      filterOptions: {
        skills: [],
        country: ["India", "USA", "England"],
        __experience_in_years: [1, 2, 3, 4, 5],
      },
      freeLancerListSelectedOption: {
        skills: [],
        country: [],
        experience_in_years:null,
      },
      displayFilterSortCard: false,
      tabLables:
      {
        tabFilterLabels: ['Newest First', "Oldest First", "Highest Budget First", "Lowest Budget First", "Most Bids First", "Least Bids First"],
      }

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentWillMount() {
    // Customizable Area Start
    this.freeLancerList(this.state.pageNumber);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
  
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (responseJson && !responseJson.errors) {
      if (
        apiRequestCallId === this.freeLancerListCallID ||
        apiRequestCallId === this.sendFilterCallID ||
        apiRequestCallId ===this.freeLancerSearchListCallID
      ) {
        this.setState((prevState) => ({
          cards: [...prevState.cards, ...(responseJson?.data || [])]
        }));
        this.setState({
          loading: false,
            totalCards:responseJson?.meta?.total         
        });
      } else if (apiRequestCallId === this.sendMesageCallID) {
        this.props.navigation.navigate('SearchSuccessWeb');
        this.setState({
          loading: false,
          totalCards:responseJson?.meta?.total 
        });
      } else if (apiRequestCallId === this.freeLancerSkillsCategoryListCallID) {
        this.setState((prevState) => ({
          filterOptions: {
            ...prevState.filterOptions,
            skills: responseJson
          }
        }));
      }
    } else {
      const error = responseJson?.errors?.[0]?.token || "Something Went Wrong";
      this.setState({
        error: error,
        pageNumber: 1,
        cards: [],
        loading: false
      });
    }
  }
  
  //web events
  // Customizable Area Start 
  freeLancerList = (pageNumber: number) => {
    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json",
      page_number: pageNumber
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.freeLancerListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/search_algorithms/freelancer_visibility`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getProjectSkillsCategoryListAPI=()=>{

    const header = {
        token: this.state.authtoken,
        "Content-Type": "application/json"


    };

    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.freeLancerSkillsCategoryListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/search_algorithms/fetch_skills`
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}


  sendMessageApi = (id: number) => {

    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json"
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendMesageCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/search_algorithms/freelancer_projects`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

 

  sendFreeLancerFilterApi = (
    pageNumber: number,
    country?: string[],
    experience_in_years?: number[],
    skills?: string[],
    filterTabValue?: string
  ) => {
    const header = {
      token: this.state.authtoken,
      "Content-Type": "application/json"
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendFilterCallID = getValidationsMsg.messageId;

    let queryParams = [];

    

    if (skills !== undefined &&skills.length > 0) {
      queryParams.push(`skills[]=${skills.join('&skills[]=')}`);
    }

    if (country !== undefined && country.length > 0) {
      queryParams.push(`country[]=${country.join('&country[]=')}`);
    }
    if (experience_in_years) {
      queryParams.push(`experience=${experience_in_years}`);
    }
    if (filterTabValue !== undefined) {
      if (filterTabValue.toLowerCase() === "newest first") {
        queryParams.push(`order=desc`);
      } else if (filterTabValue.toLowerCase() === "oldest first") {
        queryParams.push(`order=asc`);
      }
    }

    const queryString = queryParams.join('&');

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/search_algorithms/filters_profile?${queryString}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };


  sendSearchListAPI=(searchQuery:string)=>{

    const header = {
        token: this.state.authtoken,
        "Content-Type": "application/json"


    };

    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.freeLancerSearchListCallID = getValidationsMsg.messageId;

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/search_algorithms/search_profile?search=${searchQuery}`
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
    );

    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
  

  handleLoadMore = () => {
    const { loading, pageNumber, filter, searchQuery } = this.state;

    if (!loading) {
      this.setState({ loading: true, pageNumber: pageNumber + 1 }, () => {

        if (filter && searchQuery) {
          // Fetch data with filter and searchQuery
         
        } else if (filter) {
          // Fetch data with filter
         
          this.freeLancerList(this.state.pageNumber);
        } else if (searchQuery) {
          // Fetch data with searchQuery
          this.freeLancerList(this.state.pageNumber);
        } else {
          // Fetch data without filter and searchQuery
          this.freeLancerList(this.state.pageNumber);
        }
      });
    }
  };




  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ loading: true,activeTab: newValue ,cards:[] })
    this.freeLancerList(1);
  };

  handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value });
  };

  handleSearchClick = () => {

    this.setState({ cards: [], loading: true }, () => {

      this.sendSearchListAPI(this.state.searchQuery);
    
    })
  };

  handleAvailableNow = () => {
    this.setState({ availableNow: true });
  };
  handleFavourite = () => {
    this.setState({ favourite: true });

  };

  handleMessage = (id: number) => {
    this.setState({ loading: true ,message: true ,cardLoading: {  [id]: true } });
    const idx = 1
    this.sendMessageApi(idx);

  };

  handleHire = () => {
    this.setState({ hire: true });
    this.setState({ hire: true })
  };
  handleInvite = () => {
    this.setState({ invite: true });
    this.setState({ invite: true })
  };

  handleBack = () => {
    this.setState({ back: true });
    this.props.navigation.navigate("SignUp")
  };
 

  handleBrowsePostedProjects = () => { };
  handlePostAnotherProject = () => { };

 

  handleProjectApplyClick = () => {

    this.setState({ loading: true, cards: [] }, () => {
     


      this.sendFreeLancerFilterApi(this.state.pageNumber, this.state.freeLancerListSelectedOption.country, this.state.freeLancerListSelectedOption.experience_in_years, this.state.freeLancerListSelectedOption.skills, this.state.filterTabValue)
    })

  }

  handleClearSelectedOptions = () => {

    this.setState({
      freeLancerListSelectedOption: {
        skills: [],
        country: [],
        experience_in_years: [],
      }
    });
  };


  handleProjectBidOptionsChange = (selectedOptions: React.ChangeEvent<{ value: unknown }>, fieldName: string) => {
   
    this.setState((prevState) => {
      return {
        freeLancerListSelectedOption: {
          ...prevState.freeLancerListSelectedOption,
          [fieldName]: selectedOptions.target.value
        },

      };
    });
  }

  handleFilterSortCard = () => {
    this.getProjectSkillsCategoryListAPI();
    this.setState({ displayFilterSortCard: !this.state.displayFilterSortCard })

  }



handleClearSearch = () => {
    this.setState({ searchQuery: "", cards: [], loading: true }, () => {
     
        this.freeLancerList(this.state.pageNumber);
    });
};

  handleProjectBidTabChange = (event: React.ChangeEvent<{}>, newValue: number, tabId: number) => {
   

    if (tabId == 0) {

      this.setState({ filterActiveTab: newValue, filterTabValue: this.state.tabLables.tabFilterLabels[newValue] }, () => {
       
      })

    }
    if (tabId == 1) {

      this.setState({ loading: true, activeTab: newValue, cards: [] })
      this.freeLancerList(this.state.pageNumber);
    }

  };




  // Customizable Area End
}

