import React from 'react';

export const COLOR_HIGH_CONTRAST = "#8884d8";
export const COLOR_LOW_CONTRAST = "#82ca9d";
export const COLOR_DARK_YELLOW = "#ffc100";

const CustomTooltip = (props: any) => {
  
  const { active, payload, label } = props;
 
  if (active && payload && payload.length) {
    return (
      <div>
        <p style={{fontWeight:600}}> {label}</p>
             
          <div >
          <p> Status: {payload[0]?.payload.milestone_status}</p>
            <ul style={{listStyleType: "square",marginLeft:'20px'}}>
            {
            payload[0]?.payload.exactCompletedDate &&
            <li style={{padding:"3px 0 0 0", color:COLOR_HIGH_CONTRAST , fontWeight:400 }}> Completed in (days): {payload[0]?.payload.exactCompletedDate}</li>
          }
            {
            payload[0]?.payload.expectedCompletedDate &&
            <li style={{padding:"3px 0 3px 0", color:COLOR_LOW_CONTRAST , fontWeight:400 }}> Total required (days): {payload[0]?.payload.expectedCompletedDate}</li>
            }

           </ul>
    
        </div>
  
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
