import React from 'react';

interface Props{
    errors:any;
    name:string;
}

const InputError:React.FC<Props>=({errors,name})=>{
    if(errors[name]){
        return <p className='error'>{errors[name]}</p>
    }else{
        return null;
    }
}

export default InputError;