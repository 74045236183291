// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as yuppp from "yup";
// Customizable Area Start
// import {
//   Onfido,
//   OnfidoCaptureType,
// } from "@onfido/react-native-sdk";
import { Platform } from "react-native";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { toast } from "react-toastify";

type IVerificationStatus="approval_pending"|"approved"|"rejected"|"requested"|"not_initiated";
interface IDigioData {
  data: {
      id: string;
      type: string;
      attributes: {
          account_id: number;
          status: IVerificationStatus;
          kid: string;
          otp_verified: boolean;
      };
  };
}
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;

  // Customizable Area Start
  currentUser:IProfile|null;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  firstNameDefault:string,
  lastNameDefault: string,
  middleNameDefault:string,
  emailStateDefault:string,
  nationalityDefault:string,
  dateOfBirth:string,
  phoneNumber:string;
  openVerificationModal:boolean;
  getVerificationStatusLoading:boolean;
  sendVerificationStatusLoading:boolean;
  visibleSentRequestBtn:boolean;
  verificationStatus:{
    status:IVerificationStatus,
    phoneUpdated:boolean,
  }
  // Customizable Area End
}
interface SS {
  // Customizable Area Start

  // Customizable Area End
}
export default class KnowYourCustomerKycVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getVerifyIndentitycallID: string = '';
  getCreateaccountCallID: string = '';
  getreportCallID: string = '';
  // Customizable Area Start
  getVerificationStatusApiCallId:string="";
  sendVerificationRequestApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      firstNameDefault: "",
      lastNameDefault: "",
      middleNameDefault: "",
      emailStateDefault: "",
      nationalityDefault: "",
      dateOfBirth: "",
      phoneNumber: "",
      openVerificationModal:false,
      getVerificationStatusLoading:false,
      sendVerificationStatusLoading:false,
      visibleSentRequestBtn:false,
      verificationStatus:{
        phoneUpdated:false,
        status:"not_initiated"
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message)//istanbul ignore next
  {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id)//istanbul ignore next
    {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     if(apiRequestCallId===this.getVerificationStatusApiCallId){ 
      this.handleVerificationStatusResponse(responseJson);
     }else if(apiRequestCallId===this.sendVerificationRequestApiCallId){
      this.handleSendVerificationResponse(responseJson);
     }
    }
  }
  handleSendVerificationResponse=(response:{status:string,id:string})=>{
    if(response?.status){
     this.setState(prev=>({
          sendVerificationStatusLoading:false,
          openVerificationModal:this.canModalVisable(response.status,prev.verificationStatus.phoneUpdated),
          visibleSentRequestBtn:this.isVisibleSentRequestBtn(response.status,prev.verificationStatus.phoneUpdated),
          verificationStatus:{
            phoneUpdated:prev.verificationStatus.phoneUpdated,
            status:response?.status
          }
      }),()=>{
          this.loadDigioSDK(response?.id)
      })
     toast.success("Verification link sent to your mail successfully.")
    }else{
      console.log("Error",response)
      this.setState({sendVerificationStatusLoading:false})
      toast.error("Oops! Something went wrong.")
    }
  }
  handleVerificationStatusResponse=(response:IDigioData)=>{
    this.setState({getVerificationStatusLoading:false});
    if(response?.data){
      const data=response?.data.attributes;
        this.setState({
            openVerificationModal:this.canModalVisable(data.status,data.otp_verified),
            visibleSentRequestBtn:this.isVisibleSentRequestBtn(data.status,data.otp_verified),
            verificationStatus:{
              phoneUpdated:data.otp_verified,
              status:this.getInitialStatus(data.status,data.otp_verified)
            }
          },()=>{
             if(response.data.attributes.status==="requested"){
               this.loadDigioSDK(response.data.attributes.kid)
             }
          })
    }else{
      this.setState({
         openVerificationModal:true,
         visibleSentRequestBtn:true
      })
    }
  }
 getInitialStatus=(status:IVerificationStatus,phone_verified:boolean)=>{
    if(status==="approved" && !phone_verified){
      return "not_initiated";
    }else if(status==="rejected" && !phone_verified){
      return "rejected";
    }
    return status;
 }
  canModalVisable=(status:IVerificationStatus,phone_verified:boolean)=>{
    if((status==="approved" || status==="rejected") && !phone_verified){
      return true;
    }else if(status==="approval_pending" || status==="requested"){
      return true;
    }else if(status==="rejected"){
      return true;
    }else{
      return false;
    }
  }
  
  isVisibleSentRequestBtn=(status:IVerificationStatus,phone_verified:boolean)=>{
    if(status==="approval_pending" || status==="requested"){
      return false;
    }
    return true;
  }

  getStatusProperties=()=>{
    const {status}=this.state.verificationStatus
     const propertiesObject:{
        [key:string]:{
           label:string;
           color:string;
        }
     }={
      "approval_pending":{
        label:"Pending",
        color:"#FFC107"
      },
      "approved":{
        label:"Approved",
        color:"#4CAF50"
      }, 
      "rejected":{
        label:"Rejected",
        color:"#F44336"
      },
      "requested":{
        label:"Requested",
        color:"#00ab41"
      },
      "not_initiated":{
        label:"Not Initiated",
        color:"#9E9E9E"
      },
     }
     return propertiesObject[status] || propertiesObject["not_initiated"];

  }


  componentDidMount(): Promise<void> {
      this.getVerificationStatus();
  }

  getVerificationStatus=async()=>{
    this.setState({getVerificationStatusLoading:true});
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `bx_block_knowyourcustomerkycverification2/check_kyc_status`,
      body: null,
      method: "POST",
    });
    this.getVerificationStatusApiCallId= apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  sendVerificationRequest=async()=>{
    this.setState({sendVerificationStatusLoading:true});
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `bx_block_knowyourcustomerkycverification2/create_kyc_request`,
      body: null,
      method: "POST",
    });
    this.sendVerificationRequestApiCallId= apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  getCurrentUserTheme=()=>{
    const {currentUser}=this.props;
    const isLoggedinUserSponsor=currentUser?.currentState?.toLowerCase()?.includes("sponsor");
    const color=isLoggedinUserSponsor?"#00A7A2":"#EB9E00";
    return color;
  }


  loadDigioSDK=(kycID:string)=>{
    const scriptEle = document.createElement("script");
    scriptEle.setAttribute("src",configJSON.DIGIO_SDK_SRC);
    document.head.appendChild(scriptEle);
    scriptEle.addEventListener("load", this.startDigio(kycID));
    scriptEle.addEventListener("error", this.digioLoadErrorCallBack);
  }
  digioLoadErrorCallBack=()=>{
    toast.error("Unable to load Digio Sdk.");
  }
  digioCallBack=(_digio:any)=>{
    if (_digio.error_code === "CANCELLED") {
      toast.error("Flow cancelled by user");
      return;
    }else if (_digio.error_code !== undefined) {
      toast.error(_digio.message)
    }
    this.getVerificationStatus();
  }
  startDigio=(kycID:string)=>{
    return ()=>{
      const {currentUser}=this.props;
      const email=currentUser?.attributes.account_detail?.data?.attributes?.email;
      const digoOptions = {
        logo:"",
        is_iframe: false
      };
    
      digoOptions.callback = this.digioCallBack;
      const digioInstance = new window.Digio(digoOptions);
      digioInstance.init();
      digioInstance.submit(kycID, email);

    };
  }



  startSDK(responseJson: string) {
    Onfido?.start({
      sdkToken: responseJson,
      flowSteps: {
        welcome: true,
        userConsent: true,
        captureDocument: {
        },
        captureFace: {
          type: OnfidoCaptureType.PHOTO
        },
        enableNFC: false
      }
    })
      ?.then((response: string) => { this.onreportApi() })
      .catch((error: string) => { })
  }

  signupValidationSchema = () => {
    return yuppp.object().shape({
      firstNameDefault: yuppp.string().required("Please, Enter your firstNameDefault!"),
      lastNameDefault: yuppp.string().required("Please, Enter your lastNameDefault!"),
      middleNameDefault: yuppp.string().required("Please, Enter your middleNameDefault!"),
      emailStateDefault: yuppp
        .string()
        .required("Please, provide your email")
        .email("Please enter valid email"),
      phoneNumber: yuppp
        .string()
        .required("Please, provide your mobile number"),
      nationalityDefault: yuppp.string().required("Please, Enter your nationalityDefault!"),
      dateOfBirth: yuppp.string().required("Please, Enter your Date of Birth!"),
    })
  }
  onNavigationGobackscreen = () => {
    
    this.props.navigation.navigate("KnowYourCustomerKycVerification")
  }

  createAccount = async (values: { firstNameDefault: string; lastNameDefault: string; middleNameDefault: string; emailStateDefault: string; nationalityDefault: string; dateOfBirth: string; phoneNumber: string; }) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const codedata = {
      first_name: values.firstNameDefault,
      last_name: values.lastNameDefault,
      middle_name: values.middleNameDefault,
      date_of_birth: values.dateOfBirth,
      nationalityDefault: values.nationalityDefault,
      phone_number: values.phoneNumber,
      email: values.emailStateDefault,
    }
    const httpBody = {
      data: codedata,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage))
    this.getCreateaccountCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
"https://datingappdemo-260453-ruby.b260453.dev.eastus.az.svc.builder.cafe//bx_block_knowyourcustomerkycverification2/applicant_create"
      
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  onfidouserdeta = async () => {
    const UserDataValidation = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getVerifyIndentitycallID = UserDataValidation.messageId;
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getonfidoApi
    )
    const headers = {
      "Authorization":"Token token=api_sandbox.VfzFDCaYox9.rTHqR4jClR6-IHzdAQQUML5bd7PlNZ_Q"
    }
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POSTAPiMethod
    )
    let APPID = Platform.OS == "ios" ? "DatingAppDemo" : "com.DatingAppDemo";
    let formdata = new FormData();
    const confidoId = await getStorageData('AppicantId');
    formdata.append("applicant_id", confidoId)
    formdata.append("application_id", APPID)
    UserDataValidation.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    runEngine.sendMessage(UserDataValidation.id, UserDataValidation);
  }

  onreportApi = async () => {
    const confidoId = await getStorageData('AppicantId');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.getreportCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      ''
      )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GETApiMethod
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }
  // web events
  // Customizable Area Start
  // Customizable Area End
}


