import React from "react";

import {
  // Customizable Area Start
  Grid,
  Box,
  Typography,
  FormHelperText,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  InputAdornment
  // Customizable Area End
} from "@material-ui/core";
import {configJSON} from '../../../blocks/projecttemplates/src/CreateNewMilestone.web'
// Customizable Area Start
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";
import { createStyles, Theme, makeStyles,withStyles } from "@material-ui/core/styles";
import {
  Add as AddCircleOutlineIcon,
} from "@material-ui/icons";
import { Milestone } from "../../../blocks/scheduling/src/SchedulingController";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    milestone_item_backButtonStyle: {
        borderRadius: 23,
        background: "#F2F2F2",
        color: "#222",
        textAlign: "center" as "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        padding: "5 35",
      },
      milestone_item_continueButtonSyle: {
        margin:"0 0 0 1rem",
        borderRadius: 23,
        backgroundColor: "#00A7A2",
        color: "#fff",
        textAlign: "center" as "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        padding: "5 35",
        "&:hover": {
          background: "#00A7A2",
        },
      },
      milestone_item_bottomBtnContainerStyle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 40,
        columnGap: 10,
        marginBottom: 20,
      },
      milestone_item_topContainer: {
        borderRadius: 14,
        border: "1px solid #E4E6E8",
        padding: 25,
      },
      milestone_item_timelineConectorStyles: {
        border: "1px dashed #00A7A2",
        width: "0",
        backgroundColor: "#fff",
      },
      milestone_item_timelineContentHeaderStyle: {
        color: "#222222",
        fontSize: "16px",
        fontWeight: 700,
        marginRight: "0.7vw",
        fontFamily: "Inter",
        marginBottom: 80,
      },
      milestone_item_addNewMilestoneBtn: {
        color: "#00A7A2",
        border: "none",
        backgroundColor: "#E5F6F6",
        width:"30px",
        height:"30px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        fontSize:"14px"
      },
      milestone_item_addNewMilestoneFieldsContainer: {
        display: "flex",
        flexDirection: "column" as "column",
      },
      milestone_item_formControlStyle: {
        margin: "15px 0px",
      },
      milestone_item_labelStyle: {
        color: "#8083A3",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 700,
        margin:"-7px 0 0 0"
      },
      milestone_item_placeholderStyle: {
        "&::placeholder": {
          color: "#8083A3",
          fontSize: "14px",
          fontFamily: "Inter",
          fontWeight: 400,
        },
      },
      milestone_item_inputValueStyle: {
        color: "#222",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 700,
      },
      milestone_item_sendContainerStyle: {
        display: "flex",
        justifyContent: "flex-end",
      },
      milestone_item_btnContainerStyle: {
        display: "flex",
        alignItems: "center",
        marginTop: 20,
        columnGap: 10,
      },
      milestone_item_closeBtnSyles: {
        borderRadius: 23,
        backgroundColor: "#00A7A2",
        color: "#fff",
        textAlign: "center" as "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        padding: "5 35",
        "&:hover": {
          backgroundColor: "#00A7A2",
        },
      },
      milestone_item_addNewmilestoneStyle: {
        cursor: "pointer",
        marginTop: 15,
      },
  })
);
// Customizable Area End
interface Props{
  value:Milestone | null;
  changeValue:(keyName:string,value:string)=>void;
  acceptedHourlyCost:number;
  newMilestone?:boolean;
  onSubmit:()=>void;
  onCancel:()=>void;
  saveLoading?:boolean;
}
 const MilestoneItem:React.FC<Props>=({value,changeValue,acceptedHourlyCost,onSubmit,onCancel,newMilestone,saveLoading})=>{
    const webStyles=useStyles();
    return (
      // Customizable Area Start

      <Box className={webStyles.milestone_item_topContainer}>
              <TimelineContent>
                <Box className={webStyles.milestone_item_addNewMilestoneFieldsContainer}>
                  
                      <FormControl fullWidth className={webStyles.milestone_item_formControlStyle}>
                        <FormHelperText
                          className={webStyles.milestone_item_labelStyle}
                          component="p"
                        >
                          {configJSON.milestoneTitleLabelTxt}
                        </FormHelperText>
                        <TextField
                          name="addNewMilestoneTitle"
                         
                          placeholder={configJSON.titlePlaceholder}
                          InputProps={{
                            classes: {
                              input: webStyles.milestone_item_placeholderStyle,
                              root: webStyles.milestone_item_inputValueStyle,
                            },
                          }}
                          onChange={(event) => changeValue("milestone_title",event?.target?.value)}
                          value={value?.attributes?.milestone_title}
                          error={Boolean(value?.errors?.milestone_title)}
                          helperText={value?.errors?.milestone_title}
                        />
                      </FormControl>
                      <FormControl fullWidth className={webStyles.milestone_item_formControlStyle}>
                        <FormHelperText
                          className={webStyles.milestone_item_labelStyle}
                          component="p"
                        >
                          {configJSON.milestoneDescriptionLabelTxt}
                        </FormHelperText>
                        <TextField
                          minRows={3}
                          multiline
                          name="addNewMilestoneDescription"
                          placeholder={configJSON.descriptionPlaceholder}
                          InputProps={{
                            classes: {
                              input: webStyles.milestone_item_placeholderStyle,
                              root: webStyles.milestone_item_inputValueStyle,
                            },
                          }}
                          onChange={(event) => changeValue("milestone_description",event?.target?.value)}
                          value={value?.attributes?.milestone_description}
                          error={Boolean(value?.errors?.milestone_description)}
                          helperText={value?.errors?.milestone_description}
                        
                        />
                      </FormControl>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            className={webStyles.milestone_item_formControlStyle}
                          >
                            <FormHelperText
                              className={webStyles.milestone_item_labelStyle}
                              component="p"
                            >
                              Required Hours
                            </FormHelperText>
                            <TextField
                              type="number"
                              InputProps={{
                                classes: {
                                  input: webStyles.milestone_item_placeholderStyle,
                                  root: webStyles.milestone_item_inputValueStyle,
                                },
                              }}
                              placeholder={"Required Hours"}
                              onChange={(event) => changeValue("hours",event?.target?.value)}
                              value={value?.attributes?.hours}
                              error={Boolean(value?.errors?.hours)}
                              helperText={value?.errors?.hours}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            className={webStyles.milestone_item_formControlStyle}
                          >
                            <FormHelperText
                              className={webStyles.milestone_item_labelStyle}
                              component="p"
                            >
                              {configJSON.milestoneDepositAmountLabelTxt}
                            </FormHelperText>
                            <TextField
                              type="number"
                              name="addNewMilestoneAmount"
                              disabled
                              placeholder={configJSON.despostiAMountPlaceholder}
                              InputProps={{
                                classes: {
                                  input: webStyles.milestone_item_placeholderStyle,
                                  root: webStyles.milestone_item_inputValueStyle,
                                },
                                inputProps: {
                                  min: 0,
                                }
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={(Number(value?.attributes?.hours)*Number(acceptedHourlyCost)).toFixed(2)}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl
                            fullWidth
                            className={webStyles.milestone_item_formControlStyle}
                          >
                      <FormHelperText
                          className={webStyles.milestone_item_labelStyle}
                          component="p"
                        >
                          Sequence
                        </FormHelperText>
                        <TextField
                          type="number"
                          placeholder={"Sequence"}
                          InputProps={{
                            classes: {
                              input: webStyles.milestone_item_placeholderStyle,
                              root: webStyles.milestone_item_inputValueStyle,
                            },
                          }}
                          onChange={(event) => changeValue("milestone_sequence",event?.target?.value)}
                          value={value?.attributes?.milestone_sequence}
                          error={Boolean(value?.errors?.milestone_sequence)}
                          helperText={value?.errors?.milestone_sequence}
                        />
                      </FormControl>
                      <Box
                      className={`${webStyles.milestone_item_sendContainerStyle} ${webStyles.milestone_item_btnContainerStyle}`}
                    >
                      <Button 
                        style={{background:"#F2F2F2",color:"black"}}
                        onClick={onCancel}
                        className={webStyles.milestone_item_closeBtnSyles}>
                        Cancel
                      </Button> 
                      <Button 
                        disabled={saveLoading}
                        onClick={onSubmit}
                        className={webStyles.milestone_item_closeBtnSyles}>
                        {saveLoading?<CircularProgress size={18}/>:newMilestone?"Create":"Update"}
                      </Button>
                    </Box>
                </Box>
              </TimelineContent>
      </Box>

      // Customizable Area End
    );
  }
export default MilestoneItem;