import React from "react";

// Customizable Area Start
import {
  InputLabel,
  Box,
  Button,
  Grid,
  Container,
  Typography,
  MenuItem
 } from "@material-ui/core";

import { ThemeProvider,withStyles, createTheme } from "@material-ui/core/styles";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "../assets/css/Basic_details_customizable.css";
import Dropzone from "react-dropzone";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from "@material-ui/core/CircularProgress";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
  AddCorporateValidation
} from "./Customisableuserprofiles2Controller";
import CustomSelect from "../../../components/src/utill/CustomSelect.web";

export default class FreelancerCorporateDetails extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const{classes}=this.props;
    this.getCorporateSkillsListing = this.state.skillList.filter( (list) => list.selected);
    const leftCorporateSkill = this.state.skillList.filter((list) => !list.selected);
    this.getCorporateCategoryListing = this.state.categoryList.filter( (cat) => cat.selected);
    const leftCorporateCategory = this.state.categoryList.filter((cat) => !cat.selected);
    // Customizable Area End
    return (
      // Customizable Area Start
 

<ThemeProvider theme={theme}>
      <Container maxWidth="lg">
         <Box className="profile-block">
          <Formik
          enableReinitialize={true}
            initialValues={this.state.corporateUserDetails}
            onSubmit={values => {
            this.handleUpdateFreelancerCorporateDetails(values);
            }}
            
            validationSchema={AddCorporateValidation}
            data-testid = 'formik'
          >
            {({  values,errors,resetForm , setFieldValue}) => (
              <Form translate={undefined}>
                <Box className="basic-details-main-container">
                  <Box className= "img-upload-block" >
                    <Dropzone onDrop={this.handleCorporateFilesDrop} data-testid = 'dropzone'>
                      {({ getRootProps, getInputProps }) => (
                        <section className="container" >
                          <div
                            {...getRootProps({ className: "drop-zone-block" })}
                          >
                            <input {...getInputProps()} />
                            {this.state.profileCorporatePicImgUrl ? (
                              <img
                                src={this.state.profileCorporatePicImgUrl}
                                style={webStyle.uploadCorporateImageStyle}
                              />
                            ) : (
                              <>
                              <p className="upload-image-text"> {configJSON.attachCorporateImgTxt} <br />
                                <span className="size-of-image">{configJSON.maxCorporateFileSize} </span>
                              </p>
                              <EditIcon/>
                              </> 
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <h6>{configJSON.logoPhoto}</h6>
                  </Box>

                  <Box className="row form-block">
                    <Grid container spacing={2}>
                    
                      <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="companyName">
                          {configJSON.companyNameTxt}
                        </InputLabel>
                        <Field
                          name="companyName"
                          fullWidth
                          placeholder={configJSON.companyNamePlaceholderTxt}
                          data-testid="companyName"
                          style={webStyle.fieldCorporateStyle}
                          />
                          <ErrorMessage
                          name="companyName"
                          component="div"
                          className="error-class"
                        />
                      </Grid>

                      <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="yearReg">
                          {configJSON.yearRegTxt}
                        </InputLabel>
                        <Field
                          name="yearReg"
                          fullWidth
                          placeholder={configJSON.yearRegPlaceholderTxt}
                          data-testid="yearReg"
                          style={webStyle.fieldCorporateStyle}
                          onKeyPress={this.onKeyPressAvoidNumbers}
                        />
                        <ErrorMessage
                          name="yearReg"
                          component="div"
                          className="error-class"
                        />
                      </Grid>

                      <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="companyRegNo">
                          {configJSON.companyRegNoTxt}
                        </InputLabel>
                        <Field
                          name="companyRegNo"
                          fullWidth
                          placeholder={configJSON.companyRegNoPlaceholderTxt}
                          data-testid="companyRegNo"
                          style={webStyle.fieldCorporateStyle}
                          />
                          <ErrorMessage
                          name="companyRegNo"
                          component="div"
                          className="error-class"
                        />
                      </Grid>

                      <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="companyWebsite">
                          {configJSON.companyWebsiteTxt}
                        </InputLabel>
                        <Field
                          name="companyWebsite"
                          fullWidth
                          placeholder={configJSON.companyWebsitePlaceholderTxt}
                          data-testid="companyWebsite"
                          style={webStyle.fieldCorporateStyle}
                        />
                        <ErrorMessage
                          name="companyWebsite"
                          component="div"
                          className="error-class"
                        />
                      </Grid>

                       <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="address1">
                          {configJSON.addressLine1}
                        </InputLabel>
                        <Field
                          as="textarea"
                          name="address1"
                          placeholder={configJSON.addressline1PlaceholderTxt}
                          style={webStyle.inputCorporateStyle}
                          rows="4"
                          data-testid="address1"
                        />
                        <ErrorMessage
                          name="address1"
                          component="div"
                          className="error-class"
                        />
                      </Grid>
                      <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="address2">
                          {configJSON.addressLine2}
                        </InputLabel>
                        <Field
                          as="textarea"
                          name="address2"
                          placeholder={configJSON.addressline2PlaceholderTxt}
                          style={webStyle.inputCorporateStyle}
                          rows="4"
                          data-testid="address2"
                        />
                        <ErrorMessage
                          name="address2"
                          component="div"
                          className="error-class"
                        />
                      </Grid>
                        <Grid item xs={6} className="field-group">
                         
                          <CustomSelect
                            value={values.country}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { this.onChangeCorporateCountry(e, setFieldValue) }}
                            label={configJSON.country}
                            data-testid="country"
                            renderItem={(item, props) => (
                              <MenuItem
                                key={item}
                                value={item}
                                {...props}
                              >
                                {item}
                              </MenuItem>
                            )}
                            labelStyle={{
                              marginBottom: "3px"
                            }}
                            rootStyle={{
                              paddingRight: "20px"
                            }}
                            name="country"
                            data={this.state.getCountriesData}
                            error={errors["country"] as string}
                            placeholder=" Select your Country"
                          />

                        </Grid>

                      <Grid item xs={6} className="field-group">
                       
                          {!this.state.isLoadingStates ?
                            <>
                              {this.state.getStatesData.length == 0 ?
                                <>
                                  <InputLabel htmlFor="state">
                                    {configJSON.stateTxt}
                                  </InputLabel>
                                  <Field
                                    name="state"
                                    fullWidth
                                    displayEmpty
                                    style={webStyle.inputCorporateStyle}
                                    data-testid="state"
                                    placeholder={configJSON.placeholderState}
                                  />
                                  <ErrorMessage
                                    name="state"
                                    component="div"
                                    className="error-class"
                                  />
                                </>
                                :
                                <>
                                  <CustomSelect
                                    value={values.state}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { this.onChangeCorporateState(e, setFieldValue) }}
                                    label={configJSON.stateTxt}
                                    data-testid="state"
                                    renderItem={(item, props) => (
                                      <MenuItem key={item} value={item} {...props}>
                                        {item}
                                      </MenuItem>
                                    )}
                                    labelStyle={{ marginBottom: "3px" }}
                                    rootStyle={{ paddingRight: "20px" }}
                                    name="state"
                                    data={this.state.getStatesData}
                                    error={errors["state"] as string}
                                    placeholder=" Select your state"
                                  />
                                </>}</>
                            : <p data-testid="corporateStatesLoadingText" className={classes.loadingTxtStyle}>{configJSON.loaderTxt}</p>
                          }
                       
                      </Grid>
                        <Grid item xs={6} className="field-group">
                         
                          {this.state.cityData.length == 0 ?
                          <>
                           <InputLabel htmlFor="city">
                           {configJSON.corporateCityLabel}
                         </InputLabel>
                            <Field
                              name="city"
                              fullWidth
                              displayEmpty
                              style={webStyle.inputCorporateStyle}
                              disabled={!values.state}
                              data-testid="city"
                              placeholder={configJSON.placeholderCity}
                            />
                            <ErrorMessage
                            name="city"
                            component="div"
                            className="error-class"
                          />
                          </>
                            :
                            
                              <CustomSelect
                                value={values.city}
                                data-testid="city"
                                onChange={(e: any) => setFieldValue("city", e.target.value)}
                                disabled={!values.state}
                                label={configJSON.city}
                                renderItem={(item, props) => (
                                  <MenuItem
                                    key={item}
                                    value={item}
                                    {...props}
                                  >
                                    {item}
                                  </MenuItem>
                                )}
                                labelStyle={{
                                  marginBottom: "3px"
                                }}
                                rootStyle={{
                                  paddingRight: "20px"
                                }}
                                name="city"
                                data={this.state.cityData}
                                error={errors["city"] as string}
                                placeholder="Select your city"
                              />
                            }
                        </Grid>
                        <Grid item xs={6} className="field-group">
                          <InputLabel htmlFor="postalCode">
                            {configJSON.corporatePostalLabel}
                          </InputLabel>
                          <Field
                            name="postalCode"
                            fullWidth
                            style={webStyle.fieldCorporateStyle}
                            placeholder={configJSON.postalCorporateSelectTxt}
                            data-testid="postalCode"
                            onKeyPress={this.onKeyPressAvoidNumbers}
                          />
                          <ErrorMessage
                            name="postalCode"
                            component="div"
                            className="error-class"
                          />
                        </Grid>

                        <Grid item xs={6} className="field-group">
                         
                          <CustomSelect
                                value={values.corporateIndustry}
                                data-testid="corporateIndustry"
                                onChange={(e: any) => setFieldValue("corporateIndustry", e.target.value)}
                                label={configJSON.IndustryLabelTxt}
                                renderItem={(item, props) => (
                                  <MenuItem
                                    key={item.attributes.id}
                                    value={item.attributes.industry_name}
                                    {...props}
                                  >
                                    {item.attributes.industry_name}
                                  </MenuItem>
                                )}
                                labelStyle={{
                                  marginBottom: "3px"
                                }}
                                rootStyle={{
                                  paddingRight: "20px"
                                }}
                                name="corporateIndustry"
                                data={this.state.industryname}
                                error={errors["corporateIndustry"] as string}
                                placeholder=" Select your corporateIndustry"
                              />
                        </Grid>

                      <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="noOfEmp">
                          {configJSON.noOfEmpTxt}
                        </InputLabel>
                        <Field
                          name="noOfEmp"
                          fullWidth
                          style={webStyle.fieldCorporateStyle}
                          placeholder={configJSON.empCorporateSelectTxt}
                          data-testid="noOfEmp"
                          onKeyPress={this.onKeyPressAvoidNumbers}
                        />
                        <ErrorMessage
                          name="noOfEmp"
                          component="div"
                          className="error-class"
                        />
                      </Grid>

                      <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="overview">
                          {configJSON.overviewTxt}
                        </InputLabel>
                        <Field
                          name="overview"
                          fullWidth
                          style={webStyle.fieldCorporateStyle}
                          placeholder={configJSON.overviewCorporateSelectTxt}
                          data-testid="overview"
                        />
                        <ErrorMessage
                          name="overview"
                          component="div"
                          className="error-class"
                        />
                      </Grid>

                      <Grid item xs={6} className="field-group">
                        <InputLabel htmlFor="vat_tax">
                          {configJSON.vatTaxTxt}
                        </InputLabel>
                        <Field
                          name="vat_tax"
                          fullWidth
                          style={webStyle.fieldCorporateStyle}
                          placeholder={configJSON.vatTaxCorporateSelectTxt}
                          data-testid="vat_tax"
                        />
                        <ErrorMessage
                          name="vat_tax"
                          component="div"
                          className="error-class"
                        />
                      </Grid> 


                      <Grid item xs={12} className="field-group">
                        <InputLabel htmlFor="companyProfile">
                          {configJSON.companyProfileTxt}
                        </InputLabel>
                        <Field
                          as="textarea"
                          name="companyProfile"
                          placeholder={configJSON.companyProfilePlaceholderTxt}
                          style={webStyle.inputCorporateStyle}
                          rows="4"
                          data-testid="companyProfile"
                        />
                        <ErrorMessage
                          name="companyProfile"
                          component="div"
                          className="error-class"
                        />
                      </Grid>

                      <Grid item xs={12} className="field-group">
                        <InputLabel htmlFor="awardCertification">
                          {configJSON.awardCertificationTxt}
                        </InputLabel>
                        <Field
                          as="textarea"
                          name="awardCertification"
                          placeholder={configJSON.awardCertificationPlaceholderTxt}
                          style={webStyle.inputCorporateStyle}
                          rows="4"
                          data-testid="awardCertification"
                        />
                        <ErrorMessage
                          name="awardCertification"
                          component="div"
                          className="error-class"
                        />
                      </Grid>
          {/* ------------------------------- Certification ----------------------------------------- */}
          <Grid container className={classes.skillsTxtFieldContainerStyle}>
            <Grid item xs={12}  className={classes.subContainerStyle}>
          <Box className={classes.certificationsBlock}> 
              <InputLabel className={classes.inputLabelTxtStyle}htmlFor="certifications">
                {configJSON.certifications}
              </InputLabel>
   
              <Field
                id="certifications"
                fullWidth
                InputProps={{ classes: { input: classes.educationDetailsTextField }}}
                placeholder={configJSON.addCertificationsPlaceholder}
                onChange={this.onCertificationChange}
                onKeyPress={ this.handleAddCertifications}
                data-testid = 'addCertification'
                value={this.state.certificateName}
              />
            </Box>
     
             <Box className={classes.skillsSubContainerStyle}>
             {this.state.certifications && this.state.certifications.map((certificate, index) => (
              <Box className={classes.skillWrapperStyle} key={certificate.name}>
                <Typography className={classes.addedSkillNameStyle}>   {certificate.name}</Typography>
                <ClearIcon
                  className={classes.crossIconStyle}
                  onClick={this.deleteCertification.bind(this,index)}
                  data-testid={`deleteCertification-${index}`}
                />
              </Box>
            ))}
            </Box>
           
        </Grid>
        </Grid>
  
 {/* ------------------------------- Corporate Skills ----------------------------------------- */}

          <Grid container className={classes.skillsTxtFieldContainerStyle}>
            <Grid item xs={12}  className={classes.subContainerStyle}>
                <div className="form-group">
                  <SearchIcon style={{ marginTop: '30px' , marginRight:"15px" }} />
                  </div>
                  
                  <CustomSelect
              value={""}
              data-testid='selectedSkill'
              onChange={this.selectNewElement}
              label=  {configJSON.labelSkills}
              renderItem={(item, props) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  {...props}
                >
                  {item.name}
                </MenuItem>
              )}
              name="selectedSkill"
              tabIndex={3}
              data={leftCorporateSkill}
              placeholder={leftCorporateSkill.length == 0 ? configJSON.primaryNoSkillsPlaceHoldertxt :configJSON.primarySkillsPlaceHoldertxt }
            />
                  
              <Box className={classes.skillsSubContainerStyle}>

              {this.getCorporateSkillsListing && this.getCorporateSkillsListing.map((list, index) => (
                <Box className={classes.skillWrapperStyle} key={list.id}>
                  <Typography className={classes.addedSkillNameStyle}> {list.name} </Typography>
                  <ClearIcon
                    className={classes.crossIconStyle}
                    onClick={ this.deleteSelected.bind(this,list.id)}
                    data-testid={`deleteSkill-${index}`}
                  />
                </Box>
              ))}
              </Box>
              <Box>
                    {
                      (this.state.error.skills.length > 0)
                        && <Typography variant="caption" className={classes.errorSkillStyle}>{this.state.error.skills}</Typography>
                    }
              </Box>
            </Grid>
          </Grid>

{/* ------------------------------- Category ----------------------------------------- */}
                        <Grid container className={classes.skillsTxtFieldContainerStyle}>
                          <Grid item xs={12} className={classes.subContainerStyle}>
                           
                            <div className="form-group">
                              <SearchIcon style={{ marginTop: '30px', marginRight: "15px" }} />
                              </div>
                            <CustomSelect
                              value={""}
                              data-testid='selectedCategory'
                              onChange={this.selectNewCategory}
                              label={configJSON.labelCategory}
                              renderItem={(item, props) => (
                                <MenuItem
                                  key={item.id}
                                  value={item.id}
                                  {...props}
                                >
                                  {item.name}
                                </MenuItem>
                              )}
                              name="selectedCategory"
                              tabIndex={3}
                              data={leftCorporateCategory}
                              placeholder=  {leftCorporateCategory.length == 0 ? configJSON.categoryNoPlaceHoldertxt : configJSON.categoryPlaceHoldertxt}
                            />
                            
                            <Box className={classes.skillsSubContainerStyle}>

                              {this.getCorporateCategoryListing && this.getCorporateCategoryListing.map((cat_list) => (
                                <Box className={classes.skillWrapperStyle} key={cat_list.id}>
                                  <Typography className={classes.addedSkillNameStyle}> {cat_list.name} </Typography>
                                  <ClearIcon
                                    data-testid='deleteCat'
                                    className={classes.crossIconStyle}
                                    onClick={this.deleteSelectedCategory.bind(this, cat_list.id)}
                                  />
                                </Box>
                              ))}
                            </Box>
                            <Box>
                              {
                                (this.state.error.category.length > 0)
                                && <Typography variant="caption" className={classes.errorSkillStyle}>{this.state.error.category}</Typography>
                              }
                            </Box>
                          </Grid>
                        </Grid>      
        {/* -------------------------------- freelancer experience ---------------------------------------- */}
                        <Grid item xs={6} className="field-group">
                          <InputLabel htmlFor="experience">
                            {configJSON.freelancingExperience}
                          </InputLabel>
                              <Field
                                fullWidth
                                name="freelanceexperience"
                                value={this.state.freelanceexperience}
                                onChange={(event: Event)=>this.handleExperienceChange(event)}
                                style={webStyle.inputCorporateStyle}
                                data-testid="experience"
                                placeholder= {configJSON.freelancingPlaceHoldertxt}
                              />
                              <ErrorMessage
                              name="experience"
                              component="div"
                              className="error-class"
                            />
                        </Grid>
  
                    </Grid>
                  </Box>
                   {/* ------------------------------- Buttons----------------------------------------- */}
                  <Box className="button-block">
                    <Button  data-testid="resetCorporate"  style={webStyle.cancelButtonStyle} onClick={this.resetCorporateFormValues.bind(this, resetForm)}>
                      {configJSON.cancelBtnTxt}
                    </Button>
 
                     <Button
                      data-testid="submitBtn"
                      type="submit"
                      style={webStyle.corporateSaveBtnStyle}
                    >
                       {this.state.isLoadingPersonalInfo?<CircularProgress size={24} className={classes.loadingColorStyle} />:configJSON.saveBtnTxt}
                      
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>

          
        </Box>
      </Container>
      </ThemeProvider>
        
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = () => ({
  inputLabelTxtStyle: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    color: "#8083A3",
  },
  skillsTxtFieldContainerStyle: {
    marginTop: 20,
    display: "flex",
    gap: 20,
    borderRadius: "14px",
    padding: "8px"
  },
  addedSkillNameStyle: {
    color: "#222222",
  },
  crossIconStyle: {
    color: "red",
    cursor:'pointer'
  },
  subContainerStyle: {
    flex: 1,
  },

  skillsSubContainerStyle:{
    display: "flex",
    flexWrap: 'wrap' as const,
    gap: "3px"
  },
  skillWrapperStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 7,
    backgroundColor: "#F6F6F7",
    margin: "8px 0px",
    borderRadius: "20px",
    color: "#8083A3",
  },
  errorSkillStyle:{
    color:"red",
    fontSize: '11px',
   },
   loadingColorStyle:{
    color:'#8083a3'},
});

const webStyle = {
  inputCorporateStyle:{
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: '14px',
    color:'#8083a3',
  },
  fieldCorporateStyle:{ 
    borderBottom: '1px solid rgba(0,0,0,0.1)' ,
    fontWeight:400,
     color:'#8083a3'
    },
    uploadCorporateImageStyle:{
    borderRadius:'50%',
    height:'120px',
     width:'120px'
    },
    cancelButtonStyle:{
      minWidth: '140px',
      borderRadius: '23px',
      background: "#f2f2f2",
      padding: '9px 30px',
      color: '#000',
      fontSize: '14px',
      margin: '0 15px',
      textTransform:'none' as const,
   },
  
   corporateSaveBtnStyle:{
    cursor:'pointer',
    minWidth: '140px',
    borderRadius: '23px',
    padding: '9px 30px',
    fontSize: '14px',
    fontWeight:600,
    fontFamily:'Inter',
    margin: '0 15px',
    textTransform:'none' as const,
    height:'42px',
    color:'#f2f2f2',
    backgroundColor: "#ec952d",
  },
    categoryMarginStyle:{
      marginTop:'-30px'
    },
    certificationsBlock: {
      marginBottom: '40px',
    },
    educationDetailsTextField: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "14px",
      color: "#222222",
    },
    certificationsBlockNew: {
      display: "flex",
      flexWrap:'wrap' as const,
   },
   specificSkillContainer: {
    display: "flex",
    padding: 7,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F6F6F7",
    borderRadius: "20px",
    color: "#8083A3",
  },
  loadingTxtStyle:{
    color:"#8083a3"
  },
}
export const FreelancerCorporateStyles = withStyles(styles)(FreelancerCorporateDetails);
// Customizable Area End