import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";

import {
  Container,
  Box,
  Typography,
  // Customizable Area Start
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start

import '../assets/css/notification.web.css';
import { Link } from "react-router-dom";
// Customizable Area End
import NotificationController, { Props } from "./NotificationController.web";

export default class Notificationboxsponser extends NotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  NavigationButtons = (notificationType: string | null, url: string | null) => {
    if (notificationType == "Updates") {
      return (
        <Link target="_blank" to={`/${url}`} id="projectbutton">
          <span style={{ ...webStyle.notificationbutton, color: "#ffffff", background: "#00A7A2", cursor: "pointer" }}>
            Go to Project
          </span>
        </Link>)

    }
    if (notificationType == "Message") {
      return (
        <Link target="_blank" to={`/${url}`}>
          <span style={{ ...webStyle.notificationbutton, color: "#ffffff", background: "#00A7A2", cursor: "pointer" }}>
            Message
          </span>
        </Link>
      )
    }
    if (notificationType == "Proposals") {
      return (
        <Link target="_blank" to={`/${url}`} className="webStyle.notificationbutton">
          <span style={{ ...webStyle.notificationbutton, color: "#ffffff", background: "#00A7A2", cursor: "pointer" }}>
            View Proposal
          </span>
        </Link>
      )
    }

  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Container id="nbox" className="notification-container" style={{ padding: "0px", margin: "0px" }}>
          <Box style={webStyle.main_Boxe} className="notall">
            {this.state.loading ? (
              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight:'60px' }}>
                <CircularProgress /></Box>
            ) : (
              <Box>
                {this.state.notificationdata && this.state.notificationdata.length > 0 && this.state.notificationdata.slice(0, 3).map((data: any, index: any) => {

                  return (
                    <Box key={index} id="tttk" style={{ marginTop: "1px" }}>
                      <Card
                        square={true}
                        style={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                      >
                        <CardContent id="cc" style={{ paddingBottom: '15px' }}>
                          <Box style={{ display: "flex",  alignItems:"flex-start", gap: "15px" }}>

                            <Box style={webStyle.image_Items}>
                              <Avatar src={data.attributes.profile_photo?.url} />
                            </Box>
                            <Box id="sponser">
                              <Typography className="htmlcontent"
                                  style={
                                    data?.attributes.is_read?{
                                       flexGrow: 1, 
                                       fontSize: '12px',
                                       marginBottom: "10px",
                                      }:{
                                        marginBottom: "10px",
                                        flexGrow: 1, 
                                        fontSize: '13px',
                                        fontWeight:"bolder"
                                     }
                                }
                                dangerouslySetInnerHTML={{ __html: data.attributes.content }}
                              />
                              {this.NavigationButtons(data.attributes.notification_for, data.attributes.link)}
                            </Box>
                            <Typography style={{ fontSize: '10px', display: "block ruby" , marginLeft:"23px", whiteSpace: "nowrap" }}>{data.attributes.created_at}</Typography>
                            </Box>
                        </CardContent>

                      </Card>
                    </Box>
                  )
                })}
                {this.state.notificationdata && this.state.notificationdata.length == 0 &&
                  <Box id="kkkk" style={{ marginTop: "1px" }}>
                    <Card
                      square={true}
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <CardContent id="cc" style={{ paddingBottom: '15px' }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          No notifications found
                        </Box>
                      </CardContent>

                    </Card>
                  </Box>}
              </Box>
            )}
            <Box id="cbox" style={{ marginTop: "1px" }}>
              <Card
                square={true}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomRightRadius: "8px",
                  borderBottomLeftRadius: "8px",
                }}
              >
                {this.state.notificationdata && this.state.notificationdata.length > 0 && <CardContent>
                  <CardActions id="btnnon" onClick={() => { this.props.navigation.navigate('Notificationsponser') }} >

                    <Typography style={{ fontWeight: "bold", fontSize: '12px', cursor: 'pointer' }}>
                      View All Notifications{"  >"}
                    </Typography>

                  </CardActions>
                </CardContent>}
              </Card>
            </Box>
          </Box>
        </Container>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  main_Boxe: {
    paddingTop: "10px",
    width: "28rem",
    maxHeight: "435px",
    borderRadius: "12px",

  },

  image_Items: {
    marginRight: "14px",
    // flex:"1"
  },

  notificationbutton: {
    borderRadius: "18px",
    textTransform: "none",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "center",
    marginTop : "5px",  
    padding : "7px 13px"
} as const,

};
// Customizable Area End
