export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const avatar_lancer = require("../assets/avatar.png");
export const calender = require("../assets/calender.png");
export const chat = require("../assets/chat.png");
export const dots = require("../assets/dots.png");
export const download = require("../assets/download.png");
export const leftarrow = require("../assets/leftarrow.png");
export const line = require("../assets/line.png");
export const location = require("../assets/location.png");
export const localtime = require("../assets/localtime.png");
export const menuArrowBlack = require("../assets/menuArrowBlack.png");
export const menuArrowOrange = require("../assets/menuArrowOrange.png");
export const menuBid = require("../assets/menuBid.png");
export const menuBrowse = require("../assets/menuBrowse.png");
export const menuComplete = require("../assets/menuComplete.png");
export const menuProgress = require("../assets/menuProgress.png");
export const network = require("../assets/network.png");
export const submitwork = require("../assets/submitwork.png");
export const wallet = require("../assets/wallet.png");
export const workicon = require("../assets/workicon.png");
export const uploadIcon = require("../assets/uploadIcon.png");
export const deleteFileIcon = require("../assets/deleteFileIcon.png");
export const gifIcon = require("../assets/gifIcon.png");
export const sendIcon = require("../assets/sendIcon.png");
export const linkIcon = require("../assets/linkIcon.png");
export const ItalicIcon = require("../assets/ItalicIcon.png");
export const chatDots = require("../assets/chatDots.png");
export const chatList = require("../assets/chatList.png");
export const boldIcon = require("../assets/boldIcon.png");

export const downloads=require("../assets/downloads.png");
export const request=require("../assets/request.png");
export const correction=require("../assets/correction.png");
export const rightImage=require("../assets/right.png");
export const projectCloseIcon=require("../assets/projectCloseIcon.png");
export const disputeIcon=require("../assets/disputeIcon.png");