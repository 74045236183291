import React from "react";
// Customizable Area Start
import {
    Grid,
    Button,
    Box,
} from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
import { ThemeProvider,createTheme,withStyles} from "@material-ui/core/styles";
import VerificationMsgForm from "../../../components/src/VerificationMsgForm.web"
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import {
  verifyFailIcon
} from './assets'
export const theme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
 });

// Customizable Area End

import FreelancerWithdrawalController, {
    Props, configJSON
} from "./FreelancerWithdrawalController.web";


export class WithdrawalFail extends FreelancerWithdrawalController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
   

       // Customizable Area End
    render() {
        // Customizable Area Start
          const{classes}=this.props;
        // Customizable Area End
        return (

            // Customizable Area Start

        <ThemeProvider theme={theme}>
            <FreelancerHeader 
            navigation={this.props.navigation} history={undefined} 
            location={undefined} 
            id={""} route={"projects"}
            />
    
           <Grid container className={classes.failMainBox}>
                <Grid item xs={12} md={6} className={classes.failContentGrid}>
                    <VerificationMsgForm
                    iconImg = {verifyFailIcon}
                    formTitle = {configJSON.withdrawalFailTitle}
                    formTxt = {configJSON.verificationTxt}
                    />
                    <Box className={classes.btnBox}>
                    <Button className={classes.earningsBtn}>{configJSON.goToEarningsBtn}</Button> 
                    <Button className={classes.retryBtn}>{configJSON.retryWithdrawalBtn}</Button>    
                    </Box>
                </Grid> 
         </Grid>
       

      <DashboardFooter history={undefined} location={undefined} />    
    </ThemeProvider>

            // Customizable Area End
        );
    }
}
// Customizable Area Start

export const styles = () => ({

  failMainBox:{ 
    display:'flex',
   justifyContent:'center',
    alignItems:'center',
    width:'100%',
    margin:'3vw 0'
},
  failContentGrid:{ 
      display:'flex',
     justifyContent:'center',
      alignItems:'center',
      height:'80vh',
      flexDirection:'column' as const,
  },

  earningsBtn:{
    padding:'0 24px',
    margin:"20px",
     backgroundColor: '#f2f2f2',
    fontSize:'14px',
    fontWeight: 700,
    minWidth: '100px',
    height: '46px',
    color: '#000',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    borderRadius: '20px',
    textTransform:'none' as const,
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
  retryBtn:{
    padding:'0 24px',
    margin:"20px",
     backgroundColor: '#ec952d',
    fontSize:'14px',
    fontWeight: 700,
    minWidth: '100px',
    height: '46px',
    color: '#fff',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    borderRadius: '20px',
    textTransform:'none' as const,
    '&:hover': {
      backgroundColor: '#ec952d',
    },
  },
  btnBox:{
    display:'flex',
    flexWrap:'wrap' as const,
    justifyContent:'center',
    alignItems:'center',
  }
  })

export default withStyles(styles)(WithdrawalFail);
  // Customizable Area End