import React from "react";

// Customizable Area Start
import "../assets/CSS/index.css";
import SearchBar from "material-ui-search-bar";
import {
    filter
} from "./assets";

import ProjectDisplay from "../../../components/src/ProjectDisplay";
import SponsorFilter from "../../../components/src/Sponsorfilter";
// Customizable Area End

import Sponsorlistingcontroller, {
    Props
} from "./Sponsorlistingcontroller.web";
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";

export default class Deletedproject extends Sponsorlistingcontroller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div id="a1">
                <ProjectFilterWeb
                    data-testid="delete_project_filter"
                   onCancelFilter={this.cancelFilterProjectHandler}
                   onSearch={this.searchProjectHandler}
                   onFilter={this.filterProjectHandler}
                   activeTabColor="#00A7A2"
                />
                <ProjectDisplay
                     data-testid="delete_project_display"
                    loading={this.state.loading}
                    isShowMore={this.state.totalProjectsCount > this.state.projectdata?.length}
                    projectList={this.state.projectdata}
                    loadMore={this.handleProjectLoadMore}
                    tabDataList={this.state.chipNames}
                    type = "deleted"
                    navigate={this.props.navigation?.navigate}
                    onTabChange={this.handleChipClick}
                    copyURL={this.handleCopyClick}
                />
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area End
