import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  history: any;
  location: any;
  classes?: any;
}

export interface S {
  // Customizable Area Start
  responseData: any[];
  projectInProgressCards: any[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class ProjectInProgressLancerController extends BlockComponent<
  Props, S, SS
> {

  // Customizable Area Start
  ProjectInProgressCallId: any

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      // Customizable Area Start
      projectInProgressCards: [],
      responseData: [],
    
      // Customizable Area End
    };
  

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }
  async componentDidMount() {
    super.componentDidMount();
    this.ProjectInProgressApi()
    }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId)

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.ProjectInProgressCallId) {
        if (responseJson) {
          this.setState({projectInProgressCards: responseJson.data?responseJson?.data:[] })

        } else {
          this.parseApiErrorResponse(responseJson);
        }


      }

    }
    // Customizable Area End
  }
 
  // Customizable Area Start
  ProjectInProgressApi = () => {
      
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("loginSuccessToken"),
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ProjectInProgressCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/projects/get_lancers_inprogress_projects`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // Customizable Area End
  
}
