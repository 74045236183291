import React from "react";

// Customizable Area Start
import { Button, Grid } from "@material-ui/core";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import SearchController, { Props } from "./SearchController.web";
import { successImage } from "./assets";
import "../assets/css/Search.css";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class SearchSuccess extends SearchController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <FreelancerHeader
                    navigation={undefined}
                    history={undefined}
                    location={undefined}
                />
      
     
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Grid item xs={12}>
          {/* Center Grid */}
          <Grid container spacing={2} direction="column" alignItems="center">
            {/* Row Grid 1 */}
            <Grid item>
              <Grid container spacing={2}>
                {/* Content for Row 1 */}
                <Grid item xs={12}>
                  {/* Content */}
                  <img src={successImage} alt="Example" />
                </Grid>
              </Grid>
            </Grid>
            {/* Row Grid 2 */}
            <Grid item>
              <Grid container spacing={2}>
                {/* Content for Row 2 */}
                <Grid item xs={12} className="freelancer-search-success-2">
                  {/* Content */}
                  Congratulations
                </Grid>
              </Grid>
            </Grid>
            {/* Row Grid 3 */}
            <Grid item>
              <Grid container spacing={2}>
                {/* Content for Row 3 */}
                <Grid item xs={12} className="freelancer-search-success-3">
                  {/* Content */}
                  Project Posted Successfully
                </Grid>
              </Grid>
            </Grid>
            {/* Row Grid 4 */}
            <Grid item>
              <Grid container spacing={2}>
                {/* Content for Row 4 */}
                <Grid item xs={12} className="freelancer-search-success-4">
                  {/* Content */}
                  You will receive a notification once the freelancer applies.
                </Grid>
              </Grid>
            </Grid>
            {/* Row Grid 5 */}
            <Grid item data-test-id="buttonContainerId">
              <Grid container spacing={2}>
                {/* Content for Row 5 */}
                <Grid item xs={12} className="freelancer-search-success-5">
                  {/* Content */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}> 
                      <Button  data-test-id="postAnotherProjectId"
                        className="freelancer-search-success-secondary-button"
                        variant="contained"
                        color="primary"
                        onClick={this.handlePostAnotherProject}
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#22A699",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          color: "#0000"
                        }}
                      >
                        Post Another Project
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button  data-test-id="browsePostedProjectId"
                        className="freelancer-search-success-primary-button"
                        variant="contained"
                        color="primary"
                        onClick={this.handleBrowsePostedProjects}
                        style={{
                          borderRadius: "20px",
                          backgroundColor: "#22A699",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                          color: "#ffff"
                        }}
                      >
                        Browse Posted Projects
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DashboardFooter history={undefined} location={undefined} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
