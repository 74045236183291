import { Message } from "../../framework/src/Message";

import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";

export const getNavigationMessage = (route: string, props: any, payload?: any): Message => {

const message = new Message(getName(MessageEnum.NavigationMessage));
message.addData(getName(MessageEnum.NavigationTargetMessage), route);

message.addData(getName(MessageEnum.NavigationPropsMessage), props);

if (payload) {
    const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), payload);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
}
return message;
}