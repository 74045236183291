import React from "react";

// Customizable Area Start
import {
    lancerscapelogo,
    switchbtn,
    bellicon_img,
    help_img_,
    sms_image,
} from "./assets";
import "../assets/css/dashboard.css";
import SearchBar from "material-ui-search-bar";
import {
    Box,
    Typography,
    Button,
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Avatar,
    Badge,
    Drawer,
} from '@material-ui/core';
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import Switch from '@material-ui/core/Switch';


// Customizable Area End

// Customizable Area Start
export const OnlineBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: "#44b700",
            color: "#44b700",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        },
    })
)(Badge);

export const OfflineBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: "#ff9808",
            color: "#ff9808",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        },
    })
)(Badge);
// Customizable Area End

import Notificationbox from "../../notificationsettings/src/Notificationboxsponser.web";
import HeaderController, { Props } from "./HeaderController.web";
import { Link } from "react-router-dom";
export class Sponserheader extends HeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.currentPage = "Sponsor";
        // Customizable Area End
    }
    // Customizable Area Start

    Switch = withStyles((theme: Theme) =>
        createStyles({

            switchBase: {
                padding: 1,
                '&$checked': {
                    transform: 'translateX(16px)',
                    color: theme.palette.common.white,
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: '#00A7A2', // Change this line to set the background color
                        border: 'none',
                    },
                },
                '&$focusVisible $thumb': {
                    color: '#52d869',
                    border: '6px solid #fff',
                },
            },
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: theme.spacing(1),
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 13,
                backgroundColor: '#00A7A2',
                border: `1px solid ${theme.palette.grey[400]}`,
                opacity: 1,
                transition: theme.transitions.create(['background-color', 'border']),
            },
            checked: {},
            focusVisible: {},
        })
    )(Switch);


    navigateToPostProjectPage = () => {
        this.props.navigation.navigate('Projects', { type: "createNewProject" })
    }
    renderSponsorProfilePic = (user: any, shortuser: string) => {
        return <Avatar style={{ background: !user?.attributes?.photo[0]?.url  ?  "#00A7A2" : "rgb(255, 255, 255)" }} src={user?.attributes?.photo[0]?.url != null && user?.attributes?.photo[0]?.url}>{shortuser}</Avatar>
    }

    addSpace = (isSwitchable: boolean) => {

        if (isSwitchable && this.props.route != "dashboard") {
            return (
                <>
                    <br />
                    <br />
                    <br />
                    <br />
                </>
            )
        }
        if (!isSwitchable && this.props.route != "dashboard") {
            return (
                <>
                    <br />
                    <br />
                </>
            )
        }
    }

    getFavouriteStyle = () => {
        return ({ color: this.props.route == "favourites" ? "#00A7A2" : "" })
    }

    renderBadge = (availability: any, loggedInUser: any, shortCode: any) => {
        const BadgeComponent = availability ? OnlineBadge : OfflineBadge;

        return (
            <BadgeComponent
                overlap="circular"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                variant="dot"
            >
                {/* <Avatar>{shortCode}</Avatar> */}
                {this.renderSponsorProfilePic(loggedInUser, shortCode)}
            </BadgeComponent>
        );
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        const { currentUser: loggedInUser, mobileOpen, profilePopup, notificationPopup } = this.state;
        const openProfileModel = Boolean(profilePopup);
        const openNotificationPopup = Boolean(notificationPopup);
        const currentUserEmail =
            loggedInUser?.attributes?.account_detail?.data?.attributes?.email;
        let maskedEmail = "";
        if (currentUserEmail) {
            const [username, domain] = currentUserEmail.split('@');
            maskedEmail = `${username.slice(0, 5)}....@${domain}`;
        }
        const firstName = loggedInUser?.attributes?.first_name;
        const lastName = loggedInUser?.attributes?.last_name;
        const loggedInuser = `${firstName} ${lastName}`
        let shortCode = "";
        if (firstName && lastName) {
            shortCode =
                firstName.trim().substring(0, 1).toUpperCase() + lastName.trim().substring(0, 1).toUpperCase();
        } else {
            shortCode = currentUserEmail?.trim().substring(0, 2).toUpperCase() as string;
        }
        const isSwitchable = loggedInUser?.attributes?.role_name?.toLowerCase().includes("both");
        return (
            // Customizable Area Start

            <div>
                <AppBar
                    // position="fixed"
                    style={{ background: "white", color: "black" }}
                >
                    <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }} className="resSpace">
                        <Box style={{ display: "flex", alignItems: "center", gap: "20px" }}
                            data-test-id="togglediv" >
                            <Box
                                data-id="menus"
                                style={{ display: 'flex' }}>
                                <IconButton
                                    onClick={this.handleDrawerOpen}
                                    className={classes.menuButton}

                                >
                                    <MenuIcon htmlColor="black" />
                                </IconButton>
                                <img
                                    data-test-id="logo1"
                                    style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "pointer" }}
                                    className={classes.hide_on__above_mobile}
                                    src={lancerscapelogo}
                                    alt="log_image"
                                    onClick={this.navigateToHomePage}
                                />
                            </Box>

                            <Box
                                data-id="hidden_item_on_mobile_size"
                                className={classes.hidden_on_mobile}
                                style={{ width: "120px" }}
                            >
                                <img
                                    data-test-id="logo2"
                                    style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
                                    src={lancerscapelogo}
                                    alt="log_image"
                                    onClick={this.navigateToHomePage}
                                />
                            </Box>
                            <Box className={classes.nav__hidden__items}>
                                <ul className={`${classes.nav__hidden__items} navigation`}>
                                    <li
                                        data-test-id="dashboardnav"
                                        className={this.props.route == "dashboard" ? "active" : ""}
                                    >
                                        <Link to="/SponsorDashboard">
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li
                                        data-test-id="projectnav"
                                        className={this.props.route == "projects" ? "active" : ""}
                                    >
                                        <Link to="/Projects/activeprojects">
                                            Projects
                                        </Link>
                                    </li>

                                    <li
                                        data-test-id="favouritenav"
                                        className={this.props.route == "favourites" ? "active" : ""}
                                    >
                                        <Link to="/FavouritesFreelancer">
                                            Favourites
                                        </Link>
                                    </li>
                                </ul>
                                <button data-testid="postProject" className="btn-green" onClick={this.navigateToPostProjectPage}>Post a Project</button>
                            </Box>
                        </Box>
                        <Box
                            data-id="hiddenRightSideContent"
                            style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <Box
                                data-id="hiddenrightSideButtons"
                                className={classes.hidden_on_mobile_flex}
                                style={{ alignItems: "center", gap: "5px" }}
                            >
                                {isSwitchable &&
                                    <Box
                                        data-id="freelancer"
                                        onClick={this.sendSwitchAccountMessage}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "80px",
                                            margin: "10px",
                                            width: "80px",
                                            border: "1px solid #00A7A2",
                                            borderRadius: "15px",
                                            cursor: "pointer",
                                        }}
                                        data-testId="sponser"
                                    >
                                        <img style={webStyle.largeImage} src={switchbtn} />
                                        <Typography
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            Switch to Freelancer
                                        </Typography>
                                    </Box>}
                                    <Box className="notification-count">

                                <Box onClick={()=>this.unreadMessageNotifsAndRedirect()} data-test-id="check-message-notif-btn">
                                    <IconButton style={{ padding: '6px' }} >
                                        <img src={sms_image} />
                                    </IconButton>
                                    <span className="notification-bar">
                                   <span>{this.state.unreadNotifications.length}</span> 
                                    </span>
                                </Box>
                                    </Box>
                                {" "}
                                <Box className="notification-count">
                                <IconButton
                                    id="btnnon"
                                    onClick={(e) => this.handleOpenNotificationPopup(e)}
                                    style={{ padding: '6px' }}>
                                    <img src={bellicon_img} />

                                </IconButton>
                                <span className="notification-bar">
                                   <span>{this.state.notificationcount}</span> 
                                    </span>
                                </Box>

                                <Link to="/Contactus" data-test-id="contactusImg">
                                    <IconButton style={{ padding: '6px' }}>
                                        <img src={help_img_} />
                                    </IconButton>
                                </Link>

                            </Box>
                            <Button
                                onClick={this.handleProfilePopupOpen}
                                aria-controls="menu"
                                color="inherit"
                                style={{
                                    textTransform: "capitalize",
                                    textAlign: "left",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                {this.renderBadge(this.state.currentUser?.attributes?.availability, loggedInUser, shortCode)}

                                {loggedInUser &&
                                    <>
                                        <Box
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginLeft: "10px",
                                            }}
                                            className="resAccountName"
                                        >
                                            <Typography style={{ fontWeight: "bold" }}>
                                                {currentUserEmail?.slice(0, 10)}...
                                            </Typography>
                                            <Typography>Sponsor</Typography>
                                        </Box>
                                        <ExpandMoreIcon />
                                    </>
                                }
                            </Button>
                            <Menu
                                // id='menu'
                                key="profile_popup"
                                anchorEl={profilePopup}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                open={openProfileModel}
                                onClose={this.handleCloseProfilePopup}
                                style={{
                                    marginTop: "64px",
                                }}
                            >
                                <Box style={{ display: "flex", margin: "5px 10px", alignItems: "center" }}>
                                    <Box>
                                        {this.renderBadge(this.state.currentUser?.attributes?.availability, loggedInUser, shortCode)}
                                    </Box>
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        <Typography style={{ fontWeight: "bold" }}>
                                        {loggedInuser && loggedInuser.length > 22 ? loggedInuser.slice(0, 22) + "..." : loggedInuser}
                                        </Typography>
                                        <p style={{ margin: "0px", fontWeight: "bold", color: "gray" }}>Sponsor</p>
                                    </Box>
                                </Box>
                                <hr style={{ margin: '0px', padding: '0px' }} />
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <MenuItem style={{ marginRight: '30px' }}>
                                        Availability
                                    </MenuItem>
                                    <this.Switch style={{ background: "#00A7A2" }} data-id="Switch" checked={this.state.currentUser?.attributes?.availability} name="Switch" onChange={() => {
                                        this.handleavailability()
                                    }} />
                                </Box>
                                <hr style={{ margin: '0px', padding: '0px' }} />
                                <Link to={"/SposorCustomisableUserProfile"}
                                    style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Box style={{ display: "flex", margin: "0px 10px", alignItems: "center" }}>

                                        <AccountCircleOutlinedIcon />
                                        <MenuItem style={{ paddingRight: '30px', width: "100%" }}>
                                            My Profile
                                        </MenuItem>
                                    </Box>
                                </Link>
                                <Box style={{ display: "flex", margin: "0px 10px", alignItems: "center" }}>

                                    <ExitToAppOutlinedIcon style={{ transform: 'rotate(180deg)' ,  cursor:"pointer" }}  onClick={this.sendLogoutMessage}/>
                                    <MenuItem
                                        style={{ paddingRight: '30px', width: "100%" }}
                                        onClick={this.sendLogoutMessage}
                                    >
                                        Logout
                                    </MenuItem>
                                </Box>
                            </Menu>
                            <Menu
                                data-id="notifications"
                                anchorEl={notificationPopup}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                open={openNotificationPopup}
                                onClose={this.handleCloseNotificationPopup}
                                style={{
                                    top: "50px",
                                }}
                                className={`${isSwitchable ? "notification-main-menu-switch" :""}  notification-main-menu-sponser`}
                            >
                                <Notificationbox navigation={this.props.navigation} id={""}></Notificationbox>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                    data-id="drawers"
                    anchor={"left"}
                    open={mobileOpen}
                    onClose={this.handleDrawerClose}
                >
                    <Box
                        data-id="drawerContainers"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            justifyContent: "center",
                            alignItems: 'center',
                            width: "300px",
                            marginTop: "15px",
                        }}

                    >
                        <Box
                            data-id="logo"
                            style={{ width: "120px", margin: "0 auto" }}>
                            <img
                                data-test-id="logo3"
                                style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
                                src={lancerscapelogo}
                                alt="log_image"
                                onClick={() => { this.props.navigation.navigate('SponsorDashboard') }}
                            />
                        </Box>
                        <Link to="/SponsorDashboard">
                            <Button
                                className={`${classes.nav__items}`}
                                data-test-id='dashboard'
                                style={{ color: this.props.route == "dashboard" ? "#00A7A2" : "" }}
                            >
                                Dashboard
                            </Button>
                        </Link>
                        <Link to="/Projects/activeprojects">
                            <Button className={`${classes.nav__items}`}
                                data-test-id='projects'
                                style={{ color: this.props.route == "projects" ? "#00A7A2" : "" }}>
                                Projects
                            </Button>
                        </Link>
                        <Link to="">
                            <Button className={`${classes.nav__items}`}
                                data-test-id='reports'
                                style={{ color: this.props.route == "reports" ? "#00A7A2" : "" }}>
                                Reports
                            </Button>
                        </Link>
                        <Link to="/FavouritesFreelancer">
                            <Button className={`${classes.nav__items}`}
                                data-test-id='favourites'
                                style={this.getFavouriteStyle()}>
                                Favourites
                            </Button>
                        </Link>
                        <button data-testid="postProject" className="btn-green" onClick={this.navigateToPostProjectPage}>Post a Project</button>
                        {/* <Divider /> */}
                        <Link to="/Chat" className="linkButton">
                            <Button className={`${classes.nav__items}`}
                                data-test-id='message'
                                style={{ color: this.props.route == "chat" ? "#00A7A2" : "" }}>
                                Message
                            </Button>
                        </Link>


                        <Link to="/Notificationsponser" className="linkButton">
                            <Button className={`${classes.nav__items}`}
                                data-test-id='notifications'
                            >
                                Notifications <span className="notification-bar"><span>{this.state.notificationcount}</span></span>
                            </Button>
                        </Link>
                        <Link to="/Contactus" className="linkButton">
                            <Button className={`${classes.nav__items}`}
                                data-test-id='notifications'
                            >
                                Contact Us
                            </Button>
                        </Link>
                        {isSwitchable &&
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "80px",
                                    margin: "0 auto",
                                    width: "80px",
                                    border: "2px solid #00A7A2",
                                    borderRadius: "15px",
                                    cursor: "pointer",
                                }}
                                onClick={this.sendSwitchAccountMessage}
                            >
                                <img style={webStyle.largeImage} src={switchbtn} />
                                <Typography
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    Switch to Freelancer
                                </Typography>
                            </Box>}
                    </Box>
                </Drawer>
                <br />
                <br />
                <br />
                {this.addSpace(isSwitchable as boolean)}
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const styles: any = (theme: Theme) =>
    createStyles({
        search_input_box: {
            width: "100%",
            margin: "0 auto",
            borderRadius: "24px",
            border: "1px solid #e4e6e8",
            padding: "10px",
            maxWidth: "500px",
        },
        nav__active: {
            color: "rgb(236, 149, 45)",
            borderBottom: "5px solid rgb(236, 149, 45)",
            fontWeight: "bolder",
            textTransform: 'none',
        },
        menuButton: {
            marginRight: "0",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        nav__hidden__items: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
                alignItems: "center",
                // gap: "15px",
                // padding:'30px'
            },
        },
        notification__container: {
            padding: "30px",
        },
        nav__items: {
            fontWeight: "bolder",
        },
        hidden_on_mobile_flex: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        hidden_on_mobile: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "block",
            },
        },
        hide_on__above_mobile: {
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        sponserLogo: {
            height: "20px",
            marginTop: "8.5px",

        },
        profile_menuItem: {
            color: 'inherit',
            textDecoration: 'none',
            marginLeft: "5px",
            marginRight: '10px',
        }

    })
const webStyle = {
    largeImage: {
        height: "20px",
        marginTop: "8.5px",
    },
}
const SponserHeaderStyles = withStyles(styles, { withTheme: true })(Sponserheader);
export default SponserHeaderStyles
// Customizable Area End
