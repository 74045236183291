import React from "react";
import { Box,Paper,Grid, Typography,Tooltip , Badge } from "@material-ui/core";
import { rightArrow, location, leftArrow, images, info_circle } from "./assets";
import "./MyBid.css";
import { Favorite, FavoriteBorderOutlined } from "@material-ui/icons";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import Sponsorlistingcontroller from "../../joblisting/src/Sponsorlistingcontroller.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";
import {createTheme, ThemeProvider,withStyles, createStyles, Theme} from "@material-ui/core/styles";
import { configJSON } from "./AuctionBiddingController";

const theme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
      overrides: {
        MuiSelect:{
            select: {
            fontWeight:700,
            margin: '3px 2px 0 1px',
            backgroundColor: "transparent !important"
        },
        selectMenu :{
            minWidth: '100px'
        },
      }
      }
});

const selectionButtonArr: string[] = [
    "Favourites",
    "Messaged",
];

export const OnlineBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: "#44b700",
            color: "#44b700",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        },
    })
)(Badge);

export const OfflineBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            backgroundColor: "#ff9808",
            color: "#ff9808",
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        },
    })
)(Badge);


export default class ProposalListing extends Sponsorlistingcontroller {

    getProjectCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean) => {
        return (
            <>
                {freelancerCost?.currency_symbol} {freelancerCost?.amount} {"   "}
                {sponsorCost?.currency != freelancerCost?.currency &&
                    <>
                        <span  id="listofall" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "15px" }} >({sponsorCost?.currency_symbol}{sponsorCost?.amount})</span>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} id="abc" title={`Converted from (${freelancerCost.currency}) into (${sponsorCost.currency}) based on today's conversion rate`}>
                            <img style={{ height: "13px", width: "13px", marginLeft: "5px" }} src={info_circle} alt="info" />
                        </Tooltip> </>}
                {hourlyRated && " /hr"}
            </>
        )
    } 
    renderUserApplied = (item: any, index: any) => {
        const convertedCost=item.attributes?.currency_conversion;
        const freelancerConvertedCost=convertedCost?.freelancer;
        const sponsorConvertedCost=convertedCost?.sponsor;
        const BadgeComponent = item.attributes.freelancer_availability ? OnlineBadge :OfflineBadge
        return (
            
            <Box className="personDetailStack">
                <Grid container  spacing={2} style={webStyle.proposalListGridStyle}>
                    <Grid item sm={12} md={7}>
                        <Grid container style={webStyle.innerGridContainerStyle}>
                                {/* --------------- avatar----------------------- */}
                                <Grid item sm={12} md={3}>
                                <Box className="imageInnerBox">
                                    <BadgeComponent
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right",
                                        }}
                                        variant="dot"
                                        className="userStatus"
                                    >
                                        <img src={item.attributes.photo && item.attributes.photo.length > 0 ? item.attributes.photo[0].url : images} />
                                    </BadgeComponent>
                                </Box>
                                </Grid>
                                 {/* --------skills and locations------------------------------------------ */}
                                 <Grid item  sm={12} md={9}>
     
                                    <Box className="userNameSpan">{item.attributes.profile_name}</Box>
                                        <Box style= {webStyle.listLocationStyle} className="skillLocationBox">
                                            <span>{item.attributes.profile_skills?.map((e: any, index: any) => {
                                                let skill;
                                                if (item.attributes.profile_skills.length != index + 1) {
                                                    skill = e + " | "
                                                }
                                                else {
                                                    skill = e
                                                }
                                                return (
                                                    skill
                                                )
                                            })}</span>
                    
                                        </Box>
                                        <Box className="locationImageSpan" style={webStyle.listLocation}>
                                        <img src={location} alt="location Icon" />&nbsp; <span>{item.attributes.location}</span>
                                            </Box> 
                                        <Box className="ratingBox margin-container" >
                                            {Array.from(Array(5)).map((item, index) => (
                                                <svg
                                                key={index}
                                                    height="12px"
                                                    width="12px"
                                                    viewBox="0 0 358.97 358.97"
                                                    fill={index < item?.attributes?.sponsor.rating ? "orange" : "#eeeded"}
                                                >
                                                    <g>
                                                        {" "}
                                                        <path d="M358.966,143.652c0-11.38-9.047-20.582-20.183-20.582c-0.301,0-0.602,0.024-0.894,0.041 l-0.154-0.569H232.323l-30.076-92.544l-0.268-0.016c-0.293-12.404-10.234-22.419-22.5-22.419c-12.03,0-21.817,9.616-22.459,21.736 h-0.081l-30.311,93.243H25.247c-1.13-0.179-2.276-0.301-3.471-0.301C9.754,122.242,0,132.175,0,144.449 c0,9.673,6.088,17.875,14.55,20.923l79.424,57.697l-31.986,98.47l0.602,0.447c-0.805,2.26-1.26,4.698-1.26,7.259 c0,11.811,9.397,21.386,20.996,21.386c5.064,0,9.714-1.845,13.347-4.91l0.236,0.195l83.562-60.72l84.854,61.655l0.406-0.276 c3.56,3.008,8.137,4.828,13.111,4.828c11.372,0,20.582-9.397,20.582-20.972c0-2.122-0.309-4.154-0.886-6.08l0.293-0.179 l-32.839-101.103l83.919-60.981l-0.171-0.553C354.845,157.975,358.966,151.293,358.966,143.652z"></path>{" "}
                                                    </g>
                                                </svg>
                                            ))}
                                            <span className="ratingRatioSpan">{item.attributes.sponsor.rating == null ? "0" : item.attributes.sponsor.rating}/5</span>
                                        </Box>
                                        <Box className="experienceBox">
                                            {
                                            item.attributes.amount && item.attributes.project.negotiable && (
                                                <Box>
                                                <span className="experienceBoxTitle">Total Quote Amount</span>
                                                <span id="abccc"  test-id="proposal" style={{color:"black",fontWeight:700}}>{this.getProjectCostElement(freelancerConvertedCost,sponsorConvertedCost,this.state?.singleProjectInfo?.attributes?.budget_type==="hourly_price")} </span>
                                                </Box>
                                            )
                                            }
                                                                                                                  
                                            <Box>
                                                <span className="experienceBoxTitle">Total Hours</span>
                                                <span className="experienceBoxValue">{item.attributes.total_ammount_of_time ? item.attributes.total_ammount_of_time : "8"}{" "}hours</span>
                                            </Box>
                                            <Box>
                                                <span className="experienceBoxTitle">Total Experience </span>
                                                <span className="experienceBoxValue">{item.attributes.total_experince} years</span>
                                            </Box>
                                        </Box>
                                        <Box className="featureBox">
                                            {item.attributes.category_of_interests?.map((item: any, index: any) => {
                                                return (
                                                        <Box key={index} className="featureInnerBox">
                                                            {item}
                                                        </Box>
                                                )
                                            })}
                                        </Box>
                            
                                </Grid>   
                        </Grid>
                    </Grid>

                    <Grid item sm={12} md={5} className="resWidth">
                            <Box className="actionButtonContainerBox">
                                <Box
                                    className="iconBox"
                                    data-testid="fav"
                                    onClick={() => this.handleLike(item.attributes, index)}
                                >
                                    {item.attributes.is_favourite_freelancer ? (
                                        <Favorite />
                                    ) : (
                                        <FavoriteBorderOutlined />
                                    )}
                                </Box>
                                <Box onClick={()=>this.onSendMessage(item.attributes?.freelancer_id,this.state.singleProjectInfo?.id as string)}>{configJSON.btnMessage}</Box>
                                <Box data-testid="HirefreelancerTestID" className="hireButton" onClick={() => this.handlePraposalDetailNavigation(item.id)}>{configJSON.btnHire}</Box>
                            </Box>
                    </Grid>
                </Grid>
            </Box>
            
        );
    };
    isBidNotExists=()=>{
        const {getBidLoading,bidScope,sponsorBidData,selectedCategoryButton}=this.state;
        if(selectedCategoryButton===0 && !getBidLoading && !sponsorBidData?.length){
            return true;
        } 
        if(selectedCategoryButton!==0 && !getBidLoading && !bidScope?.length){
            return true;
        }
    }
    handleCategorySelection = (item: string, index: number) => {
        if(index == 0) {
            this.handleSelectCatagoryButton(item, index);
        } else {
            this.redirectToChat();
        }
        
    };
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Sponserheader navigation={this.props.navigation} id={""} route={"projects"} />
                <br/>
                <Box className="mainStack body_proppsal_wrapper">
                    <Box className="mainTitleBox">
                        <Box
                            className="backIconButtonBox"
                            onClick={this.handleGoBack}
                            data-testid="GoBackButton"
                        >
                            <img src={leftArrow} alt="backArrowIcon" />
                            Back
                        </Box>
                        <span>{this.state?.singleProjectInfo?.attributes?.project_name}</span>
                    </Box>
                    {
                        this.state.selectedCategoryButton===0 && (
                            <ProjectFilterWeb
                                onSearch={this.searchProjectHandler}
                                onCancelFilter={this.cancelFilterProjectHandler}
                                data-testid="active_search"
                                onFilter={this.filterProjectHandler}
                                activeTabColor="#00A7A2"
                                sortInOutside={true}
                                searchPlaceholder={"Search Proposals"}
                            />
                        )
                    }
                   
                    <Box className="selectionButtonBox">
                        <Box
                            className={
                                0 === this.state.selectedCategoryButton
                                    ? "buttonBox1 buttonBox"
                                    : "buttonBox1 buttonBox inactiveButtonBox1"
                            }
                            onClick={this.handleSelectCatagory}
                            data-testid="selectCatagory1"
                        >
                            {configJSON.allProposalsBtn} 
                            <Box className="proposalCountBox">
                                <Typography style={webStyle.proposalBtnCount}>{this.state.sponsorBidData?.length}</Typography>
                                </Box>
                        </Box>
                        {selectionButtonArr.map((item, index) => (
                            <Box
                                data-testid={`selectCatagory${index + 1}`}
                                key={index}
                                className={
                                    index + 1 === this.state.selectedCategoryButton
                                        ? "buttonBox1 buttonBox"
                                        : "buttonBox1 buttonBox inactiveButtonBox1"
                                }
                                onClick={() => this.handleCategorySelection(item, index)}
                            >
                                {item}

                            </Box>
                        ))}
                    </Box>
                    <Box className="personDetailListStack"> 
                        {
                            this.state.getBidLoading && (
                                <Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    <CircularProgress/>
                                </Box>
                            )
                        }

                        {
                            this.isBidNotExists() && (
                                <Paper className="border-block p-0 projectloading-main" > 
                                <div  className='projectloading'> <h2>{this.state.selectedCategoryButton === 2 ? "No Messages Found" : "No Proposals Found"}</h2></div>
                               </Paper>
                            )
                        }
                        {
                            this.state.selectedCategoryButton == 0 ?
                                this.state.sponsorBidData?.slice(0, this.state.count)?.map((item: any, index: any) => {
                                    
                                    return (
                                        <Box  key={index}>{this.renderUserApplied(item, index)} </Box>
                                    )
                                }) :
                                this.state.bidScope?.slice(0, this.state.count)?.map((item: any, index: any) => {
                                    return (
                                        <Box  key={index}>{this.renderUserApplied(item, index)}</Box>
                                    )
                                })
                        }
                    </Box>
                    <Box className="Show_more_btn">
                        {
                            this.state.sponsorBidData.length > this.state.count &&
                            <Box className="backIconButtonBox showButton" onClick={this.showMore}>
                                Show More <img src={rightArrow} alt="rightArrow" />
                            </Box>
                        }
                    </Box>
                </Box>
                <Sponserfooter navigation={this.props.navigation}  />
            </ThemeProvider>
        );
    }
}
export const webStyle = {
    proposalBtnCount: {
    fontSize:'12px',
    fontWeight:700
},
listLocation:{
    color: '#8083a3',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    display:'flex',
    alignItems:'center'
},
listLocationStyle:{
   fontSize:'14px',
   fontWeight:500
},
proposalListGridStyle:{
    justifyContent:"space-between",
     width:'100%'
 },
 innerGridContainerStyle:{
    display:"flex",
    width:'100%'
},

  };