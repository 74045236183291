// Customizable Area Start
import { runEngine } from "../../../../framework/src/RunEngine";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import CreateRestApiMessage from "../../../../components/src/CreateRestApiMessage.web";
import { IProjectData } from "../types";
import { Message } from "../../../../framework/src/Message";
import { IProfile } from "../../../rolesandpermissions/src/Roles.types";
export const configJSON = require("../config");
import { getStorageData } from "../../../../framework/src/Utilities";
import moment from 'moment';
type ProjectSizeType="hours"|"day(s)"|"month(s)"|"year(s)"
export interface Props {
  navigation: any;
  id: string;
}
interface InputValues{ 
  amount:string;
  startDate:string;
  endDate:string;
  description:string;
}

interface S {
  inputs:InputValues,
  errors:InputValues,
  projectLoading:boolean;
  project:IProjectData|null;
  currentUser:IProfile|null;
  submitBidLoading:boolean;
  showBidSuccessPage:boolean;
  successMessage:string;
  hourlyRate:string;
  getBidLoading:boolean;
  bidid: any;
  milestoneDetails: any
}

interface SS {}

const inputInitialValue={
  amount:"",
  startDate:"",
  endDate:"",
  description:""
}

export default class ProjectBidController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSingleProjectInfoApiCallId:string="";
  submitBidApiCallId:string="";
  applyProjectApiCallId:string="";
  getHourlyRatedApiCallId:string="";
  addFavouriteProjectApiCallId:string="";
  getBidInfoApiUrl:string="";
  
  
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ]);

    this.state = {
      inputs:inputInitialValue,
      errors:inputInitialValue,
      projectLoading:false,
      project:null,
      currentUser:null,
      submitBidLoading:false,
      showBidSuccessPage:false,
      successMessage:"",
      hourlyRate:"",
      getBidLoading:false,
      bidid: "",
      milestoneDetails: {}
    };
  }
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      if(user) {
        this.setState({ currentUser: user });
      }
    }
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getSingleProjectInfoApiCallId) {
        this.handleGetProjectsPayload(responseData);
      }
      if (apiRequestCallId === this.submitBidApiCallId) {
        this.handleSubmitBidPayload(responseData);
      }
      if (apiRequestCallId === this.applyProjectApiCallId) {
        this.handleApplyProjectPayload(responseData);
      }
      if (apiRequestCallId === this.getHourlyRatedApiCallId) {
        this.handleHourlyRatedPayload(responseData);
      }
      if(apiRequestCallId===this.addFavouriteProjectApiCallId){
        this.getSingleProjectInfo()
      }
      if (apiRequestCallId === this.getBidInfoApiUrl) {
        this.setState({milestoneDetails:responseData?.bid?.data},)
      }
    }
  }
  handleGoBackButton = () => window.history.back();

  handleGetProjectsPayload=(responseJson:any)=>{
    if(responseJson?.data?.id){
      this.setState({
        projectLoading:false,
        project:responseJson?.data,
        bidid: responseJson?.data?.attributes?.bid_id
      },()=>{
        const {project}=this.state;
         if(project?.attributes?.budget_type==="hourly_price"){
          this.getHourlyRatedAmount()
          
         }
         this.getBidInfo(this.state.bidid)
         this.calculateBidEndDate(project?.attributes?.select_duration_type as ProjectSizeType,Number(project?.attributes?.project_size))
         
        })
    }else{
       this.setState({
        projectLoading:false,
       },()=>{
        toast.error("Project not found!")
       })
    }
  }  
  handleApplyProjectPayload=(responseJson:any)=>{
    if(responseJson?.meta?.message){
      this.setState({
        successMessage:"Proposal Successfully Submitted",
        showBidSuccessPage:true,
        submitBidLoading:false
      })
    }else if(responseJson?.success===false){
      toast.error(responseJson?.errors);
      this.setState({
        showBidSuccessPage:false,
        submitBidLoading:false,
      })
    }else{
      if(responseJson?.errors && responseJson?.errors?.length>0){
        responseJson?.errors?.forEach((err:string)=>toast.error(err))
      }
      this.setState({
        showBidSuccessPage:false,
        submitBidLoading:false,
      })
    }
 
   
  }
   getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  handleSubmitBidPayload=(responseJson:any)=>{
    if(responseJson?.data?.id){
      this.setState({
        submitBidLoading:false,
        showBidSuccessPage:true,
        successMessage:"Quote Submitted Successfully!"
      })
    }else if(responseJson?.success===false){
      toast.error(responseJson?.errors);
      this.setState({
        showBidSuccessPage:false,
        submitBidLoading:false,
      })
    }else{
      if(responseJson?.errors && responseJson?.errors?.length>0){
        responseJson?.errors?.forEach((err:string)=>toast.error(err))
      }
      this.setState({
        showBidSuccessPage:false,
        submitBidLoading:false,
      })
    }
  }

  handleHourlyRatedPayload=(responseJson:any)=>{
    if(responseJson?.data){
         this.setState((prev)=>({
          inputs:{
           ...prev.inputs,
          amount:responseJson?.data.attributes.charges.toString() || "",
          }
       }))
      
     
    }
  } 


  async componentDidMount(){
    super.componentDidMount();
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
    this.getSingleProjectInfo();
  }

  getSingleProjectInfo=()=>{
    this.setState({
      projectLoading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header:any = {
      "Content-Type": "application/json",      
      "token": token
    };
    token && (header.token=token)
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getSingleProjectApiUrl}/${this.getProjectId()}`,
      body: null,
      method: "GET",
    });

    this.getSingleProjectInfoApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  getBidInfo = (bidid:any) => {
    this.setState({
      getBidLoading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.getSingleBidApiUrl}/${bidid}?calculate=true`,
      body: null,
      method: "GET",
    });

    this.getBidInfoApiUrl = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };
  getBidId = () => {
    return this.props.navigation?.getParam("bidId");
};
          // get hourly rate api start
          getHourlyRatedAmount = async() => {
            const token =   await getStorageData('loginSuccessToken', false)
            const header = {
              token: token,
              "Content-Type": configJSON.exampleApiContentType,
            };
        
            const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
        
            this.getHourlyRatedApiCallId = requestMessage.messageId;
            requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.getHourlyRatesApiCallUrl
            );
        
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(header)
            );
        
            requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.httpGetMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
          };
          // get hourly rate api end


  handleSubmit=()=>{
    if(this.state.submitBidLoading) return
    const {inputs:{amount,description,endDate,startDate},project}=this.state;
    const projectType=project?.attributes.bid_status;
    let newErrors:any={};
    if(!amount?.trim() && projectType!==1){
      newErrors.amount="Please enter quote amount"
    }
    if(!description?.trim()){
      newErrors.description="Please enter quote description"
    }
    if(!endDate?.trim()){
      newErrors.endDate="Please select end date"
    }
     if(!startDate?.trim()){
      newErrors.startDate="Please select start date"
    }
    const currentDate=this.getCurrentDate();
    if(startDate && startDate<currentDate){
      newErrors.startDate="Please select a start date that is later than today."
    }
    if(endDate && endDate<currentDate){
      newErrors.endDate="Please select a end date that is later than today."
    
    }
    if(startDate && endDate){
       this.validateDate(newErrors)
    }

    if(Object.keys(newErrors)?.length>0){
      this.setState({errors:newErrors})
      return;
    }
   this.setState({errors:inputInitialValue});
    if(projectType===1){
      //this is a non bid project
      this.applyProject();
    }else{
      this.submitBid();
    }
  }
  validateDate=(newErrors:any)=>{
    const {inputs:{startDate,endDate}}=this.state;
    const stDate = new Date(startDate);
    const enDate = new Date(endDate);
    if (stDate > enDate) {
      newErrors.startDate="start date should not be later than end date"
    }
    if(stDate.getFullYear()>9999 || isNaN(stDate.getFullYear())){
      newErrors.startDate="Please enter valid year"
    } 
    if(enDate.getFullYear()>9999 || isNaN(enDate.getFullYear())){
      newErrors.endDate="Please enter valid year"
    }
  }
  applyProject=()=>{
    this.setState({
      submitBidLoading: true,
    });
    
    const token = localStorage.getItem("loginSuccessToken");
    const {currentUser,inputs}=this.state;
    const formData=new FormData();
    formData.append("bid[profile_id]",`${currentUser?.id}`);
    formData.append("bid[project_id]",`${this.getProjectId()}`);
    formData.append("bid[proposal]",`${inputs?.description}`);
    formData.append("bid[start_date]",`${inputs?.startDate}`);
    formData.append("bid[end_date]",`${inputs?.endDate}`);

    const header = {
      token: token,
      // "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.applyProjectApiUrl}`,
      body: formData,
      method: "POST",
    });

    this.applyProjectApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }


  calculateBidEndDate=(projectSizeType:ProjectSizeType,projectDuration:number,dt?:string)=>{
    let currentDate=moment(dt);
    if(projectSizeType.includes("year")){
      currentDate.add(projectDuration,"years");
    }else if(projectSizeType.includes("month")){
      currentDate.add(projectDuration,"months");
    }else if(projectSizeType.includes("hour")){
      currentDate.add(projectDuration,"hours");
    }else if(projectSizeType.includes("day")){
      currentDate.add(projectDuration,"days");
    }
    const formattedDate=currentDate.format('YYYY-MM-DD');
    this.setState(prev=>({
       inputs:{
         ...prev.inputs,
         endDate:formattedDate
       }
    }))

  }

  submitBid=()=>{
    const {currentUser,inputs}=this.state;
    this.setState({
      submitBidLoading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const body={
      bid:{
        project_id:this.getProjectId(),
        profile_id:currentUser?.id,
        amount:inputs.amount,
        start_date:inputs.startDate,
        end_date:inputs.endDate,
        proposal:inputs.description
      }
    }
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.submitBidApiUrl}`,
      body: JSON.stringify(body),
      method: "POST",
    });

    this.submitBidApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }


  navigateToBidProject=(prId:any)=>{
    const id=this.getProjectId();
    const project = this.state.project;

    if(project){
      if(project.attributes.number_of_propsals < project.attributes.number_of_bidders || project.attributes.bid_status === 1) {
        return this.props?.navigation?.navigate("BidProject", { projectId: id })
      }
    }

    return toast.error("Quote limit has been exceeded");
  }

  navigateToFreelancerDashboard=()=>{
    this.props?.navigation?.navigate("FreelancerDashboard")
  }
  navigateToFreelancerBrowseMoreProjects=()=>{
    this.props?.navigation?.navigate("FreelancerProjects",{
      type:"browse-all"
    })
  }

  goBack=()=>{
    this.props.navigation?.goBack();
  }
  getProjectId = () => {
    return this.props.navigation.getParam("projectId");
  };

  handleInputChange=(e:ChangeEvent<HTMLInputElement>)=>{
    const keyName=e.target?.name as any;
    const value=e.target?.value;
    this.setState((prev)=>({
       inputs:{
        ...prev.inputs,
        [keyName]:value
       }
    }),()=>{
      if(keyName==="startDate"){
        const {project}=this.state;
        if(this.isDateValid()){
          this.calculateBidEndDate(project?.attributes?.select_duration_type as ProjectSizeType,Number(project?.attributes?.project_size),value)
        }
      }
    })

    
  }

  isDateValid=()=>{
    const {inputs:{startDate}}=this.state;
    const stDate = new Date(startDate);
    let isValid=true;
    const currentDate=this.getCurrentDate();
    if(startDate && startDate<currentDate){
      isValid=false;
    }
    if(stDate.getFullYear()>9999 || isNaN(stDate.getFullYear())){
        isValid=false;
    } 
    return isValid;
  }
  addFavouriteProject = (isFavouriteProfileID: any) => {
    
    const authToken = localStorage.getItem("loginSuccessToken");
    let projectBody = {
      favourite: {
        project_id: isFavouriteProfileID
      }
    }
    const APIHeader = {
      "Content-Type": "application/json",
      token: authToken,
    };

    this.createFavouritesAPICallMessage(projectBody,APIHeader)
    
  };

  createFavouritesAPICallMessage = (projectBody:any,APIHeader:any)=>{
    const favMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addFavouriteProjectApiCallId = favMessage.messageId;
    favMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addFavouritesURL
      
    );
    favMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(APIHeader)
    );
    favMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(projectBody)
    );
    favMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(favMessage.id, favMessage);
    return true;

  }
}

// Customizable Area End
