import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IProjectData } from "../../dashboard/src/types";
import { PROJECT_PER_PAGE } from "../../dashboard/src/freelancer-projects/FreelancerBrowseProjectController.web";
import { toast } from "react-toastify";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  history?: any;
  location?: any;
  classes?: any;
  route?: any;
  id?: any;
}

export interface S {
  // Customizable Area Start
  favproject: boolean;
  favProjectList: IProjectData[];
  favProjectLoading: boolean;
  totalProjects: number;
  currentPage: number;
  activeTab: number;
  searchText: string;
  queryString: string;
  isActiveAll: boolean;
  showSimilar:boolean;
  similarProjectId:string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class FavouriteLancerProjectController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getMyFavProjectApiCallId: string = "";
  getAllProjectApiCallId:string = "";
  addOrRemoveFavProjectID:string="";
  addAndRemoveFavouritesProjectApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

    this.state = {
      // Customizable Area Start
      favproject: true,
      favProjectList: [],
      favProjectLoading: false,
      totalProjects: 0,
      currentPage: 1,
      activeTab: 0,
      searchText: "",
      queryString: "",
      isActiveAll: true,
      showSimilar:false,
      similarProjectId:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    this.favPrepareFinalQuery();
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getMyFavProjectApiCallId) {
        this.handleGetAllFavProjectsPayload(responseJson);
      }
      if(apiRequestCallId === this.getAllProjectApiCallId){
        this.handleGetAllFavProjectsPayload(responseJson)
      }
      if(apiRequestCallId === this.addAndRemoveFavouritesProjectApiCallId){
        toast.success("Project removed from favourites")        
        this.favPrepareFinalQuery()                
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetAllFavProjectsPayload = (responseJson: any) => {
    if (responseJson && responseJson?.projects?.data) {
      this.setState((prev) => {
        let newProjectList: any[] = [];
        if (prev.currentPage === 1) {
          newProjectList = responseJson?.projects?.data;
        } else {
          newProjectList = [...prev.favProjectList, ...responseJson.projects.data];
        }
        return {
          favProjectList: newProjectList,
          favProjectLoading: false,
          totalProjects: responseJson?.pagination?.total_data || 0,
        };
      });
    } else {
      this.setState({ favProjectLoading: false,favProjectList:[],totalProjects:0 });
    }
  };
  handleProjectShowMore = () => {
    const { favProjectLoading } = this.state;
    if (!favProjectLoading) {
      this.setState(
        (prev) => ({ currentPage: prev.currentPage + 1 }),
        () => {
          this.favPrepareFinalQuery()
            
        }
      );
    }
  };
  favSearchHandlers = (searchText: string) => {
    this.setState({ currentPage: 1, searchText: searchText ,favProjectList:[] }, () => {
      this.favPrepareFinalQuery();
    });
  };
  favFilterHandlers = (query: string, selectedOptions: any) => {
    this.setState({ currentPage: 1, queryString: query,favProjectList:[]  }, () => {
      this.favPrepareFinalQuery();
    });
  };
  favCancelFilterHandlers = () => {
    this.setState({ currentPage: 1, queryString: "",favProjectList:[],showSimilar:false  }, () => {
      this.favPrepareFinalQuery();
    });
  };

  favHandleTabChange = (e: any, newValue: number) => {
    const { activeTab } = this.state;
    if (activeTab !== newValue) {
      this.setState(
        { activeTab: newValue, currentPage: 1, favProjectList: [],showSimilar:false },
        () => {
          this.favPrepareFinalQuery();
        }
      );
    }
  };
  favNavigateToBidProjectDetails = (id: string) => {
    this.props?.navigation?.navigate("BidProjectDetails", { projectId: id })
  }
  favNavigateToSubmitBidPage = (id: string) => {
    const project = this.state.favProjectList.find(data=> data.id === id) || null;

    if(project){
      if(project.attributes.number_of_propsals < project.attributes.number_of_bidders || project.attributes.bid_status === 1) {
        return this.props?.navigation?.navigate("BidProject", { projectId: id })
      }
    }

    return toast.error("Quote limit has been exceeded");
  }

  onShowSimilar=(projectId:string)=>{
    this.setState({showSimilar:true,currentPage:1,similarProjectId:projectId,favProjectList:[]},()=>{
       this.favPrepareFinalQuery();
    })
}

  getMyFavProject = (query: string) => {
    this.setState({
      favProjectLoading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");

    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getMyFavProjectApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouritesLancerEndPoint}?${query}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  favGetTaQueryValue = () => {
    const { activeTab } = this.state;
    if (activeTab === 0) {
      return "";
    } else if (activeTab === 1) {
      return `bid_status=open_bid_project`;
    } else if (activeTab === 2) {
      return `bid_status=non_bid_project`;
    }
  };
  favPrepareFinalQuery = () => {
    let favFinalQuery: string[] = [];
    const { searchText, queryString, currentPage, favproject,showSimilar,similarProjectId } = this.state;
    favFinalQuery.push(`current_page=${currentPage}`);
    favFinalQuery.push(`per_page=${PROJECT_PER_PAGE}`);
    favFinalQuery.push(`fav=${favproject}`);
    if(showSimilar){
      favFinalQuery.push(`show_similar=true`);
      favFinalQuery.push(`project_id=${similarProjectId}`);
    }
    if (searchText) {
      favFinalQuery.push(`search=${searchText}`);
    }
    if (queryString) {
      favFinalQuery.push(queryString);
    }
    const tabQuery = this.favGetTaQueryValue();
    if (tabQuery) {
      favFinalQuery.push(tabQuery);
    }
    const newQueryText = favFinalQuery.join("&");

    this.getMyFavProject(newQueryText);
  };

  addAndRemoveFavouritesProject = (favouriteProfileID: any) => {
    this.addOrRemoveFavProjectID = favouriteProfileID
    const token = localStorage.getItem("loginSuccessToken");
    let body = {
      favourite: {
        project_id: favouriteProfileID
      }
    }
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAndRemoveFavouritesProjectApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAndRemoveFavouritesAPIEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}