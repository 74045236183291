import React from 'react';
import {Dialog,DialogTitle,DialogContent,Box, IconButton,Divider,Button,Typography,FormControl,FormLabel,FormControlLabel,RadioGroup,Radio} from "@material-ui/core"
import { CloseOutlined } from '@material-ui/icons';
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { StyledTextField } from './SendInvitationModal.web';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    send_button:{
      background:"#00A7A2",
      borderRadius:"20px",
      padding:"10px 20px",
      color:"white",
      textTransform:"capitalize",
      "&:hover":{
          background:"#00A7A2"
        }
    },
    cancel_button:{
      background:"#f2f2f2",
      borderRadius:"20px",
      padding:"10px 20px",
      textTransform:"capitalize",
    },
    action_container:{
      display:"flex",
      alignItems:"center",
      justifyContent:"flex-end",
      gap:"20px",
      marginTop:"20px",
      marginBottom:"20px"
    },
  })
);
interface Props{
    open:boolean;
    setOpen:(open:boolean)=>void;
    loading:boolean;
    onDecline:(msg:string)=>void;
    clearInput:boolean;
}

const DeclineInvitationModal:React.FC<Props> = ({open,setOpen,loading,onDecline,clearInput}) => {
  const classes=useStyles();
  const [message,setMessage]=React.useState("");
  const [value,setValue]=React.useState("");
  const [errors,setErrors]=React.useState("");

  React.useEffect(()=>{
    if(clearInput){
      setMessage("");
      setValue("");
    }
  },[clearInput]);
  const handleSend=()=>{
    if(value!=="Other"){
      setErrors("");
      onDecline(value);
      return;
    }
    if(!message.trim()){
      setErrors("Please enter invitation message!")
      return;
    }
    setErrors("");
    onDecline(message);
  }
    return ( 
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{ style: { borderRadius: 16 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
       <DialogTitle
        id="max-width-dialog-title"
        style={{ paddingBottom: 0 }}
      >
        <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <strong>Decline</strong>
          <IconButton onClick={() => setOpen(false)}>
              <CloseOutlined/>
            </IconButton>
        </Box>
      </DialogTitle>
      <Divider/>
      <DialogContent>
         <Typography style={{fontWeight:"bold"}} component="h6" variant="h6">Please select a reason for declining this freelancer</Typography>
            <br/>
            <FormControl component="fieldset">
            <RadioGroup value={value} onChange={(e)=>setValue(e.target.value)}>
              {[
                "Lacks desired skills or qualifications",
                "Just preferd other applicant",
                "Rate bid too high",
                "Insufficient Lancerscape history",
                "Other"
              ].map((item)=>(
               <FormControlLabel key={item} value={item} control={<Radio />} label={item} />
              ))}
            </RadioGroup>
          </FormControl>
          {
            value==="Other" && (
              <StyledTextField
                            label="Message*"
                            placeholder={"Message"}
                            fullWidth
                            inputProps={{
                              style: { height: "120px",
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontWeight: 600,
                              color: '#000',    
                            },
                            }}
                            multiline={true}
                            margin="normal"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={message}
                            onChange={(e)=>setMessage(e.target.value)}
                            error={Boolean(errors)}
                            helperText={errors}
                          />   
            )
          }
          
            <Box className={classes.action_container}>
                <Button onClick={()=>setOpen(false)} className={classes.cancel_button}>
                    Cancel
                </Button>
                 <Button disabled={loading} onClick={handleSend} className={classes.send_button}>
                    {loading?<CircularProgress size={18}/> :"Decline"}
                </Button>
            </Box>                                   
      </DialogContent>
      <br/>
      <br/>
    </Dialog>
     );
}
 
export default DeclineInvitationModal;