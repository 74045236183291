Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.getThemeApiEndpoint = "/themes/themes/"
exports.getAllThemesApiEndpoint = "/themes/themes";


exports.updateMethodType = "PUT";
exports.updateThemeApiEndpoint = "/themes/themes/"

exports.deleteMethodType = "DELETE";
exports.deleteThemeApiEndpoint = "/themes/themes/";

exports.createApiMethodType = "POST";
exports.createThemeApiEndpoint = "/themes/themes";

exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ThemeBlock";
exports.labelBodyText = "ThemeBlock Body";
exports.submitButton = "Submit Theme";
exports.createThemeApi = "/themes/themes";
exports.themeConstant = "Theme";
exports.chooseBackgroundColor = "Choose Background Color";
exports.chooseTextColor = "Choose Text Color";
exports.isBoldLabel = "Is Bold";
exports.isItalicLabel = "Is Italic";
exports.isUnderlinedLabel = "Is Underlined";
exports.chooseTextBackgroundColor = "Choose Text Background";
exports.updateButton = "Update Theme";
exports.deleteButton = "Delete Theme";
exports.getAllThemesButton = "Get all themes";
exports.thisIsTextLabel = "This is text!";
exports.resetButton = "Reset Theme";



// Customizable Area End