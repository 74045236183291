import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import * as Yup from 'yup';
import {toast} from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");
import { WithStyles} from '@material-ui/core';
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";
export interface Props extends WithStyles{
  navigation:any;
}

export interface S {
  // Customizable Area Start
  email: any;
  password: any;
  c_password: any;
  errorMsg:any;
  error:any;
  Email_invalid:any;
  emailblank:any;
  passworderror:any;
  confirmpassworderror:any;
  showPasswordCriteria:boolean;
  errors:any;
  signupLoading:boolean;
  termsAndService:boolean;
  facebookSignupLoading:boolean;
  modalLoading:boolean;
  visiblePassword:{
    password:boolean;
    confirmPassword:boolean;
  }
  openverify: boolean;
  termsOfServiceTitle:string;
  termsOfServiceDesc:string;
  linkedinSignupLoading:boolean;
  googleSignupLoading:boolean;
  openTermsAndServiceModal:boolean;
  apiResponse: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class SignUpController extends BlockComponent<
  Props, S, SS
> {

  // Customizable Area Start
  SignUpCallId: any
  facebookLoginApiCallId:string="";
  apiTermsContentCallId:string="";
  linkedinSignupApiCallId:string="";
  googleSignupApiCallId:string="";
  getGoogleProfileApiUrl:string="";

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      // Customizable Area Start
      email: "",
      password: "",
      c_password: "",
      errorMsg:"",
      error:"",
      Email_invalid:"",
      emailblank:false,
      passworderror:false,
      confirmpassworderror:false,
      showPasswordCriteria:true,
      errors:{},
      signupLoading:false,
      termsAndService:false,
      facebookSignupLoading:false,
      modalLoading:false,
      visiblePassword:{
        confirmPassword:false,
        password:false,
      },
      openverify:false,
      termsOfServiceTitle:'',
      termsOfServiceDesc:'',
      linkedinSignupLoading:false,
      googleSignupLoading:false,
      openTermsAndServiceModal:false,
      apiResponse: {}
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async componentWillMount() {
    // Customizable Area Start
    super.componentDidMount();
    // this.getTermOfServiceContent()

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (apiRequestCallId === this.SignUpCallId) {
        this.setState({signupLoading:false})
        if(responseJson.data && responseJson?.data?.id) {
          localStorage.clear();
          localStorage.setItem("loginSuccessToken", responseJson?.meta?.token);
          toast.success("Please check your inbox and activate your account! ")
          this.props.navigation.navigate('Accountinfo');
          return;
        }
        this.handleErrorSignup(responseJson);
      }else if(apiRequestCallId===this.facebookLoginApiCallId || apiRequestCallId===this.linkedinSignupApiCallId || apiRequestCallId===this.googleSignupApiCallId){
        this.setState({linkedinSignupLoading:false,googleSignupLoading:false})
         this.handleFacebookSignupApiPayload(responseJson)
      }else if(apiRequestCallId===this.getGoogleProfileApiUrl){
        this.handleGoogleProfileApiResponse(responseJson)
      }
  
    }

    if (this.apiTermsContentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if(apiResponse?.terms_of_service?.description) {
      this.setState({
        modalLoading:false,
        openTermsAndServiceModal:true
      })
     }

    
    
      if (apiResponse) {
        this.setState({apiResponse : apiResponse})
        if(apiResponse?.terms_of_service?.url){
          window.open(apiResponse.terms_of_service.url, '_blank');
        }
        else if(apiResponse?.terms_of_service?.description) {
          this.setState({
            modalLoading:false,
            termsOfServiceTitle:apiResponse?.terms_of_service?.title || "", 
            termsOfServiceDesc:apiResponse?.terms_of_service?.description || "",
          })   
        }
         
     }
 

       
    }




     
  // Customizable Area End
  }
 
  // Customizable Area Start
 
  handleGoogleProfileApiResponse=async(responseJson:any)=>{
    const firstName=responseJson?.given_name || "";
    const lastName=responseJson?.family_name || "";
    const data= {
      profilephoto: null,
      first_name: firstName,
      last_name: lastName,
      gender: "",
      mobileNumber: "",
      address: "",
      address_line_2: "",
      country: "",
      city: "",
      state: "",
      postal_code: "",
      date_of_birth: "",
      time_zone: "",
    };
    await setStorageData("Basicdetailarray",JSON.stringify(data));

  }
handleFacebookSignupApiPayload=async(responseJson:any)=>{
  this.setState({facebookSignupLoading:false})
  if(responseJson?.data && responseJson?.data?.id){
    await this.handleGoogleProfileApiResponse({
      given_name: responseJson?.meta?.first_name || "",
      family_name:responseJson?.meta?.last_name || "",
    })
    await removeStorageData("currentState")
    localStorage.setItem("loginSuccessToken", responseJson?.meta?.token);
    this.props.navigation.navigate('Accountinfo');
    return;
  }
  
  if(responseJson?.errors?.length>0){
    const key=Object.keys(responseJson?.errors[0])[0];
    toast.error(responseJson?.errors[0][key])
  }
}
handleCloseTermsAndServiceModal=(open:boolean)=>{
   this.setState({openTermsAndServiceModal:open})
}

handleChangePasswordVisibility=()=>{
   this.setState((prev)=>({
     visiblePassword:{
       ...prev.visiblePassword,
       password:!prev.visiblePassword.password
     }
   }))
}

handleChangeConfirmPasswordVisibility=()=>{
  this.setState((prev)=>({
    visiblePassword:{
      ...prev.visiblePassword,
      confirmPassword:!prev.visiblePassword.confirmPassword
    }
  }))
}
handleTermsAndConditionChange=(checked:boolean)=>{
  this.setState({termsAndService:checked})
}
handleErrorSignup=(responseJson:any)=>{
  this.setState({signupLoading:false})
    if (responseJson?.errors?.length>0) {
      let newErrors:any={};
      responseJson?.errors.forEach((err:any)=>{
         const key=Object.keys(err)[0];
         newErrors[key]=err[key];
      })
      if(newErrors.email){
        newErrors.account=newErrors.email
      }
      this.setState({
        errors:newErrors
      })
    }
  }
  SignInonclick=()=>{
    this.props.navigation.navigate("SignIn") 
  }
  handleLinkedinLoginResponse=(linkedinAccessToken:string)=>{
    this.setState({linkedinSignupLoading:true})
    const header = {
      "Content-Type": "application/json",
    };
    const httpBody={
        data:{
          type:"linkedin",
          action:"signup",
          attributes:{
            access_token:linkedinAccessToken
          }
        }
     }
     const requestMessage=CreateRestApiMessage({
      apiUrl:`bx_block_login/social_logins`,
      body:JSON.stringify(httpBody),
      header:header,
      method:"POST"
     })
    this.linkedinSignupApiCallId=requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }
   handleLinkedinLoginError=(errorRes:{
    error:string,
    errorMessage:string
   })=>{
    if(errorRes?.error!=="user_closed_popup"){
      toast.error(errorRes?.errorMessage)
    }
   }

  handleValidate=()=>{
    const {email,password,c_password,termsAndService}=this.state;
    let userSchema = Yup.object({
      password:Yup.string().required("Please enter your password.").matches(
           configJSON.passwordRegPattern,
           configJSON.msgDisplay,
           ), 
      password_confirmation: Yup.string().required("Please confirm your password.").oneOf([Yup.ref('password')], "Password and confirm password doesn't match."),
      account:Yup.string().required("Please enter your email.").email("Invalid email address."),
    });

    userSchema.validate({
      password:password,
      password_confirmation:c_password,
      account:email
   }, { abortEarly: false }).then((value)=>{
        this.setState({errors:{}})
        if(!termsAndService){
          this.setState({errors:{terms:"Please accept terms & conditions."}})
          return;
        }

        this.handleSignUp();
   }).catch((err)=>{
     let newError:any={};
     err.inner?.forEach((item:any)=>{
        newError[item.path]=item.message
     })
     if(!termsAndService){
       newError.terms="Please accept terms & conditions."
     }
     this.setState({errors:newError})
   })


  }
  onGoogleSignupSuccess=(res:{access_token:string})=>{
    this.setState({googleSignupLoading:true})
    this.fetchGoogleUserInfo(res?.access_token);
    const header = {
      "Content-Type": "application/json",
    };
    const httpBody={
        data:{
          type:"google",
          action:"signup",
          attributes:{
            access_token:res?.access_token
          }
        }
     }
     const requestMessage=CreateRestApiMessage({
      apiUrl:`bx_block_login/social_logins`,
      body:JSON.stringify(httpBody),
      header:header,
      method:"POST"
     })
    this.googleSignupApiCallId=requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }

   fetchGoogleUserInfo=(access_token:string)=>{
    const header = {
      "Authorization":`Bearer ${access_token}`
    };
     const requestMessage=CreateRestApiMessage({
      apiUrl:configJSON.getGoogleProfileApiEndpoint,
      body:null,
      header:header,
      method:"GET"
     })
    this.getGoogleProfileApiUrl=requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
   }


   onGoogleSignupError=(error:any)=>{
    runEngine.debugLog("Google Error",error)
   }
  handleFacebookResponse=(response:any)=>{
     if(response?.accessToken){
       this.handleFacebookSignup(response?.accessToken);
     }
  }

  handleFacebookSignup= (accessToken:string) => {

    let token = localStorage.getItem("loginSuccessToken")
    this.setState({facebookSignupLoading:true})
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const body={
        data:{
          type:"facebook",
          action:"signup",
          attributes:{
            access_token:accessToken
          }
        }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.facebookLoginApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_login/social_logins`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSignUp= (event?: any) => {

    let token = localStorage.getItem("loginSuccessToken")
    this.setState({signupLoading:true})
    const header = {
      "Content-Type": "application/json",
      "token": token
    };
    const attrs = {
      "email": this.state.email,
      "password": this.state.password,
      "password_confirmation": this.state.c_password,
    };
    const data = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SignUpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `sign_up`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }

  getTermOfServiceContent = (terms:string) => {
    this.setState({modalLoading:true})
    
    const header = {
      "Content-Type": configJSON.contentTypeApi,
     };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiTermsContentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      //configJSON.termsContentApiCallUrl`${terms}`
     `account_block/terms_of_services?title=${terms}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePasswordChange = (event: { target: { value: any; }; }) => {
    const passwordPattern =configJSON.changepassvalidation;
  
    const newPassword = event.target.value;
    this.setState({ password: newPassword,error:false  });

    if (!passwordPattern.test(newPassword)) {
      this.setState({
        error: configJSON.msgDisplay
      });
    } else {
      this.setState({ error: '' });
    }
  };

  handleCallModal=(terms:string)=>{
    this.getTermOfServiceContent(terms)
    this.handleOpen()
  }
  handleOpen = () => {
    this.setState({ openverify: true })
  }
  handleClose = () => {
    this.setState({ openverify: false })
  }
  // Customizable Area End



}