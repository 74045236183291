// Customizable Area Start
import React from "react";

import FreelancerMyBidController, {
  Props,
  configJSON,
} from "./FreelancerMyBidController.web";
import { Box, Container,Paper,Avatar } from "@material-ui/core";
import ProjectFilter from "./ProjectFilter.web";
import { completedProject } from "../../../auctionbidding/src/assets"
import "../../../auctionbidding/src/MyBid.css"
import {webStyle} from './FreelancerBrowseProject.web'
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getProjectCostElement as getBidCostElement} from '../../../../components/src/ProjectCardProgress.web';
class FreelancerMyBid extends FreelancerMyBidController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      bidList,
      bidLoading,
      totalBids
    } = this.state;
    const isShowMoreEnable = totalBids > bidList?.length;
    return (
      <Container maxWidth="lg" className="respaddingWidth">
        <Box>
          <ProjectFilter
            onSearch={this.bidSearchHandler}
            onFilter={this.bidFilterHandler}
            onCancelFilter={this.bidCancelFilterHandler}
            isUserFreelancer={true}
          />
          <br />
          <br />
        </Box>
          {!bidLoading && bidList?.length===0 && (
            <Paper className="border-block p-0 projectloading-main" > 
            <div  className='projectloading'> <h2>No Quote Found</h2></div>
            </Paper>
          )}
          {
             bidList?.map((item)=>{
              const isFixedPrice =item?.attributes?.project?.budget_type==="fixed_price";
              const projectType=item?.attributes?.project?.budget_type==="hourly_price"?"Hourly Rated":"Fixed Price";
              const sponsorPhotos=item?.attributes?.sponsor_photo;
              const sponsorImg=sponsorPhotos && sponsorPhotos?.length>0 ? sponsorPhotos[0]?.url:"";
              const bidCurrencyConversion=item.attributes.currency_conversion;
              const negotiationConversion=this.getNegotiationsConversion(item.attributes?.negotiations);
              return(
                <Box data-testid="projectCard" key={item?.id} className="mouse_event hoverClass" onClick={()=>this.handleProjectDetailNavigation(item?.id)}>
                <Box className="project_common_container">
                  <h2 style={{color:"black",fontWeight:"bolder"}} className="company_logo_container">
                    {item?.attributes.project_name}
                  </h2>
                  <Box className="price_negotiation">
                    <span style={{marginRight:"15px"}}>{projectType}</span>
                    <span>Posted At: {new Date(item?.attributes?.created_at).toLocaleDateString()}</span>
                  </Box>
                </Box>
      
                <Box className="project_container1"></Box>
      
                <Box className="project_common_container">
                  <span className="price_negotiation">Sponsor</span>
                  <Box className="profile_wrapper">
                  <Avatar src={sponsorImg} className="profile_image" />
                    <Box style={{color:"black"}} className="project_owner_name">{item?.attributes?.sponsor?.full_name}</Box>
                  </Box>
                </Box>
      
                <Box className="project_container1"></Box>
      
                <Box className="bid_negititation project_common_container">
                { item.attributes.project.bid_status != "non_bid_project" && <Box className="price_negotiation_outer_wrapper">
                    <Box className="price_negotiation">Submitted Quote</Box>
                    <Box style={{color:"black",fontSize:"14px"}} className="price_cantainer">{getBidCostElement(bidCurrencyConversion.freelancer,bidCurrencyConversion.sponsor,!isFixedPrice)}</Box>
                  </Box>}
                  {
                    negotiationConversion.currency_conversion && (
                      <Box className="price_negotiation_outer_wrapper">
                        <Box className="price_negotiation">{negotiationConversion.negotiations_sender}</Box>
                        <Box style={{color:"black",fontSize:"14px"}} className="price_cantainer">{getBidCostElement(negotiationConversion.currency_conversion?.freelancer,negotiationConversion.currency_conversion.sponsor,!isFixedPrice)}</Box>
                      </Box>
                    )
                  }
                  
                  <Box className="price_negotiation_outer_wrapper">
                    <Box className="price_negotiation">Status</Box>
                    <Box style={{color:"black",fontSize:"14px"}} className="price_cantainer">{negotiationConversion.status}</Box>
                  </Box>
                </Box>
              </Box>
               )
             })
          }
       
        <Box style={webStyle.loadMoreBtn}>
          {bidLoading && (
            <Box>
              <CircularProgress />
            </Box>
          )}

          {isShowMoreEnable && !bidLoading && (
            <button
              data-testid="loadMore"
              style={webStyle.showMoreBtn}
              onClick={this.handleProjectLoadMore}
              hidden={bidLoading}
            >
              {configJSON.showMoreTxt}
              <ArrowForwardIosIcon style={webStyle.forwardIcon} />
            </button>
          )}
        </Box>
      </Container>
    );
  }
}
export default FreelancerMyBid;

// Customizable Area End