// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import { Customisableuserprofiles2Styles } from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Docusignintegration from "../../blocks/docusignintegration/src/Docusignintegration";
import Agentdashboard2 from "../../blocks/agentdashboard2/src/Agentdashboard2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Cfuseraccessmanagement from
"../../blocks/cfuseraccessmanagement/src/Cfuseraccessmanagement";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Collecttransactionfees from "../../blocks/collecttransactionfees/src/Collecttransactionfees";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import MultipleCurrencySupport from
"../../blocks/multiplecurrencysupport/src/MultipleCurrencySupport";
import Notifications from "../../blocks/notifications/src/Notifications";
import Download from "../../blocks/download/src/Download";
import Location from "../../blocks/location/src/Location";
import FullTeamVisibility from "../../blocks/fullteamvisibility/src/FullTeamVisibility";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import Formapprovalworkflow from "../../blocks/formapprovalworkflow/src/Formapprovalworkflow";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Refundmanagement from "../../blocks/refundmanagement/src/Refundmanagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import { SchedulingPageStyles } from "../../blocks/scheduling/src/Scheduling.web";
import Ticketsystem2 from "../../blocks/ticketsystem2/src/Ticketsystem2";
import {ContactUsStyle} from "../../blocks/contactus/src/Contactus.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Share from "../../blocks/share/src/Share";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Favourites from "../../blocks/favourites/src/Favourites";

import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Taxcalculator from "../../blocks/taxcalculator/src/Taxcalculator";
import Sorting from "../../blocks/sorting/src/Sorting";
import AuctionBidding from "../../blocks/auctionbidding/src/AuctionBidding";

import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";

import Organisationhierarchy from "../../blocks/organisationhierarchy/src/Organisationhierarchy";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import Search from "../../blocks/search/src/Search";
import Reviews from "../../blocks/reviews/src/Reviews.web";
import AddReview from "../../blocks/reviews/src/AddReview";

import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";

import SignUp from "../../blocks/social-media-account-registration/src/SignUp";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import {ChatPage} from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Projecttemplates from "../../blocks/projecttemplates/src/Projecttemplates.web";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import Certificationtracking from "../../blocks/certificationtracking/src/Certificationtracking";
import PrjSummary from "../../blocks/certificationtracking/src/PrjSummary.web";
import PrjDetails from "../../blocks/certificationtracking/src/PrjDetails.web";
import PrjMessages from "../../blocks/certificationtracking/src/PrjMessages.web";

import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";

import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import Joblisting from "../../blocks/joblisting/src/Joblisting";

import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";

import Analytics from "../../blocks/analytics/src/Analytics";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import Customadvertisements from "../../blocks/customadvertisements/src/Customadvertisements";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Accountinfo from "../../blocks/social-media-account-registration/src/Accountinfo.web";
import Basicdetails from "../../blocks/social-media-account-registration/src/Basicdetails.web";
import ForgetPassword from "../../blocks/forgot-password/src/ForgetPassword.web";
import ResendLink from "../../blocks/forgot-password/src/ResendLink.web";
import ResetEmail from "../../blocks/forgot-password/src/ResetEmail.web";
import SignIn from "../../blocks/social-media-account-login/src/SignIn.web";
import Profilesetup from "../../blocks/social-media-account-registration/src/Profilesetup.web";
import SearchProject from "../../blocks/dashboard/src/Search.web"
import SearchSuccess from "../../blocks/dashboard/src/Search-post-success.web"
import SearchHireFreeLancer from "../../blocks/dashboard/src/SearchHireFreeLancer.web"
import Sponserdashboard from '../../blocks/dashboard/src/Sponserdashboard.web';
import Notificationlancer from "../../blocks/notificationsettings/src/Notificationall.web";
import Notificationsponser from "../../blocks/notificationsettings/src/Notificationallsponser.web";
import Notificationbox from "../../blocks/notificationsettings/src/Notificationbox.web";
import Notificationboxspon from "../../blocks/notificationsettings/src/Notificationboxsponser.web";
import FreelancerDashboard from "../../blocks/dashboard/src/FreelancerDashboard.web";
import ProjectReviewPage from "../../blocks/projecttemplates/src/ProjectReviewPage.web";
import PostProjectSuccessfulPage from "../../blocks/projecttemplates/src/PostProjectSuccessfulPage.web";
import { SponsorCustomisableuserprofilesStyles } from '../../blocks/customisableuserprofiles2/src/SponsorCustomisableuserprofiles.web'
import { BidStyles } from "../../blocks/dashboard/src/Bid.web";
import MyBidProjectDetails from "../../blocks/auctionbidding/src/MyBidProjectDetails.web";
import FreelancerProjects from '../../blocks/dashboard/src/freelancer-projects/FreelancerProjects.web'
import {LinkedInCallback} from '../../components/src/linkedin-components/LinkedInCallback.web'
import FavouritesFreelancer from "../../blocks/favourites/src/FavouritesFreelancer.web";
import FavouriteLancerProject from "../../blocks/favourites/src/FavouriteLancerProject.web";
import LancerPublicProfile from "../../blocks/customisableuserprofiles2/src/LancerPublicProfile.web";
import SponsorPublicProfile from "../../blocks/customisableuserprofiles2/src/SponsorPublicProfile.web";


import BidProject from "../../blocks/dashboard/src/freelancer-projects/BidProject.web";
import BidProjectDetails from "../../blocks/dashboard/src/freelancer-projects/BidProjectDetails.web";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from "../../blocks/rolesandpermissions/src/AuthProvider.web";
import FreelancerViewCertificate from "../../blocks/certificationtracking/src/FreelancerViewCertificate.web";
import FreelancerRequestForCorrection from "../../blocks/certificationtracking/src/FreelancerRequestForCorrection.web";
import FreelancerSentForCorrection from "../../blocks/certificationtracking/src/FreelancerSentForCorrection.web";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ListOfAllPraposals from "../../blocks/auctionbidding/src/ListOfAllPraposals.web";
import PraposalDetails from "../../blocks/auctionbidding/src/PraposalDetails.web";
import SendAnOffer from "../../blocks/auctionbidding/src/SendAnOffer.web";
import OfferSendSuccess from "../../blocks/auctionbidding/src/OfferSendSuccess.web";
import OfferSendFailed from "../../blocks/auctionbidding/src/OfferSendFailed.web";
import ActiveAccount from '../../blocks/forgot-password/src/ActiveAccount.web';
import PayAndHire from "../../blocks/auctionbidding/src/PayAndHire.web";
import FreelancerEarnings from "../../blocks/ccavenueintegration/src/FreelancerEarnings.web";
import FreelancerWithdrawal from "../../blocks/ccavenueintegration/src/FreelancerWithdrawal.web";
import IdVerificationSuccess from "../../blocks/ccavenueintegration/src/IdVerificationSuccess.web";
import WithdrawalSuccess from "../../blocks/ccavenueintegration/src/WithdrawalSuccess.web";
import WithdrawalFail from "../../blocks/ccavenueintegration/src/WithdrawalFail.web";
import IdVerification from "../../blocks/ccavenueintegration/src/IdVerification.web";
import PayMilestone from '../../blocks/scheduling/src/PayMilestone.web';
import DisputeRequest from "../../blocks/scheduling/src/DisputeRequest.web"
import FreelancerListings from '../../blocks/auctionbidding/src/SponsorInviteFreelancer.web';
import FreelancerInvitationDetails from '../../blocks/auctionbidding/src/FreelancerInvitationDetails.web'
const routeMap = {
InvitationDetails: {
  component: FreelancerInvitationDetails,
  path: "/InvitationDetails/:invitationId",
  exact:true
},freelancers: {
  component: FreelancerListings,
  path: "/freelancers/:projectId",
  exact:true
},
PayMilestone: {
  component: PayMilestone,
  path: "/PayMilestone/:milestoneId/:projectId",
  exact:true
}, 
   ActivateAccount: {
    component: ActiveAccount,
    path: "/ActivateAccount/:token",
    exact:true
  }, 
BidProject: {
component: BidProject,
path: "/BidProject/:projectId",
exact:true
},
QuoteProjectDetails: {
component: BidProjectDetails,
path: "/QuoteProjectDetails/:projectId",
exact:true
},
QuoteDetails: {
component: MyBidProjectDetails,
path: "/QuoteDetails/:bidId",
exact:true
},
Customisableuserprofiles2: {
component: Customisableuserprofiles2Styles,
path: "/Customisableuserprofiles2"
},
SponsorCustomisableuserprofiles: {
component: SponsorCustomisableuserprofilesStyles,
path: "/SposorCustomisableUserProfile"
},
Tasks: {
component: Tasks,
path: "/Tasks"
},
Projects: {
component: Projecttemplates,
path: "/Projects/:type/:status?/:id?"
},
Notificationbox: {
component: Notificationbox,
path: "/Notificationbox"
},

Notificationsponser: {
component: Notificationsponser,
path: "/Notificationsponser"
},
Notificationboxspon: {
component: Notificationboxspon,
path: "/Notificationboxspon"
},
TaskList: {
component: TaskList,
path: "/TaskList"
},
Task: {
component: Task,
path: "/Task"
},
Notificationlancer: {
component: Notificationlancer,
path: "/Notificationlancer"
},
Docusignintegration: {
component: Docusignintegration,
path: "/Docusignintegration"
},
Agentdashboard2: {
component: Agentdashboard2,
path: "/Agentdashboard2"
},
Filteritems: {
component: Filteritems,
path: "/Filteritems"
},
Filteroptions: {
component: Filteroptions,
path: "/Filteroptions"
},
Notificationsettings: {
component: Notificationsettings,
path: "/Notificationsettings"
},
Cfuseraccessmanagement: {
component: Cfuseraccessmanagement,
path: "/Cfuseraccessmanagement"
},
PhoneNumberInput: {
component: PhoneNumberInput,
path: "/PhoneNumberInput"
},
AdditionalDetailForm: {
component: AdditionalDetailForm,
path: "/AdditionalDetailForm"
},
ThemeBlock: {
component: ThemeBlock,
path: "/ThemeBlock"
},
Emailnotifications2: {

component: Emailnotifications2,
path: "/Emailnotifications2"
},
OTPInputAuth: {
component: OTPInputAuth,
path: "/OTPInputAuth"
},
Documentstorage: {
component: Documentstorage,
path: "/Documentstorage"
},
Collecttransactionfees: {
component: Collecttransactionfees,
path: "/Collecttransactionfees"
},
Contentmanagement3: {
component: Contentmanagement3,
path: "/Contentmanagement3"
},
Adminconsole2: {
component: Adminconsole2,
path: "/Adminconsole2"
},
MultipleCurrencySupport: {
component: MultipleCurrencySupport,
path: "/MultipleCurrencySupport"
},
Notifications: {
component: Notifications,
path: "/Notifications"
},
Download: {
component: Download,
path: "/Download"
},
Location: {
component: Location,
path: "/Location"
},
FullTeamVisibility: {
component: FullTeamVisibility,
path: "/FullTeamVisibility"
},
UserStatus: {
component: UserStatus,
path: "/UserStatus"
},
Formapprovalworkflow: {
component: Formapprovalworkflow,
path: "/Formapprovalworkflow"
},
Paymentadmin2: {
component: Paymentadmin2,
path: "/Paymentadmin2"
},
Refundmanagement: {
component: Refundmanagement,

path: "/Refundmanagement"
},
CountryCodeSelector: {
component: CountryCodeSelector,
path: "/CountryCodeSelector"
},
Catalogue: {
component: Catalogue,
path: "/Catalogue"
},
Pushnotifications: {
component: Pushnotifications,
path: "/Pushnotifications"
},
AccountGroups: {
component: AccountGroups,
path: "/AccountGroups"
},
Scheduling: {
component: SchedulingPageStyles,
path: "/Scheduling"
},
Ticketsystem2: {
component: Ticketsystem2,
path: "/Ticketsystem2"
},
Contactus: {
component: ContactUsStyle,
path: "/Contactus"
},
AddContactus: {
component: AddContactus,
path: "/AddContactus"
},
Settings2: {
component: Settings2,
path: "/Settings2"
},
Share: {
component: Share,
path: "/Share"
},
Rolesandpermissions: {
component: Rolesandpermissions,
path: "/Rolesandpermissions"
},
Favourites: {
component: Favourites,
path: "/Favourites"
},
AddFavourites: {
component: AddFavourites,
path: "/AddFavourites"
},
FavouritesFreelancer:{
  component:FavouritesFreelancer,
  path:"/FavouritesFreelancer"
},
FavouriteLancerProject:{
  component:FavouriteLancerProject,
  path:"/favorites-project"
},
LancerPublicProfile:{
  component:LancerPublicProfile,
  path:"/lancerprofile/:profileId"
},
SponsorPublicProfile:{
  component:SponsorPublicProfile,
  path:"/sponsorprofile/:profileId"
},
Taxcalculator: {
component: Taxcalculator,
path: "/Taxcalculator"

},
Sorting: {
component: Sorting,
path: "/Sorting"
},
AuctionBidding: {
component: AuctionBidding,
path: "/AuctionBidding"
},
SocialMediaAccountLoginScreen: {
component: SocialMediaAccountLoginScreen,
path: "/SocialMediaAccountLoginScreen"
},
SignIn: {
component: SignIn,
path: "/",
exact: true,
},
Organisationhierarchy: {
component: Organisationhierarchy,
path: "/Organisationhierarchy"
},
RequestManagement: {
component: RequestManagement,
path: "/RequestManagement"
},
Search: {
component: Search,
path: "/Search"
},
Reviews: {
component: Reviews,
path: "/Reviews"
},
AddReview: {
component: AddReview,
path: "/AddReview"
},
SocialMediaAccountRegistrationScreen: {
component: SocialMediaAccountRegistrationScreen,
path: "/SocialMediaAccountRegistrationScreen"
},
SignUp: {
component: SignUp,
path: "/SignUp"
},
Profilesetup: {
component: Profilesetup,
path: "/Profilesetup"
},
ForgotPassword: {
component: ForgotPassword,
path: "/ForgotPassword"
},
ForgetPassword: {
component: ForgetPassword,

path: "/ForgetPassword"
},
ResendLink: {
component: ResendLink,
path: "/ResendLink"
},
ResetEmail: {
component: ResetEmail,
path: "/reset"
},
FreelancerDashboard: {
component: FreelancerDashboard,
path: "/FreelancerDashboard"
},
ForgotPasswordOTP: {
component: ForgotPasswordOTP,
path: "/ForgotPasswordOTP"
},
NewPassword: {
component: NewPassword,
path: "/NewPassword"
},
Dashboard: {
component: Dashboard,
path: "/Dashboard"
},
SponsorDashboard: {
component: Sponserdashboard,
path: "/SponsorDashboard"
},
Chat: {
component: ChatPage,
path: "/Chat"
},
ChatView: {
component: ChatView,
path: "/ChatView"
},
StripePayments: {
component: StripePayments,
path: "/StripePayments"
},
FreelancerEarnings: {
  component: FreelancerEarnings,
  path: "/FreelancerEarnings"
  },
  FreelancerWithdrawal: {
  component: FreelancerWithdrawal,
  path: "/FreelancerWithdrawal"
},
  SponsorWithdraw: {
  component: FreelancerWithdrawal,
  path: "/SponsorWithdraw/:milestoneId"
},
IdVerificationSuccess: {
  component: IdVerificationSuccess,
  path: "/IdVerificationSuccess"
},
WithdrawalSuccess: {
  component: WithdrawalSuccess,
  path: "/WithdrawalSuccess"
},
WithdrawalFail: {
  component: WithdrawalFail,
  path: "/WithdrawalFail"
},
IdVerification: {
  component: IdVerification,
  path: "/IdVerification"
},
ImportExportData: {
component: ImportExportData,
path: "/ImportExportData"
},
Projecttemplates: {
component: Projecttemplates,
path: "/Projecttemplates"
},
InvoiceBilling: {
component: InvoiceBilling,
path: "/InvoiceBilling"
},
Certificationtracking: {
component: Certificationtracking,

path: "/Certificationtracking"
},
PrjSummary: {
  component: PrjSummary,
  path: "/PrjSummary/:projectid"
},
CompletedPrjSummary: {
  component: PrjSummary,
  path: "/CompletedPrjSummary/:projectid"
},
ClosedPrjSummary: {
  component: PrjSummary,
  path: "/ClosedPrjSummary/:projectid"
},
OnHoldPrjSummary: {
  component: PrjSummary,
  path: "/OnHoldPrjSummary/:projectid"
},
DisputePrjSummary: {
  component: PrjSummary,
  path: "/DisputePrjSummary/:projectid"
},
PrjDetails: {
component: PrjDetails,
path: "/PrjDetails"
},
PrjMessages: {
component: PrjMessages,
path: "/PrjMessages"
},
MobileAccountLoginBlock: {
component: MobileAccountLoginBlock,
path: "/MobileAccountLoginBlock"
},
Uploadmedia3: {
component: Uploadmedia3,
path: "/Uploadmedia3"
},
Joblisting: {
component: Joblisting,
path: "/Joblisting"
},
EmailAccountRegistration: {
component: EmailAccountRegistration,
path: "/EmailAccountRegistration"
},
Analytics: {
component: Analytics,
path: "/Analytics"
},
Multilevelapproval2: {
component: Multilevelapproval2,
path: "/Multilevelapproval2"
},
Customadvertisements: {
component: Customadvertisements,
path: "/Customadvertisements"
},
Categoriessubcategories: {
component: Categoriessubcategories,
path: "/Categoriessubcategories"
},
ReviewApprovalAdmin: {
component: ReviewApprovalAdmin,
path: "/ReviewApprovalAdmin"
},
ReviewApprovalBasicUser: {
component: ReviewApprovalBasicUser,
path: "/ReviewApprovalBasicUser"
},
Accountinfo: {
component: Accountinfo,
path: '/Accountinfo'
},
FreelancerViewCertificate: {
  component: FreelancerViewCertificate,
  path: "/FreelancerViewCertificate/:projectid",
},
FreelancerRequestForCorrection:{
  component:FreelancerRequestForCorrection,
  path:"/FreelancerRequestForCorrection/:projectid",
},
FreelancerSentForCorrection:{
  component:FreelancerSentForCorrection,
  path:"/FreelancerSentForCorrection/",
},
Basicdetails: {
component: Basicdetails,
path: '/Basicdetails'
},
InfoPage: {
component: InfoPage,
path: '/InfoPage'
},
AlertWeb: {
component: AlertBlock,
path: '*/AlertWeb',
modal: true
},
SearchWeb: {
component: SearchProject,
path: "/search-list"
},
SearchSuccessWeb: {
component: SearchSuccess,
path: "/search-list-success"
},
SearchHireFreeLancerWeb: {
component: SearchHireFreeLancer,
path: "/search-hire-freelancer"
},
Bid: {
component: BidStyles,
path: "/search-bid"
},
MyBidProjectDetails: {
component: MyBidProjectDetails,
path: "/ProjectDetails"
},
ProjectReviewPage: {
component: ProjectReviewPage,
path: "/ProjectReviewPage"
},
PostProjectSuccessfulPage: {
component: PostProjectSuccessfulPage,
path: "/PostProjectSuccessfulPage"
},
ListOfAllPraposals: {
component: ListOfAllPraposals,
path: "/ListOfAllPraposals/:id"
},
PraposalDetails: {
component: PraposalDetails,
path: "/PraposalDetails",
},

PraposalDetails: {
component: PraposalDetails,
path: "/PraposalDetails/:id",
},
SendAnOffer: {
component: SendAnOffer,
path: "/SendAnOffer/:id",
},
PayAndHire: {
  component: PayAndHire,
  path: "/PayAndHire/:navigationBarTitleText",
  },
OfferSendSuccess: {
component: OfferSendSuccess,
path: "/PaymentSuccess/:paymentId"
},
PaymentFailure: {
component: OfferSendFailed,
path: "/PaymentFailure/:paymentId"
},
FreelancerProjects:{
component: FreelancerProjects,
path:"/FreelancerProjects/:type"
},
linkedin:{
component: LinkedInCallback,
path:"/linkedin",
exact:true
},
Disputes:{
  component:DisputeRequest,
  path:"/Disputes/:projectId/:milestoneId?"
}
};
const Authroutes = {}
const firebaseAPI = firebase.initializeApp({
apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
projectId: 'rnmasterapp-c11e9',
storageBucket: 'rnmasterapp-c11e9.appspot.com',
messagingSenderId: '649592030497',
appId: '1:649592030497:web:7728bee3f2baef208daa60',
measurementId: 'G-FYBCF3Z2W3'
});
const AuthComponent = routeMap => {
return (
<Switch>
<div className="main-wrapper">
<Sidebar />
<div className="page-wrapper">
<Navbar />
<Route exact path="/" render={() => <Redirect to="/Dashboard" />} />
<Route exact path="/Dashboard" render={() => <Redirect to="/Dashboard" />} />
{WebRoutesGenerator({ routeMap })}
</div>
</div>
</Switch>
);
};

const AppLayoutComponents = routeMap => {
return (
<LandingPage>
<Switch>
{WebRoutesGenerator({ routeMap })}
<Route exact path="*" render={() => <Redirect to="/SignIn" />} />
</Switch>
</LandingPage>
)
};
class App extends Component {
render() {
const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');
const loggedin = window.localStorage.getItem('authToken');
// return (
// <View >
// {/* {loggedin
// ? AuthComponent(Authroutes)
// : AppLayoutComponents(routeMap)
// } */}
// {AppLayoutComponents(routeMap)}
// <ModalContainer />
// </View>
// )
return (
<View style={{ minHeight: '100vh' }}>
<GoogleOAuthProvider clientId="675941141844-rg6lbibejm22bj3fmbngsbcgjrg934a9.apps.googleusercontent.com">
<AuthProvider>
{WebRoutesGenerator({ routeMap })}
<ModalContainer />
<ToastContainer theme="dark" />
</AuthProvider>
</GoogleOAuthProvider>
</View>
);
}
}
export default App;