import React from "react";
import {
    // Customizable Area Start
    Grid,
    Typography,
    Box,   
    Button,
    TextareaAutosize,
    CardMedia,
   WithStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme,withStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {

    submitwork,
    workicon,
    deleteFileIcon,
    uploadIcon,
} from './assets'

export const trackingTheme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
});

// Customizable Area End
import  {configJSON} from "./PrjSummaryController.web";

export interface Props extends WithStyles{

    // Customizable Area Start
    msgToSponsor:string;
    onMsgChange:any;
    milestoneNumber:number;
    deleteUploadedFiles:any;
    uploadedFiles:any;
    handleFilesDrop:any;
    onConvertFileSize:any;
    milestone:any;
    onSubmitMilestone:any
    documentsError:string
    onCancelDetails:any
    btnLoader:boolean
    onConvertFileName:any
    // Customizable Area End
  }
  
interface S {
    // Customizable Area Start
    workReviewActiveState:boolean;
   // Customizable Area End
  }


export class UploadFilesComp extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = { 
        workReviewActiveState:false,
    };
  }
 
  submitWorkReviewHandler=()=>{
    this.setState({workReviewActiveState:true})
  }
  cancelWorkReviewHandler=()=>{
    this.setState({workReviewActiveState:false})
    this.props.onCancelDetails()
  }
  render() {
    const{classes,
         msgToSponsor,
        onMsgChange,
        deleteUploadedFiles,
        uploadedFiles,
        handleFilesDrop,
        onConvertFileSize,
        milestone,
        onSubmitMilestone,
        documentsError,
        milestoneNumber,
        btnLoader,
        onConvertFileName
     }=this.props;
    return <>
                <Box className={classes.submitBox}>
                <Button variant='contained' disableElevation className={classes.SubmitBtn}
                data-testId= "btnSubmit"
                disabled={milestoneNumber > 0 || milestone?.attributes.milestone_status=='pending'}
                onClick={this.submitWorkReviewHandler}>
                {configJSON.btnSubmit}
                </Button>

             </Box>
          {this.state.workReviewActiveState &&
            <>

                 <Box className={classes.displayVerifyBox} mb={2}>
                     <img className={classes.milestoneGridImage} src={submitwork} />
                    <Typography className={classes.timelineContentRelatedReview}>{configJSON.labelSubmitWorkReview}</Typography>
                 </Box>
               
                 <Grid container className={classes.uploadFilesGrid}>
                    <Box className={classes.uploadFilesBox}>
                    
                   <Typography className={classes.inputLabelStyle}>{configJSON.labelSponsorMsg}</Typography>
                     <TextareaAutosize minRows={3}
                     autoFocus
                      value={msgToSponsor}
                      onChange={onMsgChange}
                      data-test-id='msgbox'
                      className={classes.inputTextStyle}></TextareaAutosize>
                     
                            <Box className={classes.uploadStyle}>
                            <Box className={classes.uploadTextStyle}>{configJSON.labelUpload}</Box>                
                            <input type="file" 
                            multiple 
                            onChange={handleFilesDrop} 
                            className={classes.uploadInputStyle}
                              data-testid = "dropzone"
                              value=""
                              disabled={btnLoader}
                            />
                            </Box> 
                     <Box>
                    {
                      (documentsError.length > 0)
                        && <Typography variant="caption" className={classes.errorStyle}>{documentsError}</Typography>
                    }
                  </Box>
                   <Grid container className={classes.projectDetailsBox}>
                   { 
              
                   uploadedFiles.map((file:any, index:number) => {
                            return (
                                <Grid key={file.lastModified} item xs="auto" sm={6}>
                                <Box className={classes.downloadBorder}>
                                   <Box className={classes.downloadItem}>
                                   <img className={classes.milestoneGridImage} src={workicon} />
                                   <Grid container>
                                      <Grid item xs="auto">
                                        <Typography noWrap={false} className={classes.downloadName} style={{
                                          whiteSpace: 'nowrap',
                                          overflow:'hidden',
                                          textOverflow: 'ellipsis',}}>{onConvertFileName(file.name)}</Typography>
                                      </Grid>
                                      <Grid item xs="auto">
                                        <Typography className={classes.downloadSize}> ({onConvertFileSize(file.size)})</Typography>
                                      </Grid>
                                   </Grid>
                                   </Box>
                                   <Box className={classes.downloadItemBox}>
                                    <CardMedia
                                    component="img"
                                    data-testId="delIcon"
                                    className={classes.filesImage}
                                    image={deleteFileIcon}
                                    onClick={ deleteUploadedFiles.bind(this,index) }
                                />
                                  </Box>
                               </Box>
                               </Grid>
                            );
                        })}

                        
                    </Grid> 
                   
                   <Box className={classes.submitBox} my={2}>
                    <Button  variant='contained' disableElevation className={classes.cancelFile}
                    data-testId = "btnCancel"
                    onClick={this.cancelWorkReviewHandler}
                    disabled={btnLoader}>
                            {configJSON.cancelButton}
                    </Button>
                    <Button  variant='contained' disableElevation
                    className={classes.submitFile}
                    onClick={onSubmitMilestone.bind(this, milestone?.attributes?.id)}
                    data-test-id = "btnDeliver"
                    disabled={btnLoader}
                    
                    >
                      {btnLoader?<CircularProgress size={24} />:configJSON.submitButton}      
                    </Button>
                    </Box> 

                    
                   </Box>

                 </Grid>
              </>
             } 

    </>
  }
}
 
// Customizable Area Start

export const style = () => ({

     projectDetailsBox:{
        display:'flex', 
        alignItems:'center',
      },
    
      displayVerifyBox:{
         display:'flex',
        alignItems:'center',
        },
    
        milestoneGridImage:{
            width: '20px',
            height: '21px',
            marginRight:'1vw',
        },
        uploadedImage:{
            width: '36px',
            height: '36px',
            position:"absolute" as const,
            marginTop:"-25px",
     
        },
        downloadBorder:{
          display:'flex',
          alignItems:'center',
          borderRadius: '10px',
          border: '1px solid #e4e6e8',
          backgroundColor:'#ffffff',
          justifyContent:'space-between',
          padding:'1vw',
          width:'88%',
          marginBottom:'1vw'
      },
        filesImage:{
            width: '26px',
            height: '26px',
            cursor:'pointer'
        },

        timelineContentRelatedReview:{
          fontSize: '14px',
          fontWeight: 700,
            color: '#222',
           
        },
    
        downloadItem:{
            display:'flex',
            alignItems:'center',
        },
    
        downloadName:{
            color: '#222222',
            fontSize: '12px',
            fontWeight: 600,
            marginRight:'24px',
            '@media (max-width: 500px)':{
              width: "100px"
            },
            '@media (max-width: 360px)': {
              marginRight:'2px',
              width: "70px"
            }
        },
        downloadSize:{
            color: 'rgba(128,131,163,0.76)',
            fontSize: '10px',
            fontWeight: 500,
        },

            SubmitBtn:{
                color: '#fff',
                fontSize:'12px',
                fontWeight: 700,
                width: '100px',
                height: '36px',
                borderRadius: '30px',
                backgroundColor: '#ec952d',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                textTransform:'none' as const,
                '&:hover': {
                    backgroundColor: '#ec952d',
                    color: '#fff',
                },
             },
        submitBox:{
            display:'flex',
            justifyContent:'flex-end',
            width:'100%'
        },

        uploadFilesGrid:{
            display:'flex',
            alignItems:'center',
            flexDirection:'column' as const,
        },
        uploadFilesBox:{
        width: '90%',
        backgroundColor: '#fff',
        padding: '2vw',
        borderRadius: '10px',
        border: '1px solid #e4e6e8',
        },
        uploadTextStyle:{
            color: '#fff',
            fontSize:'12px',
            fontWeight: 500,
            position:"absolute" as const,
            backgroundColor:"#ec952d",
            height:'18px',
            width:'80px',
            padding:'8px 14px',
            borderRadius:'20px',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'

 
         },
    uploadStyle:{
      position:'relative'as const,
        width:'100%',
        height: '123px',
        borderRadius: '10px',
        border: '1.5px dashed #ec952d',
        backgroundColor: 'rgba(236,149,45,0.06)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column' as const,
        margin:"2vw 0",
       
      },
      submitFile:{
        color: '#fff',
        fontSize:'12px',
        fontWeight: 700,
        width: '82px',
        height: '36px',
        borderRadius: '30px',
        backgroundColor: '#ec952d',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        textTransform:'none' as const,
        margin:'0 2px',
        '&:hover': {
            backgroundColor: '#ec952d',
            color: '#fff',
        },
        '&:disabled': {
          backgroundColor: '#ec952d',
        },
     },
     cancelFile:{
        color: '#222',
        fontSize:'12px',
        fontWeight: 700,
        width: '82px',
        height: '36px',
        borderRadius: '30px',
        backgroundColor: '#f2f2f2',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        textTransform:'none' as const,
        margin:'0 2px',
        '&:hover': {
            backgroundColor: '#f2f2f2',
            color: '#222',
        },
     },
     
     inputLabelStyle:{
      color:'#8083a3',
      fontSize: '14px',
      fontWeight: 700,
     },
     inputTextStyle:{
      color:'#8083a3',
      fontSize: '14px',
      fontWeight: 400,
     },
     errorStyle:{
      color:"red",
      fontSize: '11px',
     },
     uploadInputStyle:{
      position:"absolute" as const,
      top:0,
      left:0, 
      height:'100%',
      width:'100%',
      opacity:0, 
      cursor:"pointer",
      },
    });

    export default withStyles(style)(UploadFilesComp);
  // Customizable Area End