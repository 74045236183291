import React from 'react';
import {Dialog,DialogTitle,DialogContent,Box, IconButton} from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress";
import { CloseOutlined } from '@material-ui/icons';
interface Props{
    open:boolean;
    setOpen:(open:boolean)=>void;
    loading:boolean;
    title:string;
    description:string;
}

const TermsAndServiceModal:React.FC<Props> = ({open,setOpen,loading,title,description}) => {
    return ( 
    <Dialog
      fullWidth
      maxWidth="xs"
      PaperProps={{ style: { borderRadius: 16 } }}
      open={open}
      onClose={() => setOpen(false)}
    >
       <DialogTitle
        id="max-width-dialog-title"
        style={{ paddingBottom: 0 }}
      >
        <Box style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <strong>{title}</strong>
          <IconButton onClick={() => setOpen(false)}>
              <CloseOutlined/>
            </IconButton>
        </Box>
      </DialogTitle>
     
     
      <DialogContent>
        {
          loading?<Box sx={{display:"flex",alignItems:"center",justifyContent:"center"}}><CircularProgress/></Box>:
          <p>{description}</p>
        }
      </DialogContent>
      <br/>
      <br/>
    </Dialog>
     );
}
 
export default TermsAndServiceModal;