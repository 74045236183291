import React from "react";
// Customizable Area Start
import {
    Box,
    withStyles,
    Container,
    Typography,
    Avatar,
    Grid,Button,
    Paper,
} from "@material-ui/core";
import FreelancerInvitationsController ,{Props} from './FreelancerInvitationsController.web';
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

export class FreelancerInvitations extends FreelancerInvitationsController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
    
       // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes}=this.props;
        const {invitationList,getInvitationLoading,totalInvitations}=this.state;
        const isShowMoreEnable = totalInvitations > invitationList?.length && invitationList?.length>0;
        // Customizable Area End
        return (

            // Customizable Area Start
            <Container maxWidth="lg">
                <Box>
                <ProjectFilterWeb
                    onSearch={this.invitationSearchHandler}
                    onFilter={this.invitationFilterHandler}
                    onCancelFilter={this.invitationCancelFilterHandler}
                    isUserFreelancer={true}
                />
                <br />
                <br />
                </Box>
                <Box className={classes.invite_listing_container}>
                    {
                        !getInvitationLoading && invitationList?.length===0 && (
                            <Paper className="border-block p-0 projectloading-main" > 
                                    <Box className='projectloading'>
                                    <Typography variant='h2'>No Invitation Found</Typography>
                                </Box>
                            </Paper>
                        )
                    }
                    {
                        invitationList.map((item,index)=>{
                            const profileImages=item.attributes?.sponsor_info?.data?.attributes?.photo;
                            const profilePhoto=profileImages && profileImages?.length>0?profileImages[0]?.url:"";
                            const projectInfo=item?.attributes?.project_info?.data?.attributes;
                            return(
                                    <Box data-testid="invitation_item" onClick={()=>this.handleNavigateToInvitationsDetails(item.id)} className={classes.invitation_item_container}>
                                        <Box className={classes.project_info}>
                                            <Typography component={"h1"} variant="h6" className={classes.page_heading}>
                                                {projectInfo?.project_name}
                                            </Typography>
                                            <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                                                    {projectInfo?.budget_type==="hourly_price"?"Hourly Rated":"Fixed Price"}<span style={{ color: 'gray' }}> • </span>
                                                    Est. Time: {projectInfo?.EST_time}<span style={{ color: 'gray' }}> • </span>{projectInfo?.posted_at}
                                              </Typography>
                                        </Box>
                                        <Box className={classes.project_info}>
                                            <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                                                 Sponsor
                                            </Typography>
                                            <Box style={{display:"flex",alignItems:"center",gap:"10px"}}>
                                                  <Avatar className={classes.freelancer_avatar} src={profilePhoto}/>
                                                  <Typography style={{fontSize:"15px"}} component={"h1"} variant="h6" className={classes.page_heading}>
                                                     {item?.attributes?.sponsor_info?.data?.attributes?.full_name}
                                                  </Typography>
                                            </Box>
                                            
                                        </Box>
                                        <Grid container spacing={1} style={{ padding: "20px"}}>
                                       <Grid item xs={6} sm={4}>
                                           <Box className={classes.properties_item_container}>
                                               <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                                                  Status
                                                </Typography> 
                                                <Typography style={{fontSize:"13px"}} className={classes.page_heading} component={"p"} variant="subtitle1">
                                                  {this.capitalizeFirstLetter(item.attributes?.status)}
                                                </Typography>
                                           </Box>
                                       </Grid>
                                       <Grid item xs={6} sm={4}>
                                           <Box className={classes.properties_item_container}>
                                                   <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                                                    {this.capitalizeFirstLetter(item.attributes?.status)} On
                                                   </Typography> 
                                                   <Typography style={{fontSize:"13px"}} className={classes.page_heading} component={"p"} variant="subtitle1">
                                                    {this.formatDate(item.attributes.updated_at)}
                                                   </Typography>
                                               </Box>
                                       </Grid>
                                   </Grid>
                                     </Box>
                                )
                        })
                    }
                </Box>
                <br/>
                <br/>
                <Box  style={{justifyContent:"center",display:"flex",alignItems:"center"}}>
                        {
                            getInvitationLoading && (
                                <CircularProgress size={24}/>
                            )
                        }
                        {
                            isShowMoreEnable && !getInvitationLoading && (
                                <Button data-testid="loadMoreBtn" onClick={this.handleProjectLoadMore} className={classes.back_button} endIcon={<ChevronRightIcon/>}>
                                    Show More
                                </Button>
                            )
                        }
                        
                    </Box>
            </Container>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const styles = () => ({
    invite_listing_container:{
        display:"flex",
        flexDirection:"column",
        gap:"20px",
        cursor:"pointer"
    } as const,
    invitation_item_container:{
        border:"1px solid rgba(0,0,0,.1)",
        borderRadius:"20px",
    },
    project_info:{
        padding:"20px",
        borderBottom:"1px solid rgba(0,0,0,.1)",
    },
    page_heading:{
        fontWeight:"bolder",

    } as const,
    subtitle:{
        color:"#96939c",
        fontWeight:"bold",
        fontSize:"13px",
        gap:"5px",
        '@media (max-width: 350px)': {
            fontSize: "10px"
        }
    } as const,
    freelancer_avatar:{
        width:"50px",
        height:"50px",
    },
});

export default withStyles(styles)(FreelancerInvitations);

// Customizable Area End