import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles} from '@material-ui/core';
import { getStorageData } from "../../../framework/src/Utilities";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { toast } from "react-toastify";

// Customizable Area Start
export interface InputData{
  name:string;
  profileUrl:string;
  projectName:string;
  projectDescription:string;
  completionDate:string;
}
const inputData:InputData={
  name:"",
  profileUrl:"",
  projectName:"",
  projectDescription:"",
  completionDate:""
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
  navigation:any;
  id:string;

}
export interface S {
  // Customizable Area Start
  inputData:InputData,
  fetchProject:boolean;
  errors:{
    [key:string]:string;
  },
  submitRequestLoading:boolean;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class FrelancerRequestForCorrectionController extends BlockComponent<
  Props, S, SS
> {

    // Customizable Area Start
    getProjectInfoApiCallId:string="";
    submitRequestApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

        this.state = {
            // Customizable Area Start
          inputData:inputData,
          fetchProject:false,
          errors:{},
          submitRequestLoading:false,
            // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
       this.handleLoginUserMessage(user);
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId===this.getProjectInfoApiCallId){
         this.handleGetProjectInfoResponse(responseJson);
      }else if(apiRequestCallId===this.submitRequestApiCallId){
         this.handleSubmitRequestApiResponse(responseJson);
      }
    }
    // Customizable Area End
  }
  handleSubmitRequestApiResponse=(responseJson:any)=>{
    this.setState({
      submitRequestLoading:false,
    })
    if(responseJson?.message){
      toast.success(responseJson?.message);
      this.goBack();
    }else{
       toast.error("Something went wrong!")
    }
  }

  
  handleGetProjectInfoResponse=(responseJson:any)=>{
     if(responseJson?.data?.id){
      const project=responseJson?.data?.attributes;
      this.setState((prev)=>({
          inputData:{
            ...prev.inputData,
            projectName:project?.project_name,
            projectDescription:project?.description
          },
          fetchProject:false,
      }))
     }else{
        this.setState({fetchProject:false})
       toast.error("No Project Found!!")
     }
  }
  handleLoginUserMessage=(user:any)=>{
    if(user?.id){
       const userInfo=user?.attributes;
       this.setState((prev)=>({
         inputData:{
           ...prev.inputData,
           name:userInfo?.full_name,
           profileUrl:`${window.origin}/lancerprofile/${user?.id}`
         }
       }))
    }
   
  }
  async componentDidMount() {
    // Customizable Area Start
    const projectId=this.props.navigation.getParam("projectid")
    this.sendGetCurrentUserMessage();
    this.getSingleProjectData(projectId);
    // Customizable Area End
  }
  sendGetCurrentUserMessage=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(msg);
  }

  handleSubmit=async()=>{
      const {inputData,}=this.state;
      const projectId=this.props.navigation.getParam("projectid")
      const newError:any={};

      if(!inputData?.name?.trim()){
        newError.name="Please enter your full name";
      }
      if(!inputData?.profileUrl?.trim()){
        newError.profileUrl="Please enter profile url";
      }

      if(!inputData?.projectDescription?.trim()){
        newError.projectDescription="Please enter project description";
      }
      if(!inputData?.projectName?.trim()){
        newError.projectName="Please enter project name";
      } 
      if(!inputData?.completionDate?.trim()){
        newError.completionDate="Please enter project completion date";
      }

      if(Object.keys(newError).length>0){
        this.setState({
          errors:newError
        })
        return;
      }
      //form data is fine submit the info here

      const data={
        project_id: projectId,
        full_name: inputData.name,
        project_name:inputData.projectName,
        url: inputData.profileUrl,
        project_completed_date:inputData.completionDate,
        description:inputData.projectDescription,
    }

    this.setState({submitRequestLoading:true});
    const header=await this.getHeader();
    const apiRequestMessage = CreateRestApiMessage({
     header:header,
     apiUrl: `${configJSON.submitCertificateCorrectionApiUrl}`,
     body:JSON.stringify({data}),
     method: "POST",
   });
   this.submitRequestApiCallId = apiRequestMessage.messageId;
   runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);

  }

  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json"
    };
    return header;
  }

  getSingleProjectData=async(projectId:string)=>{
    this.setState({fetchProject:true});
    const header=await this.getHeader();
    const apiRequestMessage = CreateRestApiMessage({
     header:header,
     apiUrl: `${configJSON.getDataApiCallIdUrl}${projectId}`,
     body: null,
     method: "GET",
   });
   this.getProjectInfoApiCallId = apiRequestMessage.messageId;
   runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
 }
  
  handleInputChange=(e:any)=>{
    const name=e.target.name;
    const value=e.target.value;
    this.setState((prev)=>({
       inputData:{
        ...prev.inputData,
        [name]:value
       }
    }))
  }
  goBack=()=>{
    this.props.navigation?.goBack();
  }
 
  // Customizable Area End
}