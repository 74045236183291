export const lancerlogo = require("../assets/images/lancerlogo.png");
export const freelancer = require("../assets/images/freelancer.png");
export const freelanceractive = require("../assets/images/freelanceractive.png");
export const sponser = require("../assets/images/sponser.png");
export const sponseractive = require("../assets/images/sponsoractive.png");
export const activelogo = require("../assets/images/active-icon.png");
export const searchicon = require("../assets/images/search-icon.png");
export const countryCodes = require("../assets/countryCodes.json");
export const FaceBookImg = require("../assets/Fb.png");
export const LinkedInImg = require("../assets/Linkdin.png");
