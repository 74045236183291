import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import { IBidData } from "../../dashboard/src/freelancer-projects/bid.types";
import { IProjectData } from "../../dashboard/src/types";
// Customizable Area Start
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    relodeBid:()=>void;
    navigation: any;
    currentUser:IProfile;
    project:IProjectData;
    bid:IBidData;
    // Customizable Area End
}

interface S {
    // Customizable Area Star
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class FreelancerBidNegotionController extends BlockComponent<Props, S, SS> {
   
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [];
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.LoginUserMessage),
        ];

        this.state = {
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    converFileSize=(bytes:number)=>{
        if (bytes >= 1024 * 1024) {
         return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
       } else {
         return (bytes / 1024).toFixed(2) + ' KB';
       }
    }
    downloadFile = async (url: string, fileName: string) => {
        const res = await fetch(url);
        const blob = await res.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
      };
      
    getBidId = () => {
        return this.props.navigation?.getParam("bidId");
      };
    isNegotiationsFromSponsor=(role:string|null)=>{
        if(!role){
            return false;
        }
        return role?.toLowerCase()?.includes("sponsor") || role?.toLowerCase()?.includes("both");
    }
}
