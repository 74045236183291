import React from "react";

// Customizable Area Start
import "../assets/CSS/index.css";
import ProjectDisplay from "../../../components/src/ProjectDisplay";
import Sponsorlistingcontroller, {
  Props
} from "./Sponsorlistingcontroller.web";
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";
// Customizable Area End


export default class Drafts extends Sponsorlistingcontroller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div data-test-id="2aq">
              <ProjectFilterWeb
                   onSearch={this.searchProjectHandler}
                   data-testid="draft_project_filter"
                   onFilter={this.filterProjectHandler}
                   activeTabColor="#00A7A2"
                   onCancelFilter={this.cancelFilterProjectHandler}
                />
                <ProjectDisplay
                    data-id="draft_project_display"
                    loading={this.state.loading}
                    projectList={this.state.projectdata}
                    type = "draft"
                    isShowMore={this.state.totalProjectsCount > this.state.projectdata?.length}
                    onTabChange={this.handleChipClick}
                    loadMore={this.handleProjectLoadMore}
                    navigate={this.props.navigation?.navigate}
                    tabDataList={this.state.chipNames}
                    onDraftDelete={this.deleteProject}
                    onDraftReuse={this.props.getSingleProject}
                  />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area End
