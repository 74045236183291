import React from "react";
import FreelancerCertificationtrackingControllers, {
  Props,
  configJSON,
} from "./FreelancerCertificationtrackingControllers.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import {correction} from "../src/assets";
import {Box,Typography,Button} from "@material-ui/core";

class FreelancerSentForCorrection extends FreelancerCertificationtrackingControllers {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div>
        <FreelancerHeader
          navigation={this.props.navigation}
          history={undefined}
          location={undefined}
        />
        <Box style={{width:"1440px",height:"1080px",background:"#fff"}}>
          <Box style={{position :"relative", top:"20%",left:"55%"}}>
        <img style={{ width:"154px",height: "154px"}} src={correction}/>
        <Typography component="h1" variant="h4" 
        style={{fontWeight: 700,textAlign: "center"}}>
            Sent For Correction</Typography>
        <Typography variant="h4" 
        style={{fontWeight: 500,
          textAlign: "center"}}>
            You will receive a message once the sponser makes the correction</Typography>
        <Box style={{display:"flex"}}>
          <Button type="submit" variant="contained"  
            data-testid="GoToDashboardTxtbtnFsfrc" 
          style={{fontWeight:"bold",textTransform: "none"}}>
            {configJSON.gotoDashboard}
          </Button>
          <Button type="submit" variant="contained" 
          data-testid="GoToBrowsemoreprjTxtbtnFsfrc"  
          style={{fontWeight:"bold",textTransform: "none"}}>
            {configJSON.browseMoreProjects}
          </Button>
          </Box>
          </Box>
        </Box>
        <DashboardFooter history={undefined} location={undefined} />
      </div>
    );
  }
}
export default FreelancerSentForCorrection;

export const webStyleFSFC={
  


}
