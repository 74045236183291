import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles} from '@material-ui/core';
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
import {toast} from "react-toastify"
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
  navigation:any;
  id:string;

}
interface CurrencyConversion{
  freelancer:{
    currency:string;
    currency_symbol:string;
    amount:number;
  }; 
  sponsor:{
    currency:string;
    currency_symbol:string;
    amount:number;
  };
}
export type HistoryDetails={
  title:string,
  user_name:string,
  created_at:string,
  description:string,
  profile_pic?:{url?:string},
  currency_conversion:CurrencyConversion,
}
export type FileDetails={
  blob_id:number,
  created_at:string,
  name:string,
  size:number,
  url:string
}

export type DisputeDetails={
  title:string,
  user_name:string,
  created_at:string,
  profile_pic?:{url?:string},
  description:[
    {
    html:string,
    reason_html:string,
    image:[
      {
      id: number,
      url: string,
      name: string,
      created_at: string,
      size: number,
      blob_id:number,
      }]
    }
    ]
 }

 export type DisputeRaisedDetails={
  title:string,
  user_name:string,
  created_at:string,
  profile_pic?:{url?:string},
  description:[
    {
    html:string,
    description_html:string,
    raise_dispute_id:number | undefined,
    images_html:[
      {
      id: number,
      url: string,
      name: string,
      created_at: string,
      size: number,
      blob_id:number,
      }]
    }
    ]
 }

 export type RefundDetails={
  title:string,
  user_name:string,
  created_at:string,
  profile_pic?:{url?:string},
  currency_conversion:CurrencyConversion,
  description: {
    milestone:string,
    html:string,
    html_refund_amount:string,
    },
    milestone_title: string,
    refund_id: number,
    refund_status: string,
    reason_for_refund: string,
 }
 export type MilestoneDisputeDetails={
  title:string,
  user_name:string,
  created_at:string,
  profile_pic?:{url?:string},
  currency_conversion:CurrencyConversion,
  description: {
    dispute_id: number,
    milestone_id: number,
    milestone:string,
    html:string,
    html_dispute_amount:string,
    dispute_request:string,
    reason:string,
    dispute_documents:[
      {
      id: number,
      url: string,
      name: string,
      created_at: string,
      size: number,
      blob_id:number,
      }],
      rejection_documents:[
        {
        id: number,
        url: string,
        name: string,
        created_at: string,
        size: number,
        blob_id:number,
        }]
    },
    milestone_title: string,
    dispute_id: number,
    refund_id:number,
    refund_dispute_status: string,
    reason_for_dispute: string,
 }
export interface S {
  // Customizable Area Start
   milestoneDetails:any;
   detailsLoader:boolean;
   milestoneFlags:string[];
   open:boolean;
   rating:number;
   review:string;
   sponserId:number|null
   hideBtn:boolean;
   openRejectBox:boolean;
   rejectionValue:string;
   otherRejection:string;
   uploadDisputeFiles: File[];
   closureLoader:boolean;
   rejectedLoader:boolean;
   ratingLoader:boolean;
   ratingError:string;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class PrjDetailsController extends BlockComponent<
  Props, S, SS
> {

    // Customizable Area Start
    milestoneDetailsApiCallId:string="";
    prjID:string = "";
    rateSponserAPIcallId:string = "";
    acceptClosureApiCallId:string = "";
    rejectClosureApiCallId:string = "";
    acceptDisputeApiCallId:string = "";
    rejectDisputeApiCallId:string = "";
    acceptRefundApiCallId:string = "";
    rejectRefundApiCallId:string="";
    refundStatusId:string="";
    refundRejectStatusId:string="";
    acceptMilestoneDisputeApiCallId:string = "";
    acceptMilestoneDisputeId:string="";
    rejectMilestoneDisputeId:string="";
    rejectMilestoneDisputeApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

        this.state = {
            // Customizable Area Start
            milestoneDetails:{},
            open: false,
            rating:0,
            detailsLoader:false,
            milestoneFlags:[
              'Bid accepted',
             'Milestone Created',
             'Message Sent',
              'Message Received',
              'File Uploaded',
              'Requested for revision',
              'Completed Project', 
              'Payment Initiated',
              'Project On Hold',
              'Project Closure Accepted',
              'Project Closure Requested',
              "Project Finished",
              "Dispute has been Raised for Project",
              "Dispute Request for Project is Resolved",
              "Dispute Request for Project is Rejected",
              'Project Closure Rejected',
              'Refund Request Send',
              "Refund Request Accept",
              "Refund Request Reject",
              "Dispute has been Raised",
              "Dispute Request Resolved",
              "Dispute Request Rejected",
              "Project has been resumed successfully",
            ],
            review:'',
            sponserId:null,
            hideBtn:false,
            openRejectBox:false,
            rejectionValue:"",
            otherRejection:'',
            uploadDisputeFiles:[],
            closureLoader:false,
            rejectedLoader:false,
            ratingLoader:false,
            ratingError:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    this.prjID= this.props.navigation.getParam("projectid")
    this.getMilestoneDetails();

    // Customizable Area End
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (this.milestoneDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setMilestoneDetails(apiResponse)
    }
    if (this.rateSponserAPIcallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (apiResponse) {
        this.setState({ rating:0,open:false,review:'',ratingLoader:false})
        toast.success(apiResponse.message)
        this.getMilestoneDetails();
      }
    }

    if (this.acceptClosureApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.acceptedClosureCall(apiResponse)
    }

    if (this.rejectClosureApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.rejectClosureCall(apiResponse)
      
    }

    if (this.acceptDisputeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.acceptedDisputeCall(apiResponse)
        window.location.reload()
    }

    if (this.rejectDisputeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
     this.rejectClosureCall(apiResponse)
     window.location.reload()
    }
    if (this.acceptRefundApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.acceptedRefundCall(apiResponse)
        window.location.reload()
    }
    if (this. rejectRefundApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.rejectClosureCall(apiResponse)
        window.location.reload()
    }

    if (this.acceptMilestoneDisputeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.acceptedDisputeCall(apiResponse)
        window.location.reload()
    }

    if (this. rejectMilestoneDisputeApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.rejectMilestoneDisputeCall(apiResponse)
        window.location.reload()
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  acceptedClosureCall=(apiResponse:{message:string, error:string})=>{
    if (apiResponse) {
      if(apiResponse?.message){
       this.setState({ closureLoader:false, hideBtn:true})
       toast.success(apiResponse?.message)
       this.props.navigation.navigate(configJSON.pathFreelancerProjects, { type: configJSON.closedType });
      }
     else if(apiResponse?.error){
       this.setState({ closureLoader:false,  hideBtn:true})
       toast.error(apiResponse?.error)
      }
   }
  }
  acceptedDisputeCall=(apiResponse:{message:string, error:string})=>{
    if (apiResponse) {
      if(apiResponse?.message){
       this.setState({ closureLoader:false, hideBtn:true})
       toast.success(apiResponse.message)
       this.props.navigation.navigate(configJSON.pathFreelancerProjects, { type: configJSON.disputeType });
      }
     else if(apiResponse?.error){
       this.setState({ closureLoader:false,  hideBtn:true})
       toast.error(apiResponse.error)
      }
   }
  }
  rejectClosureCall=(apiResponse:{message:string, error:string})=>{
    if (apiResponse) {
      if(apiResponse?.message){
        this.setState({rejectedLoader:false})
        toast.success(apiResponse?.message)
        this.getMilestoneDetails()
       }
      else if(apiResponse?.error){
        this.setState({rejectedLoader:false})
        toast.error(apiResponse?.error)
       }

    }
  }

  acceptedRefundCall=(apiResp:{message:string, error:string})=>{
    if (apiResp) {
      if(apiResp?.message){
       this.setState({hideBtn:true, closureLoader:false},()=>{
        this.getMilestoneDetails()
       })
       toast.success(apiResp?.message)
   
      }
     else if(apiResp?.error){
       this.setState({ closureLoader:false,hideBtn:true})
       this.getMilestoneDetails()
       toast.error(apiResp?.error)
      }
   }
  }

  rejectMilestoneDisputeCall=(disputeApiRes:{meta:{message:string}, error:string})=>{
    if (disputeApiRes) {
      if(disputeApiRes?.meta?.message){
        this.setState({rejectedLoader:false})
        toast.success(disputeApiRes?.meta.message)
        this.getMilestoneDetails()
       }
      else if(disputeApiRes?.error){
        this.setState({rejectedLoader:false})
        toast.error(disputeApiRes?.error)
       }
    }
  }

  setMilestoneDetails = (apiResponse:any) => {

      for (const date in apiResponse){
        const projectFinishedEntries = apiResponse[date].filter((entry: MilestoneDisputeDetails) => entry.title === "Project Finished")
          
        if(projectFinishedEntries?.length > 1){
          projectFinishedEntries.sort((prev: MilestoneDisputeDetails, next:MilestoneDisputeDetails) => new Date(next.created_at).getTime() - new Date(prev.created_at).getTime())
          
          const uniqueFinishedEntries = new Set<string>()
          const uniqueProjectFinishedEntries = projectFinishedEntries.filter((entry: MilestoneDisputeDetails) => {
            if(!uniqueFinishedEntries.has(entry.title)){
              uniqueFinishedEntries.add(entry.title);
              return true;
            }
            return false;
          })
          const allEntries = apiResponse[date].filter((entry:MilestoneDisputeDetails) => entry.title !== "Project Finished" );
          apiResponse[date] = [...allEntries, ...uniqueProjectFinishedEntries]
          apiResponse[date].sort((prev:MilestoneDisputeDetails, next:MilestoneDisputeDetails) => new Date(prev.created_at).getTime() - new Date(next.created_at).getTime())
        }
      }

      this.setState({detailsLoader:false,
        milestoneDetails:apiResponse
      },
      ()=>this.getSponserId()
      )
  }
getMilestoneDetails = async() => {
  this.setState({detailsLoader:true,hideBtn:false})
  const token =   await getStorageData('loginSuccessToken', false)
  const header = {
    token: token,
    "Content-Type": configJSON.contentTypeApi,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.milestoneDetailsApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.milestonesDetailsApiCallUrl + `${this.prjID}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiGetMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
 
convertFileSize=(bytes:number)=>{
  if (bytes >= 1024 * 1024) {
   return (bytes / (1024 * 1024)).toFixed(2) + configJSON.labelMB;
 } else {
   return (bytes / 1024).toFixed(2) + configJSON.labelKB;
 }
}

onDownloadFile = async (url: string, fileName: string) => {
const response = await fetch(url);
const blob = await response.blob();
const fileUrl = window.URL.createObjectURL(blob);
const link = document.createElement('a');
link.href = fileUrl;
link.download = fileName;
link.click();
window.URL.revokeObjectURL(fileUrl);
};
handleOpen = () => {
  this.setState({ open: true });
  window.scrollTo({
    top:100
  });
};

handleClose = () => {
  this.setState({ rating:0,open:false,review:'',ratingError:''})
};
ratingChanged = (newRating:any) => {
  this.setState({
    rating:newRating,
    ratingError:"",
  })
};

convertFileName=(name:string)=>{
  if (name.length > 12) {
    const dotIndex = name.lastIndexOf('.');
    if (dotIndex !== -1) {
      const firstEight = name.substring(0, 8);
      const hiddenPart = '*'.repeat(4);
      const extension = name.substring(dotIndex);
      return `${firstEight}${hiddenPart}${extension}`;
    } else {
      return name;
    }
  } else {
    return name;
  }
}

handleReviewChange = (event:any)=>{
  this.setState({review:event.target.value})
 }
 
 getSponserId = ()=>{
  if(Object.keys(this.state.milestoneDetails).length>0){
    const sponserId = this.state.milestoneDetails[Object.keys(this.state.milestoneDetails)[0]][0].profile_id
    this.setState({sponserId:sponserId})
  }
 }
 handleValidateRatings=()=>{
  const {rating} = this.state
  if (rating==0){
    this.setState({ratingError: configJSON.ratingErrorMsg})
  }
  else{
    this.rateSponser()
  }
 }

 rateSponser = async()=>{
   const {rating,sponserId,review} = this.state
   this.setState({ratingLoader:true, ratingError:''})
   const header = {
     token: await getStorageData('loginSuccessToken', false)
 
   }
 
   let formdata = new FormData();
   formdata.append('review[score]',String(rating))
   formdata.append('review[description]',review)
   formdata.append('review[profile_id]',String(sponserId))
   formdata.append('review[project_id]',this.prjID)
   formdata.append('review[rated_from]','freelancer')
 
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
 
   this.rateSponserAPIcallId = requestMessage.messageId;
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.rateSponserAPIEndpoint
   );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestBodyMessage),
     formdata
   );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.httpPostMethod
   );
 
   runEngine.sendMessage(requestMessage.id, requestMessage);
 
 
 }
 handleAcceptButton=async()=>{
  this.setState({ closureLoader:true})
  const token =   await getStorageData('loginSuccessToken', false)
     const header = {
       token: token,
     };
 
   let formdata = new FormData();
 
   formdata.append("project_id", this.prjID);
   formdata.append("project_clouser_status", configJSON.acceptTxt);
 
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   this.acceptClosureApiCallId = requestMessage.messageId;
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.acceptClosureApiCallUrl
     );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestBodyMessage),
     formdata
   );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.exampleAPiMethod
   );
 
   runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 handleAcceptDisputeButton=async(disputedId:any)=>{
  this.setState({ closureLoader:true})
  const token =   await getStorageData('loginSuccessToken', false)
     const header = {
       token: token,
     };
 
   let formdata = new FormData();
 
   formdata.append("project_id", this.prjID);
   formdata.append("dispute_request", configJSON.acceptTxt);
   formdata.append("dispute_id", disputedId);
 
   const requestMessage = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
   );
   this.acceptDisputeApiCallId = requestMessage.messageId;
   requestMessage.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.disputeApiCallUrl
     );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(header)
   );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestBodyMessage),
     formdata
   );
 
   requestMessage.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.exampleAPiMethod
   );
 
   runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 handleRejectButton=()=>{
  this.setState({hideBtn:true, openRejectBox:true})
 }
 handleRejectionOptionChange=(event:React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({rejectionValue: event.target.value})
}
handleOtherRejectionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  this.setState({ otherRejection: (event.target as HTMLTextAreaElement).value });
};

handleOptionSubmit=()=>{
  let isOptionValid = true;
  if(this.state.rejectionValue == ''){
    toast.error(configJSON.errorNoSelection)
    isOptionValid = false;
  }
 else if(this.state.rejectionValue == 'other' && this.state.otherRejection == ""){
    toast.error(configJSON.errorNoReason)
    isOptionValid = false;
  }
  return isOptionValid
}

submitDisputeOption=async(disputedId: any)=>{
  if(this.handleOptionSubmit()){
   const token:string = await getStorageData('loginSuccessToken', false)
    this.setState({rejectedLoader:true})
    const header = {
      token: token
    };
  this.state.rejectionValue == configJSON.rejectOptionValue3 && this.setState({rejectionValue: this.state.otherRejection})
  let formdata = new FormData();
  formdata.append("dispute_reason", this.state.rejectionValue);
  formdata.append("project_id", this.prjID);
  formdata.append("dispute_id", disputedId);
  formdata.append("dispute_request", configJSON.rejectTxt);
  this.state.uploadDisputeFiles && this.state.uploadDisputeFiles.forEach((file:any) => {
    formdata.append(`dispute_images[]`, file);
  });
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.rejectDisputeApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.disputeApiCallUrl
    );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod 
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
    }
}

submitClosureOption=async()=>{
  if(this.handleOptionSubmit()){
  this.setState({rejectedLoader:true})
  const header = {
    token: await getStorageData('loginSuccessToken', false)
  };
this.state.rejectionValue == configJSON.rejectOptionValue3 && this.setState({rejectionValue: this.state.otherRejection})
let formdata = new FormData();
formdata.append("reason_for_rejection", this.state.rejectionValue);
formdata.append("project_id", this.prjID);
formdata.append("project_clouser_status", configJSON.rejectTxt);

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.rejectClosureApiCallId = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.acceptClosureApiCallUrl
  );

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  formdata
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.httpPostMethod 
);

runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

handleOptionCancel=()=>{
  this.setState({
    hideBtn:false, 
    openRejectBox:false, 
    rejectionValue: '',
    otherRejection:"", 
    uploadDisputeFiles:[],
  })
  this.refundRejectStatusId=""
  this.rejectMilestoneDisputeId= ""
 }
 handleDisputeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
  const files = event.target.files;

  if (files) {
    let sizeExceedsLimit = false;
    let newDocs :File[]= [...this.state.uploadDisputeFiles]; 

    const filesArray = Array.from(files);
    filesArray.forEach((doc: File) => {

        if (doc.size > 15 * 1024 * 1024) { 
         sizeExceedsLimit = true;
       } 
      else {
        newDocs.push(doc); 
      }
    });

    if (sizeExceedsLimit) {
      toast.error(configJSON.errorMaxUpload);
      
    } 
    else {
      this.setState({ uploadDisputeFiles: newDocs }); 
    }
 }
};

handleAcceptRefundButton=async(refundId:number)=>{
  this.setState ({closureLoader: true, });
  this.refundStatusId=refundId.toString()
  const header = {
    token:await getStorageData('loginSuccessToken', false),
  };

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.acceptRefundApiCallId = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.acceptRefundApiCallUrl + `${refundId}&status=accept`
  );

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.apiPatchMethodType
);

runEngine.sendMessage(requestMessage.id, requestMessage);
 }



handleRejectRefundButton=(rejectRefundId:number)=>{
  this.refundRejectStatusId=rejectRefundId.toString()
  this.setState({openRejectBox:true})
}

onSubmitRejectRefundButton=async(rejectRefundId:number)=>{
  const token:string = await getStorageData('loginSuccessToken', false)
  if(this.handleOptionSubmit()){
    this.setState({
      rejectedLoader:true
    })
    const header = {
      token: token
    };
  this.state.rejectionValue == configJSON.rejectOptionValue3 && this.setState({rejectionValue: this.state.otherRejection})
 
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.rejectRefundApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.acceptRefundApiCallUrl + `${rejectRefundId}&status=reject&reason_for_rejection=${this.state.rejectionValue}`
    );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiPatchMethodType
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
    }
}

handleAcceptMilestoneDispute=async(milestoneDisputeId:number)=>{
  this.setState ({closureLoader: true, });
  this.acceptMilestoneDisputeId=milestoneDisputeId.toString()
  const header = {
    token:await getStorageData('loginSuccessToken', false),
  };
  let formdata = new FormData();
  formdata.append('raise_dispute[dispute_request]',configJSON.acceptTxt)
  formdata.append('raise_dispute[project_milestone_id]',milestoneDisputeId.toString())


const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
this.acceptMilestoneDisputeApiCallId = requestMessage.messageId;
requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  configJSON.disputeMilestoneApiCallUrl
  );

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  formdata
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.httpPostMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);
 }

 handleRejectMilestoneDispute=(milestoneDisputeId:number)=>{
  this.rejectMilestoneDisputeId=milestoneDisputeId.toString()
  this.setState({openRejectBox:true})
}
onSubmitRejectMilestoneDispute=async(milestoneDisputeId:number)=>{
  if(this.handleOptionSubmit()){
    this.setState({
      rejectedLoader:true
    })
    const header = {
      token: await getStorageData('loginSuccessToken', false)
    };
  this.state.rejectionValue == configJSON.rejectOptionValue3 &&
   this.setState({
    rejectionValue: this.state.otherRejection
  })
 
  let formdata = new FormData();
  formdata.append("raise_dispute[dispute_request]", configJSON.rejectTxt);
  formdata.append('raise_dispute[project_milestone_id]',milestoneDisputeId.toString())
  formdata.append("raise_dispute[dispute_reason]", this.state.rejectionValue);
  this.state.uploadDisputeFiles && this.state.uploadDisputeFiles.forEach((file:any) => {
    formdata.append(`raise_dispute[reject_docs][]`, file);
  });
  

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.rejectMilestoneDisputeApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.disputeMilestoneApiCallUrl
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  
  runEngine.sendMessage(requestMessage.id, requestMessage);
    }
}

deleteDisputedFiles = (disputeId: number) => {
  let updatedFiles = this.state.uploadDisputeFiles.filter(
    (file,index) => index !== disputeId
  );
  this.setState({ uploadDisputeFiles: updatedFiles });
};
  // Customizable Area End
}

