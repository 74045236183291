import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {  Field} from "formik";
import InputError from '../../../blocks/social-media-account-registration/src/InputError.web';
import CustomSelect from '../utill/CustomSelect.web';
import {MenuItem} from '@material-ui/core'
interface Props{
    setFieldValue:(key:string,value:string)=>void;
    index:number;
    getStateList:(country:string)=>void;
    getCityList:(state:string)=>void;
    countryLoading:boolean;
    experienceErrors:any;
    values:any;
    cityLoading:boolean;
    getStateLoading:boolean;
    countryList:string[];
    cityList:string[];
    stateList:string[];

}

const LocationContainer:React.FC<Props>=({
    setFieldValue,
    index,
    getStateList,
    countryLoading,
    experienceErrors,
    values,
    getCityList,
    cityLoading,
    getStateLoading,
    countryList:mCountryList,
    cityList:mCityList,
    stateList:mStateList
})=>{
    const [countryList,setCountryList]=useState<string[]>(mCountryList);
    const [stateList,setStateList]=useState<string[]>([]);
    const [cityList,setCityList]=useState<string[]>([]);
    const [selectedCountry,setSelectedCountry]=useState<string>("");
    const [selectedState,setSelectedState]=useState<string>("");
    const changingFieldName=`country_state_${index}`


    useEffect(()=>{
        setCountryList(mCountryList)
    },[countryLoading,mCountryList])

    useEffect(()=>{
        console.log({
            countryCheck:!getStateLoading && values?.career_experiences[index]?.country?.trim()===selectedCountry,
            indexCountry:values?.career_experiences[index]?.country?.trim(),
            selectedCountry:selectedCountry
        })
        const prevCountry=values?.career_experiences[index]?.country?.trim()
        if(!getStateLoading && values?.change_experiences_for===changingFieldName){
            setStateList(mStateList)
        }
    },[getStateLoading,mStateList]) 
    useEffect(()=>{
        const prevState=values?.career_experiences[index]?.state?.trim();
        if(!cityLoading && values?.change_experiences_for===changingFieldName){
            setCityList(mCityList)
        }
    },[cityLoading,mCityList])

   
    return(
        <>
              <div className="d-flex">
                            <CustomSelect
                                  label="Country*"
                                  value={values?.career_experiences[index]?.country}
                                  onChange={(e:any)=>{
                                    setFieldValue(`career_experiences.${index}.country`,e.target?.value);
                                    setFieldValue(`career_experiences.${index}.state`,"");
                                    setFieldValue(`career_experiences.${index}.city`,"");
                                    setFieldValue("change_experiences_for",changingFieldName);
                                    setSelectedCountry(e.target?.value);
                                    getStateList(e.target?.value)
                                  }}
                                  placeholder=" Select country"
                                  name={`career_experiences.${index}.country`}
                                  id={`career_experiences.${index}.country`}
                                  data={countryList}
                                  error={experienceErrors[index]?.country || ""}
                                  renderItem={(item,props)=>(
                                    <MenuItem
                                      key={item}
                                      value={item}
                                      {...props}
                                    >
                                    {item}
                                  </MenuItem>
                                )}
                                  labelStyle={{
                                    marginBottom:"10px"
                                  }}
                                  rootStyle={{
                                    paddingRight:"20px"
                                  }}
                                />
                                  {
                                   values?.career_experiences[index]?.country?.trim() && stateList.length>0 ?(
                                      <>
                                        <CustomSelect
                                          label="State*"
                                          value={values?.career_experiences[index]?.state}
                                          onChange={(e:any)=>{
                                            setFieldValue(`career_experiences.${index}.state`,e.target?.value);
                                            setSelectedState(e.target?.value);
                                            setFieldValue(`career_experiences.${index}.city`,"");
                                            setFieldValue("change_experiences_for",changingFieldName);
                                            getCityList(e.target?.value)
                                          }}
                                          placeholder="Select state"
                                          name={`career_experiences.${index}.state`}
                                          id={`career_experiences.${index}.state`}
                                          data={stateList}
                                          error={experienceErrors[index]?.state || ""}
                                          disabled={!values?.career_experiences[index]?.country?.trim()}
                                          renderItem={(item,props)=>(
                                            <MenuItem
                                              key={item}
                                              value={item}
                                              {...props}
                                            >
                                            {item}
                                          </MenuItem>
                                        )}
                                          labelStyle={{
                                            marginBottom:"14px"
                                          }}
                                        />
                                      </>
                                   ) :(
                                    <div className="form-group" id="checkpls">
                                       <label
                                            className="helloooo"
                                              htmlFor={`career_experiences.${index}.state`}
                                            >
                                              State*
                                            </label>
                                            <Field
                                              placeholder="Enter state"
                                              disabled={!values?.career_experiences[index]?.country?.trim()}
                                              name={`career_experiences.${index}.state`}
                                              id={`career_experiences.${index}.state`}
                                              onChange={(e:any)=>{
                                                setFieldValue(`career_experiences.${index}.state`,e.target?.value);
                                                setFieldValue(`career_experiences.${index}.city`,"");
                                                setFieldValue("change_experiences_for",changingFieldName);
                                                setSelectedCountry(e.target?.value);
                                              }}
                                            />
                                        <InputError errors={values?.career_experiences[index]?.country?.trim() && stateList.length===0 &&   experienceErrors[index]?.state?{state:"Please enter state"}:experienceErrors[index] || {}} name="state"/>
                                    </div>
                                   )
                                  }
                                </div>
                               
                                {
                                  !cityLoading && cityList?.length===0 && values?.career_experiences[index]?.country && values?.career_experiences[index]?.state?(
                                    <div className="form-group" id="checkpls">
                                     <label
                                          className="helloooo"
                                          htmlFor={`career_experiences.${index}.city`}
                                        >
                                          City*
                                        </label>
                                        <Field
                                          name={`career_experiences.${index}.city`}
                                          id={`career_experiences.${index}.city`}
                                          tabIndex={11}
                                          placeholder="Enter City"
                                        />
                                         <InputError errors={!cityLoading && cityList?.length===0 && values?.career_experiences[index]?.country && values?.career_experiences[index]?.state && experienceErrors[index]?.city?{city:"Please enter city"}:experienceErrors[index] || {}} name="city"/>
                                    </div>
                                  ):(<>
                                      <CustomSelect
                                          label="City*"
                                          value={values?.career_experiences[index]?.city}
                                          onChange={(e:any)=>{
                                            setFieldValue(`career_experiences.${index}.city`,e.target?.value);
                                          }}
                                          placeholder="Select city"
                                          name={`career_experiences.${index}.city`}
                                          id={`career_experiences.${index}.city`}
                                          data={cityList}
                                          error={experienceErrors[index]?.city || ""}
                                          disabled={!values?.career_experiences[index]?.state?.trim()}
                                          renderItem={(item,props)=>(
                                            <MenuItem
                                              key={item}
                                              value={item}
                                              {...props}
                                            >
                                            {item}
                                          </MenuItem>
                                        )}
                                          labelStyle={{
                                            marginBottom:"14px"
                                          }}
                                        />
                                      {/* <ExpandMoreIcon />
                                        <label
                                          className="helloooo"
                                          htmlFor={`career_experiences.${index}.city`}
                                        >
                                          City*
                                        </label>
                                        <Field
                                          name={`career_experiences.${index}.city`}
                                          id={`career_experiences.${index}.city`}
                                          as="select"
                                          disabled={!values?.career_experiences[index]?.state?.trim()}
                                        >
                                          <option value="" selected hidden>
                                            Select city
                                          </option>
                                          {
                                            cityLoading?(
                                              <option value="" disabled>Loading...</option>
                                            ):cityList?.map((city)=>(
                                              <option value={city} key={city}>{city}</option>
                                            ))
                                          }
                                        </Field> */}
                                  </>)
                                  }
                               
        </>
    )

}


export default LocationContainer;