Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.getAuctionApiEndPoint = "/bx_block_auctionbidding/auction_biddings";
exports.getAuctionApiMethod = "GET";
exports.getAuctionApiContentType = "application/json";

exports.getAuctionDetailApiEndPoint = "/bx_block_auctionbidding/auction_biddings";
exports.getAuctionDetailApiMethod = "GET";
exports.getAuctionDetailApiContentType = "application/json";

exports.getBiddingApiEndPoint = "/bx_block_auctionbidding/bids_listing";
exports.getBiddingApiMethod = "GET";
exports.getBiddingApiContentType = "application/json";

exports.placeBidApiEndPoint = "/bx_block_auctionbidding/place_bid";
exports.placeBidApiMethod = "POST";
exports.placeBidApiContentType = "application/json";

exports.loginApiEndPoint = "/login/login";
exports.loginApiMethod = "POST";
exports.loginApiContentType = "application/json";

exports.AuctionAcceptedNotificationApiEndPoint ="/bx_block_push_notifications/push_notifications";
exports.AuctionAcceptedNotificationApiMethod = "GET";
exports.AuctionAcceptedNotificationApiContentType = "application/json";
exports.AuctionBiddingBidsGetApiEndPoint = "bx_block_auctionbidding/bids";
exports.NewBidSendBySponsorApiEndPoint = "/bx_block_auctionbidding/bids/negotiate";
exports.NewBidResponseApiEndPoint = "/bx_block_auctionbidding/bids/get_negotiations";
exports.ManageStatusApiEndPoint = "/bx_block_auctionbidding/bids/manage_status";
exports.ShowProjectApiEndPoint = "/bx_block_profile/projects"
exports.sendInvitationApiUrl = "bx_block_auctionbidding/invitations"


exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AuctionBidding";
exports.labelBodyText = "AuctionBidding Body";

exports.btnExampleTitle = "CLICK ME";
exports.getSingleBidApiUrl="bx_block_auctionbidding/bids"
exports.getSingleProjectApiUrl="bx_block_profile/projects"
exports.getFreelancersApiCallId="bx_block_auctionbidding/bids/list_all_freelancer"
exports.sendNewBidApiUrl="bx_block_auctionbidding/bids/send_new_bids"
exports.acceptBidApiUrl="bx_block_auctionbidding/bids/"
exports.hireFreelancerApiUrl="bx_block_auctionbidding/bids/hire_freelancer"
exports.changeBidStatusApiUrl="bx_block_auctionbidding/bids/manage_status"
exports.hireTxt= "Hire "
exports.freelnacerNameTxt= " Amin Mohammed M."
exports.projectNameTxt="Logo Design for an Insurance Company"
exports.skillsTxt="Java | React | ROR"
exports.escrowTxt ="Escrow Deposits"
exports.totalAmtTxt="Total Amount"
exports.dollar='$'
exports.taxAmtInitialTxt="you will be charged upto a"
exports.taxAmtTxt="Taxes may also apply."
exports.taxPercentTxt=" 5% marketplace fee."
exports.btnPay= 'Pay & Hire'
exports.btnBackTxt="Back"
exports.projectMilestonesHeading ="Project Milestones"
exports.projectMilestoneDetails =" Add project milestones and pay installments as each milestone is completed to your satisfaction. Due dates will be set in Coordinated Universal Time (UTC)"
exports.milestoneNo ="No."
exports.milestoneTitle ="Milestone Title"
exports.milestoneDesc ="Milestone Description"
exports.milestoneDueDate ="Due Date"
exports.milestoneAmt ="Deposit Amount"
exports.cardLabelEstimate= "Estimated Budget"
exports.cardLabelDuration= "Duration"
exports.cardLabelExperience= "Experience"
exports.cardLabelLanguage= "Language"
exports.cardLabelCurrentBids= "Current Quotes"
exports.viewProfileBtn="View Profile"
exports.enterBidAmtLabel="Please enter quote amount."
exports.validBidAmtLabel="Please enter valid amount!"
exports.bidAcceptedSuccess="Quote Successfully Accepted."
exports.bidAcceptedFailed="Quote Accept Failed."
exports.bidRejectedSuccess="Quote Successfully Rejected."
exports.bidRejectedFaied="Quote Reject Failed."
exports.coverLetterLabel ="Cover Letter"
exports.paymentPolicyTxt1 ="You're protected by"
exports.paymentPolicyTxt2 ="Lancerscape Payment Protection,"
exports.paymentPolicyTxt3 ="Only pay for the work you authorize."
exports.escrowLabel ="Escrow is a neutral holding place that protects your deposit until work is approved."
exports.paymentProjectApiCallId ="bx_block_ccavenueintegration2/payment_for_project"
exports.paymentDetailsApiEndPoint ="bx_block_payments/orders";
exports.approveMilestoneApiEndPoint = 'bx_block_scheduling/approve_milestones'
exports.getInvitationsApiCallId = 'bx_block_auctionbidding/get_all_invitations'
exports.addAndRemoveFavouritesAPIEndpoint ="bx_block_favourites/favourites"
exports.exampleAPiMethod ="POST"
exports.allProposalsBtn = 'All Proposals'
exports.localTimeLabel ="local time"
exports.viewProfileLabel ="View Profile"
exports.btnNegotiation ="Negotiate Quote"
exports.btnHireFreelancer =" Hire Freelancer"
exports.btnHire =" View"
exports.btnMessage =" Message"

exports.getSingleInvitationApiEndPoint = 'bx_block_auctionbidding/invitations'
exports.acceptInvitationApiEndPoint = 'bx_block_auctionbidding/accept_invitation'
exports.rejectInvitationApiEndPoint = 'bx_block_auctionbidding/reject_invitation'
exports.amountPlaceholder= "Enter Quote Amount"
exports.proposalPlaceholder="Write your proposal here"
exports.proposedDetailsHeader="Proposal Details"
exports.projectDetailsHeader="Past Completed & Reviews"
exports.proposedBidTxt="Proposed Quote"
exports.btnSendLabel=" Send"
exports.dollarLabel ="$"
exports.lancerscapePaymentPolicy ="Payment Protection"
exports.privacyPolicy="Privacy Policy"
exports.NoData= "No Data Found"
// Customizable Area End