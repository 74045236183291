Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.logInTitleText = "Log In";
exports.logInBodyText = "The reason why we require login.";
exports.loginFacebookButtonText = "Log in with Facebook";
exports.loginGoogleButtonText = "Log in with Google";
exports.loginButtonText = "Log in with email and password";
exports.orText = "or";
exports.passworderror="Please enter valid password";
exports.passwordRegPattern= /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\_*])(?=.{8,})/;
exports.projectSponsor= 'Project Sponsor';
exports.freelancer='Freelancer';
exports.incorrectEmailAddress='The email address entered is incorrect.';
exports.loginFailed='Login Failed.';
exports.accountNotFound='Account not found, or not activated.';
exports.emailRequired="Please enter your email.";
exports.passwordRequired="Please enter your password.";
exports.invalidEmail="Invalid email address.";
exports.invalidPassword="Password should be a minimum of 8 characters long, should contain uppercase and lowercase characters, at least one digit and one special character.";
exports.googleProfileApiEndPoint="https://www.googleapis.com/oauth2/v1/userinfo?alt=json";
exports.getLinkedinAccessTokenApiCallId="https://www.linkedin.com/oauth/v2/accessToken";
exports.LINKEDIN_CLIENT_ID="77lhuxznlm3psp";
exports.LINKEDIN_CLIENT_SECRET="HZgO7DUvdqNcKXQf";
// Customizable Area End