import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?:any;
 

}
export interface S {
  // Customizable Area Start
  
  authtoken: any;
  error: any;
  proposalOptions: any[] ;
  proposalSelectedOption: any
  proposalCards:any[], 
  proposalPageNumber:number,
   proposalActiveTab:any,
   filterActiveTab: number;
   proposalVisibleCards:number;
    proposalLoading:boolean, 
    proposalTotalCards:number,
    proposalSearchQuery: string;
    proposalHire: boolean;
    proposalFilter: boolean;
    proposalBack: boolean;
    proposalError:any;
    proposalFavourite: boolean;
    proposalCardLoading:{};
  proposalMessage: boolean;
  filterTabValue: string;
  filterOptions: any;

  freeLancerListSelectedOption: {
    skills: any[],
    country: any[],
    experience_in_years: any,

  };
  displayFilterSortCard: boolean;
  tabLables: {
    tabFilterLabels: string[]
  };
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class SearchHireFreeLancerController extends BlockComponent<
  Props, S, SS
> {

  // Customizable Area Start
  
  proposalListCallID:any;
  proposalMesageCallID:any;
  proposalFilterCallID:any;
  proposalSkillsCategoryListCallID:any;
  proposalSortCallID:any;
  proposalSearchListCallID:any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      // Customizable Area Start
      
      authtoken: window.localStorage.getItem("loginSuccessToken"),
      error: '',
      proposalOptions: ['Latest', 'Oldest'], // Dynamic options array
      proposalSelectedOption: '',
      
  proposalCards:[], 
  proposalVisibleCards:4,
  proposalPageNumber:1,
   proposalActiveTab:0,
   filterActiveTab: 0,
    proposalLoading:true, 
    proposalTotalCards:5,
    proposalSearchQuery: '',
    proposalHire: false,
    proposalFilter:false,
    proposalBack: false,
    proposalError:'',
    proposalFavourite:false,
    proposalMessage:false,
    proposalCardLoading:{},
    filterTabValue: '',
      filterOptions: {
        skills: ["Java", "Python", "ROR"],
        country: [ "India" ,
        "USA" ,
        "Europe" ],
        __experience_in_years: [1, 2, 3, 4, 5],
      },
      freeLancerListSelectedOption: {
        skills: [],
        country: [],
        experience_in_years:null,
      },
      displayFilterSortCard: false,
      tabLables:
      {
        tabFilterLabels: ['Newest First', "Oldest First", "Highest Budget First", "Lowest Budget First", "Most Bids First", "Least Bids First"],
      }

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentWillMount() {
    // Customizable Area Start
    
   
    
    this.proposalListAPI(this.state.proposalPageNumber);
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start 
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
  
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    console.log(this.proposalSortCallID);
    
    console.log(responseJson)
    if (responseJson && !responseJson.errors) {
      if (
        apiRequestCallId === this.proposalListCallID ||
        apiRequestCallId === this.proposalFilterCallID ||
        apiRequestCallId === this.proposalSortCallID ||
        apiRequestCallId === this.proposalSearchListCallID
      ) {
        this.setState((prevState) => ({
          proposalCards: [...prevState.proposalCards, ...(responseJson?.data || [])]
        }));
        this.setState({
          proposalLoading: false,
          proposalTotalCards:responseJson?.meta?.total 
        });
      } else if (apiRequestCallId === this.proposalMesageCallID) {
        this.props.navigation.navigate('SearchSuccessWeb');
        this.setState({
          proposalLoading: false,
          proposalTotalCards:responseJson?.meta?.total
        });
      } else if (apiRequestCallId === this.proposalSkillsCategoryListCallID) {
        this.setState((prevState) => ({
          filterOptions: {
            ...prevState.filterOptions,
            skills: responseJson
          }
        }));
      }
    } else {
      const error = responseJson?.errors?.[0]?.token || "Something Went Wrong";
      this.setState({
        error: error,
        proposalPageNumber: 1,
        proposalCards: [],
        proposalLoading: false
      });
    }
      // Customizable Area End
  }
  
  //web events

  // Customizable Area Start 

 

    proposalListAPI = (pageNumber:number) => {
      const header = {
        token: this.state.authtoken,
        "Content-Type": "application/json",
        page_number:pageNumber
      };
  
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.proposalListCallID = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/search_algorithms/freelancer_visibility`
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    };

  


    proposalSortListAPI = (pageNumber:number,order:string) => {
      const header = {
        token: this.state.authtoken,
        "Content-Type": "application/json",
        page_number:pageNumber
      };
  
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.proposalSortCallID = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/search_algorithms/profile_order?order=${order}`
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    };


    sendProposalSearchListAPI=(searchQuery:string)=>{

      const header = {
          token: this.state.authtoken,
          "Content-Type": "application/json"
  
  
      };
  
      const getValidationsMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      this.proposalSearchListCallID = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_profile/search_algorithms/search_profile?search=${searchQuery}`
      );
  
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
      );
  
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
    
    getProposalSkillsCategoryListAPI=()=>{

      const header = {
          token: this.state.authtoken,
          "Content-Type": "application/json"
  
  
      };
  
      const getValidationsMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      this.proposalSkillsCategoryListCallID = getValidationsMsg.messageId;
  
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `/bx_block_profile/search_algorithms/fetch_skills`
      );
  
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          header
      );
  
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  

    sendProposalFilterApi = (
      pageNumber: number,
      country: string[],
      experience_in_years: number[],
      skills: string[],
      filterTabValue: string
    ) => {
      const header = {
        token: this.state.authtoken,
        "Content-Type": "application/json"
      };
  
      const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.proposalFilterCallID = getValidationsMsg.messageId;
  
      let queryParamsProposal = [];
  
      // Build the query parameters array
  
      if (skills !== undefined &&skills.length > 0) {
        queryParamsProposal.push(`skills[]=${skills.join('&skills[]=')}`);
      }
  
      if (country !== undefined && country.length > 0) {
        queryParamsProposal.push(`country[]=${country.join('&country[]=')}`);
      }
      if (experience_in_years) {
        queryParamsProposal.push(`experience=${experience_in_years}`);
      }
      if (filterTabValue !== undefined) {
        if (filterTabValue.toLowerCase() === "newest first") {
          queryParamsProposal.push(`order=desc`);
        } else if (filterTabValue.toLowerCase() === "oldest first") {
          queryParamsProposal.push(`order=asc`);
        }
      }
  
      const queryStringProposal = queryParamsProposal.join('&');
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/search_algorithms/filters_profile?${queryStringProposal}`
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
  
      getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    };

    handleProposalLoadMore = () => {
      const { proposalLoading, proposalPageNumber, proposalFilter, proposalSearchQuery } = this.state;
    
      if (!proposalLoading) {
        this.setState({ proposalLoading: true, proposalPageNumber: proposalPageNumber + 1 }, () => {

          if (proposalFilter && proposalSearchQuery) {
            this.proposalListAPI(this.state.proposalPageNumber);
          } else if (proposalFilter) {
           
           //this.sendProposalFilterApi(this.state.proposalPageNumber)
          } else if (proposalSearchQuery) {
            // Fetch data with searchQuery
            this.proposalListAPI(this.state.proposalPageNumber);
          } else {
            // Fetch data without filter and searchQuery
            this.proposalListAPI(this.state.proposalPageNumber);
          }
        });
      }
    };
  

    handleProposalTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      this.setState({proposalLoading:true,proposalActiveTab: newValue ,proposalCards:[]})
    
      this.proposalListAPI(this.state.proposalPageNumber);
   
    };
  
    handleProposalSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ proposalSearchQuery: event.target.value });
    };
  
    handleProposalSearchClick = () => {
      this.setState({proposalCards:[],proposalLoading:true},()=>{

       this.sendProposalSearchListAPI(this.state.proposalSearchQuery);
      })
    
    };
    handleProposalClearSearch = () => {
      this.setState({ proposalSearchQuery: "", proposalCards: [], proposalLoading: true }, () => {
          this.proposalListAPI(this.state.proposalPageNumber);
      });
  };

    handleProposalFavourite = () => {
      this.setState({ proposalFavourite: true });
    
    };
    handleProposalOptionsChange = (event:any) => {
        this.setState({ proposalSelectedOption: event.target.value,proposalCards:[],proposalLoading:true },()=>{
          this.proposalSortListAPI(this.state.proposalPageNumber,this.state.proposalSelectedOption.toLowerCase()=='latest'?'desc':'asc',); //this.state.proposalSelectedOption.toLowerCase()=='latest'?'desc':'asc',
      
         });
      };
  
  
    handleProposalMessage = (id:number) => {
      this.setState({ proposalLoading: true });
    
      this.setState({proposalMessage:true});
   
    };
  
    handleProposalHire = () => {
      this.setState({ proposalHire: true });
    };
   
  
    handleProposalBack = () => {
      this.setState({ proposalBack: true });
      
      this.props.navigation.navigate("SignUp")
    };
   


    //Filter Reusable Comp

  handleProjectApplyClick = () => {

    this.setState({ proposalLoading: true, proposalCards: [] }, () => {
      
      this.sendProposalFilterApi(this.state.proposalPageNumber, this.state.freeLancerListSelectedOption.country, this.state.freeLancerListSelectedOption.experience_in_years, this.state.freeLancerListSelectedOption.skills, this.state.filterTabValue)
    })

  }

  handleClearSelectedOptions = () => {

    this.setState({
      freeLancerListSelectedOption: {
        skills: [],
        country: [],
        experience_in_years: [],
      }
    });
  };


  handleProjectBidOptionsChange = (selectedOptions: React.ChangeEvent<{ value: unknown }>, fieldName: string) => {



    this.setState((prevState) => {
      return {
        freeLancerListSelectedOption: {
          ...prevState.freeLancerListSelectedOption,
          [fieldName]: selectedOptions.target.value
        },

      };
    });
  }

  handleFilterSortCard = () => {

    this.getProposalSkillsCategoryListAPI();
    this.setState({ displayFilterSortCard: !this.state.displayFilterSortCard })

  }

  handleProjectBidTabChange = (event: React.ChangeEvent<{}>, newValue: number, tabId: number) => {
    

    if (tabId == 0) {

      this.setState({ filterActiveTab: newValue, filterTabValue: this.state.tabLables.tabFilterLabels[newValue] }, () => {
       
      })

    }
    if (tabId == 1) {

      this.setState({ proposalLoading: true, proposalActiveTab: newValue, proposalCards: [] })
      this.proposalListAPI(this.state.proposalPageNumber);
    }

  };

  // Customizable Area End
}

