import React from "react";

// Customizable Area Start
import "../assets/css/Accountinfo.css";
import { lancerlogo, searchicon } from "./assets";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Formik, Field, Form } from "formik";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Select from 'react-select';
import { Button } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start

// Customizable Area Enad

import ProfilesetupController, { Props } from "./ProfilesetupController.web";
import ProfileSetupHeader from "../../../components/src/profile-setup/ProfileSetupHeader.web";
import CorporateForm from "../../../components/src/profile-setup/CorporateForm.web";
import SponsorForm from "../../../components/src/profile-setup/SponsorForm.web";
import ProfileSetupSteper from "../../../components/src/profile-setup/ProfileSetupSteper.web";

export default class Corporateboth extends ProfilesetupController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { skill_ids, hobby_ids, company_logo } = this.state;

    return (
      // Customizable Area Start
      <div className="main-wrapper corporate_both">
        <ProfileSetupHeader
          email={this.state.loggedInUserEmail}
          onLogout={this.sendLogoutMessage}
        />
        <div className="site-wrapper corporate_both">
          <div className="setup-account-block corporate_both">
            <div className="inner-block">
              <a href="#">
                <img src={lancerlogo} alt="logo" />
              </a>
              <h3>
                Welcome to the Lancerscape Portal. Quickly setup your account to
                get started.
              </h3>
            </div>
            <ProfileSetupSteper
              activeBasicDetails={true}
              activeProfileSetup={true}
            />
          </div>

          <div className="choose-profile-block corporate_both">
            <div className="profile-heading">
              <h6>Fill the details for profile(s) selected by you</h6>
            </div>
            <div className="profile-tab-wrapper corporate_both">
              <div className="tab-panel-block corporate_both">
                <Tabs
                  data-testid="tabs"
                  value={this.state.tab}
                  id="tabss"
                  centered
                  onChange={(event: any, value: boolean) =>
                    this.handleChange(event, value)
                  }
                >
                  <Tab label="Freelancer Profile (Corporate)" value={true} />
                  <Tab label="Sponsor" value={false} />
                </Tabs>
                <div hidden={!this.state.tab}>
                  <div className="form-block">
                    <CorporateForm
                      data-testid="corporateForm"
                      submitHandler={this.handleCorporateFormSubmitBoth}
                      company_logo={this.state.company_logo}
                      handleChangeCompanyLogo={this.setCompanyLogo}
                      cancelHandler={this.handleGoBack}
                      submitLoading={this.state.submitLoading}
                      countryList={this.state.countryDataList}
                      countryLoading={this.state.getCountryLoading}
                      getStateList={this.getStateDataList}
                      stateList={this.state.stateDataList}
                      stateLoading={this.state.getStateLoading}
                      skills={this.state.skillList}
                      categories={this.state.categoriesList}
                      getCityLoading={this.state.getCityLoading}
                      freelancingExperienceList={this.state.freelancingExperienceList}
                      cityList={this.state.cityDataList}
                      industryList={this.state.industryList}
                      getCityList={this.getCityDataList}
                    />
                  </div>
                </div>
                <div hidden={this.state.tab}>
                  <div className="form-block">
                    <SponsorForm
                      data-testid="sponsorForm"
                      cancelHandler={this.handleGoBack}
                      submitHandler={(values:any,{setErrors}:any)=>{
                        localStorage.setItem("sponsorForm",JSON.stringify(values))
                        this.checkSponsorProfile({values,setErrors},"corporateForm")
                     }}
                     submitLoading={this.state.submitLoading}
                     categories={this.state.categoriesList}
                     industryList={this.state.industryList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
