export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const postProjectIcon = require("../assets/post_project_icon.svg");
export const myProjectsIcon = require("../assets/my_project_icon.svg");
export const downloadIcon = require("../assets/download_icon.svg");
export const fileIcon = require("../assets/file_icon.svg");
export const deleteIcon = require("../assets/delete_Icon.svg");
export const emptyDraftIcon = require("../assets/empty_draft_icon.svg");
export const filterIcon = require("../assets/filter_icon.svg");
export const uploadIcon = require("../assets/upload.png");
