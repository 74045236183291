// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  Divider,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../assets/css/ResendLink.css";
import { logoImg, successImg,contact } from "./assets";

const theme = createTheme({
  palette: {},
  overrides: {
    MuiFormLabel: {
      root: {
        lineHeight: "none",
      },
    },
    MuiInputBase: {
      input: {
        padding: 0,
      },
    },
    MuiButton: {
      contained: {
        color: "#fff",
      },
    },
  },
});
const labelStyle = {
  color: "#8083A3",
};
import ActiveAccountController, {
  Props,
  configJSON,
} from "./ActiveAccountController.web";
import FullPageBackdropLoader from "../../../components/src/utill/FullPageBackdropLoader.web";

export default class ActiveAccount extends ActiveAccountController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {
      showBackdropLoader,
      showResetLinkPage,
      showSuccessPage,
      activateLoading,
      errors,
      activateLinkSendingLoading,
      successBody
    } = this.state;

    if (activateLoading && showBackdropLoader) {
      return <FullPageBackdropLoader open={showBackdropLoader} />;
    }
    return (
      <ThemeProvider theme={theme}>
        <Box
          justifyContent="end"
          display="flex"
          style={{
            display: "flex",
            marginTop: "6px",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <img style={{ height: "5vh" }} src={contact} alt="" />
          <a
            style={{
              color: "#8083A3",
              textDecoration: "none",
              marginTop: "5px",
              marginLeft: "5px",
              fontWeight: 700,
            }}
            href="#"
          >
            Help and Support
          </a>
        </Box>

        <Box sx={webStyle.ac_main_container}>
          {showResetLinkPage && (
            <>
              <Box data-testid="resetLinkContainer" py={4} style={webStyle.ac_logo_imgBox}>
                <img style={webStyle.logo_img} src={logoImg} alt="" />
              </Box>
              <Box
                width={450}
                component={Paper}
                mt={5}
                style={webStyle.main_box}
              >
                <Box py={3} style={webStyle.text_box1}>
                  <Typography style={{ fontWeight: "bold" }}>
                    Active Account?
                  </Typography>
                </Box>
                <Divider />
                <Box p={3}>
                  <Typography
                    gutterBottom
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      fontWeight: 500,
                    }}
                  >
                    {configJSON.forgetText}
                  </Typography>
                  <Typography
                    gutterBottom
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontFamily: "Inter",
                      fontWeight: 500,
                    }}
                  >
                    You will receive a link to active your account via email.
                  </Typography>
                  <TextField
                    autoFocus={true}
                    tabIndex={0}
                    data-test-id="txtInputEmail"
                    fullWidth
                    inputProps={webStyle.email_text}
                    type="email"
                    margin="normal"
                    id="email"
                    label="Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.changeEmail}
                    InputLabelProps={{ style: labelStyle }}
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                    data-testid="emailInput"
                  />
                  <Box display="flex" justifyContent="space-between" mt={4}>
                    <div tabIndex={3}>
                      <Button
                        style={webStyle.cancel_btn}
                        disableFocusRipple
                        variant="contained"
                        id="cancel"
                        data-test-id="btnonclick"
                        onClick={this.cancelActiveAccount}
                        data-testid="cancelButton"
                      >
                        <Typography
                          tabIndex={3}
                          variant="button"
                          style={webStyle.btn_text}
                        >
                          Cancel
                        </Typography>
                      </Button>
                    </div>
                    <div tabIndex={2}>
                      <Button
                        variant="contained"
                        data-test-id="btnresend"
                        id="two"
                        disableFocusRipple
                        className={"button resetLink-button"}
                        disabled={activateLinkSendingLoading}
                        onClick={this.handleValidate}
                        data-testid="submitBtn"
                      >
                        {activateLinkSendingLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Typography
                            tabIndex={2}
                            variant="button"
                            style={webStyle.btn_text}
                          >
                            Send Activation Link
                          </Typography>
                        )}
                      </Button>
                    </div>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {showSuccessPage && (
            <Box>
              <Box
                py={2}
                style={{
                  textAlign: "center",
                  display: "inline",
                }}
              >
                <img style={webStyle.logo_img} src={logoImg} alt="" />
              </Box>

              <Box
                width={450}
                component={Paper}
                mt={5}
                style={webStyle.main_box}
              >
                <Box textAlign="center">
                  <img src={successImg} alt="" />
                </Box>
                <Box  style={{ textAlign: "center" }}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {successBody?.message}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#8083A3",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                   {successBody?.desc}
                  </Typography>
                </Box>
                <Box py={3} style={{ textAlign: "center" }}>
                  <Button
                    data-testid="goToLoginBtn"
                    variant="contained"
                    id="gotologinbtn"
                    style={webStyle.ac_gotologin_btn}
                    onClick={this.cancelActiveAccount}
                  >
                    <Typography style={webStyle.ac_save_btn_text}>
                      {configJSON.gotoLogin}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    );
  }
}
const webStyle = {
  ac_gotologin_btn: {
    backgroundColor: "#EC952D",
    width: "338px",
    borderRadius: "23px",
    textTransform:"capitalize"
  } as const,
  ac_save_btn_text: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: "#FFFFFF",
    textTransform: "initial" as const,
  },
  ac_logo_imgBox: {
    display: "inline",
  },
  logo_img: {
    height: "35px",
    width: "145.15px",
    marginLeft: "156px",
  },
  main_box: {
    border: "1px solid #E4E6E8",
    borderRadius: "18px",
    padding: "10px 15px",
  },
  text_box1: {
    display: "flex",
    justifyContent: "center",
  },

  text_subtitle2: {
    fontSize: "14px",
    textAlign: "center",
  },
  email_text: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#000000",
  },
  checkbox_btn: {
    marginTop: "4px",
  },
  checkbox_box: {
    backgroundColor: "F2F2F2",
    border: "1px solid #E4E6E8",
  },
  checkbox_text: {
    marginTop: "11px",
  },
  cancel_btn: {
    borderRadius: "19px",
    width: "180px",
    height: "43px",
    overflow: "hidden",
  },
  btn_text: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#FFFFFF",
    textTransform: "capitalize" as const,
    overflow: "hidden",
  },
  email_validation_error: {
    fontSize: "0.75rem",
    color: "#f44336",
    marginBottom: 27,
  },

  ac_main_container: {
    alignSelf: "center",
  },
};
// Customizable Area End
