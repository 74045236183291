
import React from "react";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Input,
  ListItemText,
  Checkbox,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface Props {
  label: string;
  values: string[];
  onChange: any;
  selectedOptions:any[];
}

const MultiSelect: React.FC<Props> = ({ label, values, onChange,selectedOptions }) => {
  return (
    <>
      <InputLabel style={{ marginBottom: "0px" }}>{label}</InputLabel>
      <FormControl style={{ display: "block" }}>
        <Select
          fullWidth
          displayEmpty
          multiple={true}
          value={selectedOptions}
          onChange={(selectedOptions) => onChange(selectedOptions)}
          input={<Input />}
          IconComponent={() => (
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "8px",
                transform: "translateY(-50%)",
                zIndex: -1,
                color:"#8083A3"
              }}
            >
              <ExpandMoreIcon />
            </div>
          )}
          renderValue={(selected) => {
            if ((selected as string[]).length === 0) {
              return <Typography style={{fontWeight:"bold",fontSize:"14px"}}>Select {label}</Typography>;
            }
            const selectedData = Array.isArray(selected)
              ? (selected as string[])
              : [selected as string];
            return Array.isArray(selectedData)
              ? selectedData.join(", ")
              : selectedData;
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          {values?.map((option) => (
            <MenuItem key={option} value={option}>
                  <Checkbox
                    checked={selectedOptions.includes(option)}
                  />
                  <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MultiSelect;
