import React from "react";
import {
    Container,
    Box,
    Typography,
    Divider
} from "@material-ui/core";
// Customizable Area Start
import {
    chatIcon,
    sponserProfile
} from "./assets";
// Customizable Area End

import ProjectInProgressLancerController, {
    Props, configJSON,
} from "./ProjectInProgressLancerController";


export default class ProjectInProgressLancer extends ProjectInProgressLancerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Container maxWidth="lg">
                    {this.state.projectInProgressCards.map((card: any, index: number) => {
                        return (
                            <Box style={webStyle.projectDescriptionBox}>
                                <Box>
                                    <Typography style={webStyle.cardProjectName}>{ }Logo Design for an Insurance Company</Typography>
                                </Box>
                                <Box style={webStyle.projectSubInfo}>
                                    <Typography style={webStyle.projectSubInfoType}>{ }Fixed Price</Typography>
                                    <Typography style={webStyle.projectSubInfoType}>{ }Est. Time: 1 to 2 Months</Typography>
                                    <Typography style={webStyle.projectSubInfoType}>{ }Posted 9h ago</Typography>
                                </Box>
                                <Divider />
                                <Box style={webStyle.sponserBox}>
                                    <Box>
                                        <Typography variant="body2" >{ }Sponsor</Typography>
                                        <Box style={webStyle.sponserSubBox}>
                                            <img style={webStyle.sponserImage} src={sponserProfile} />
                                            <Typography style={webStyle.sponserName}>{ }Amin Mohamed M.</Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <button style={webStyle.messageWithIcon}>
                                            <img src={chatIcon} />
                                            <Typography style={webStyle.messageText}>{ }10</Typography>
                                        </button>
                                    </Box>
                                </Box>
                                <Divider />

                                <Box style={webStyle.showSimilarContainers}>
                                    <Box style={webStyle.category}>
                                        {card?.attributes?.skills.map((skill: any) => (
                                            <Box style={webStyle.categoryBox}>{skill.name}</Box>
                                        ))}
                                    </Box>
                                    <Box style={webStyle.projectDescriptionText}>
                                        <Typography style={webStyle.projectDescriptionTextDesign}>{ }We are starting an insurance company and have named it Fourth Island Insurance because we are based out of Kauai, Hawaii and the island of Kauai is considered&</Typography>
                                    </Box>
                                    <Box style={webStyle.projectDetails}>
                                        <Box >
                                            <Typography style={webStyle.projectSubDetails}>{configJSON.total}Total Budget</Typography>
                                            <Typography style={webStyle.projectSub_subDetails}>{ }$250</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={webStyle.projectSubDetails}>Total Amount Paid</Typography>
                                            <Typography style={webStyle.projectSub_subDetails}>{ }$70</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={webStyle.projectSubDetails}>Total Amount in Escrow</Typography>
                                            <Typography style={webStyle.projectSub_subDetails}>{ }$180</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={webStyle.projectSubDetails}>No. of Milestone Completed</Typography>
                                            <Typography style={webStyle.projectSub_subDetails}>{ }1/2</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={webStyle.projectSubDetails}>Experience</Typography>
                                            <Typography style={webStyle.projectSub_subDetails}>{ }Intermediate</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.projectDetailss}>
                                        <Typography style={webStyle.projectSubDetails}>Language</Typography>
                                        <Typography style={webStyle.projectSub_subDetails}>{ }English, Native</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    }
                    )}

                </Container>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    root: {
        display: "flex",
        justifyContent: "space-evenly"
    },
    messageText: {
        color: "black"
    },
    sponserName: {
        fontSize: "16px",
        color: "#000"
    },
    sponserImage: {
        height: "30px",
        margin: "4px 8px 0px 0px"
    },
    sponserSubBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    sponserBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "14px 26px"
    },
    messageWithIcon: {
        border: "none",
        backgroundColor: "#F2F2F2",
        cursor: "pointer",
        color: "white",
        borderRadius: "23px",
        padding: "14px 20px",
        height: "46px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    } as const,
    showSimilarContainers: {
        alignItems: "center",
    },
    projectSub_subDetails: {
        color: '#000',
        fontSize: '16px',
        fontWeight: 600
    },
    projectSubDetails: {
        fontSize: '12px',
        color: '8083A3'
    },
    projectDescriptionTextDesign: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#000'
    },
    projectSubInfoType: {
        color: '#8083A3',
        fontSize: '12px',
    },
    projectDescriptionBox: {
        width: "915px",
        borderRadius: '15px',
        border: '1px solid rgb(228, 230, 232)',
        margin: '24px 0px 24px -21px',
    },
    cardProjectName: {
        fontSize: '18px',
        padding: "24px 26px 8px 26px",
        fontWeight: 600,
        color: "#000000",
    },
    projectSubInfo: {
        display: 'flex',
        padding: "0px 26px 18px 26px",
        alignItems: 'center',
        gap: '12px',
    },
    category: {
        display: 'flex',
        padding: "17px 26px 0px 26px",
        justifyContent: ' space-between',
        flexFlow: 'wrap',
        maxWidth: '600px',
        alignItems: 'center',
        gap: ' 10px',
    },
    categoryBox: {
        borderRadius: '20px',
        background: '#E4E6E8',
        padding: '10px 20px',
        color: '#8083A3',
    },
    projectDescriptionText: {
        padding: "14px 26px"
    },
    projectDetailss: {
        padding: "0px 26px 18px 26px"
    },
    projectDetails: {
        alignItems: 'center',
        justifyContent: ' space-between',
        padding: "3px 26px 18px 26px",
        display: 'flex',
    },
}
// Customizable Area End
