Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpDeleteMethod = "DELETE";
exports.httpPostMethod = "POST";
exports.favouritesApiApiContentType = "application/json";
exports.favouritesApiEndPoint = "favourites/favourites";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorType = "Please enter a type";
exports.configErrorId = "Please enter a valid id";
exports.headerFavouriteText = "Favourites";
exports.popUpTextMessage = "Look up to the Freelancer that you have saved";
exports.searchInputPlaceHolder = "Search Freelancer"
exports.sortAndistoText = "Sort:";
exports.filterText = "Filter";
exports.popUpTextMessagelancer = "Look up to the Projects that you have saved";
exports.favouritesSponsorEndPoint = "bx_block_favourites/favourites/get_favourites_for_sponsor"
exports.favouritesLancerEndPoint ="bx_block_profile/projects/get_lancers_projects"
exports.addAndRemoveFavouritesAPIEndpoint ="bx_block_favourites/favourites"
exports.RemoveFavouritesAPIEndpoint ="bx_block_favourites/favourites/dislike_multiple_profiles"
// Customizable Area End