import React from "react";
import { Box, Typography } from "@material-ui/core";
import { LinkedInImg } from "../../../blocks/social-media-account-registration/src/assets";
import { LinkedIn } from "../linkedin-components/LinkedIn.web";
import CircularProgress from "@material-ui/core/CircularProgress";
interface Props {
  buttonText?: string;
  submitHandler: any;
  errorHandler: any;
  loading:boolean;
}

const LinkedinSignin: React.FC<Props> = ({
  buttonText = "Sign up with Linkedin",
  submitHandler,
  errorHandler,
  loading
}) => {
  return (
    <LinkedIn
      clientId="77lhuxznlm3psp"
      redirectUri={`${window.location.origin}/linkedin`}
      onSuccess={submitHandler}
      onError={errorHandler}
      scope="r_liteprofile r_emailaddress"
    >
      {({ linkedInLogin }) => (
        <Box
          onClick={linkedInLogin}
          py={1}
          display="flex"
          style={webStyle.facebookLinkedin_subbox}
        >
          <img style={webStyle.linkedin_img} src={LinkedInImg} alt="" />
          <Typography
            style={{
              fontFamily: "inter",
              color: "#8083A3",
              marginTop: "7px",
              fontWeight: "bold",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {buttonText}
          </Typography>
          {
            loading && (
              <div style={webStyle.loaderContainer}>
                <CircularProgress size={24} />
              </div>
            )
          }
         
        </Box>
      )}
    </LinkedIn>
  );
};

export default LinkedinSignin;

export const webStyle = {
  facebookLinkedin_subbox: {
    height: "38px",
    borderRadius: "23px",
    border: "1px solid #E4E6E8",
    padding: "6px",
    justifyContent: "center",
    position:"relative"
  } as const,

  linkedin_img: {
    left: "26px" as const,
    top: "14px" as const,
    height: "14px",
    paddingTop: "4px",
    position: "absolute" as const,
  },
  loaderContainer:{ position: "absolute", top: "14px", right: "23px" } as const
};
