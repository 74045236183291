import React from "react";
import {
    // Customizable Area Start
    Grid,
    Typography,
    WithStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {withStyles} from "@material-ui/core/styles";
// Customizable Area End

export interface Props extends WithStyles{

    // Customizable Area Start
    iconImg:string;
    formTitle:string;
    formTxt:string;
    // Customizable Area End
  }
  
interface S {
    // Customizable Area Start

   // Customizable Area End
  }

export class VerificationMsgForm extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
 
    };
  }

   render() {
    const{classes, iconImg,formTitle,formTxt}=this.props;

    return  <>
            <img src={iconImg} className={classes.imgStyle} alt='image' />
            <Typography align="center" className={classes.verificationSuccessTitle}>{formTitle}</Typography> 
            <Typography align="center" className={classes.verificationSuccessTxt}>{formTxt}</Typography> 
            </>
   
  
  }
}
 
// Customizable Area Start
export const styles = () => ({

    MainBox:{ 
      display:'flex',
     justifyContent:'center',
      alignItems:'center',
      width:'100%'
  },
    ContentGrid:{ 
        display:'flex',
       justifyContent:'center',
        alignItems:'center',
        height:'80vh',
        flexDirection:'column' as const,
    },
  
    withdrawalProceedBtn:{
      padding:'0 24px',
      margin:"20px 0 0 0",
       backgroundColor: '#ec952d',
      fontSize:'14px',
      fontWeight: 700,
      minWidth: '100px',
      height: '46px',
      color: '#fff',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      borderRadius: '20px',
      textTransform:'none' as const,
      '&:hover': {
        backgroundColor: '#ec952d',
      },
    },
  
  
  
    imgStyle:{
      width: '100px',
      height: '100px',
      borderRadius:'80px'
    },
    verificationSuccessTitle:{
      color: ' #222',
      fontSize: '30px',
      fontWeight: 700,
    },
    verificationSuccessTxt:{
      color: ' #8083a3',
      fontSize: '16px',
      fontWeight: 500,
      padding:"20px 0"
    },
   
    })

    
    export default withStyles(styles)(VerificationMsgForm);
  // Customizable Area End