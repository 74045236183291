import React, { Component } from 'react'
import { Box } from '@material-ui/core'
import {image_close} from "./assets";
import "./MyBid.css";

import PaymentResponseController,{Props} from "./AuctionBiddingPaymentController.web";
import FullPageBackdropLoader from "../../../components/src/utill/FullPageBackdropLoader.web";

import Sponserheader  from "../../dashboard/src/Sponserheader.web";
export default class OfferSendFailed extends PaymentResponseController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
  render() {
     // Customizable Area Start
     const {loader}=this.state;
      // Customizable Area End
    return (
      <div>
         <Sponserheader navigation={this.props.navigation} id={""} route={"projects"} />
          
        {
            loader ? <FullPageBackdropLoader open={loader}/> : (
              <Box className="offer_Send_success_container" style={{gap:"10px"}}>
                <img src={image_close} alt="accept" className="accept_bid1" />
                <h1>Payment Failed</h1>

              <Box>
                <button onClick={this.onTryAgain} className="btn-continue fixed_width">Try Again</button>
              </Box>
            </Box>
            )
        }
     

      </div>
      
    )
  }
}
