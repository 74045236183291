import React from "react";

// Customizable Area Start
import { Tab, Tabs } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "../../blocks/dashboard/assets/css/Search.css";
interface ITab {
  activeTabColor: string;
  tabLabels: string[];
  activeTab?: number;
  className: string;
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}
const GenericTab = ({
  className,
  activeTabColor,
  tabLabels,
  activeTab,
  handleTabChange,
}: ITab) => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)')
  return (
    <Tabs
      value={activeTab}
      className="tabscroll"
      indicatorColor="primary"
      scrollButtons="on"
      onChange={handleTabChange}
      TabIndicatorProps={{
        style: { display: "none" }, // Hide the tab indicator
      }}
    >
      {[...Array(tabLabels.length)].map((_, index) => (
        <Tab
          key={index}
          label={tabLabels[index]}
          style={{
            borderRadius: "30px",
            textTransform: "none",
            fontWeight: "bold",
            padding: isSmallScreen ? "6px 15px" : "12px 30px",
            width: "auto",
            fontSize: isSmallScreen ? "11px": "14px",
            backgroundColor: activeTab === index ? activeTabColor : undefined,
            color: activeTab === index ? "#FFF" : undefined,
          }}
        >
          {tabLabels[index]}
        </Tab>
      ))}
    </Tabs>
  );
};

export default GenericTab;
