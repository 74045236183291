// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { Message } from "../../../framework/src/Message";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import * as Yup from 'yup';
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}
interface S {
  activateLoading: boolean;
  activateLinkSendingLoading: boolean;
  email: string;
  showSuccessPage: boolean;
  showBackdropLoader: boolean;
  showResetLinkPage: boolean;
  errors:any;
  successBody:{
    message:string;
    desc:string;
  }
}

interface SS {}

export default class ActiveAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  activeAccountApiCallId: string = "";
  resendActivationiLinkApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ]);

    this.state = {
      activateLoading: false,
      email: "",
      activateLinkSendingLoading: false,
      showSuccessPage: false,
      showBackdropLoader: false,
      showResetLinkPage: true,
      errors:{},
      successBody:{
        message:"",
        desc:""
      }
    };
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.activeAccountApiCallId) {
        this.handleActiveAccountPayload(responseJson);
      }else if (apiRequestCallId === this.resendActivationiLinkApiCallId) {
        this.handleResendLinkPayload(responseJson);
      }
    }
  }
  handleResendLinkPayload=(responseJson: any)=>{
    this.setState({activateLinkSendingLoading:false})
    if(responseJson?.message){
      this.setState({
        showBackdropLoader:false,
        showSuccessPage:true,
        showResetLinkPage:false,
        successBody:{
         message:responseJson?.message,
         desc:""
        }
     })
    }else if(responseJson?.errors && responseJson?.errors?.length>0){
      const message=responseJson?.errors[0][Object.keys(responseJson?.errors[0])[0]] || "Activation link send failed"
       toast.error(message);
    }
   
  }

  handleActiveAccountPayload = (responseJson: any) => {
    this.setState({ activateLoading: false });

    if(responseJson?.errors && responseJson?.errors?.length>0){
      const message=responseJson?.errors[0][Object.keys(responseJson?.errors[0])[0]] || "Account Activate Failed"
       toast.error(message);
       this.setState({
        showBackdropLoader:false,
        showSuccessPage:false,
        showResetLinkPage:true
       })
       return;
    }
    this.setState({
       showBackdropLoader:false,
       showSuccessPage:true,
       showResetLinkPage:false,
       successBody:{
        message:responseJson?.meta?.message,
        desc:""
       }
    })

  };

  async componentDidMount() {
    this.activeAccount();
  }

  changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value });
  };
  cancelActiveAccount = () => {
    this.props.navigation.navigate("SignIn");
  };

 
handleValidate=()=>{
  const {email}=this.state;
  let userSchema = Yup.object({
    email:Yup.string().required("Please enter your email").email("Please enter valid email")
  });
  userSchema.validate({
    email:email
 }, { abortEarly: false }).then((value)=>{
      this.setState({errors:{}})
      this.resendActivateLink();
 }).catch((err)=>{
   let newError:any={};
   err.inner?.forEach((item:any)=>{
        newError[item.path]=item.message
   })
   this.setState({errors:newError})
 })


}

  activeAccount = () => {
    this.setState({
      activateLoading: true,
      showBackdropLoader:true,
    });
    const header = {
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.activeAccountApiUrl}?token=${this.getToken()}`,
      body: null,
      method: "GET",
    });
    this.activeAccountApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };

  resendActivateLink = () => {
    this.setState({
      activateLinkSendingLoading: true,
    });
    const header = {
      "Content-Type": "application/json",
    };
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `activate?email=${this.state.email}`,
      body: null,
      method: "GET",
    });
    this.resendActivationiLinkApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  };

  getToken = () => {
    return this.props.navigation.getParam("token");
  };
}

// Customizable Area End
