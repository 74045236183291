import React from "react";
import { Box, Typography } from "@material-ui/core";
import {googleImg} from '../../../blocks/social-media-account-login/src/assets'
import CircularProgress from "@material-ui/core/CircularProgress";
import { useGoogleLogin } from "@react-oauth/google";
import {webStyle} from './LinkedinSignin.web'
interface Props {
  buttonText?: string;
  submitHandler: any;
  errorHandler: any;
  loading:boolean;
}

const GoogleSignin: React.FC<Props> = ({
  buttonText = "Sign up with Google",
  submitHandler,
  errorHandler,
  loading
}) => {

    const login = useGoogleLogin({
        onSuccess: submitHandler,
        onError: errorHandler,
      });


  return (
        <Box
          onClick={()=>login()}
          py={1}
          display="flex"
          style={webStyle.facebookLinkedin_subbox}
        >
          <img style={webStyle.linkedin_img} src={googleImg} alt="" />
          <Typography
            style={{
              fontFamily: "inter",
              color: "#8083A3",
              marginTop: "7px",
              fontWeight: "bold",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {buttonText}
          </Typography>
          {
            loading && (
              <div style={webStyle.loaderContainer}>
                <CircularProgress size={24} />
              </div>
            )
          }
         
        </Box>
  );
};

export default GoogleSignin;