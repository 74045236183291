import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { PROJECT_PER_PAGE } from "../../dashboard/src/freelancer-projects/FreelancerBrowseProjectController.web";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {

  navigation?: any;
  history?: any;
  location?: any;
  classes?: any;
  route?: any;
  id?: any;
}

export interface S {
  // Customizable Area Start

  queryString: string;
  openFilter: boolean;
  totalProjects: number;
  searchText: string;
  currentPage: number;
  perPage: number;
  currentUserProfile: any;
  proposalOptions: any[];
  favLancerCards: any[];
  isLoading: boolean;
  freelancerId: number,
  showModal: boolean,
  option: any,
  selectedOption: any;
  selectedproject: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class FavouritesFreelancerController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getFavApiCallId: string = "";
  removeFavouritesAPICallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

    this.state = {
      // Customizable Area Start
      queryString: "",
      openFilter: false,
      totalProjects: 0,
      searchText: "",
      currentPage: 1,
      perPage: 5,
      proposalOptions: ['Latest', 'Oldest'],
      favLancerCards: [],
      currentUserProfile: "",
      isLoading: false,
      freelancerId: 0,
      showModal: false,
      option: [],
      selectedOption: [],
      selectedproject: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
    this.sponsorPrepareFinalQuery();
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const userProfile = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      this.setState({ currentUserProfile: userProfile });
    }
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getFavApiCallId) {
        this.handleGetAllFavLancersPayload(responseJson)
      }
      if (apiRequestCallId === this.removeFavouritesAPICallId) {
        const updatedFavouriteFreelancers = this.state.favLancerCards.filter((freelancer) => {
          return freelancer.attributes.lancer.id !== this.state.freelancerId
        })
        this.setState(prev=>({ favLancerCards: updatedFavouriteFreelancers,totalProjects:prev.totalProjects-1 }))
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleGetAllFavLancersPayload = (responseJson: any) => {
    if (responseJson && responseJson?.favourites?.data) {
      this.setState((prev) => {
        let newProjectList: any[] = [];
        if (prev.currentPage === 1) {
          newProjectList = responseJson?.favourites?.data;
        } else {
          newProjectList = [...prev.favLancerCards, ...responseJson.favourites.data];
        }
        return {
          favLancerCards: newProjectList, isLoading: false,
          totalProjects: responseJson?.pagination?.total_data || 0,
        }
      });
    }
    else {
      this.setState({ isLoading: false, favLancerCards: [], totalProjects: 0 });
    }
  };

  handleSendMessage=(freelancerId:string, projectid:string)=>{
    const inviteChatMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    inviteChatMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Chat"
    );
    inviteChatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
     
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      createConversation:true,
      userId:String(freelancerId),
      projectId:String(projectid),
      userType:"freelancer"
    });
    inviteChatMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(inviteChatMessage);
   }

  //wev events start
  handlesearch = (value: string) => {
    this.setState({ searchText: value }, () => {
      this.sponsorPrepareFinalQuery();
    })
    if (value.length == 0) {
      this.setState({ searchText: "" }, () => {
        this.sponsorPrepareFinalQuery();
      })
    }
  }
  handleProjectLoadMore = () => {
    const { isLoading } = this.state;
    if (!isLoading) {
      this.setState(
        (prev) => ({ currentPage: prev.currentPage + 1 }),
        () => {
          this.sponsorPrepareFinalQuery();
        }
      );
    }
  };
  spocorSearchHandler = (searchText: string) => {
    this.setState({ searchText: searchText }, () => {
      this.sponsorPrepareFinalQuery();
    });
  };
  spocorFilterHandler = (query: string, selectedOptions: any) => {
    this.setState({ currentPage: 1, queryString: query }, () => {
      this.sponsorPrepareFinalQuery();
    });
  };
  spocorCancelFilterHandler = () => {
    this.setState({ currentPage: 1, queryString: "" }, () => {
      this.sponsorPrepareFinalQuery();
    });
  };

  navigateToProfilepage = (id: string) => {
    this.props?.navigation?.navigate("LancerPublicProfile", { profileId: id })
  }

  sponsorPrepareFinalQuery = () => {
    let sponsorinalQuery: string[] = [];
    const { searchText, queryString, currentPage, perPage } = this.state;
    sponsorinalQuery.push(`current_page=${currentPage}`);
    sponsorinalQuery.push(`per_page=${perPage}`);

    if (searchText) {
      sponsorinalQuery.push(`search=${searchText}`);
    }
    if (queryString) {
      sponsorinalQuery.push(queryString);
    }
    const newQueryText = sponsorinalQuery.join("&");
    this.getFav(newQueryText);
  };
  getFav = (query: string) => {
    this.setState({
      isLoading: true,
    });
    const token = localStorage.getItem("loginSuccessToken");
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFavApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.favouritesSponsorEndPoint}?${query}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleRemoveFavourites = async (freelancerId: number) => {
    this.setState({ freelancerId: freelancerId }, () => this.removeFavourites())
  }

  handleShowModal = (selectedFavItem?: any) => {
    if (selectedFavItem?.attributes?.projects_with_bids?.length > 0) {
      this.setState({ showModal: true, option: selectedFavItem })
    }
  }

  handleCloseModal = () => {
    this.setState({ showModal: false, selectedOption: "", selectedproject: [] })
  }

  handleRadioChange = (e: any, data: any) => {
    if (data.length > 0) {
      let selectedproject = data.filter((item: any) => { return item.project_id == e.target.value })
      this.setState({ selectedOption: e.target.value, selectedproject: selectedproject })
    }
  }

  handleHireLancer = () => {
    console.log(this.state?.option,"this.state?.option",this.state.selectedproject)
    if (this.state.selectedproject[0].bid_id !== null) {
      this.props.navigation.navigate("PraposalDetails", { id: this.state.selectedproject[0].bid_id });
    }
    else {
      this.props.navigation.history.push(`/freelancers/${this.state.selectedproject[0].project_id}?name=${this.state?.option?.attributes?.lancer.full_name}`)
    }
  }

  removeFavourites = async () => {
    const token = localStorage.getItem("loginSuccessToken");
    
    let formdata = new FormData();
    formdata.append("lancer_id", String(this.state.freelancerId));

    const header = {
      token: token,
    };
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.RemoveFavouritesAPIEndpoint}`,
      body: formdata,
      method: configJSON.httpPostMethod
    })

    this.removeFavouritesAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}