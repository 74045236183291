import React, { useState } from 'react';
import {Dialog,DialogTitle,DialogContent,DialogActions,Button,TextField,makeStyles} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
  textField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'gray',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiFormLabel-root': {
      color: 'gray',
      '&.Mui-focused': {
        color: 'black',
      },
    },
  },
}));
interface Props{
   open:boolean;
   setOpen:(open:boolean)=>void;
   createUniversityLoading:boolean;
   onCreateUniversity:(name:string)=>void;
}
const CreateUniversityModal=({
  open,
  setOpen,
  createUniversityLoading,
  onCreateUniversity
}:Props)=>{
  let color="#EB9E00";
  const [universityName,setUniverisytName]=useState("");
  const [error,setError]=useState("");
  const classes = useStyles()

  const handleSubmit=()=>{
     if(!universityName.trim()){
      setError("Please enter university name");
      return;
     }
     setError("");
     onCreateUniversity(universityName)
     setUniverisytName("");
  }
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{ style: { borderRadius: 16 } }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle
          id="max-width-dialog-title"
          style={{ paddingBottom: 0 }}
        >
          <strong>Create New University</strong>
        </DialogTitle>
        <DialogContent>
          <TextField  
              label="University/College"
              variant="outlined" 
              className={classes.textField}
              value={universityName}
              onChange={(e)=>setUniverisytName(e.target.value)}
              error={Boolean(error)}
              helperText={error}
              fullWidth
              size='small'
              />
        </DialogContent>
        <DialogActions style={{ margin: "15px" }}>
              <Button
              variant="contained"
              disableElevation
              style={{
                borderRadius: "9px",
                height: "40px",
                width: "100%",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
              onClick={() => setOpen(false)}
            >
             Cancel
            </Button>
          <Button
            onClick={handleSubmit}
            disabled={createUniversityLoading}
            variant="contained"
            disableElevation
            style={{
              borderRadius: "9px",
              height: "40px",
              width: "100%",
              background: color,
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            {
              createUniversityLoading?<CircularProgress size={20}/>:"Create"
            }
         </Button>
        </DialogActions>
      </Dialog>
    );
}

export default CreateUniversityModal;