Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.exampleApiContentType = "application/json";
exports.MemberListEndpoint = "/bx_block_fullteamvisibility/members_list"
exports.TeamListEndpoint = "/bx_block_fullteamvisibility/teams";
exports.DepartmentListEndpoint ="/bx_block_fullteamvisibility/departments"
exports.OrgDataEndpoint = "/bx_block_fullteamvisibility/organizations";
exports.GET_Method = "GET";

// Customizable Area End