import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles} from '@material-ui/core';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { IProjectCost } from "./PersonalDetailsController.web";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import {toast} from 'react-toastify'
import { IProfile } from "../../rolesandpermissions/src/Roles.types";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");


export interface Props extends WithStyles{
  navigation:any;
  id:string;
}


export interface S {
  // Customizable Area Start
  auctionBidData:any;
  escrowAmount:"first_milestone" | "full_project" | "";
  getProjectCostLoading:boolean;
  projectCost:IProjectCost|null;
  payProjectLoading:boolean;
  currentUser:IProfile|null;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class PrjSummaryController extends BlockComponent<
  Props, S, SS
> {

    // Customizable Area Start
 
    AuctionBiddingBidGetCallID: string = "";
    getProjectCostApiCallId:string="";
    payProjectApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

        this.state = {
            // Customizable Area Start
            auctionBidData:null,
            escrowAmount:"",
            getProjectCostLoading:false,
            projectCost:null,
            payProjectLoading:false,
            currentUser:null
          // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    this.sendGetCurrentUserMessage();
    this.setState({escrowAmount:  await getStorageData('payLabel')})
    this.handleAuctionBiddingGetApi();
    this.fetchProjectCost();
     // Customizable Area End
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
        if (message.id === getName(MessageEnum.LoginUserMessage)) {
          const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
          this.handleCurrentUserResponse(user);
        }
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );

          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          this.handleApiRequest(apiRequestCallId,responseJson)
        
        }
    // Customizable Area End
  }
  
  // Customizable Area Start
  handleCurrentUserResponse=(user:any)=>{
    if(user?.id){
      this.setState({ currentUser: user });
    }
  }
  handleApiRequest=(apiRequestCallId:string,responseJson:any)=>{
      if(apiRequestCallId===this.AuctionBiddingBidGetCallID){
          this.handleBidResponse(responseJson);
      }else if(apiRequestCallId===this.getProjectCostApiCallId){
        this.handleProjectCostApiResponse(responseJson);
      }else if(apiRequestCallId===this.payProjectApiCallId){
        this.handlePayProjectApiResponse(responseJson);
      }
  }

  handlePayProjectApiResponse=async(responseJson:any)=>{
      this.setState({
        payProjectLoading:false
      })
      
      if(responseJson?.message){
        toast.error(responseJson.message);
        return;
      }
      
     if(responseJson?.payment_link){
        await setStorageData("bidId",this.props?.navigation?.getParam("navigationBarTitleText"))
        const link = document.createElement('a');
        link.href = responseJson?.payment_link;
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
     }else{
      toast.error("Something wen't wrong, Please try again.")
     }

  } 
  handleProjectCostApiResponse=(responseJson:any)=>{
      this.setState({
        getProjectCostLoading:false
      })
      if(responseJson?.response){
        this.setState({projectCost:responseJson?.response})
     }

  }

  handleBidResponse=(apiResponse:any)=>{
    if (apiResponse?.bid?.data) {
      this.setState({auctionBidData:apiResponse.bid?.data})
    }else{
      toast.error("Bid not found!!")
    }
  }

  handleGoBackButton = () => window.history.back();

  handleAuctionBiddingGetApi = async() => {
    const token =   await getStorageData('loginSuccessToken', false)
    const header = {
      token: token,
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AuctionBiddingBidGetCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AuctionBiddingBidsGetApiEndPoint}/${this.props.navigation.getParam("navigationBarTitleText")}?calculate=true`
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAuctionApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token
    };
    return header;
  }

  fetchProjectCost=async()=>{
      this.setState({
        getProjectCostLoading:true,
      })

      const header=await this.getHeader();
      const apiMessage=CreateRestApiMessage({
          apiUrl: `bx_block_profile/projects/get_project_costs_via_bid_id?bid_id=${this.props?.navigation?.getParam("navigationBarTitleText")}`,
          method:"GET",
          body:null,
          header:header,
      })
      this.getProjectCostApiCallId = apiMessage.messageId;
      runEngine.sendMessage(apiMessage.id, apiMessage);
  }


  sendGetCurrentUserMessage=()=>{
    const currentUserMessage: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(currentUserMessage);
  }
  
  handlePayProject=async()=>{
    this.setState({
      payProjectLoading:true,
    })
    const bidId=this.props?.navigation?.getParam("navigationBarTitleText");
    const totalProjectPaid=this.state.escrowAmount==="full_project";
    const header=await this.getHeader();
    const apiMessage=CreateRestApiMessage({
        apiUrl: `${configJSON.paymentProjectApiCallId}?bid_id=${bidId}&total_project_paid=${totalProjectPaid}`,
        method:"POST",
        body:null,
        header:header,
    })
    this.payProjectApiCallId = apiMessage.messageId;
    runEngine.sendMessage(apiMessage.id, apiMessage);
  }

 // Customizable Area End
}

