import React from "react";

import {
  // Customizable Area Start
  Grid,
  Box,
  Typography,
  FormHelperText,
  FormControl,
  TextField,
  Button,
  Divider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import {
  Add as AddCircleOutlineIcon,
} from "@material-ui/icons";
const StyledTimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
})(TimelineItem);
// Customizable Area End

import CreateNewMilestoneController, {
  Props,
  configJSON,
} from "./CreateNewMilestoneController";
import { calender, wallet } from "./assets";

export default class CreateNewMileStone extends CreateNewMilestoneController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start

      <Box className={classes.topContainer}>
        <Timeline>
          {this.state.mileStonetemplates && this.state.mileStonetemplates.map((milestone: any) => {
            return (
              <StyledTimelineItem key={milestone.id}>
                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDotStyle}>
                    {milestone.id}
                  </TimelineDot>
                  <TimelineConnector
                    className={classes.timelineConectorStyle}
                  />
                </TimelineSeparator>
                <TimelineContent>
                  <Box className={classes.displayBox}>
                    <Typography className={classes.timelineContentHeader}>
                      {milestone.attributes.milestone_title}
                    </Typography>
                  </Box>
                  <Grid
                    container
                    className={classes.displayGrid}
                    id="milestone"
                  >
                    <Typography className={classes.timelineContentDesc}>
                      {milestone.attributes.milestone_description}
                    </Typography>
                    <Divider className={classes.divider} />
                    <Box className={classes.displayVerifyBox}>
                      <img
                        className={classes.milestoneGridImage}
                        src={calender}
                      />
                      <Typography
                        className={`${classes.dueDateStyle} ${classes.timelineContentDesc}`}
                      >
                        {milestone.attributes.days} {configJSON.daysLabelText}
                      </Typography>
                      <img
                        className={classes.milestoneGridImage}
                        src={wallet}
                      />
                      <Typography
                        className={`${classes.paymentStyle} ${classes.timelineContentDesc1}`}
                      >
                        {milestone.attributes.cost}
                      </Typography>
                      <Button
                        variant="contained"
                        disableElevation
                        disableRipple
                        className={classes.timelineContentVerifyBtn1}
                      >
                        {configJSON.labelVerify}
                      </Button>
                    </Box>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Box
                    className={`${classes.sendContainerStyle} ${classes.btnContainerStyle}`}
                  >
                    <Button className={classes.onHoldBtnStyle}>
                      {configJSON.deleteBtnTxt}
                    </Button>
                    <Button className={classes.closeBtnSyles} data-testid = 'editbutton' onClick = {() => this.editMilestone(milestone)}>
                      {configJSON.editBtnTxt}
                    </Button>
                  </Box>
                </TimelineContent>
              </StyledTimelineItem>
            );
          })}
          <StyledTimelineItem>
            <TimelineSeparator>
              <TimelineDot
                variant="outlined"
                className={classes.addNewMilestoneBtn}
              >
                <AddCircleOutlineIcon />
              </TimelineDot>
              {this.state.isAddnewMilestoneBtnClicked && (
                <TimelineConnector className={classes.timelineConectorStyles} />
              )}
            </TimelineSeparator>
            <TimelineContent>
              <Box className={classes.addNewMilestoneFieldsContainer}>
                {this.state.isAddnewMilestoneBtnClicked ? (
                  <>
                    <FormControl fullWidth className={classes.formControlStyle}>
                      <FormHelperText
                        className={classes.labelStyle}
                        component="p"
                      >
                        {configJSON.milestoneTitleLabelTxt}
                      </FormHelperText>
                      <TextField
                        name="addNewMilestoneTitle"
                        onChange={(event) => this.handleOnChange(event)}
                        value={this.state.addNewMilestoneTitle}
                        placeholder={configJSON.titlePlaceholder}
                        InputProps={{
                          classes: {
                            input: classes.placeholderStyle,
                            root: classes.inputValueStyle,
                          },
                        }}
                        data-testid="addNewMilestoneTitle"
                      />
                    </FormControl>
                    <FormControl fullWidth className={classes.formControlStyle}>
                      <FormHelperText
                        className={classes.labelStyle}
                        component="p"
                      >
                        {configJSON.milestoneDescriptionLabelTxt}
                      </FormHelperText>
                      <TextField
                        minRows={3}
                        multiline
                        name="addNewMilestoneDescription"
                        onChange={(event) => this.handleOnChange(event)}
                        value={this.state.addNewMilestoneDescription}
                        placeholder={configJSON.descriptionPlaceholder}
                        InputProps={{
                          classes: {
                            input: classes.placeholderStyle,
                            root: classes.inputValueStyle,
                          },
                        }}
                        data-testid="addNewMilestoneDescription"
                      />
                    </FormControl>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          className={classes.formControlStyle}
                        >
                          <FormHelperText
                            className={classes.labelStyle}
                            component="p"
                          >
                            {configJSON.milestoneDuedateLabelTxt}
                          </FormHelperText>
                          <TextField
                            name="addNewMilestoneDueDate"
                            onChange={(event) => this.handleOnChange(event)}
                            value={this.state.addNewMilestoneDueDate}
                            InputProps={{
                              classes: {
                                input: classes.placeholderStyle,
                                root: classes.inputValueStyle,
                              },
                            }}
                            type="text"
                            data-testid="addNewMilestoneDueDate"
                            placeholder={configJSON.requiredDaysPlaceholder}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          className={classes.formControlStyle}
                        >
                          <FormHelperText
                            className={classes.labelStyle}
                            component="p"
                          >
                            {configJSON.milestoneDepositAmountLabelTxt}
                          </FormHelperText>
                          <TextField
                            name="addNewMilestoneAmount"
                            onChange={(event) => this.handleOnChange(event)}
                            value={this.state.addNewMilestoneAmount}
                            placeholder={configJSON.despostiAMountPlaceholder}
                            InputProps={{
                              classes: {
                                input: classes.placeholderStyle,
                                root: classes.inputValueStyle,
                              },
                            }}
                            data-testid="addNewMilestoneAmount"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box
                      className={`${classes.sendContainerStyle} ${classes.btnContainerStyle}`}
                    >
                      <Button className={classes.closeBtnSyles}>
                        {configJSON.cancelBtnTxt}
                      </Button>
                      <Button
                        className={classes.onHoldBtnStyle}
                        onClick={this.createNewMileStone}
                        data-testid="createNewMileStone"
                      >
                        {configJSON.sendBtnTxt}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Typography
                    className={`${classes.addNewmilestoneStyle} ${classes.timelineContentHeaderStyle}`}
                    onClick={this.addNewMilestone}
                    data-testid="add-newmilestone"
                  >
                    {configJSON.addNewMilestoneBtntxt}
                  </Typography>
                )}
              </Box>
            </TimelineContent>
          </StyledTimelineItem>
        </Timeline>
      </Box>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  topContainer: {
    borderRadius: 14,
    border: "1px solid #E4E6E8",
    padding: 25,
  },
  timelineConectorStyles: {
    border: "1px dashed #00A7A2",
    width: "0",
    backgroundColor: "#fff",
  },
  dueDateStyle: {
    marginRight: "2vw",
  },
  displayBox: {
    display: "flex",
    alignItems: "center",
    margin: "8px 0",
  },
  timelineContentHeaderStyle: {
    color: "#222222",
    fontSize: "16px",
    fontWeight: 700,
    marginRight: "0.7vw",
    fontFamily: "Inter",
    marginBottom: 80,
  },
  timelineContentVerifyBtn1: {
    color: "#fff",
    fontSize: "10px",
    fontWeight: 700,
    width: "63px",
    height: "24px",
    borderRadius: "12px",
    backgroundColor: " #16a700",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none" as const,
  },
  addNewMilestoneBtn: {
    color: "#00A7A2",
    border: "none",
    backgroundColor: "#E5F6F6",
  },
  addNewMilestoneFieldsContainer: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  formControlStyle: {
    margin: "15px 0px",
  },
  labelStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  placeholderStyle: {
    "&::placeholder": {
      color: "#8083A3",
      fontSize: "14px",
      fontFamily: "Inter",
      fontWeight: 700,
    },
  },
  inputValueStyle: {
    color: "#222",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
  },
  sendContainerStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btnContainerStyle: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    columnGap: 10,
  },
  closeBtnSyles: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
    "&:hover": {
      backgroundColor: "#00A7A2",
    },
  },
  milestoneGridImage: {
    width: "20px",
    height: "21px",
    marginRight: "1vw",
  },
  onHoldBtnStyle: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "5 35",
  },
  addNewmilestoneStyle: {
    cursor: "pointer",
    marginTop: 10,
  },
  timelineDotStyle: {
    background: "#00A7A2",
    color: "#fff",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 700,
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timelineConectorStyle: {
    border: "1px dashed #00A7A2",
    width: "0",
    backgroundColor: "#fff",
  },
  paymentStyle: {
    marginRight: "1vw",
  },
  timelineContentHeader: {
    color: "#222222",
    fontSize: "16px",
    fontWeight: 700,
    marginRight: "0.7vw",
    fontFamily: "Inter",
  },
  displayGrid: {
    display: "flex",
    alignItems: "center",
    padding: "2vw",
    borderRadius: "10px",
    backgroundColor: "#f8f8fb",
    opacity: 0.7,
  },
  timelineContentDesc: {
    color: "#8083a3",
    fontSize: "14px",
    fontWeight: 500,
  },
  divider: {
    margin: "1vw 0",
    width: "100%",
  },
  displayVerifyBox: {
    display: "flex",
    alignItems: "center",
  },
};

export const CreateNewMileStoneStyles = withStyles(webStyles)(
  CreateNewMileStone
);
// Customizable Area End
