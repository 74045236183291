import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,

} from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
import { ThemeProvider,createTheme,withStyles} from "@material-ui/core/styles";
import {
  gifIcon,
  sendIcon,
  linkIcon,
  ItalicIcon,
  chatDots,
  boldIcon,

} from './assets'
export const trackingTheme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
    
});


// Customizable Area End

import PrjMessagesController, {
    Props, configJSON
} from "./PrjMessagesController.web";

export class PrjMessages extends PrjMessagesController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start

      // Customizable Area End
    render() {
        // Customizable Area Start
        const{classes}=this.props;
        // Customizable Area End
        return (

            // Customizable Area Start
            <>
                <ThemeProvider theme={trackingTheme}>
                <Box className={classes.MainMsgBoxStyle}>
                {/* -------------------------------- Messages start------------------------------------------------ */}
                         <Box className={classes.msgBoxWrapper}>
                                <Box className={classes.msgDateBox}>{this.state.msgData.date}</Box>
                          </Box>
                      {/* ----------------- sender box ------------------------ */}
                       <Box className={classes.mainBoxWrapper}>
                          <Box className={classes.sendBoxWrapper}>
                                   <img className={classes.chatDotsImage} src={chatDots} />
                                   <Box className={classes.subWrapper}>
                                      <Box className={classes.msgSendBox}>
                                        <Box className={classes.talkbubbleBefore}></Box>
                                          <Typography className={classes.msgText}>{this.state.msgData.receiver_msg}</Typography>
                                        </Box>
                                        <Box className={classes.sendTimeWrapper}>
                                        <Typography className={classes.msgTime} >{this.state.msgData.time}</Typography>
                                        </Box>
                                   </Box>
                           </Box>
                      </Box>
                     {/* ----------------- receiver box ------------------------ */}
                     <Box className={classes.mainBoxReceiveWrapper}>
                          <Box className={classes.sendBoxWrapper}>                                
                                   <Box className={classes.subWrapper}>
                                      <Box className={classes.msgReceiveBox}>
                                      <Box className={classes.talkbubbleReceiveBefore}></Box>
                                          <Typography className={classes.msgText}>{this.state.msgData.sender_msg}</Typography>
                                        </Box>
                                        <Box className={classes.receiveTimeWrapper}>
                                        <Typography className={classes.msgTime} >{this.state.msgData.time}</Typography>
                                        </Box>
                                   </Box>
                                   <img className={classes.chatDotsImage} src={chatDots} />
                           </Box>
                      </Box>
           
                      {/* ----------------- Input box ------------------------ */}
                          <Box className={classes.msgInputBoxWrapper}>
                        <Box className={classes.msgInputBox}>
                        <input className={classes.seacrhInputBox}type="text"  placeholder={configJSON.searchPlaceholder} />
                          <img className={classes.chatInputImgIcon} src={ItalicIcon} />
                          <img className={classes.chatInputImgIcon} src={boldIcon} />
                          <img className={classes.chatInputImgIcon} src={linkIcon} />
                          <img className={classes.chatInputImgIcon} src={gifIcon} />
                          <img className={classes.chatSendImgIcon} src={sendIcon} />
                        </Box>
                        </Box>
                         
                       {/* -------------------------------- Messages end------------------------------------------------ */}
                  </Box>          
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export const styles = () => ({
msgDateBox :{
  borderRadius: '28px',
  backgroundColor: '#f3f3f3',
  padding:'10px',
  marginBottom:'1vw',
  color: "#222222",
  fontSize: '12px',
  fontWeight: 500
 },

 msgText :{
  color: "#222222",
  fontSize: '14px',
  fontWeight: 500,
  padding:'1vw',
 },
 msgSendBox: {
  width: '300px',
  height: '57px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative' as const,
  borderRadius: '6px',
  backgroundColor: '#fff1dd',
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  borderTopLeftRadius: '6px',
},

talkbubbleBefore: {
  content: '""',
  position: 'absolute' as const,
  left: '98%',
  top: '-1px',
  width: '0',
  height: '0',
  borderRight: '20px solid #fff1dd',
  borderBottom: '15px solid transparent',
  transform:'rotateY(180deg)',
  borderRadius: '10px',
},

msgReceiveBox: {
  width: '300px',
  height: '57px',
  backgroundColor: '#efefef',
  borderRadius:'6px',
  display:'flex',
  alignItems:'center',
  position: 'relative' as const,
  borderBottomLeftRadius: '6px',
  borderBottomRightRadius: '6px',
  borderTopRightRadius: '6px',
},
talkbubbleReceiveBefore: {
  content: '""',
  position: 'absolute' as const,
  right: '97%',
  top: '0px',
  width: '0',
  height: '0',
  borderRight: '20px solid #efefef',
  borderBottom: '15px solid transparent',
  borderRadius: '10px',
},

msgTime: {
  color: "#8083a3",
  fontSize: '10px',
  fontWeight: 500
},
chatDotsImage:{
  width: '20px',
  height: '21px',
},
chatInputImgIcon:{
  width: '24px',
  height: '24px',
  marginRight:'0.5vw',
},
chatSendImgIcon:{
  width: '45px',
  height: '45px',
},
msgBoxWrapper:{
  display:'flex',
  alignItems:'center',
   justifyContent:'center'
  },
subWrapper:{
  display:'flex',
   flexDirection:'column' as const,
  },
  mainBoxWrapper:{
    width:'100%',
    display:'flex',
     alignItems:'flex-end', 
     flexDirection: 'column' as const,
     },
     mainBoxReceiveWrapper:{
      width:'100%',
      display:'flex',
       alignItems:'flex-start', 
       flexDirection: 'column' as const,
       },

  sendBoxWrapper:{
    display:'flex',
    padding:'10px',
    },
  sendTimeWrapper:{
    display:'flex',
    alignItems:'center',
     justifyContent:'flex-start',
     width:'100%',
     marginTop:'0.7vw',
    },
    receiveTimeWrapper:{
      display:'flex',
      alignItems:'center',
       justifyContent:'flex-end',
       width:'100%',
       marginTop:'0.7vw'
      },
    msgInputBoxWrapper:{
      padding: '0 1vw',
    },
  msgInputBox:{
    display:'flex',
    alignItems:'center',
     justifyContent:'space-between',
     padding:'5px 10px',
     borderRadius:'30px',
     border: '1px solid #e4e6e8',
    marginTop: '10vw', 
     },
   seacrhInputBox:{
      height:'21px', 
      width:'70%' ,
     outline: 'none',
      border:'none',
      backgroundColor:'transparent',
      boxShadow: 'none', 
      fontSize:'14px',
       color:'#8083a3',
       fontWeight:500,
     },
     mainMsgBoxStyle:{
      paddingLeft:'1vw',
      paddingRight:'1vw'
    },
});

export default withStyles(styles)(PrjMessages);
  // Customizable Area End