import React from "react";

import {
  // Customizable Area Start
  Typography,
  Box,
  Button,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
// Customizable Area End

import ProjecttemplatesController, {
  Props,
  configJSON,
} from "./ProjecttemplatesController";

export default class PostProjectSuccessfulPage extends ProjecttemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getProjectPostSuccessMessage=()=>{
     if(this.state.editingExistingProject){
       return configJSON.editProjectSuccessMessage;
     }else if(this.state.isProjectDrafted){
        return configJSON.draftProjectSuccessMessage;
     }else {
       return configJSON.successfulMsgTxt;
     }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Sponserheader navigation={this.props.navigation} id={""} route={""} />
      <Box style={webStyle.mainContainer}>
        <CheckCircleIcon style={webStyle.checkIconStyle} />
        <Typography style={webStyle.congratulationsTxtStyle}>
          {configJSON.congratulationsTxt}
        </Typography>
        <Typography style={webStyle.successfulMsgStyle}>
          {this.getProjectPostSuccessMessage()}
        </Typography>
        <Typography style={webStyle.getNotificationMsgStyle}>
          {!this.state.isProjectDrafted && configJSON.getNotificationMsgTxt}
        </Typography>
        <Grid container style={webStyle.btnContainerStyle} className="resbtnStyle">
          <Grid item xs={12} sm={6}>
            <Button
              data-testid="newProjectBtn"
              style={webStyle.saveasDraftBtnTxt}
              onClick={this.navigateToCreateNewProjectPage}
              >
              {configJSON.postAnotherProjectBtnTxt}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              data-testid="activeProjectBtn"
              style={webStyle.postProcessBtnTxt}
              onClick={this.navigateToActiveProjectPage}
              >
              {configJSON.browsePostedProjectsBtnTxt}
            </Button>
          </Grid>
        </Grid>
      </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column" as "column",
    rowGap: 20,
    justifyContent: "center",
    height: "100vh",
  },
  checkIconStyle: {
    color: "#16A700",
    border: "1px solid #16A700",
    borderRadius: "50%",
    fontSize: 100,
  },
  congratulationsTxtStyle: {
    color: "#222",
    fontFamily: "Inter",
    fontSize: 30,
    fontWeight: 700,
  },
  successfulMsgStyle: {
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 700,
  },
  getNotificationMsgStyle: {
    color: "#8083A3",
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center' as const
  },
  btnContainerStyle: {
    display: "flex",
    alignItems: "center",
   maxWidth: "550px",
   padding: "0 25px",
   gap: '10px',
   flexWrap: "nowrap" as const
  },
  saveasDraftBtnTxt: {
    borderRadius: 23,
    background: "#F2F2F2",
    color: "#222",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "10 20",
    width: "100%"
  },
  postProcessBtnTxt: {
    borderRadius: 23,
    backgroundColor: "#00A7A2",
    color: "#fff",
    textAlign: "center" as "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    padding: "10 20",
    width: "100%"
  },
};
// Customizable Area End
