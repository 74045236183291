
import React from "react";
import {
  Box,
  Button,
  Typography,

  // Customizable Area Start
  Paper,
  IconButton,
  CircularProgress
  // Customizable Area End

} from "@material-ui/core";

const ResetImg = require("../assets/ResetImg.png");
const logoImg = require("../assets/logo.jpeg");
// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { contact } from "./assets";
import "../assets/css/ResendLink.css";

const theme = createTheme({
  palette: {

  },
  overrides: {
    MuiButton:
    {
      contained :{
      color: '#fff'
    }
  }
  }
});

// Customizable Area End
import ForgetPasswordController, {
  Props, configJSON,
} from "./ForgetPasswordController.web";

export default class ResendLink extends ForgetPasswordController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      // Customizable Area Start
      <>
        <Box display='flex' justifyContent='end' style={{ marginTop: '6px', marginRight: '10px', fontWeight: 700 }}>
          <img style={{ height: '5vh' }} src={contact} alt="" />
          <a style={webStyle.help_and_support_txt}
            href="#">Help and Support
          </a>
        </Box>
        <Box sx={{ mx: 'auto' }}>
          <Box py={2} style={webStyle.logo_imgBox}>
            <img style={webStyle.logo_img} src={logoImg} alt="" />
          </Box>
          <Box component={Paper} mt={3} style={webStyle.main_box}>
            <Box pb={2} textAlign="center">
              <img src={ResetImg} alt="" />
            </Box>
            <Typography variant="h5" style={{
              fontWeight: 'bold',
              textAlign: 'center',
              fontFamily: "inter"
            }}>Check Your Mail </Typography>
            <Box pb={4} pt={2.5} textAlign='center'>
              <Typography style={webStyle.checkYourInboxTxt}>{configJSON.checkYourInboxTxt1} </Typography>
              {
                !this.state.linkSentLoader && (
                  <Typography style={{
                    fontSize: "14px",
                    color: "#1C1C1C",
                    marginTop: 10,
                    fontWeight: 500
                  }}>
                    {configJSON.didntReciveLink} &nbsp;
                    <span
                       color="black" 
                       className="underline-on-hover" 
                       style={webStyle.resend_ink_btn} 
                       onClick={() => this.forgetPassword(false)}>
                        {this.state.resetTriggerCount>1?configJSON.resendLinkAgain:configJSON.resendLink}
                    </span>
                </Typography>
                 
                )
              }
             {this.state.showResendLinkStatus &&
              <Typography style={{
                fontSize: "14px",
                color: "#8083A3",
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
                fontWeight: "bold",

              }}>{configJSON.statusResendLink}</Typography>}
              {this.state.linkSentLoader&&<IconButton><CircularProgress size={20}/></IconButton>}
            </Box>
            
            <Box style={{ textAlign: 'center' }}>
              <Button variant="contained"
                id="same"
                style={webStyle.goTologin_btn}
                onClick={this.cancelonClick}
              ><Typography style={webStyle.goTolgoin_btn_text}>{configJSON.gotoLogin}</Typography></Button>
            </Box>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  logo_imgBox: {
    display: "block",
    textAlign: "center" as const
  },
  logo_img: {
    height: "35px",
    width: "145.15px",
  },
  main_box: {
    border: '1px solid #E4E6E8',
    borderRadius: "18px",
    paddingBottom: "20px",
    maxWidth: '450px'
  },

  resend_ink_btn: {
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: '14px',
    color: '#1C1C1C',
    cursor: "pointer",
    textDecoration:"underline"
   
  },
  
  goTologin_btn: {
    backgroundColor: '#EC952D',
    minWidth: '300px',
    borderRadius: '23px',
    height:"46px"
  },
  goTolgoin_btn_text: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '14px',
    color: '#FFFFFF',
    textTransform:"none" as const
  },
  help_and_support_txt: {
    fontWeight: 700, marginTop: '5px', marginLeft: '5px', color: '#8083A3', textDecoration: 'none'
  },
  checkYourInboxTxt: {
    fontSize: "14px",
    color: "#8083A3",
    fontWeight: 500,
    fontFamily: "inter"
  }
};
// Customizable Area End

