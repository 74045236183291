import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("../../blocks/adminconsole2/config.json")
// Customizable Area End


export const ApiCall = async (data: any) => {
    const token = (await localStorage.getItem("loginSuccessToken")) || "";        
    const { contentType, method, endPoint, body } = data;
    const header = {
        "Content-Type": contentType,
        token: token,
        redirect: 'follow'
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMessage),
        configJSON.baseURL
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
};