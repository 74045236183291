import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { myProjectsIcon, postProjectIcon } from "./assets";
import {
  ExpandLess as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon
} from "@material-ui/icons";
import CreateNewProjectPage from "./CreateNewProjectPage.web";
import { EditExistingDraftPageStyles } from "./EditExistingDraftPage.web";

const outerList = [
  {
    title: "Post a Project",
    innerList: [
      { label: "Create a New Project", route: "createNewProject" },
      { label: "Edit an Existing Draft", route: "editExstingDraft" },
      { label: "Reuse Previous Project Post", route: "reUsePreviouProject" }
    ],
    img: postProjectIcon
  },
  {
    title: "My Projects",
    innerList: [
      { label: "Active Projects", route: "activeprojects" },
      { label: "Projects In-Progress", route: "projectinprogress" },
      { label: "Projects on Hold", route: "projectonhold" },
      { label: "Completed Projects", route: "completedproject" },
      { label: "Dispute Projects", route: "disputeproject" },
      { label: "Closed Projects", route: "closedproject" },
      { label: "Drafts", route: "draft" }
    ],
    img: myProjectsIcon
  }
];

const pageTitles:any={
  "draft":"Drafts",
  "closedproject":"Closed Projects",
  "disputeproject":"Dispute Projects",
  "completedproject":"Completed Projects",
  "projectonhold":"Projects on Hold",
  "projectinprogress":"Projects In-Progress",
  "activeprojects":"Active Projects",
  "createNewProject":"Create a New Project",
  "editExstingDraft":"Edit an Existing Draft",
  "reUsePreviouProject":"Reuse Previous Project Post",
}


// Customizable Area End

import ProjecttemplatesController, {
  Props,
  configJSON
} from "./ProjecttemplatesController";
import Activeproject from "../../joblisting/src/Activeproject.web";
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import Inprogrssproject from "../../joblisting/src/Inprogrssproject.web";
import Onholdproject from "../../joblisting/src/Onholdproject.web";
import Completedproject from "../../joblisting/src/Completedproject.web";
import Deletedproject from "../../joblisting/src/Deletedproject.web";
import Drafts from "../../joblisting/src/Drafts.web";
import { CreateNewMileStoneStyles } from "../../scheduling/src/CreateNewMilestone.web";
import { SchedulingPageStyles } from "../../scheduling/src/Scheduling.web";

export default class Projecttemplates extends ProjecttemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetailsViewComponent() {
    const componentMapping: Record<string, JSX.Element> = {
      createNewProject: (
        <CreateNewProjectPage
          classes={{}}
          navigation={this.props.navigation}
          id={""}
          singleProjectDetails={this.state.singleProjectDetails}
        />
      ),
      editExstingDraft: (
        <EditExistingDraftPageStyles
          key="keep_from_draft"
          name="draft"
          navigation={this.props.navigation}
          getSingleProject={this.getSingleProject}
          id=""
        />
      ),
      reUsePreviouProject: (
        <EditExistingDraftPageStyles
          key="keep_from_active"
          name="active"
          navigation={this.props.navigation}
          id=""
          getSingleProject={this.getSingleProject}
        />
      ),
      activeprojects: (
        <Activeproject
          navigation={this.props.navigation}
          id={""}
          name="active"
        />
      ),
      projectinprogress: (
        <Inprogrssproject
          navigation={this.props.navigation}
          id={""}
          name="in-progress"
        />
      ),
      projectonhold: (
        <Onholdproject
          navigation={this.props.navigation}
          id={""}
          name="on-hold"
        />
      ),
      completedproject: (
        <Completedproject
          navigation={this.props.navigation}
          id={""}
          name="completed"
        />
      ),
      disputeproject: (
        <Activeproject
          key="dispute_project"
          navigation={this.props.navigation}
          id={""}
          name="dispute"
        />
      ),
      closedproject: (
        <Deletedproject
          navigation={this.props.navigation}
          id={""}
          name="closed"
        />
      ),
      draft: <Drafts 
          navigation={this.props.navigation} 
          id={""} 
          name="draft"
          getSingleProject={this.getSingleProject}
           />,
      scheduling: (
        <SchedulingPageStyles navigation={this.props.navigation} id={""} />
      ),
      createNewMilestone: (
        <CreateNewMileStoneStyles navigation={this.props.navigation} id={""} />
      )
    };

    const renderPageView = this.getViewType();

    return componentMapping[renderPageView];
  }
  activeRouteStyle = (route: string) => {
    let renderPageView = this.getViewType();
    renderPageView === "scheduling" && (renderPageView = this.props.navigation.getParam("status"))
    return renderPageView === route
      ? webStyle.activeListItemStyle
      : webStyle.listItemStyle;
  };
  getProjectTitle=()=>{
     const renderPageView:any=this.getViewType();
     if(renderPageView==="createNewProject" && this.state.editingExistingProject){
      return "Edit Project";
    }
     const title=pageTitles[renderPageView] || "";
     return title;
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Sponserheader
          navigation={this.props.navigation}
          id={""}
          route={"projects"}
        />
        <Container maxWidth="lg">
          <Typography variant="h5" style={webStyle.profileHeading}>
            {this.getProjectTitle()}
          </Typography>
          <style>
              {`
                 @media(max-width: 960px){
                  .filedsContainer {
                    padding: 0 !important;
                  }
                 }
                `}
          </style>
          <Grid container>
            <Grid item xs={12} sm={12} md={3} style={webStyle.sideRoutingBlock}>
              {outerList.map((outerItem, index) => (
                <Accordion
                  key={index}
                  expanded={this.state.expandedIndex === index}
                  onChange={() => this.handleAccordionChange(index)}
                  style={webStyle.accordionStyle}
                  data-testid={`maintabView-${index}`}
                >
                  <AccordionSummary
                    expandIcon={
                      this.state.expandedIndex === index ? (
                        <ExpandMoreIcon style={webStyle.arrowIconStyle} />
                      ) : (
                        <ChevronRightIcon style={webStyle.arrowIconStyle} />
                      )
                    }
                  >
                    <img
                      src={outerItem.img}
                      alt={outerItem.title}
                      style={webStyle.accordtionIconStyle}
                    />
                    <Typography style={webStyle.accordationNameStyle}>
                      {outerItem.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={webStyle.accordionDetailsStyle}>
                    <List style={webStyle.listStyle}>
                      {outerItem.innerList.map((innerItem, innerIndex) => (
                        <ListItem
                          key={innerIndex}
                          onClick={() =>
                            this.handleRenderPageView(
                              innerItem.route,
                              outerItem.title
                            )
                          }
                          style={this.activeRouteStyle(innerItem.route)}
                          data-testid={`innerListItem-${innerIndex}`}
                        >
                          {innerItem.label}
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid id="project_input_area" className="filedsContainer" item xs={12} sm={12} md={9} style={webStyle.filedsContainer}>
              {this.renderDetailsViewComponent()}
            </Grid>
          </Grid>
        </Container>
        <Sponserfooter navigation={this.props.navigation} id={""} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  profileHeading: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    color: "#222222",
    margin: "20px 0px 50px 0px"
  },
  sideRoutingBlock: {
    border: "1px solid #e4e6e8",
    borderRadius: "14px",
    dropShadow: "inset 0px 0px 28px -3px rgba(0,0,0,0.1)",
    maxHeight: "26rem",
    padding: "0 13",
    marginBottom: "15px"
  },
  accordionStyle: {
    boxShadow: "none",
    cursor: "pointer"
  },
  listItemStyle: {
    color: "#8083A3",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 500,
    display: "list-item",
    listStyleType: "disc"
  },
  accordationNameStyle: {
    color: "#222",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 600
  },
  accordtionIconStyle: {
    width: 22,
    height: 22,
    marginRight: 20
  },
  listStyle: {
    marginLeft: 30
  },
  arrowIconStyle: {
    color: "#222",
    fontSize: "20px"
  },
  accordionDetailsStyle: {
    marginTop: "-30px",
  },
  activeListItemStyle: {
    color: "#00A7A2",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 500,
    display: "list-item",
    listStyleType: "disc"
  },
  filedsContainer: {
    padding: "0 16"
    //display:"flex"
  }
};
// Customizable Area End
