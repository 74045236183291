Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPatchMethod =  "PATCH";

exports.getNotificationAPIEndPoint = "notifsettings/notification_settings";
exports.updateNotificationAPIEndPoint = "notifsettings/notification_settings/";
exports.updateNotificationGroupAPIEndPoint = "notifsettings/groups/";
exports.updateNotificationSubGroupAPIEndPoint = "notifsettings/subgroups/";

exports.notificationApiContentType = "application/json";
exports.deleteNotificationAPIEndpoint = "bx_block_notifications/notifications/"
exports.clearNotificationsAPIEndpoint = "bx_block_notifications/clear_all_notifications"
exports.notificationsAPIEndpoint = "bx_block_notifications/notifications"
exports.unreadNotificationApiUrl = "bx_block_notifications/mark_as_read"
// Customizable Area End