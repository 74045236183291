import React from "react";
// Customizable Area Start
import {
    Grid,
    Typography,
    Box,
    Button,

} from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
import { ThemeProvider,createTheme,withStyles} from "@material-ui/core/styles";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import BackNavigation from "../../../components/src/BackNavigation.web"
import {
  verifyIcon,
  upload,
  backArrow,
} from './assets'
export const theme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
 });


// Customizable Area End

import FreelancerWithdrawalController, {
    Props, configJSON
} from "./FreelancerWithdrawalController.web";


export class IdVerification extends FreelancerWithdrawalController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
      renderGuidelines=(verifyIcon:string, guidelineTxt:string)=>{
        const{classes}=this.props;
        return  <Grid item xs={12} className={classes.flexDisplay}>
        <img className={classes.verifyImage} src={verifyIcon} alt="Upload Icon" />
        <Typography className={classes.guldelineTextStyle}>{guidelineTxt}</Typography>
        </Grid>
      }
       // Customizable Area End
    render() {
        // Customizable Area Start
          const{classes}=this.props;
        // Customizable Area End
        return (

            // Customizable Area Start

            <ThemeProvider theme={theme}>
            <FreelancerHeader 
            navigation={this.props.navigation} history={undefined} 
            location={undefined} 
            id={""} route={"projects"}
            />
        <Box className={classes.verifyMainBox}>
          <Grid container className={classes.verifyContentBox}>

            <Grid item xs={10} className={classes.verifyDetailsBtnGrid}>

               <BackNavigation 
               data-testid="backNavigationComp"
               onHandleBack={this.onHandleBack}
                backArrow ={backArrow}
                backTxt={configJSON.backTxt}
                headerTxt={configJSON.verificationHeader}
               />
              
            </Grid>
                    
              <Grid item xs={10} className={classes.verifyDetailsGrid}>
                   <Grid container className={classes.verifyDetailsBox}>
                 <Grid item xs={12} sm={6} className={classes.verifyGridItem}>
  
                        <Box>
                                <Box className={classes.uploadIdStyle}>
                                  {this.state.uploadedIdFiles.length > 0 && (
                                    <Box>
                                      <img
                                        className={classes.uploadedImageStyle}
                                        src={URL.createObjectURL(this.state.uploadedIdFiles[0])}
                                        alt="Uploaded Image"
                                        data-testid="uploadFile"
                                      />
                                    </Box>
                                  )}

                
                                  {!this.state.uploadedIdFiles.length && (
                                    <React.Fragment>
                                      <Typography className={classes.uploadIdBtnTxtStyle}>{configJSON.uploadYourIdTxt}</Typography>
                                       <input
                                        type="file"
                                        onChange={this.handleIdUpload}
                                        className={classes.uploadIdInputStyle}
                                        data-testid="dropzone"
                                      />
                                    </React.Fragment>
                                  )}
                                </Box>
 
                        </Box>
                    </Grid>
                 
                        <Grid item xs={12} sm={6} className={classes.uploadGuidelinesGridContainer}>
                        <Box>
                          {this.renderGuidelines(verifyIcon, configJSON.guidelineOne)}
                          {this.renderGuidelines(verifyIcon, configJSON.guidelineTwo)}
                          {this.renderGuidelines(verifyIcon, configJSON.guidelineThree)}
                        </Box>
                        <Box className={classes.btnStyles}>


                          <Box 
                          className={`${classes.uploadAnotherBtnStyle} ${this.state.uploadedIdFiles.length === 0 && 'hidden'}`}
                          >
                            <Typography className={classes.uploadIdTextStyle}> {configJSON.uploadAnotherIdBtn}</Typography>
                            <input
                              type="file"
                              onChange={this.handleIdUpload}
                              className={classes.uploadIdInputStyle}
                              data-testid="uploadAnotherFile"
                            />
                          </Box>

                          <Box className={classes.flexDisplay}>
                            <Button
                              variant="contained"
                              className={`${classes.commonIdBtn} ${classes.cancelIdBtn}`}
                              disableElevation
                              data-testid="cancelIdBtn"
                              disabled={this.state.uploadedIdFiles.length === 0}
                            >
                              {configJSON.cancelText}
                            </Button>
                            <Button
                              variant="contained"
                              className={`${classes.commonIdBtn} ${classes.submitIdBtn}`}
                              disableElevation
                              disabled={this.state.uploadedIdFiles.length === 0}
                              data-testid="submitIdBtn"
                              onClick={this.handleGoToIdSuccessPage}
                            >
                              {configJSON.submitText}
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                   
                 </Grid> 
             </Grid>

        </Grid>
        </Box>
      <DashboardFooter history={undefined} location={undefined} />    
    </ThemeProvider>
     // Customizable Area End
   )}
}
// Customizable Area Start

export const styles = () => ({
  
  verifyDetailsGrid:{
    margin:'0 1vw 2vw 1vw',
    width:"100%",
    flexWrap: "wrap" as const,
  },
  verifyDetailsBtnGrid:{
    margin:'1vw',
    width:"100%",
    display:'flex', 
    alignItems:'center',
    justifyContent:'space-between',
    flexWrap: "wrap" as const,
  },

  verifyDetailsBox:{
      display:'flex', 
      alignItems:'center',
      padding:'1vw',
       borderRadius: '14px',
      border: '1px solid #cfd0d1',
      flexWrap: "wrap" as const,

    },
    verifyGridItem:{
      padding:"10px 24px"
    },
    verifyMainBox:{ 
      width:'100%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
   },
   verifyContentBox:{ 
         display:'flex',
        justifyContent:'center',
         alignItems:'center',
         width:'98%',
         flexDirection:'column' as const,
     },
     uploadIdStyle:{
      position:'relative'as const,
        minWidth:'100%',
        minHeight: '300px',
        borderRadius: '10px',
        border: '1.5px dashed #ec952d',
        backgroundColor: 'rgba(236,149,45,0.06)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column' as const,
        margin:"2vw 0",
       
      },
      uploadedIdImage:{
        width: '36px',
        height: '36px',
        position:"absolute" as const,
        marginTop:"-25px",
 
    },
    uploadIdTextStyle:{
      color: '#222',
      fontSize:'14px',
      fontWeight: 600,
      position:"absolute" as const,
   },
   
   uploadIdBtnTxtStyle:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    height:'40px',
    minWidth:'140px',
    backgroundColor:'#ec952d',
    color: '#fff',
    borderRadius:'20px',
    fontSize:'14px',
    fontWeight: 600,
    position:"absolute" as const,
    
 },
   uploadIdTextMarginStyle:{
    marginTop:'40px',
 },
   uploadIdInputStyle:{
    position:"absolute" as const,
    top:0,
    left:0, 
    height:'100%',
    width:'100%',
    opacity:0, 
    cursor:"pointer",
    },
    uploadGuidelinesGridContainer:{
      display:'flex',
      justifyContent:"space-between",
      flexDirection:'column' as const,
      minHeight:"300px",
    },
    flexDisplay:{
      display:'flex',
    },
    verifyImage:{
      width: '20px',
      height:'20px',
      padding:'10px',
    },
    guldelineTextStyle:{
      color: '#222',
      fontSize:'15px',
      fontWeight: 600,
      padding:'10px',
    },
    btnStyles:{
      display:'flex',
      justifyContent:"space-between",
      alignItems:'center',
      flexWrap:'wrap' as const
    },
    commonIdBtn: {
      minWidth: "80px",
      height: '36px',
      fontWeight: 500,
      fontSize: "12px",
      borderRadius: '30px',
      textTransform: 'none' as const,
      border: 'none',
      padding: '5px 10px',
      margin: '5px',
    },
    cancelIdBtn: {
      backgroundColor: "#f2f2f2",
      color: "#222",
    },
    submitIdBtn: {
      backgroundColor: "#ec952d",
      color: "#fff",
      '&:hover': {
        backgroundColor: '#eb9734',
      },
    },

 uploadAnotherIdStyle: {
  position:'relative'as const,
  borderRadius: "18px",
  minWidth: "140px",
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "12px",
  padding: 0,
  background: "#ec952d",
  color: "#fff",
  display: "inline-block",
  margin: "0 15px",
  border: "none",
  cursor:'pointer'
},
uploadedImageStyle:{
  maxWidth: '100%', 
  maxHeight: '100%', 
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
},
uploadAnotherBtnStyle: {
  position:'relative'as const,
  borderRadius: "18px",
  minWidth: "140px",
  height:'40px',
  padding: 0 ,
  backgroundColor: "#f2f2f2",
  display: "flex",
  alignItems:'center',
  justifyContent:'center',
  border: "none",
  cursor:'pointer',
  "&.hidden":{
    visibility:'hidden'as const,
  },
}
})

export default withStyles(styles)(IdVerification);
  // Customizable Area End