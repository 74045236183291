import React from "react";

// Customizable Area Start
import "../assets/css/Accountinfo.css";
import { lancerlogo } from "./assets";
import AccountinfoController, { Props } from "./AccountinfoController.web";
import ProfileSetupHeader from "../../../components/src/profile-setup/ProfileSetupHeader.web";
import ProfileSetupSteper from "../../../components/src/profile-setup/ProfileSetupSteper.web";
import BasicDetailsForm from '../../../components/src/profile-setup/BasicDetailsForm.web'
import { createTheme} from "@material-ui/core/styles";



const theme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          fontSize: '14px', 
          fontWeight:700,
        },
      },
    },
  },
});

// Customizable Area End
export default class Basicdetails extends AccountinfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { file, initialValues,countryList,countryLoading,stateList,stateLoading,timeZoneList,cityList,cityLoading} = this.state;
    return (
      // Customizable Area Start
      
        <div className="main-wrapper">
        <ProfileSetupHeader 
          email={this.state.currentUserEmail}
          onLogout={this.sendLogoutUserMessage}
        />
        
        <div className="site-wrapper">
          <div className="setup-account-block">
            <div className="inner-block">
              <a href="#">
                <img src={lancerlogo} alt="logo" />
              </a>
              <h3>
                Welcome to the Lancerscape Portal. Quickly setup your account to
                get started.
              </h3>
            </div>
            <ProfileSetupSteper activeBasicDetails={true} />
          </div>
          <div className="choose-profile-block">
            <div className="profile-heading">
              <h6 style={{fontWeight:800}}>Fill in the required basic details</h6>
            </div>
            <div className="form-block">
              <BasicDetailsForm
                  cityList={cityList}
                  cityLoading={cityLoading}
                  countryList={countryList}
                  countryLoading={countryLoading}
                  file={file}
                  getCityList={this.getCityList}
                  handleDrop={this.handleDrop}
                  handleFileChange={this.handleFileChange}
                  initialValues={initialValues}
                  onCancel={this.handleGoBack}
                  onCountrySelected={this.onCountrySelected}
                  onSubmit={this.basicdetailsubmit}
                  saveUserInfo={this.state.saveUserInfo}
                  stateList={stateList}
                  countryCode={this.state.country_code}
                  stateLoading={stateLoading}
                  timeZoneList={timeZoneList}
                  currencyList={this.state.currencyList}
              />
            </div>
          </div>
        </div>
      </div>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
