import React from "react";
import {
    // Customizable Area Start
    Box, Typography
    , Link,Grid
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Divider from '@material-ui/core/Divider';
import {usaimg,globe,lancerscapelogo} from "./assets";

const theme = createTheme({

});


// Customizable Area End
import FreelancerDashboardController, {
    Props,
} from "./FreelancerDashboardController.web";
import { lancerScopeLogo } from "../../customisableuserprofiles2/src/assets";
import TermsAndServiceModal from "../../../components/src/TermsAndServiceModal.web";

export default class DashboardFooter extends FreelancerDashboardController {
    constructor(props: Props) {
        super(props);
        this.currentPage = "Freelancer";
    }
    render() {
        const filteredSection = Array.isArray(this.state.userFooterData) && this.state.userFooterData.length > 0 ? this.state.userFooterData : [];

        return (
            // Customizable Area Start
           <div className="footer-main">
           <style>
            {`
                .footerLeftSection{
                    display: flex;
                    justify-content: start;
                }
                .footerRightSection{
                    display: flex;
                    justify-content: end;
                }
                @media(max-width: 600px) {
                    .footerLeftSection, .footerRightSection{
                        justify-content: center;
                    }
                }
            
            `}
           </style>
           <Grid container justifyContent="center">
                    {
                        filteredSection.length > 0 ?
                        (
                            <Grid
                        item
                        xs={11}
                        style={{
                            margin: "52px auto"
                        }}
                    >
                        <Grid container justifyContent="space-around" spacing={3}>
                            <Grid item xs={12} md={3}>
                                <Grid item>
                                    <Box mt={1}>
                                        <img
                                            style={{ height: "41px", marginTop: "-31px", cursor: "pointer" }}
                                            src={lancerScopeLogo}
                                            alt=""
                                            onClick={this.navigateToHomePage}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container >
                                
                                {filteredSection.map((section:any) => (
                        
                                    <Grid xs={12} sm={6} md={3} item key={section.id}>

                                    <Typography
                                    style={{
                                        color: "#000000",
                                        fontFamily: "inter",
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                    }}
                                    >{section.title}
                                    </Typography>
                                
                                    {section.sub_sections.length > 0 ? (
                                        
                                        
                                        <ul>
                                            {section.sub_sections.map((subSection:any) => (
                                            <li style={webStyle.link_text} key={subSection.id}>
                                                <a style={webStyle.link_text} href={subSection.url} target="_blank">{subSection.header}</a>
                                            </li>
                                            ))}
                                        </ul>
                                    
                                    ) : ""}
                                    </Grid>
                                ))}
                                </Grid>
                            </Grid>
                        
                        </Grid>
                            </Grid>
                        ) : ""
                    }
                    
                
                </Grid>
                        <Divider />
                        <Grid container xs={11} style={{margin: "0 auto"}}>
                            <Grid item xs={12}>
                                <Grid container xs= {12}>
                                    <Grid item xs={12} sm={6}  className="footerLeftSection">
                                        <Typography
                                         onClick={()=>this.getTermOfServiceInfoFooter("Privacy Policy", "")}
                                         style={{ fontFamily: "inter", fontSize: "14px", cursor: "pointer" }}
                                         data-test-id="termsInfo"
                                         >
                                            Privacy Policy
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className="footerRightSection">
                                        <img
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                            }}
                                            src={globe}
                                            alt="USA Flag"
                                        />
                                        <Typography
                                            style={{
                                                fontFamily: "inter",
                                                fontSize: "14px",
                                                color: "black",
                                                display: 'flex'
                                            }}
                                        >
                                           {this.state.userCountry}
                                            
                                        </Typography>
                                        <Typography
                                            style={{
                                                fontFamily: "inter",
                                                fontSize: "14px",
                                                color: "black",
                                                display: 'flex'
                                            }}
                                        >
                                            Currency - {this.state.userCurrency}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Box justifyContent="center" display="flex" pt={3} pb={3.5}>
                            <Typography style={{ fontFamily: "inter", fontSize: "14px" }}>
                                @2021 All rights reserved
                            </Typography>
                        </Box>
                        <TermsAndServiceModal
                            open={this.state.termsAndServiceFooter?.open}
                            description={this.state.termsAndServiceFooter?.description}
                            loading={this.state.termsAndServiceFooter?.loading}
                            setOpen={this.handleCloseTermsAndServiceModal}
                            title={this.state.termsAndServiceFooter?.title}
                        />
           </div>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    link_text: {
        fontSize: "14px",
        fontFamily: "inter",
        marginTop: "20px",
        color: "#8083a3",
    }
};
// Customizable Area End


