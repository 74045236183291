// Customizable Area Start
import React from "react";
import FreelancerCertificationtrackingControllers, {
  Props,
  configJSON,
} from "../../certificationtracking/src/FreelancerCertificationtrackingControllers.web";
import { Typography, Box,Button ,Container,withStyles, Grid} from "@material-ui/core";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import CircularProgress from "@material-ui/core/CircularProgress";
import { downloads,request } from "../src/assets";
import ArrowBack from '@material-ui/icons/ArrowBack';
import ActionModal from "../../../components/src/utill/ActionModal.web";

export class FreelancerViewCertificate extends FreelancerCertificationtrackingControllers {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {classes}=this.props;
    const {getProjectLoading,projectInfo,currentUser}=this.state;
    const isCertificationCostPaid=projectInfo?.attributes?.certification_charges_paid;
    const userCurrency=currentUser?.attributes?.currency_symbol;
    return (
        <div>
             <FreelancerHeader 
              navigation={this.props.navigation}
              history={this.props.navigation.history}
              location={undefined}
            />
              <Container style={{marginTop:"20px"}}>
                  <Box className={classes.row}>
                        <Button data-testid="gobackBtn" onClick={this.goBack} className={classes.back_button_style} startIcon={<ArrowBack/>}>
                            Back
                        </Button>
                        <Typography component={"h1"} variant="h4" className={classes.page_heading_text}>
                            View Certificate for Project
                        </Typography>
                    </Box>
                    {
                        getProjectLoading && (
                          <Box style={{margin:"20px 0px",display:"flex",alignItems:"center",justifyContent:"center"}}>
                              <CircularProgress size={29} color="primary"/>
                          </Box>
                        )
                    }
                   {
                    !getProjectLoading && (
                      <Box className={classes.certificate_container}>
                        {
                          isCertificationCostPaid && (
                            <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                              <Grid item xs="auto">
                              <Button 
                                data-testid="downloadCertificate"
                                onClick={this.onDownloadCertificate}
                                className={classes.back_button_style} 
                                startIcon={<img src={downloads}/>}>
                                  Download Certificate
                              </Button>
                              </Grid>
                              <Grid item xs="auto">
                              <Button 
                                data-testid="requestRevison"
                                onClick={this.OnRequest}
                                className={classes.back_button_style} 
                                startIcon={<img src={request}/>}>
                                Request for Correction
                              </Button>
                              </Grid>
                            </Grid>
                          )
                        }
                        {
                          !isCertificationCostPaid ? (
                            <Box style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh"}}>
                            <Box style={{ height: "200px" }}>
                            <Button 
                               data-testid="payCertificate"
                               onClick={this.handlePaymentForConfirmation}
                               style={{
                                 background:"#ec952d",
                                 borderRadius:"25px",
                                 padding:"10px 20px",
                                 textTransform:"capitalize",
                                 color:"white"
                               }}
                               className={classes.back_button_style} 
                               >
                               Pay {userCurrency}{this.state.certificationCost} to get your certificate
                             </Button>
                           </Box></Box>
                          ):(
                            <Box data-testid="certificate_frame" className={classes.pdf_container}>
                              <iframe
                                src={projectInfo?.attributes?.certificate?.url}
                                width="100%"
                                height="100%"
                                style={{ border: "none" }}
                              ></iframe>
                            </Box>
                          )
                        }
                    </Box>
                    )
                   }
              </Container>
              <ActionModal
                key="confirmCertificationPaymentModal"
                open={this.state.confirmPayModal}
                setOpen={this.handleOpenConfirmModal}
                message={`Are you sure you want to pay ${userCurrency}${this.state.certificationCost} to get your certificate?`}
                title="Certification payment"
                positiveButtonText="Yes"
                actionHandler={this.paymentForCertificate}
                actionLoading={this.state.paymentLoading}
              />  

            <DashboardFooter history={undefined} location={undefined} />
        </div>
      
    );
  }
}
export const styles = () => ({
  page_heading_text:{
    fontWeight:"bolder",
    '@media (max-width: 450px)': {
      fontSize: "18px",
    }
} as const,
  row:{
      display:"flex",
      alignItems:"center",
      gap:"10px"
  } as const,
  back_button_style:{
      background:"#f2f2f2",
      borderRadius:"20px",
      padding:"10px 20px",
      textTransform:"capitalize",
      fontWeight:"bold",
      '@media (max-width: 600px)': {
        fontSize: "12px"
      }
  } as const, 
  certificate_container:{
     borderRadius:"15px",
     background:"#f2f2f2",
     padding:"20px",
     marginTop:"20px",
  }as const,
  certificate_actions:{
    display:"flex",
    alignItems:"center",
    gap:"20px",
    justifyContent:"flex-end"
  },
  pdf_container:{
    marginTop:"10px",
    width:"100%",
    height:"100vh"
  }
});

export default withStyles(styles)(FreelancerViewCertificate);

// Customizable Area End
