import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";


// Customizable Area Start
import { WithStyles } from '@material-ui/core';
import * as Yup from "yup";
import { toast } from "react-toastify"
import { getNavigationMessage } from "../../../components/src/Navigation.web"
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData } from "framework/src/Utilities";

export const BankDetailsValidation = Yup.object().shape({
  accountNumber: Yup.string().required("Account number can't be blank"),
  verifyAccountNumber: Yup.string().required("Verify account number can't be blank")
                     .oneOf([Yup.ref('accountNumber')], "Account number  doesn't match"),
  bankName: Yup.string().required("Bank name can't be blank"),
  ifscCode: Yup.string().required("IFSC code can't be blank"),
  name: Yup.string().required("Title can't be blank"),
  bankAddress: Yup.string().required("Bank address can't be blank"),
  phone_number: Yup.string().required("Phone number can't be blank"),
});

interface ITax {
  name: string;
  cost: string;
  total_cost: number;
}

interface IWithdrawalData {
  taxes: ITax[];
  withdraw_amount: number;
}

export interface BankDetails {
  accountNumber: string;
  verifyAccountNumber: string;
  bankName: string;
  name:string;
  ifscCode: string;
  bankAddress:string;
  phone_number:string;
}
// Customizable Area End



type VerifyOtpResponseTypes = {
  errors?: {
    pin: string;
  }[];
  data?: {
    id: string; 
  };
  meta?: {
    message: string;
  };
};


type SendOtpApiResponse = {
  errors?: {
    full_phone_number: string;
  }[];
  data?: {
    id: string; 
  };
  meta?: {
    token: string;
  };
};

export interface IPayeeItem{
  accountHolder: string;
  accountNumber: string;
  bankName: string;
  bankAddress: string;
  ifsc: string;
  email: string;
  payeeId: string;
  status: string;
  createdAt: string; 
  updatedAt: string;
  createdBy: string;
  documents: Document[];
  mobile: number;
}


export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;

}

export interface S {
  // Customizable Area Start
  amount: string;
  paymentValue: string;
  bankDetailsData: BankDetails;
  bankNameData: string[];
  uploadedIdFiles: File[];
  phoneConfirmModal: boolean;
  verifyflag: boolean;
  otp:string;
  currentUser:IProfile|null;
  sendOtpLoading:boolean;
  verificationLoading:boolean;
  resendCountDownTimer:number;
  resendOtpLoading:boolean;
  otpSentSuccessMessage:string;
  otpSentErrorMessage:string;
  sendOTPResponse:{
    id:string;
    token:string;
  };
  profileBalance:number;
  withdrawErrorMessage:string;
  payeeList:Array<IPayeeItem>;
  getPayeeLoading:boolean;
  createPayeeLoading:boolean;
  sponsorWithdraw:boolean;
  withdrawLoading:boolean;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}
export default class FreelancerWithdrawalController extends BlockComponent<
  Props, S, SS
> {

  // Customizable Area Start
  countDownIntervalId:any=null;
  debounceTimeoutId:any=null;
  sendOTPApiCallId:string="";
  verifyOTPApiCallId:string="";
  resendOTPApiCallId:string="";
  RESEND_OTP_WAIT_TIME:number=60;
  getBalanceApiCallId:string="";
  getPayeeListApiCallId:string="";
  withdrawAmountApiCallId:string="";
  createPayeeApiCallId:string="";
  getBankListApiCallId:string="";

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

    this.state = {
      // Customizable Area Start
      amount: "",
      paymentValue: "",
      bankDetailsData: {
        accountNumber: "",
        verifyAccountNumber: "",
        bankName: "",
        ifscCode: "",
        bankAddress:"",
        name:"",
        phone_number:""
      },
      bankNameData: ["SBI", "HDFC"],
      uploadedIdFiles: [],
      phoneConfirmModal: false,
      verifyflag: true,
      otp:"",
      currentUser:null,
      sendOtpLoading:false,
      verificationLoading:false,
      resendCountDownTimer:60,
      resendOtpLoading:false,
      sendOTPResponse:{
        id:"",
        token:""
      },
      otpSentSuccessMessage:"",
      otpSentErrorMessage:"",
      profileBalance:0,
      withdrawErrorMessage:"",
      payeeList:[
      ],
      getPayeeLoading:false,
      createPayeeLoading:false,
      sponsorWithdraw:false,
      withdrawLoading:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
       this.handleCurrentUserResponse(user);
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleApiRequest(apiRequestCallId,responseJson)
     
    }
    // Customizable Area End
  }
  handleApiRequest=(apiRequestCallId:string,responseJson:object)=>{
    if(apiRequestCallId===this.sendOTPApiCallId){
       this.handleSendOTPApiResponse(responseJson,"send");
    }else if(apiRequestCallId===this.resendOTPApiCallId){
      this.handleSendOTPApiResponse(responseJson,"resend");
    }else if(apiRequestCallId===this.verifyOTPApiCallId){
      this.handleVerifyOTPApiResponse(responseJson);
    }else if(apiRequestCallId===this.getBalanceApiCallId){
      this.handleProfileBalanceApiCallId(responseJson);
    }else if(apiRequestCallId===this.getPayeeListApiCallId){
      this.handleGetPayeeListApiPayload(responseJson);
    }else if(apiRequestCallId===this.createPayeeApiCallId){
      this.handleCreatePayeeApiResponse(responseJson);
    }else if(apiRequestCallId===this.withdrawAmountApiCallId){
      this.handleWithdrawApiResponse(responseJson);
    }else if(apiRequestCallId===this.getBankListApiCallId){
      this.handleBankListApiResponse(responseJson);
    }
  }
  handleBankListApiResponse=(response:{data?:string[]})=>{
    this.setState({bankNameData:response?.data?response?.data:[]})
  }
  handleWithdrawApiResponse=(response:{
    error?:string;
    success?:string;
  })=>{
    this.setState({withdrawLoading:false})
    if(response?.error){
      toast.error(response.error);
    }else if(response?.success){
      toast.success(response.success);
      this.setState({
         amount:"",
         paymentValue:""
      },()=>{
         this.handleBackToEarningsList();
      })
    }
  }

  handleGetPayeeListApiPayload=(response:{
    success?:boolean;
     data?:IPayeeItem[]
  })=>{
  this.setState({
      payeeList:response?.data?response?.data:[]
      ,getPayeeLoading:false,
  })
  }
  handleCreatePayeeApiResponse=(response:{error?:string,success?:string})=>{
    this.setState({createPayeeLoading:false})
      if(response?.error){
          toast.error(response.error);
          return;
      }
      if(response?.success){
        toast.success(response?.success);
        this.handleCloseForm();
        this.getPayeeList();
      }

  }
  handleProfileBalanceApiCallId=(responseJson:{
    balance?:number;
    in_progress_balance?:number;
  })=>{
    this.setState({profileBalance:responseJson.balance || 0})
  }

  handleVerifyOTPApiResponse=(responseJson:VerifyOtpResponseTypes)=>{
      this.setState({verificationLoading:false});
      if(responseJson.errors && responseJson?.errors?.length>0){
          this.setState({
            otpSentErrorMessage:responseJson.errors[0].pin,
            otpSentSuccessMessage:"",
          })
      }else if(responseJson?.data?.id){
          this.setState({
             verifyflag:false,
             phoneConfirmModal:false,
          },()=>{
             toast.success(responseJson?.meta?.message);
             this.sendResetProfileMessage();
          })
      }else{
        this.setState({
          otpSentErrorMessage:"Something wen't wrong, Please try again.",
          otpSentSuccessMessage:"",
        })
      }
  }

  handleSendOTPApiResponse=(responseJson:SendOtpApiResponse,type:"send"|"resend")=>{
    if(responseJson?.data?.id){
        this.setState({
          sendOtpLoading:false,
          resendOtpLoading:false,
          sendOTPResponse:{
            id:responseJson?.data?.id,
            token:responseJson?.meta?.token as string
          },
          resendCountDownTimer:this.RESEND_OTP_WAIT_TIME,
          verifyflag:false,
          phoneConfirmModal:true,
          otpSentSuccessMessage:"OTP is successfully sent to your mobile number.",
          otpSentErrorMessage:"",
        },this.handleStartCountDownTimer)
    }else if(responseJson.errors && responseJson?.errors?.length>0){
      const errors=responseJson?.errors[0];
      const errorMessage=errors?.full_phone_number;
      this.setState({sendOtpLoading:false,otpSentSuccessMessage:"",otpSentErrorMessage:errorMessage})
    }else{
      this.setState({sendOtpLoading:false})
      toast.error("Something wen't wrong, Please try again.")
    }
  }
  handleCurrentUserResponse=(user:IProfile)=>{
    if(user?.id){
      this.setState({ currentUser: user },this.checkPhoneVerified);
    }
  }
  async componentDidMount() {
    // Customizable Area Start
    this.sendLoginUserMessage();
    this.getProfileBalance();
    this.getPayeeList();
    this.getBankList();
    this.checkSponsorWithdraw();
    // Customizable Area End
  }

  // Customizable Area Start
  checkSponsorWithdraw=()=>{
     const milestoneId=this.props.navigation.getParam("milestoneId");
     if(milestoneId){
      this.setState({sponsorWithdraw:true})
     }
  }
  sendLoginUserMessage=()=>{
    const loginUserMessage: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(loginUserMessage);
  }
  sendResetProfileMessage=()=>{
    const resetProfileMessage: Message = new Message(
      getName(MessageEnum.ResetProfileMessage)
    );
    this.send(resetProfileMessage);
  }
  checkPhoneVerified=()=>{
     const {currentUser}=this.state;
      if(currentUser?.id){
          if(!currentUser?.attributes?.otp_verified){
              this.handleGoToVerification();
          }
      }
  }
  getDisableForWithdrawButton=()=>{
    const {amount,sponsorWithdraw,withdrawLoading,withdrawErrorMessage}=this.state;
    if(sponsorWithdraw){
      return withdrawLoading;
    }else{
      return !Number(amount) || Boolean(withdrawErrorMessage) || withdrawLoading;
    }
  }
  handleResendOTP=()=>{
    //send the otp again
    this.handleSendOTP("resend");
  }


  getProfileBalance=async()=>{
    const header = await this.getHeader();
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.getFrelancerBalanceApiCallId,
      body: null,
      method: "GET",
    });
    this.getBalanceApiCallId= apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
}
  getPayeeList=async()=>{
    this.setState({getPayeeLoading:true})
    const header = await this.getHeader();
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.getPayeeListApiCallId,
      body: null,
      method: "GET",
    });
    this.getPayeeListApiCallId= apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
}
  getBankList=async()=>{
    const header = await this.getHeader();
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.getBankListApiUrl,
      body: null,
      method: "GET",
    });
    this.getBankListApiCallId= apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
}
  handleStartCountDownTimer=()=>{
    this.countDownIntervalId=setInterval(()=>{
      this.setState((prev)=>{
         if(prev.resendCountDownTimer===0 && this.countDownIntervalId){
          clearInterval(this.countDownIntervalId);
         }
         return{
          resendCountDownTimer:prev.resendCountDownTimer-1
        }
      })
    },1000)
  }

   async componentWillUnmount(){
    if(this.countDownIntervalId){
      clearInterval(this.countDownIntervalId);
    }
  }

  handleSendOTP=async(type:"send"|"resend")=>{
     this.setState({sendOtpLoading:true,resendOtpLoading:true,
      otpSentSuccessMessage:"",
      otpSentErrorMessage:"",
    });
      const header=await this.getHeader();
      const apiRequestMessage = CreateRestApiMessage({
        header,
        apiUrl: configJSON.sendOtpApiEndPoint,
        body: null,
        method: "POST",
      });
      if(type==="send"){
        this.sendOTPApiCallId= apiRequestMessage.messageId;
      }else{
        this.resendOTPApiCallId= apiRequestMessage.messageId;
      }
      runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }

  verifyOTP=async()=>{
    const {sendOTPResponse,otp}=this.state;
      this.setState({
        verificationLoading:true
      });
      const header = {
        token: sendOTPResponse?.token,
        "Content-Type": "application/json",
      };
      const payload={
        "pin": otp,
        "sms_otp_id":sendOTPResponse?.id   
      }
      const apiRequestMessage = CreateRestApiMessage({
        header,
        apiUrl: configJSON.verifyOtpApiEndPoint,
        body: JSON.stringify(payload),
        method: "POST",
      });
      this.verifyOTPApiCallId= apiRequestMessage.messageId;
      runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }

  getHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    return header;
  }
  getTokenHeader=async()=>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };
    return header;
  }
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ amount: event.target.value,withdrawErrorMessage:"" },this.checkAmountIsValid)
  }

  checkAmountIsValid=()=>{
    const {amount,profileBalance}=this.state;
    if(Number(amount)>profileBalance){
      this.setState({withdrawErrorMessage:"Insufficient balance."})
    }
  }


  handlePaymentOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value=event.target.value
    this.setState({ paymentValue:value  },this.handleScrollTopAfterSelect)
  }

  handleScrollTopAfterSelect=()=>{
    const {paymentValue}=this.state;
    if(paymentValue!=="newBankAccount"){
      window.scrollTo(0,0);
    }
  }


  handleCloseForm = () => {
    this.setState({ paymentValue: "" })
    window.scrollTo(0, 0)
  }
  handleWithdrawAmount=()=>{
    const {amount,profileBalance,paymentValue,sponsorWithdraw}=this.state;
      if(paymentValue==="newBankAccount" || !paymentValue){
        toast.error("Please select a card to withdraw.");
        return;
      }
      if(sponsorWithdraw){
          this.withdraw();
      }else{
        if(!amount || Number(amount)<0){
          toast.error("Invalid amount.")
          this.setState({withdrawErrorMessage:"Invalid amount."})
          return;
        }
        if(Number(amount)>profileBalance){
          toast.error("Insufficient balance.")
          this.setState({withdrawErrorMessage:"Insufficient balance."})
          return;
        }
        this.withdraw();
    }
  }
  withdraw=async()=>{
    this.setState({withdrawLoading:true})
    const {amount,sponsorWithdraw,paymentValue}=this.state;
    const header=await this.getTokenHeader();
    const formData=new FormData();
    if(!sponsorWithdraw){
      formData.append("amount",amount);
      formData.append("type","withdrawal");
      formData.append("payee_id",paymentValue);
    }else{
      formData.append("type","refund");
      formData.append("payee_id",paymentValue);
      formData.append("milestone_id",this.props.navigation.getParam("milestoneId"));
    }

    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.withdrawApiCallId,
      body: formData,
      method: "POST",
    });
    this.withdrawAmountApiCallId=apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  handleSubmitBankDetails = async(values: BankDetails) => {
    this.setState({createPayeeLoading:true})
    const header=await this.getTokenHeader();
    const formData=new FormData();
    formData.append("name",values.name);
    formData.append("account_number",values.accountNumber);
    formData.append("bank_name",values.bankName);
    formData.append("bank_address",values.bankAddress);
    formData.append("ifsc",values.ifscCode);
    formData.append("phone_number",values.phone_number);

    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: configJSON.createPayeeApiCallId,
      body: formData,
      method: "POST",
    });
    this.createPayeeApiCallId=apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }
  handleBackToEarningsList = () => {
    this.props.navigation.goBack();
  }
  onHandleBack = () => {
    this.send(getNavigationMessage('FreelancerWithdrawal', this.props));
  }

  handleIdUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {

      if (files[0].size > 5 * 1024 * 1024) {
        toast.error("File size exceeds 5 MB")
      }
      else if (
        files[0].type !== "image/png" &&
        files[0].type !== "image/jpeg" &&
        files[0].type !== "image/jpg"
      ) {
        toast.error("Invalid file type. Please upload a PNG, JPG, or JPEG image.");
      }
      else {
        this.setState({ uploadedIdFiles: [files[0]] });
      }
    }

  };
  handleGoToVerification = () => {
    this.setState({ phoneConfirmModal: true })
  }
  handlesubit = () => {
    this.setState({ verifyflag: true, phoneConfirmModal: false }, () => this.send(getNavigationMessage('IdVerification', this.props)))

  }

  handleotpchange = (value:string)=>{
    this.setState({otp:value})
  }
  handleGoToIdSuccessPage = () => {
    this.send(getNavigationMessage('IdVerificationSuccess', this.props));
  }


  // Customizable Area End
}

