import React from "react";

// Customizable Area Start
import "../assets/css/Accountinfo.css";
import {
  lancerlogo,
  freelancer,
  sponser,
  activelogo,
  freelanceractive,
  sponseractive
} from "./assets";
import { Grid } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

// Customizable Area End

// Customizable Area Start

// Customizable Area End

import AccountinfoController, { Props } from "./AccountinfoController.web";
import ProfileSetupHeader from "../../../components/src/profile-setup/ProfileSetupHeader.web";
import ProfileSetupSteper from "../../../components/src/profile-setup/ProfileSetupSteper.web";

export default class Accountinfo extends AccountinfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { profileRole,roleSelectLoader } = this.state;
    const freelancerActiveLogo = profileRole.freelancer ? freelanceractive : freelancer;
    const sponserActiveLogo = profileRole.sponsor ? sponseractive : sponser;
    const activeSubRole=profileRole.subRole;

    return (
      // Customizable Area Start
      <div className="main-wrapper">
        <ProfileSetupHeader
          email={this.state?.currentUserEmail}
          onLogout={this.sendLogoutUserMessage}
        />

        <div className="site-wrapper">
          <div className="setup-account-block">
            <div className="inner-block">
              <a href="#">
                <img src={lancerlogo} alt="logo" />
              </a>
              <h3>
                Welcome to the Lancerscape Portal. Quickly setup your account to
                get started.
              </h3>
            </div>
            <ProfileSetupSteper/>
          </div>
          <div className="choose-profile-block">
           <div className="profile-heading">
              <h6 style={{fontWeight:"bold"} }>Choose profile(s) which best describes your role</h6>
            </div>
            <div className="profile-block-wrapper">
              <div className="profile-item">
                <div
                  className={`profile-block ${profileRole?.freelancer ? "active" : ""}`}
                  test-id="cardFreelancer"
                  onClick={this.handleFreelancerChange}
                >
                  <div className="inner-profile-block">
                    <div className="profile-logo">
                      <img
                        src={freelancerActiveLogo}
                        alt="profile-logo"
                        width={75}
                      />
                    </div>
                    <div className="profile-content">
                      <h5>
                        Freelancer
                        {
                          profileRole?.freelancer && (<img src={activelogo} alt="" />)
                        }
                     
                      </h5>
                      <p>Looking to work</p>
                    </div>
                  </div>
                </div>

                {profileRole?.freelancer && (
                  <div className="profile-block-nav">
                    <ul>
                      <li>
                        <button
                          className={activeSubRole === "individual" ? "current" : ""}
                          test-id="partIndividual"
                            onClick={()=>this.handleSubRoleChange("individual")}
                          style={{ fontWeight: activeSubRole === "individual" ? "bold" : "normal" }} 
                        >
                          Individual
                        </button>
                      </li>
                      <li>
                        <button
                          className={activeSubRole === "student" ? "current" : ""}
                          test-id="partStudent"
                          onClick={()=>this.handleSubRoleChange("student")}
                          style={{ fontWeight: activeSubRole === "student" ? "bold" : "normal" }}
                        >
                          Student
                        </button>
                      </li>
                      <li>
                        <button
                          className={activeSubRole === "corporate" ? "current" : ""}
                          test-id="partCorporate"
                          onClick={()=>this.handleSubRoleChange("corporate")}
                          style={{ fontWeight: activeSubRole === "corporate" ? "bold" : "normal" }}
                        >
                          Corporate
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="profile-item">
                <div
                  className={`profile-block ${profileRole.sponsor ? "active" : ""}`}
                  test-id="cardSponser"
                  onClick={this.handleSponsorChange}
                >
                  <div className="inner-profile-block">
                    <div className="profile-logo">
                      <img
                        src={sponserActiveLogo} 
                        alt="profile-logo"
                        width={75}
                      />
                    </div>
                    <div className="profile-content">
                      <h5>
                         Sponsor{" "}
                        {profileRole?.sponsor && (
                          <img
                            src={activelogo}
                            alt="icon"
                            width={18}
                            style={{ marginLeft: "10px" }}
                          />
                        )}
                      </h5>
                      <p>Hiring Talent</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Grid container className="button-block">
              <Grid item xs="auto">
                <button className="button btn" onClick={()=>this.handlecancle() }  style={{ cursor: 'pointer' }}>Cancel</button>
              </Grid>
              <Grid item xs="auto">
              {(profileRole.freelancer || profileRole?.sponsor) ? (
                <button
                  onClick={this.submitRoleSelection}
                  className="button btn-orange"
                  style={{ cursor: 'pointer' }}
                  disabled={roleSelectLoader}
                >
                  Continue
                </button>
              ) : (
                <button
                  className="button btn-orange"
                  disabled
                  style={{ cursor: 'pointer' }}
                >
                  Continue
                </button>
              )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
