import React from "react";

// Customizable Area Start
import {
  Grid,
  Typography,
  Box,
  FormControl, InputLabel, MenuItem, Select, Input, ListItemText, Checkbox
} from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";

import "../../blocks/dashboard/assets/css/Search.css";
import GenericTab from "./GenericTab";
interface ICardFilter {

  displayFilterSortCard: boolean
  labels: string[],
  selectedfilterOption: any,
  filterOptions: any,
  loading: boolean,
  activeTabColor: string,
  tabLabels?: string[],
  activeTab?: number,
  handleTabChange: (event: React.ChangeEvent<{}>, newValue: number) => void
  handleFilterSortCard: () => void,
  handleOptionsChange: (selectedOptions: any, key: string) => void,
  handleClearSelectedOptions: () => void,
  handleProjectApplyClick: () => void


}

const GenericCardFilter = (
  {
    displayFilterSortCard,
    labels, selectedfilterOption,
    filterOptions,
    loading,
    activeTab,
    tabLabels,
    activeTabColor,
    handleFilterSortCard,
    handleOptionsChange,
    handleClearSelectedOptions,
    handleProjectApplyClick,
    handleTabChange
  }: ICardFilter) => {

  const hasNoOptions = Object.keys(selectedfilterOption).every(key => {
    const option = selectedfilterOption[key];
    return option === null || option.length === 0;
  });
  const btnCondition = hasNoOptions || loading;

  return (
    <>
      {displayFilterSortCard ? <Box style={webStyle.projectFilterBox} >
        <Box style={webStyle.filterHeadLayout}>
          <Typography style={{ fontSize: '18px', fontWeight: 600, color: "#000" }}>Filters & Sort</Typography>
          <Typography><button onClick={handleFilterSortCard} style={webStyle.closeBtn}><ClearIcon style={{ fontSize: '15px' }} /></button></Typography>
        </Box>

        {console.log("tablevel", tabLabels)}
        {tabLabels?.length ?
          <GenericTab
            className={"tabLabels"}
            activeTabColor={"#EC952D"}
            tabLabels={tabLabels}
            activeTab={activeTab}
            handleTabChange={handleTabChange} />
          : null}

        <Grid style={{ marginTop: "20px" }} container spacing={2}>
          {Object.entries(filterOptions).map(([key, options], index) => {
            const actualKey = key.startsWith('__') ? key.substring(2) : key;
            const isMultiSelect = !key.startsWith('__');

    return (
      <Grid item xs={6} md={6} key={key}>
        <InputLabel id={`${actualKey}-select-label`} style={{ marginBottom: '0px' }}>
          {labels[index]}
        </InputLabel>
        <FormControl style={{ display: 'block' }}>
          <Select
            data-test-id={`${actualKey}-optionButtonId`}
            fullWidth
            labelId={`${actualKey}-select-label`}
            displayEmpty
            multiple={isMultiSelect}
            value={selectedfilterOption[actualKey] || (isMultiSelect ? [] : '')}
            onChange={(selectedOptions) => handleOptionsChange(selectedOptions, actualKey)}
            input={<Input />}
            IconComponent={()=>
              <div style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)', zIndex: -1 }}>
              <ExpandMoreIcon />
            </div>
          }
            renderValue={(selected) => {
              if ((selected as string[]).length === 0) {
                return <em>Select {labels[index]}</em>;
              }
              const selectedData = Array.isArray(selected) ? (selected as string[]) : [selected as string];
              return Array.isArray(selectedData) ? selectedData.join(', ') : selectedData;
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {(options as string[]).map((option) => (
              <MenuItem key={option} value={option}>
                {isMultiSelect ? (
                  <>
                    <Checkbox checked={(selectedfilterOption[actualKey] as string[]).includes(option)} />
                    <ListItemText primary={option} />
                  </>
                ) : (
                  <ListItemText primary={option} />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  })}
</Grid>

        <Box style={webStyle.filterBoxButtons}>
          <button
            onClick={handleClearSelectedOptions}
            style={{
              color: '#000',
              borderRadius: '26px',
              fontSize: '14px',
              fontWeight: 600,
              padding: '0px 18px',
              background: '#F2F2F2',
              height: '40px',
              width: '100px',
              border: 'none',
              cursor: 'pointer'
            }}
          >
            Cancel
          </button>
          <button
            style={{
              color: 'rgb(255, 255, 255)',
              borderRadius: '26px',
              fontSize: '14px',
              fontWeight: 600,
              padding: '0px 18px',
              background: activeTabColor,
              height: '40px',
              width: '100px',
              border: 'none',
              cursor:
                btnCondition
                  ? 'not-allowed'
                  : 'pointer',
              opacity:
                btnCondition
                  ? 0.5
                  : 1
            }}
            onClick={handleProjectApplyClick}
            disabled={
              btnCondition
            }
          >
            Apply
          </button>
        </Box>
      </Box> : null}
    </>
  )
}
const webStyle = {
  searchBarBox: {
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'center',
    gap: '30px',
  },
  BrowseHeadingBox: {
    marginBottom: '40px',
  },
  boxStyle: {
    backgroundColor: '#F5F5FA',
    padding: '20px 20px',
    borderRadius: '14px',
    border: '1px solid #E4E6E8',

  },
  browseItemsImage: {
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'center',
    gap: '10px',
    color: '#000',
  },
  browseItemslayout: {
    display: 'flex',
    cursor: 'pointer',
    padding: '20px 0',
    justifyContent: ' space-between',
    '&:hover': {
      color: 'red',
    }
  },
  projectDescriptionBox: {
    borderRadius: '15px',
    margin: '30px 0',

    border: '1px solid #E4E6E8'
  },
  projectFilterBox: {
    borderRadius: '15px',
    border: '1px solid #E4E6E8',
    margin: "24px 0px",
    padding: "18px 24px",
  },
  filterHeadLayout: {
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'center',
    marginBottom: "24px"
  },
  closeBtn: {
    width: '24px',
    height: '24px',
    background: 'transparent',
    border: '1px solid',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: "#000"
  },
  filterBoxButtons: {
    display: 'flex',
    justifyContent: ' end',
    alignItems: 'center',
    gap: '30px',
    marginTop: '30px',

  },
  projectHeader: {
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'center',
  },
  projectSubInfo: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    gap: '20px',
  },
  category: {
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'center',
    marginTop: '20px',
    flexFlow: 'wrap',
    gap: ' 10px',
    maxWidth: '600px',
  },
  categoryBox: {
    background: '#E4E6E8',
    color: '#8083A3',
    padding: '10px 20px',
    borderRadius: '20px',
  },
  projectDescriptionText: {
    marginTop: '20px'
  },
  projectDetails: {
    display: 'flex',
    justifyContent: ' space-between',
    alignItems: 'center',
    margin: '20px 0',
  },
  bidTheProjects: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'center',


  },
  loadMoreBtn: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px',
    justifyContent: 'center',
    margin: 'auto',
  } as const,
  projectDetailsTab: {
    marginTop: '24px'
  }

}



export default GenericCardFilter