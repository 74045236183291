import React from "react";
import {info_circle} from "../../../blocks/auctionbidding/src/assets";
import {Tooltip} from '@material-ui/core'
interface CurrencyConversion{
        freelancer:{
          currency:string;
          currency_symbol:string;
          amount:number;
        }; 
        sponsor:{
          currency:string;
          currency_symbol:string;
          amount:number;
        };
}
const defaultConversion:CurrencyConversion={
  freelancer:{
    currency:"",
    currency_symbol:"",
    amount:0
  },sponsor:{
    currency:"",
    currency_symbol:"",
    amount:0
  }
}
const replaceIntoSponsorString=(item:string,currency_conversion:CurrencyConversion)=>{
    if (item.includes("MARKER_FOR_PAYMENT_AMOUNT") && currency_conversion) {
        const freelancer_conversion=currency_conversion?.freelancer;
        const sponsor_conversion=currency_conversion?.sponsor;
        const isSameCurrency=freelancer_conversion?.currency!==sponsor_conversion?.currency;
        return item.replace(
            "MARKER_FOR_PAYMENT_AMOUNT",
           `<span style="display: flex; align-items: center"><span style="font-weight:bolder;color:black; display: flex">${freelancer_conversion?.currency_symbol}${freelancer_conversion?.amount}</span>${isSameCurrency?`<span style="color:rgb(0, 167, 162);display:flex;">(${sponsor_conversion?.currency_symbol}${sponsor_conversion?.amount})</span><div style="position:relative;"><img class="currency_tooltip_info_icon" style="height:15px;width:15px;display:flex;cursor:pointer" src="${info_circle}" alt="info icon"/><p class="currency_tooltip">Converted from (${freelancer_conversion?.currency}) into (${sponsor_conversion?.currency}) based on today\'s conversion rate</p></div></span>`:''}`
        );
    }
    return item;
}
const replaceIntoFreelancerString=(item:string,currency_conversion:CurrencyConversion)=>{
    if (item.includes("MARKER_FOR_PAYMENT_AMOUNT") && currency_conversion) {
        const freelancer_conversion=currency_conversion?.freelancer;
        const sponsor_conversion=currency_conversion?.sponsor;
        const isSameCurrency=freelancer_conversion?.currency!==sponsor_conversion?.currency;
        return item.replace(
            "MARKER_FOR_PAYMENT_AMOUNT",
            `<span style="display: flex; align-items: center"><span style="font-weight:bolder;color:black; display: flex">${sponsor_conversion?.currency_symbol}${sponsor_conversion?.amount}</span>${isSameCurrency?`<span style="color:#EC952D;display:flex;">(${freelancer_conversion?.currency_symbol}${freelancer_conversion?.amount})</span><div style="position:relative;"><img class="currency_tooltip_info_icon" style="height:15px;width:15px;display:flex;cursor:pointer" src="${info_circle}" alt="info icon"/><p class="currency_tooltip">Converted from (${sponsor_conversion?.currency}) into (${freelancer_conversion?.currency}) based on today\'s conversion rate </p></div></span> `:''}`
             );
    }
    return item;
}
export const getSponsorMulitCurrencyHTML=(description:string[] | string,currency_conversion:CurrencyConversion=defaultConversion)=>{
      let replacedString:string="";
      if(!currency_conversion){
        currency_conversion=defaultConversion;
      }
      if(Array.isArray(description)){
        replacedString = description.map((item) => {
            if(item){
              return replaceIntoSponsorString(item,currency_conversion);
            }
            return item;
        }).join("");
      }else if(typeof description==="string" && description){
        replacedString=replaceIntoSponsorString(description,currency_conversion);
      }else{
        replacedString=description;
      }
      return replacedString;
  }
export const getFreelancerMulitCurrencyHTML=(description:string[] | string,currency_conversion:CurrencyConversion=defaultConversion)=>{
    let replacedString:string="";
    if(!currency_conversion){
      currency_conversion=defaultConversion;
    }
    if(Array.isArray(description)){
      replacedString = description.map((item) => {
        if(item){
          return replaceIntoFreelancerString(item,currency_conversion);
        }
        return item;
      }).join("");
    }else if(typeof description==="string" && description){
      replacedString=replaceIntoFreelancerString(description,currency_conversion);
    }else{
        replacedString=description;
      }
    return replacedString;
  }



export const getSponsorTaxAmountElement=(currency_conversion:CurrencyConversion)=>{
  const freelancerCost=currency_conversion.freelancer;
  const sponsorCost=currency_conversion.sponsor;
  return(
    <>
        {freelancerCost?.currency_symbol} {freelancerCost?.amount}
        {sponsorCost?.currency != freelancerCost?.currency &&
            <>
                <span  id="1" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorCost?.currency_symbol}{sponsorCost.amount})</span>
                <Tooltip enterTouchDelay={0} leaveTouchDelay={3000}  title={`Converted from (${freelancerCost?.currency}) into (${sponsorCost?.currency}) based on today's conversion rate`}>
                    <img  id="sendoffer" style={{height: "13px",  width: "13px" , marginLeft:"5px"}} src={info_circle} alt="info"  />
                </Tooltip>
            </>
        }
    </>
  )
}