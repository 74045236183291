import React, { useRef } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { IMessage } from "../../../blocks/chat/src/ChatController";
import {Button,Box} from '@material-ui/core'
import MessageItem from "./MessageItem.web";
import CircularProgress from "@material-ui/core/CircularProgress";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chat_message_body: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "100px",
    },
    horizontal_center:{
      width:"100%",
      display:"flex",
      alignItems:"center",
      justifyContent:"center"
    }
  })
);

interface Props {
  messageList: IMessage[];
  handleSetRepliedMessage: (msg: IMessage | null) => void;
  handleChangeDeleteMessageModal: (open: boolean, msg: IMessage | null) => void;
  totalData:number;
  currentUserId:string;
  getMessageLoading:boolean;
  onLoadMore:()=>void;
  autoScroll:boolean;
  handleEditMessageOpen:(value:IMessage)=>void;
  sponsorUser?:boolean;
}
const ChatMainContent: React.FC<Props> = ({
  messageList,
  handleSetRepliedMessage,
  handleChangeDeleteMessageModal,
  totalData,
  currentUserId,
  getMessageLoading,
  onLoadMore,
  autoScroll,
  handleEditMessageOpen,
  sponsorUser
}) => {

  const classes = useStyles();
  const lastMessageRef = useRef<HTMLInputElement | null>(null);
  const beforeScrollMessageId = useRef<null|string>(null);

  React.useEffect(() => {
    if(autoScroll){
      lastMessageRef?.current?.scrollIntoView({ behavior: "auto" });
    }else if(beforeScrollMessageId.current){
      const msgItem = document.getElementById(`message_item_${beforeScrollMessageId.current}`);
      if (msgItem) {
        msgItem.scrollIntoView({ behavior: "auto" });
        beforeScrollMessageId.current=null;
      }
    }
  }, [messageList,autoScroll]);

  const handleLoadMore=()=>{
    onLoadMore();
    beforeScrollMessageId.current=messageList[0].id;
  }


  const isShowMoreEnabled=!getMessageLoading && (totalData>0 && totalData>messageList.length)
  return (
    <div className={classes.chat_message_body}>
      {
          isShowMoreEnabled && (
            <Box style={{marginTop:"10px",marginBottom:"10px"}} className={classes.horizontal_center}>
              <Button onClick={handleLoadMore}>
                Load More
              </Button>
            </Box>
          )
      }
       {
          getMessageLoading && (
            <Box style={{marginTop:"10px",marginBottom:"10px"}} className={classes.horizontal_center}>
                <CircularProgress size={24} />
            </Box>
          )
      }
      {messageList.map((item, index) => {
        const myChat=item?.attributes?.sender===currentUserId;
        return (
          <MessageItem
            key={item?.updated?new Date().getTime():item.id}
            message={item}
            myChat={myChat}
            handleSetRepliedMessage={handleSetRepliedMessage}
            onDelete={(msg) => handleChangeDeleteMessageModal(true, msg)}
            handleEditMessageOpen={handleEditMessageOpen}
            sponsorUser={sponsorUser}
          />
        );
      })}
      <div ref={lastMessageRef} />
    </div>
  );
};

export default ChatMainContent;
