import React from "react";

// Customizable Area Start
import {
    Card,
    Typography,
    Box,
    CardMedia,
    Grid,
    Button,
    Modal,
    FormControl,
    TextField,
    FormControlLabel,
    IconButton,
    RadioGroup,
    Radio,
    Avatar,
    Tooltip
} from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';
import { withStyles } from "@material-ui/core/styles";
import {
    KeyboardBackspace as KeyboardBackspaceIcon,
    MoreVert as MoreVertIcon,
    LocationOn as LocationOnIcon,
    QueryBuilder as QueryBuilderIcon,
    Error as ErrorIcon,
    CancelOutlined as CancelOutlined,
    CloseSharp as CloseSharp,
    EditOutlined, Add as AddIcon
} from "@material-ui/icons";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import "../../blocks/dashboard/assets/css/Search.css";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { chatIcon } from "../../blocks/dashboard/src/assets";
import { profileIcon } from "../../blocks/favourites/src/assets"
import StarRatings from 'react-star-ratings';
import { configJSON } from "../../blocks/scheduling/src/SchedulingController";
import CircularProgress from "@material-ui/core/CircularProgress";
import { info_circle } from "../../blocks/auctionbidding/src/assets";

interface FavLancerProps {
    favcards: any;
    instance?: any
    navigation: (id: number) => void
    removeFavourite: (id: number) => void
}
const StyledRadioButton = withStyles({
    root: {
        color: "#e4e6e8",
        '&$checked': {
            color: "#00A7A2",
        },
    },
    checked: {},
})(Radio);

const getHireTotalProjectCostElement = (lancersymbol: any, lanceramount: any , sponsorcost: any,lancercurrency:any) => {
    return (
        <>
            {lancersymbol} { lanceramount}{"/Hr "}{"   "}
            {lancersymbol != sponsorcost?.currency &&
                <>
                    <span  id="6008" style={{ color: "#00a7a2", fontWeight: 400, fontSize: "12px" }} >({sponsorcost?.currency}  {sponsorcost?.amount}{" /Hr "})</span>
                    <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${lancercurrency}) into (${sponsorcost?.currency}) based on today's conversion rate`}>
                        <img  id="sendoffer" style={{height: "13px",  width: "13px" , marginLeft:"5px"}} src={info_circle} alt="info"  />
                    </Tooltip>
                </>
            }
        </>
    )
}

const FavLancer: React.FC<FavLancerProps> = ({ favcards, navigation, removeFavourite, instance }) => {
    const rating = favcards.attributes.lancer.rating ? favcards.attributes.lancer.rating : 0
    const charges = favcards.attributes.lancer.charges ? favcards.attributes.lancer.charges : "NA"
    const earnings = favcards.attributes.lancer.earnings ? favcards.attributes.lancer.earnings : "NA"
    const freelancerId = favcards.attributes.lancer.id
    const freelanceraccountId = favcards.attributes.lancer.account_id
    const convertedCost=favcards.attributes?.currency_conversion;
    console.log(convertedCost,"convertedCost")
    const freelancerConvertedCost=convertedCost?.freelancer;
    const sponsorConvertedCost=convertedCost?.sponsor;

    const handleButtonText = (text: string) => {
        return (instance.state.loading ? <CircularProgress size={24} /> : text)
    }
    return (
        <>
            <style>
                {`
                    .resFreelancerDetails {
                        max-width: 78%;
                        margin: 0 auto;
                    }
                    .resTopFreelancerInfo {
                        padding-left: 15px;
                    }
                    @media(max-width: 959px) {
                        .resFreelancerDetails {
                            max-width: 100%;
                        }
                        .resFreelancerButton {
                            margin-top: 20px;
                            justify-content: start !important;
                        }
                    }
                        
                      @media(max-width: 520px) {
                        .resFreelancerItem {
                            flex-direction: column;
                        }
                        .resTopFreelancerInfo {
                            padding-left: 0;
                            margin-top: 15px;
                        }
                      }  
                    @media(max-width: 320px) {
                        .resFreelancerButton{
                            flex-wrap: wrap;
                        }
                        .resFreelancerButton .resHireBtn {
                            width: 100% !important;
                        }
                    }
                `}
            </style>
            <Grid container className="resFreelancer_list" style={webStyle.freelancer_list_item}  >
                <Grid item  sm={12} md={7}>
                    <Grid container className="resFreelancerItem" style={webStyle.freelancer_item_details_container}>
                        <Grid item xs="auto" sm={2} md={2}>
                            <Avatar style={webStyle.freelancer_avatar} src={favcards?.attributes?.photo?.length > 0 ? favcards.attributes.photo[0]?.url : profileIcon} />
                        </Grid>
                        <Grid item xs="auto" sm={10} md={10} className="resTopFreelancerInfo">
                            <Box style={webStyle.freelancer_item_info}>
                                <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <Typography component={"h1"} variant="h6" style={webStyle.heading}>
                                        {favcards.attributes.lancer.full_name}
                                    </Typography>
                                </Box>
                                <p style={webStyle.subtitle} title={favcards.attributes.skills.join(' | ')}>
                                {favcards.attributes.skills.length > 1 ? favcards.attributes.skills.slice(0,5).join(' | ') + '...' : favcards.attributes.skills[0]}
                                    <Box display="flex" alignItems="center">
                                        <span><LocationOnOutlinedIcon style={webStyle.subtitle} /> </span>
                                        {favcards.attributes.lancer.state},{favcards.attributes.lancer.country}
                                    </Box>
                                </p>
                                <Box style={webStyle.row}>
                                    <Rating value={rating} readOnly />
                                    <Typography style={webStyle.ratingsubtitle} component={"p"} variant="subtitle1">
                                        <span style={{ color: "black" }}>{rating} / 5</span>
                                    </Typography>
                                </Box>
                                
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item  sm={12} md={5}>
                    <Box className="resFreelancerButton" style={{ display: "flex", gap: "10px", alignSelf: "start", justifyContent: "end" }}>
                        <IconButton style={webStyle.back_button} onClick={()=>instance.handleSendMessage(freelanceraccountId,favcards.attributes.projects_with_bids[0].project_id)}>
                            <img style={{ height: "25px", width: "25px", cursor: "pointer" }} src={chatIcon} />
                        </IconButton>
                        <Button style={webStyle.removeButton} className="resRemoveButton" onClick={() => removeFavourite(freelancerId)}>Remove</Button>
                        <Button className="resHireBtn" style={webStyle.hireButton} data-id="hirebutton" onClick={() => instance.handleShowModal(favcards)}>Hire</Button>
                    </Box>
                </Grid>
                <Grid container className="resFreelancerDetails" style={{ marginTop: "15px" }} spacing={2}>
                    <Grid item xs="auto" sm={4} md={4} >
                                        <Box style={webStyle.properties_item_container}>
                                            <Typography style={webStyle.labletext} component={"p"} variant="subtitle1">
                                                Rate
                                            </Typography>
                                            {favcards?.attributes?.rate_per_hours ? <Typography style={webStyle.page_heading}  component={"p"} variant="subtitle1">
                                                {getHireTotalProjectCostElement(favcards?.attributes?.lancer?.currency_symbol,favcards?.attributes?.rate_per_hours,sponsorConvertedCost,favcards?.attributes?.lancer?.currency)}
                                            </Typography> :  <Typography style={webStyle.page_heading}  component={"p"} variant="subtitle1">
                                                NA
                                            </Typography> }
                                        </Box>
                    </Grid>
                    <Grid item xs="auto" sm={4} md={4}>
                                        <Box style={webStyle.properties_item_container}>
                                            <Typography style={webStyle.labletext} component={"p"} variant="subtitle1">
                                                Total Experience
                                            </Typography>
                                            <Typography style={webStyle.page_heading} component={"p"} variant="subtitle1">
                                                {favcards?.attributes?.lancer?.experience_in_years} years
                                            </Typography>
                                        </Box>
                    </Grid>
                    <Grid item xs="auto" sm={4} md={4}>
                                        <Box style={webStyle.properties_item_container}>
                                            <Typography
                                                style={webStyle.labletext2}
                                            >
                                                Favorite Projects
                                            </Typography>
                                            <p title={favcards.attributes.projects_with_bids?.map((val: any, index: number) => { return val.project_name })}
                                                style={webStyle.page_heading2}
                                            >
                                                {favcards.attributes.projects_with_bids?.map((val: any, index: number) => {
                                                    if (index === 0) {
                                                        return val.project_name;
                                                    } else if (index === 1) {
                                                        return ` , ${val.project_name}`;
                                                    }
                                                    return null;
                                                }).filter(Boolean)}

                                                {favcards.attributes.projects_with_bids && favcards.attributes.projects_with_bids.length > 2 && (
                                                    `, ... ${favcards.attributes.projects_with_bids.length - 2} more`
                                                )}
                                            </p>
                                        </Box>
                    </Grid>
                </Grid>
            </Grid >
            <Modal open={instance.state.showModal} >
                <Grid sm={6}
                    style={webStyle.modalStyle}
                    direction={"column"}
                >
                    <Box style={webStyle.headingContainer}>
                        <Typography style={webStyle.modalHeading}>Hire Freelancer
                        </Typography>
                        <IconButton onClick={instance.handleCloseModal}><CancelOutlined fontSize="medium" /></IconButton>
                    </Box>
                    <Box p={1}>
                        <Box style={webStyle.radioContainer}>
                            <FormControl fullWidth>
                                <Typography style={webStyle.reasonsHeading}>{configJSON.radiosLabel}</Typography>
                                <RadioGroup
                                    value={instance.state?.selectedOption}
                                    data-id="inputName"
                                    onChange={(e) => instance.handleRadioChange(e, instance.state.option?.attributes?.projects_with_bids)}
                                >
                                    {instance.state.option?.attributes?.projects_with_bids?.map((project: any) => (
                                        <FormControlLabel
                                            key={project.project_id}
                                            value={`${project.project_id}`}
                                            control={<StyledRadioButton />}
                                            label={project.project_name}
                                            style={webStyle.radioLabel}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box style={webStyle.modalButtons}>
                            <Button
                                data-id="modal"
                                style={webStyle.onHoldBtnStyle}
                                onClick={instance.handleCloseModal}
                            >
                                {configJSON.cancelBtnTxt}
                            </Button>
                            <Button
                                style={webStyle.closeBtnSyle}
                                disabled={instance.state?.selectedOption.length == 0 ? true : false}
                                onClick={() => instance.handleHireLancer(instance?.option)}
                            >
                                {handleButtonText("Hire")}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Modal>
        </>

    )
}
const webStyle = {
    removeButton: {
        fontWeight: "bold",
        height: "46px",
        width: "122px",
        backgroundColor: "#F2F2F2",
        borderRadius: "23px",
        fontSize: "14px"
    } as const,
    hireButton: {
        fontWeight: "bold",
        color: "white",
        height: "46px",
        width: "122px",
        backgroundColor: "rgba(0, 167, 162, 1)",
        borderRadius: "23px",
        fontSize: "14px"
    } as const,
    subgrid: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "24px",
        marginRight: "24px",
        width: '100%'
    },
    modalStyle: {
        display: "flex",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: '0px 3px 4px rgba(153,155,168,0.25)',
        background: '#ffffff',
        borderRadius: '16px',
    } as const,
    headingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #8083a3',
        padding: '0.5vw 0 0 1vw',
    } as const,
    modalHeading: {
        color: '#222222',
        fontSize: '20px',
        fontWeight: 700,
        fontFamily: 'inter',
    } as const,
    notification: {
        fontSize: '10px',
        border: '1px solid #8083a3',
        color: '#8083a3',
    } as const,
    reasonsHeading: {
        color: '#222222',
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: 'inter',
    } as const,
    radioLabel: {
        color: '#8083a3',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Inter',
        marginBottom: '0',
    } as const,
    notificationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1vw 0',
    } as const,
    radioContainer: {
        paddingTop: '1vw',
        paddingLeft: "1vw",
    } as const,
    modalButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '1vw',
    } as const,
    onHoldBtnStyle: {
        borderRadius: 23,
        background: "#F2F2F2",
        color: "#222",
        textAlign: "center" as "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        padding: "5 35",
    } as const,
    closeBtnSyle: {
        borderRadius: 23,
        backgroundColor: "#00A7A2",
        color: "#fff",
        textAlign: "center" as "center",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        padding: "5 35",
        "&:hover": {
            backgroundColor: "#00A7A2",
        },
    } as const,



    // New card css
    freelancer_list_item: {
        border: "1px solid rgba(0,0,0,.1)",
        borderRadius: "20px",
        padding: "20px",
        margin: "15px 0px"
    } as const,
    freelancer_avatar: {
        width: "70px",
        height: "70px",
    },
    freelancer_item_details_container: {
        // display: "flex",
        // gap: "15px",
        flexGrow: 1,
    } as const,
    freelancer_item_info: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    } as const,
    properties_item_container: {
        display: "flex",
        flexDirection: "column",
    } as const,
    skill_container: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "20px",
        marginTop: "15px",
    } as const,
    skillItem: {
        padding: "10px 20px",
        background: "#f5f6f5",
        borderRadius: "15px"
    },
    invite_button: {
        background: "#00A7A2",
        borderRadius: "20px",
        padding: "10px 20px",
        color: "white",
        textTransform: "capitalize",
        "&:hover": {
            background: "#00A7A2"
        }
    } as const,
    available_button: {
        background: "white",
        borderRadius: "20px",
        padding: "3px 11px",
        textTransform: "capitalize",
        border: "1px solid #00A7A2",
        color: "#00A7A2",
        fontWeight: "bold",
        fontSize: "11px",
        "&:hover": {
            background: "white"
        }
    } as const,

    decline_button: {
        background: "red",
        color: "white",
        borderRadius: "20px",
        padding: "6px 20px",
        textTransform: "capitalize",
        "&:hover": {
            background: "red"
        }

    } as const,
    row: {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    } as const,
    page_heading: {
        fontWeight: "bolder",
        fontSize:"15px",
    } as const,
    page_heading2: {
        fontWeight: "bolder",
        fontSize:"15px",
        margin:"0px",
        padding:"0px",
        lineHeight: "1.75",
    } as const,
    heading: {
        fontWeight: "bolder",
    } as const,
    subtitle: {
        color: "#96939c",
        fontWeight: "bold",
        fontSize: "13px"
    } as const,
    ratingsubtitle: {
        color: "#96939c",
        fontWeight: "bold",
        fontSize: "13px",
        marginTop:"2.px"
    } as const,
    labletext: {
        color: "#96939c",
        fontWeight: "bold",
        fontSize: "13px"
    } as const,
    labletext2: {
        color: "#96939c",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "1.75",
    } as const,
    back_button: {
        background: "#f2f2f2",
        textTransform: "capitalize",
        padding: "13px",
        borderRadius: "50%"
    } as const,
};

export default FavLancer;