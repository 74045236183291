import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Box,
  Button,
  Typography,

  // Customizable Area Start
  Paper,
  TextField,
  Divider,
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { contact } from "./assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../assets/css/ResendLink.css";
const logoImg = require("../assets/logo.jpeg");
const theme = createTheme({
  palette: {},
  overrides: {
    MuiFormLabel: {
      root: {
        lineHeight: "none"
      }
    },
    MuiInputBase: {
      input: {
        padding: 0
      }
    },
    MuiButton:
    {contained :{
      color: '#fff'
    }
  }
  }
});
const labelStyle = {
  color: "#8083A3"
};
// Customizable Area End
import ForgetPasswordController, {
  Props,
  configJSON
} from "./ForgetPasswordController.web";

export default class ForgetPassword extends ForgetPasswordController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    console.log("recaptcha", this.state.recaptcha);
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box
          display="flex"
          justifyContent="end"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "6px",
            marginRight: "10px"
          }}
        >
          <img style={{ height: "5vh" }} src={contact} alt="" />
          <a
            style={{
              marginTop: "5px",
              marginLeft: "5px",
              color: "#8083A3",
              textDecoration: "none",
              fontWeight: 700
            }}
            href="#"
          >
            Help and Support
          </a>
        </Box>

        <Box sx={webStyle.main_container}>
          <Box py={4} style={webStyle.logo_imgBox}>
            <img style={webStyle.logo_img} src={logoImg} alt="" />
          </Box>
          <Box component={Paper} mt={1} style={webStyle.main_box}>
            <Box py={3} style={webStyle.text_box1}>
              <Typography style={{ fontWeight: "bold" }}>
                Forgot Password?
              </Typography>
            </Box>
            <Divider />
            <Box p={3}>
              <Typography
                gutterBottom
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: 500
                }}
              >
                {configJSON.forgetText}
              </Typography>
              <Typography
                gutterBottom
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  fontWeight: 500
                }}
              >
                You will receive a link to create a new password via email.
              </Typography>
              <TextField
                autoFocus={true}
                tabIndex={0}
                data-test-id="txtInputEmail"
                fullWidth
                inputProps={webStyle.email_text}
                type="email"
                margin="normal"
                id="email"
                label="Email"
                name="email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                InputLabelProps={{ style: labelStyle }}
              />

              <Typography style={webStyle.email_validation_error}>
                {this.state.emailErrors.emailErr}
              </Typography>
              <ReCAPTCHA
                sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR"
                onChange={(e: any) => this.onChangeRecaptcha(e)}
                id="recaptchaId"
                tabIndex={1}
              />
              <style>
                {`
                 @media(max-width: 420px){
                  .bottom_btn {
                    flex-direction: column-reverse;
                  }
                  .bottom_btn div {
                    margin-top: 15px;
                    text-align: center;
                  }
                 }
                `}
              </style>
              <Box style={webStyle.bottom_btn} className="bottom_btn" mt={4}>
                <div  tabIndex={-1}>
                  <Button
                    style={webStyle.cancel_btn}
                    tabIndex={-1}
                    disableFocusRipple
                    variant="contained"
                    data-test-id="btnonclick"
                    onClick={this.cancelonClick}
                  >
                    <Typography
                      variant="button"
                      style={{...webStyle.btn_text,color:"#222"}}
                    >
                      Cancel
                    </Typography>
                  </Button>
                </div>
                <div tabIndex={0} >
                  <Button
                  tabIndex={0}
                    variant="contained"
                    data-test-id="btnresend"
                    id="two"
                    disableFocusRipple
                    className={"button resetLink-button"}
                    disabled={this.getResetDisable()}
                    onClick={() => this.forgetPassword(true)}
                  >
                    {this.state.forgetLoader ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Typography
                        // tabIndex={0}
                        variant="button"
                        style={webStyle.btn_text}
                      >
                        Send Reset Link
                      </Typography>
                    )}
                  </Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  logo_imgBox: {
    display: "block",
    textAlign: "center" as const
  },
  logo_img: {
    height: "35px",
    width: "145.15px",
  },
  main_box: {
    border: "1px solid #E4E6E8",
    borderRadius: "18px",
    padding: "10px 15px",
    maxWidth: "450px"
  },
  text_box1: {
    display: "flex",
    justifyContent: "center"
  },

  text_subtitle2: {
    fontSize: "14px",
    textAlign: "center"
  },
  email_text: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#000000"
  },
  checkbox_btn: {
    marginTop: "4px"
  },
  checkbox_box: {
    backgroundColor: "F2F2F2",
    border: "1px solid #E4E6E8"
  },
  checkbox_text: {
    marginTop: "11px"
  },
  cancel_btn: {
    borderRadius: "19px",
    width: "180px",
    height: "43px",
    overflow: "hidden",
    backgroundColor:"#F2F2F2",
  },
  // sendresend_btn: {
  //   backgroundColor: ' #EC952D',
  //   borderRadius: '19px',
  //   width: '200px',
  //   height: '43px',
  //   overflow:'hidden'
  // },
  btn_text: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#FFFFFF",
    textTransform: "capitalize" as const,
    overflow: "hidden"
  },
  email_validation_error: {
    fontSize: "0.75rem",
    color: "#f44336",
    marginBottom: 27,
   },

  main_container: {
    // marginLeft: "25rem"
    alignSelf: "center"
  },
  bottom_btn: {
    display: "flex",
    justifyContent: "space-between",
    '@media(max-width:420px)': {
      display: "block"
    }
  },
  
};
// Customizable Area End
