import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";

  // Customizable Area Start
export const configJSON = require("./config");
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { getStorageData } from "framework/src/Utilities";
interface ProjectInfo {
  id: string;
  type: string;
  attributes: {
      id: number;
      project_name: string;
      start_date: string | null;
      end_date: string | null;
      add_members: any | null;
      url: string | null;
      description: string;
      make_projects_public: boolean;
      budget_type: string;
      profile: UserProfile;
      project_status: number;
      expected_experience: number;
      bid_status: number;
      language_of_converse: string;
      level_of_language: string;
      project_size: string;
      amount: number;
      minimum_hours: number | null;
      maximum_hours: number | null;
      negotiable: boolean;
      project_category: string;
      speciality: string;
      cost: number | null;
      number_of_bidders: number;
      freelancer_hired: boolean;
      freelancer_id: number | null;
      rate_per_hour: number | null;
      accepted_amount: number | null;
      total_cost: number | null;
      reason_for_closure: string | null;
      reason_for_rejection: string | null;
      project_clouser_status: string | null;
      escrow_amount: number;
      total_paid_amount: number;
      experience_required: number;
      sponsor_profile: UserProfile;
      EST_time: string;
      posted_at: string;
      certificate: string | null;
      associated_projects: AssociatedProject[];
      documents: any[];
      skills: Skill[];
      estimated_budget: number;
      No_of_milestone_completed: string;
      profile_full_name: string;
      proposal_count: number;
      profile_image: Image[];
      pdf_image: any | null;
      project_milestones: ProjectMilestone[];
      is_favourite_project: boolean | null;
      submitted_bid_type: string | null;
      number_of_propsals: number;
      select_duration_type: string;
      bid_id: number | null;
  }
}

interface UserProfile {
  id: number;
  country: string;
  address: string;
  postal_code: string;
  account_id: number;
  created_at: string;
  updated_at: string;
  city: string;
  first_name: string;
  last_name: string;
  gender: string;
  date_of_birth: string;
  mobile_number: string;
  time_zone: string;
  state: string;
  certification: string[];
  experience_in_years: number;
  address_line_2: string;
  sub_role: string | null;
  field_of_study_id: number | null;
  rating: number | null;
  work_load: number | null;
  hobbies: any[];
  educational_qualification: any[];
  user_status: string | null;
  completeness_percentage: number;
  role_id: number;
  full_name: string;
  blocked_profile: boolean;
  country_code: string | null;
  availability: boolean;
  photo: Image[] | null;
}
interface AssociatedProject {
  id: number;
  associated_with_name: string | null;
}
interface Skill {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  expertise_in_yrs: number | null;
}

interface Image {
  url: string;
}

interface ProjectMilestone {
  id: number;
  title: string;
  description: string;
  cost: string;
  days: number;
  hours: any;
  status: string;
  due_date: any;
  start_date: any;
  end_date: any;
  completed_date: any;
  total_cost: any;
  milestone_sequence: number;
}
export interface IInvitation {
  id: string;
  type: string;
  attributes: {
      id: number;
      project_id: number;
      freelancer_id: number;
      sponsor_id: number;
      status: string;
      message: string;
      decline_reason: string | null;
      bid_id: number | null;
      created_at: string;
      updated_at: string;
      project_info: {
        data:ProjectInfo;
      };
      sponsor_info: {
        data:{
          attributes:UserProfile
        };
      };
  }
}
const INVITATION_PER_PAGE=5;
  // Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  classes?:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
 
  // Customizable Area Start
  invitationList: IInvitation[];
  getInvitationLoading: boolean;
  totalInvitations: number;
  currentPage: number;
  invitationSearchText: string;
  invitationQueryString: string;
  // Customizable Area End

}

interface SS { }

export default class FreelancerInvitationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getInvitationListApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area Start
      // Customizable Area End
    ]);

    this.state = {
        // Customizable Area Start
        currentPage:1,
        getInvitationLoading:false,
        invitationList:[],
        invitationQueryString:"",
        invitationSearchText:"",
        totalInvitations:0
        // Customizable Area End
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

    // Customizable Area Start
     this.handleApiResponse(apiRequestCallId,responseJson)
    // Customizable Area End

    }
  }
// Customizable Area Start
handleApiResponse=(apiRequestCallId:string,responseJson:any)=>{
  if(apiRequestCallId===this.getInvitationListApiCallId){
     this.handleGetInvitaionsApiResponse(responseJson)
  }
}
handleGetInvitaionsApiResponse = (responseJson: any) => {
  if (responseJson && responseJson?.invitations?.data?.length>0) {
    this.setState((prev) => {
      let newInvitationsList: any[] = [];
      if (prev.currentPage === 1) {
        newInvitationsList = responseJson?.invitations?.data;
      } else {
        newInvitationsList = [...prev.invitationList, ...responseJson.invitations.data];
      }
      return {
        invitationList: newInvitationsList,
        getInvitationLoading: false,
        totalInvitations: responseJson?.pagination?.total_data || 0,
      };
    });
  } else {
    this.setState({ getInvitationLoading: false });
  }

};
  


async componentDidMount() {
  this.prepareFinalQuery();
}



getHeader=async()=>{
  const token = await getStorageData("loginSuccessToken");
  const header = {
    token: token
  };
  return header;
}

invitationSearchHandler = (searchText: string) => {
  this.setState({ invitationList:[],currentPage: 1, invitationSearchText: searchText }, () => {
    this.prepareFinalQuery();
  });
};
invitationFilterHandler = (query: string) => {
  this.setState({ invitationList:[],currentPage: 1, invitationQueryString: query }, () => {
    this.prepareFinalQuery();
  });
};
invitationCancelFilterHandler = () => {
  this.setState({invitationList:[], currentPage: 1, invitationQueryString: "" }, () => {
    this.prepareFinalQuery();
  });
};

prepareFinalQuery = () => {
  let baseQuery: string[] = [];
  const { invitationQueryString, invitationSearchText, currentPage } = this.state;
  baseQuery.push(`current_page=${currentPage}`);
  baseQuery.push(`per_page=${INVITATION_PER_PAGE}`);
  if (invitationSearchText) {
    baseQuery.push(`search=${invitationSearchText}`);
  }
  if (invitationQueryString) {
    baseQuery.push(invitationQueryString);
    baseQuery.push(`filter=true`)
  }
  const newQueryText = baseQuery.join("&");
  this.getInvitationsList(newQueryText);
};

handleProjectLoadMore = () => {
  const { getInvitationLoading } = this.state;
  if (!getInvitationLoading) {
    this.setState(
      (prev) => ({ currentPage: prev.currentPage + 1 }),
      () => {
        this.prepareFinalQuery();
      }
    );
  }
};


getInvitationsList=async(query:string)=>{
  this.setState({getInvitationLoading:true})
  const header=await this.getHeader();
  const apiRequestMessage = CreateRestApiMessage({
    header,
    apiUrl: `${configJSON.getInvitationsApiCallId}/?${query}`,
    body: null,
    method: "GET",
  });

  this.getInvitationListApiCallId = apiRequestMessage.messageId;
  runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
 }
handleNavigateToInvitationsDetails=(invitationId:string)=>{
  this.props.navigation?.navigate("InvitationDetails",{
    invitationId:invitationId
  })
}
 capitalizeFirstLetter(word:string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

formatDate=(dateString:string)=> {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}


  // Customizable Area End
}