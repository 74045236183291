import React from "react";
// Customizable Area Start
import {
    Grid,
    Typography,
    Box,
    Button,
    Tooltip

} from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
import { ThemeProvider,createTheme,withStyles,  
  Theme,
  createStyles,
  makeStyles,} from "@material-ui/core/styles";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import BackNavigation from "../../../components/src/BackNavigation.web"
import {
  paymentWallet,
  backArrow,
} from './assets'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from '@material-ui/icons/Info';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
      head: {
          backgroundColor: theme.palette.action.hover,
          color: "#8083a3",
      },
      body: {
          fontSize: 14,
      },
      root: {
          borderBottom: "0",
      },
  })
)(TableCell);

const useStyles = makeStyles({
  table: {
      minWidth: 700,
  },
});
export const theme = createTheme({
    palette: {
      },
    typography: {
        fontFamily:'inter',
      },
 });


// Customizable Area End

import FreelancerEarningsController, {
  ITransectionHistory,
    Props, configJSON,
    ICurrencyConversion
} from "./FreelancerEarningsController.web";
import moment from "moment";

const getPaidAmountElement = (currencyConversion:ICurrencyConversion,amount_field:"tax"|"amount"="amount",type:string="") => {
  const freelancerCost=currencyConversion?.freelancer;
  const sponsorCost=currencyConversion?.sponsor;
  const freelancerAmount=freelancerCost[amount_field] || 0;
  let sponsorAmount=0;
  if(sponsorCost){
    sponsorAmount=sponsorCost[amount_field] || 0;
  }
  if(!freelancerAmount && !sponsorAmount){
    return "N/A"
  }
  if(type==="withdrawn"){
    return `${freelancerCost.currency_symbol} ${freelancerAmount.toFixed(2)}`;
  }
  return (
      <Box display="flex" alignItems="center" flexWrap="wrap" width="100%">
         <span>
          {sponsorCost.currency_symbol} {sponsorAmount.toFixed(2)}
          </span>
          {sponsorCost?.currency != freelancerCost?.currency &&
                    <>
                        <span style={{ color: "#EC952D", fontWeight: 400, fontSize: "12px" }} >({freelancerCost?.currency_symbol}{freelancerAmount?.toFixed(2)})</span>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${sponsorCost.currency}) into (${freelancerCost.currency}) based on today's conversion rate`}>
                           <InfoIcon style={
                                {height:'16px',
                                  width:'16px',
                                  color:'#000'}}
                             />
                        </Tooltip></>
          }
      </Box>
  )
} 
export class FreelancerEarnings extends FreelancerEarningsController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
   
      fundDetails=(title:string, amount:number)=>{
        const{classes}=this.props;
        const currencySymbol=this.state.currentUser?.attributes?.currency_symbol;
        return <Box className={classes.boxButtonStyle}>
        <img src={paymentWallet} className={classes.imgStyle} /> 
        <Box className={classes.walletTxtBox}>
        <Typography className={classes.walletTxtTitle} >{title}</Typography>
        <Typography className={classes.walletTxtAmt}>{currencySymbol}{amount?.toFixed(3)}</Typography>
        </Box>
      </Box>
      }
      capitalizeFirstLetter=(text:string) =>{
        if (!text) return text; 
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
      getStatusColor=(status:string)=>{
          if(status==="pending" || status==="refunded"){
            return "#FF4100";
          }else if(status==="received"){
            return "#00C000";
          }else{
            return "#000";
          }
      }

      renderPaymentDetails=(paymentItem:ITransectionHistory)=>{
        const{classes}=this.props;
        return  (
          <TableRow key={paymentItem.id}>
          <StyledTableCell
              style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "bolder",
                  width: "100px"
              }}
              component="th"
              scope="row"
          >
              {moment(paymentItem.attributes?.date).format("DD-MM-YYYY")}
          </StyledTableCell>
          <StyledTableCell align="left">
              <Typography
                  style={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: "bolder",
                      width: "150px"
                  }}
              >
                {
                  `${paymentItem.attributes.title} ${ paymentItem?.attributes?.payment_type==="withdrawn"?"":" - "+paymentItem.attributes?.project_name}`
                }
              </Typography>
              {
                paymentItem?.attributes?.payment_type==="withdrawn"?null:<Typography
                style={{
                    color: "#8083a3",
                    fontSize: "12px",
                    fontWeight: 500,
                }}
            >
                Client: {paymentItem.attributes?.description}
            </Typography>
              }
              
          </StyledTableCell>
          <StyledTableCell
              style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "bolder",
              }}
              align="left"
          >
              {getPaidAmountElement(paymentItem.attributes?.currency_conversion,"amount",paymentItem?.attributes?.payment_type)}
          </StyledTableCell>
          <StyledTableCell
              style={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "bolder",
              }}
              align="left"
          >
              {getPaidAmountElement(paymentItem.attributes?.currency_conversion,"tax")}
          </StyledTableCell>
          <StyledTableCell
              style={{
                  color: this.getStatusColor(paymentItem?.attributes?.status),
                  fontSize: "14px",
                  fontWeight: "bolder",
              }}
              align="left"
          >
              {this.capitalizeFirstLetter(paymentItem?.attributes?.status)}
          </StyledTableCell>
      </TableRow>
        )
      }

     

       // Customizable Area End
    render() {
        // Customizable Area Start
          const{classes}=this.props;
          const {balance,getTransectionHistoryLoading,totalHistory,transectionHistory}=this.state;
          const isShowMoreEnable = totalHistory > transectionHistory?.length;
        // Customizable Area End
        return (

            // Customizable Area Start

             <ThemeProvider theme={theme}>
            <FreelancerHeader 
            navigation={this.props.navigation} history={undefined} 
            location={undefined} 
            id={""} route={"projects"}
            />
            <Box className={classes.earningsMainBox}>
          <Grid container spacing={2} className={classes.earningsContentBox}>
              <Grid item xs={10} className={classes.projectDetailsBtnGrid}>
                <BackNavigation 
                data-testid="backNavigationComp"
                onHandleBack={this.onHandleBackToDashboard}
                  backArrow ={backArrow}
                  backTxt={configJSON.backTxt}
                  headerTxt={configJSON.headerTxt}
                />
                <Button className={classes.withdrawalBtnStyle} 
                data-testid= "withdraw_fund_btn"
                onClick={this.handleWithdrawFunds} disabled={balance.main <= 0}>{configJSON.withdrawalBtnTxt}
                </Button>
             </Grid>
                    
              <Grid item xs={10} className={classes.projectDetailsGrid}>
                   <Grid container className={classes.projectDetailsBox}>
                   <Grid item xs="auto" sm={6} md={3}>
                      {this.fundDetails(configJSON.walletTitle1,balance.main)}
                   </Grid>
             
                   <Grid item xs="auto" sm={6} md={3}>
                     {this.fundDetails(configJSON.walletTitle3,balance.inProgress)}
                   </Grid>
             
                    </Grid> 
             </Grid>
             <Grid item xs={10} className={classes.projectDetailsGrid}>
             <Box
                  style={{
                      border: "1px solid #cfd0d1",
                      borderRadius: "14px",
                      paddingBottom: "30px",
                      width:"100%"
                  }}
              >
                  <Typography
                      variant="h4"
                      style={{
                          padding: "20px 0px 25px 20px",
                          fontSize: "18px",
                          fontWeight: 700,
                      }}
                  >
                      {configJSON.paymentTxtTitle}
                  </Typography>
                  <TableContainer
                      style={{
                          border: "none",
                          borderRadius: "0px",
                          boxShadow: "none",
                      }}
                      component={Paper}
                  >
                      <Table style={{minWidth:"700px"}} aria-label="customized table">
                          <TableHead>
                              <TableRow>
                                  <StyledTableCell>Date</StyledTableCell>
                                  <StyledTableCell align="left">
                                      Details
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                      Amount
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                      Tax
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                      Status
                                  </StyledTableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              
                              {this.state.transectionHistory?.map((paymentItem) => (
                                 this.renderPaymentDetails(paymentItem)
                              ))}
                             
                          </TableBody>
                      </Table>
                  </TableContainer>
                  <Box style={{display:"flex",alignItems:"center",flexDirection:"column",justifyContent:"center",gap:"10px",marginTop:"10px"}}>
                              {
                                getTransectionHistoryLoading && (
                                  <Box>
                                    <CircularProgress size={20}/>
                                  </Box>
                                )
                              }
                              {
                                isShowMoreEnable && !getTransectionHistoryLoading && (
                                 <Button data-testid="loadMore" onClick={this.handleHistoryLoadMore} className={classes.showMoreBtn}>{configJSON.showMoreBtn}</Button>
                                )
                              }
                              </Box>
              </Box>
              </Grid>
        </Grid>
             </Box>
      <DashboardFooter history={undefined} location={undefined} />    
    </ThemeProvider>
     // Customizable Area End
   )}
}
// Customizable Area Start

const styles = () => ({

  btnArrowStyle:{
    width: '20px',
    height: '20px',
    marginRight:'0.5vw',
  },
  btnBack:{
    width: '100px',
    height: '46px',
    borderRadius: '23px',
    backgroundColor: '#f2f2f2',
    display:'flex',
    justifyContent:'center',
     alignItems:'center',
     cursor:'pointer',
     '&:hover': {
      backgroundColor: '#ededed',
    },
  },
  boxButtonStyle:{
    display:'flex',
     flexWrap: "wrap" as const,
     padding:"20px"
  },
  walletTxtBox:{
    display:'flex',
    justifyContent:'center',
     alignItems:'flex-start',
     flexDirection:'column' as const,
  },
  cardTxt:{
    color: ' #000',
    fontSize: '14px',
    fontWeight: 700,
  },
  cardAmt:{
    color: ' #000',
    fontSize: '14px',
    fontWeight: 700,
    padding:'10px'
  },
  cardStatus:{
    color: ' #000',
    fontSize: '14px',
    fontWeight: 700,
    padding:'15px'
  },
  walletTxtTitle:{
    color: ' #8083a3',
    fontSize: '12px',
    fontWeight: 400,
  },
  walletTxtAmt:{
    color: ' #000',
    fontSize: '18px',
    fontWeight: 800,
  },
  cardBoxTitle:{
    padding:"10px",
    backgroundColor:'#f2f2f2',
  },
  cardBoxTitle4:{
    // padding:"15px",
    backgroundColor:'#f2f2f2',
    display:'flex',
    alignItems:'center',
    width: '100%',
  },
  cardBoxTitle3:{
    // padding:"15px",
    backgroundColor:'#f2f2f2',
    display:'flex',
    alignItems:'center',
    width: '100%',
  },
  cardBoxTitle2:{
    padding:"10px 10px 10px 15px",
    backgroundColor:'#f2f2f2',
    display:'flex',
    alignItems:'center',
    width: '100%',
  },
  cardBoxTitle1:{
    padding:"10px 10px 10px 20px",
    backgroundColor:'#f2f2f2',
    display:'flex',
    alignItems:'center',
    width: '100%',
  },
  cardBoxTitleTxt:{
    color: '#8083a3',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
  },
  paymentTxtTitle:{
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 700,
    // padding:"1vw 0"
    padding:"20px 0 10px 20px"
  },
  headerText:{ 
    color: '#222222',
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: 700,
    margin:'2vw 1vw',
  },    
  projectDetailsGrid:{
    width:"100%",
    flexWrap: "wrap" as const,
  },
  projectDetailsBtnGrid:{
    margin:'1vw',
    width:"100%",
    display:'flex', 
    alignItems:'center',
    justifyContent:'space-between',
    flexWrap: "wrap" as const,
    gap:"10px"
  },

    projectDetailsBox:{
       borderRadius: '14px',
      border: '1px solid #cfd0d1',
    },
    paymentDetailsBox:{
      display:'flex', 
      // padding:'1vw',
      borderRadius: '14px',
      border: '1px solid #cfd0d1',
      flexWrap: "wrap" as const,
      flexDirection:"column" as const
    },
    projectTitleDetailsBox:{
      display:'flex', 
      margin:'1vw 0',
      flexWrap: "wrap" as const,
    },

    projectCardDetailsBox:{
      display:'flex', 
      margin:'16px 20px',
      flexWrap: "wrap" as const,
    },
  leftArrowImg:{
      paddingRight:'1vw',
      height:'13px',
       width:'20px',
       cursor:'pointer'
    },
    showMoreBtn:{
      backgroundColor: '#f2f2f2',
      fontSize:'12px',
      fontWeight: 600,
      width: '150px',
      height: '40px',
      color: '#000',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      borderRadius: '20px',
      textTransform:'none' as const,
      '&:hover': {
        backgroundColor: '#f2f2f2',
      },
    },
    withdrawalBtnStyle:{
       backgroundColor: '#ec952d',
      fontSize:'14px',
      fontWeight: 700,
      width: '237px',
      height: '46px',
      color: '#fff',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      borderRadius: '20px',
      textTransform:'none' as const,
      '&:hover': {
        backgroundColor: '#ec952d',
      },
    },
    imgStyle:{
      width: '46px',
      height: '46px',
      marginRight:'1vw',
    },
    earningsMainBox:{ 
      width:'100%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
   },
     earningsContentBox:{ 
         display:'flex',
        justifyContent:'center',
         alignItems:'center',
         width:'98%',
         flexDirection:'column' as const,
     },
  })

export const componentStyles={
  styles:styles
}

export default withStyles(styles)(FreelancerEarnings);
  // Customizable Area End