import React from "react";
import {
    Container,
    // Customizable Area Start
    Box, Typography, Divider
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import {
    chatIcon,
    sponserProfile
} from "./assets";
// Customizable Area End

import CompletedProjectLancerController, {
    Props,
} from "./CompletedProjectLancerController";

export default class CompletedProjectLancer extends CompletedProjectLancerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { completedprojectCards } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
            
            <Container maxWidth="lg">
              { completedprojectCards && completedprojectCards?.length != 0 &&
                 completedprojectCards?.map((card: any, index: number) => {
                  return (  
                    <Box style={webStyle.projectDescriptionBox}>
                        <Box>
                            <Typography style={webStyle.cardProjectName}>Logo Design for an Insurance Company</Typography>
                        </Box>
                        <Box style={webStyle.projectSubInfo}>
                            <Typography style={webStyle.projectSubInfoType}>Fixed Price</Typography>
                            <Typography style={webStyle.projectSubInfoType}>Est. Time: 1 to 2 Months</Typography>
                            <Typography style={webStyle.projectSubInfoType}>Posted 9h ago</Typography>
                        </Box>
                        <Divider />
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "14px 26px" }}>
                            <Box>
                                <Typography variant="body2" >Sponsor</Typography>
                                <Box style={{display:"flex" ,alignItems:"center",justifyContent:"space-between"}}>
                                <img style={{ height: "30px",margin:"4px 8px 0px 0px" }} src={sponserProfile} />
                                <Typography style={{fontSize:"16px", color:"#000"}}>Amin Mohamed M.</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <button style={webStyle.messageWithIcon}>
                                    <img src={chatIcon} />
                                    <Typography style={{color:"black"}}>10</Typography>
                                </button>
                            </Box>
                        </Box>
                       <Divider />
                      
                        <Box style={webStyle.showSimilarContainer}>
                        <Box style={webStyle.category}>
                                    {card?.attributes?.skills.map((skill: any) => (
                                        <Box style={webStyle.categoryBox}>{skill.name}</Box>
                                    ))}
                                </Box>
                        <Box style={webStyle.projectDescriptionText}>
                            <Typography style={webStyle.projectDescriptionTextDesign}>We are starting an insurance company and have named it Fourth Island Insurance because we are based out of Kauai, Hawaii and the island of Kauai is considered&</Typography>
                        </Box>
                        <Box style={webStyle.projectDetails}>
                            <Box >
                                <Typography style={webStyle.projectSubDetails}>Total Budget</Typography>
                                <Typography style={webStyle.projectSub_subDetails}>$250</Typography>
                            </Box>
                            <Box>
                                <Typography style={webStyle.projectSubDetails}>Total Amount Paid</Typography>
                                <Typography style={webStyle.projectSub_subDetails}>$70</Typography>
                            </Box>
                            <Box>
                                <Typography style={webStyle.projectSubDetails}>Total Amount in Escrow</Typography>
                                <Typography style={webStyle.projectSub_subDetails}>$180</Typography>
                            </Box>
                            <Box>
                                <Typography style={webStyle.projectSubDetails}>No. of Milestone Completed</Typography>
                                <Typography style={webStyle.projectSub_subDetails}>1/2</Typography>
                            </Box>
                            <Box>
                                <Typography style={webStyle.projectSubDetails}>Experience</Typography>
                                <Typography style={webStyle.projectSub_subDetails}>Intermediate</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyle.projectDetailss}>
                                <Typography style={webStyle.projectSubDetails}>Language</Typography>
                                <Typography style={webStyle.projectSub_subDetails}>English, Native</Typography>
                            </Box>
                        </Box>
                    </Box>
                  ) 
                 }  
                )}

                </Container> 
                 
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    root: {
        display: "flex",
        justifyContent: "space-evenly"
    },
    showSimilarContainer: {
        alignItems: "center",
    },
    favouriteIcon: {
        cursor: 'pointer',
        borderRadius: '50%', 
        border: '1px solid #ddd',
        height: '36px',
        width: ' 36px',
        background: '#fff'
    },
    likeIconDesign: {
        borderRadius: '50%',
        cursor: 'pointer',
        background: '#fff',
        border: '1px solid #ddd',
        height: '36px',
        width: ' 36px',
    },
    projectSub_subDetails: {
        fontSize: '16px',
        color: '#000',
        fontWeight: 600
    },
    projectSubDetails: {
        color: '8083A3',
        fontSize: '12px'
    },
        messageWithIcon: {
            display: "flex", 
            alignItems: "center", 
            justifyContent: "space-between",      
            padding: "14px 20px",
            height: "46px", 
            border: "none", 
            backgroundColor: "#F2F2F2",
            cursor: "pointer",
            color: "white",
            borderRadius: "23px",
        } as const,
    projectDescriptionTextDesign: {
        fontSize: '14px',
        color: '#000',
        fontWeight: 500
    },
    projectSubInfoType: {
        color: '#8083A3',
        fontSize: '12px',
    },
    projectDescriptionBox: {
        border: '1px solid rgb(228, 230, 232)',
        width: "915px",
        borderRadius: '15px',
        margin: '24px 0px 24px -21px',
    },
    projectSubInfo: {
        display: 'flex',
        alignItems: 'center',
        padding: "0px 26px 18px 26px",
        gap: '12px',
    },
    cardProjectName: {
        fontSize: '18px',
        fontWeight: 600,
        color: "#000000",
        padding: "24px 26px 8px 26px"
    },
 
    category: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: 'center',
        padding:"17px 26px 0px 26px",
        flexFlow: 'wrap',
        gap: ' 10px',
        maxWidth: '600px',
    },
    showMoreBtn: {
        color: '#000',
        borderRadius: '26px',
        fontSize: '14px',
        fontWeight: 600,
        padding: '0px 18px',
        background: '#F2F2F2',
        height: '40px',
        width: '151px',
        border: 'none',
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: 'center',
    },
    categoryBox: {
        background: '#E4E6E8',
        color: '#8083A3',
        padding: '10px 20px',
        borderRadius: '20px',
    },
    projectDescriptionText: {
        padding:"14px 26px"
    },
    projectDetails: {
        alignItems: 'center',
        display: 'flex',
        padding:"3px 26px 18px 26px",
        justifyContent: ' space-between',
    },
    projectDetailss: {
        padding:"0px 26px 18px 26px"
    },
    loadMoreBtn: {
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        maxWidth: '300px',
    } ,

}



// Customizable Area End



























