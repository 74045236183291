import React from "react";

// Customizable Area Start
import {
  Button,
  TextField,
  Grid,
  InputAdornment
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardList from "../../../components/src/CardList.web"
import GenericCardFilter from "../../../components/src/GenericCardFilter";
import GenericTab from '../../../components/src/GenericTab';
import SearchController, { Props } from "./SearchController.web";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import ClearIcon from "@material-ui/icons/Clear";
import "../assets/css/Search.css";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export default class Search extends SearchController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  getButtons = (card: any) => {
    return (
      <>

        <Button
          data-test-id="favoriteButtonId"
          className="freelancer-search-primary-button"
          variant="contained"
          color="primary"
          onClick={this.handleFavourite}
          style={{
            borderRadius: "50%",
            backgroundColor: "#F2F2F2",
            minWidth: "0",
            padding: "10px"
          }}
        >
          <FavoriteBorderOutlinedIcon
            style={{ color: "#000000" }}
          />
        </Button>
        <Button
          data-test-id="messageButtonId"
          variant="contained"
          color="primary"
          className="freelancer-search-primary-button"
          onClick={() => { this.handleMessage(card?.attributes.id) }}
          style={{
            borderRadius: "50%",
            backgroundColor: "#F2F2F2",
            minWidth: "0",
            padding: "10px"
          }}
          disabled={this.state.message}
        >
          {!this.state.message ? <ForumOutlinedIcon style={{ color: "#000000" }} />
            : <CircularProgress style={{ width: '25px', height: '25px' }} />}
        </Button>

        {!this.state.hire ? <Button
          data-test-id="hireButtonId"
          className="freelancer-search-secondary-button"
          variant="contained"
          color="primary"
          onClick={this.handleHire}
          style={{
            borderRadius: "20px",
            backgroundColor: "#00A7A2",
            fontWeight: "bold",
            textTransform: "capitalize",
            color: "#0000",
            height: "45px",
            width: "85px"
          }}
        >
          Hire

        </Button> : <Button
          data-test-id="messageButtonId"
          variant="contained"
          color="primary"
          className="freelancer-search-primary-button"

          style={{
            borderRadius: "50%",
            backgroundColor: "#F2F2F2",
            minWidth: "0",
            padding: "10px"
          }}
          disabled={true}
        >
          <CircularProgress style={{ width: '25px', height: '25px' }} />
        </Button>}

        <Button
          data-test-id="inviteButtonId"
          className="freelancer-search-primary-button"
          variant="contained"
          color="primary"
          onClick={this.handleInvite}
          style={{
            borderRadius: "20px",
            backgroundColor: "#00A7A2",
            fontWeight: "bold",
            textTransform: "capitalize",
            color: "#ffff",
            height: "45px",
            width: "85px"
          }}
        >
          Invite
        </Button>
      </>
    )
  }

  render() {
    const { cards, pageNumber, activeTab, loading, filterOptions, totalCards, searchQuery, freeLancerListSelectedOption, displayFilterSortCard, filterActiveTab, tabLables, error, visibleCards } = this.state;
    return (
      // Customizable Area Start
      <>
        <FreelancerHeader
          navigation={undefined}
          history={undefined}
          location={undefined}
        />
        {!error ? (<div style={{ marginLeft: "10%", marginRight: "10%" }}>
          <Grid container spacing={2}>
            <Grid item xs={3} sm={3} md={3} lg={1}>
              <Button
                data-test-id="backButtonId"
                className="freelancer-search-secondary-button"
                variant="contained"
                color="default"
                startIcon={<ArrowBackIcon />}
                onClick={this.handleBack}
                style={{
                  backgroundColor: "#F2F2F2",
                  borderRadius: "20px",
                  height: "45px",
                  width: "85px",
                  margin: "0 9px"
                }}
              >
                Back
              </Button>
            </Grid>
            <Grid
              item
              xs={7}
              sm={7}
              md={7}
              lg={7}
              style={{
                fontWeight: "bold",
                color: "#000000",
                fontSize: "x-large",
                marginLeft: "20px"
              }}
            >
              Logo Design for Insurance Company
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            style={{ margin: "20px 0" }}
          >
            <Grid item xs={12} sm={10} lg={10} md={10}>
              <TextField
                data-test-id="searchInputId"
                onChange={this.handleSearchInputChange}
                variant="outlined"
                fullWidth
                placeholder="Search Freelancer"
                style={{ width: "100%" }}
                value={searchQuery}
                className="input-box"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchQuery ?
                        <button
                          onClick={this.handleClearSearch}
                          data-test-id="clearButtonId"
                          style={{
                            backgroundColor: "#00A7A2",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            border: "none",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "5px",
                            cursor: 'pointer'
                          }}
                        >
                          <ClearIcon />
                        </button>
                        : null}
                      <button
                        onClick={this.handleSearchClick}
                        data-test-id="searchButtonId"
                        disabled={loading}
                        className={`freelancer-search-button ${loading ? 'disabled' : ''}`}

                      >
                        <SearchIcon />
                      </button>
                    </InputAdornment>
                  ),
                  style: { borderRadius: "35px", paddingRight: "5px" }
                }}
              />
            </Grid>
            <Grid item justifyContent="space-between">
              <Grid item xs={12} sm={2} md={2} lg={2}>

                <button
                  data-test-id="filterButtonId"
                  onClick={this.handleFilterSortCard}
                  style={{
                    color: 'rgb(255, 255, 255)',
                    borderRadius: '26px',
                    fontSize: '14px',
                    fontWeight: 600,
                    padding: '0px 18px',
                    background: '#00A7A2',
                    height: '40px',
                    width: '100px',
                    border: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'
                  }} >
                  <FilterListIcon /><span>Filter</span>
                </button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2} md={12} lg={12}>

              <GenericCardFilter displayFilterSortCard={displayFilterSortCard} labels={["Skills", "Country", "Experience"]} selectedfilterOption={freeLancerListSelectedOption} filterOptions={filterOptions} loading={loading} activeTab={filterActiveTab} tabLabels={tabLables.tabFilterLabels} activeTabColor={"#00A7A2"} handleFilterSortCard={this.handleFilterSortCard} handleOptionsChange={this.handleProjectBidOptionsChange} handleClearSelectedOptions={this.handleClearSelectedOptions} handleProjectApplyClick={this.handleProjectApplyClick} handleTabChange={(e, val) => this.handleProjectBidTabChange(e, val, 0)} />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ margin: "20px 0" }}
          >
            <Grid item xs={12} sm={12}>
            
            <GenericTab className={"allTabs"} activeTabColor={"#00A7A2 "} tabLabels={["All","Invited Freelancers"]} activeTab={activeTab} handleTabChange={(e: React.ChangeEvent<{}>, val: number) => this.handleTabChange(e, val)} />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ margin: "20px 0" }}
          >
            <CardList cards={cards}
              loading={this.state.loading}
              cardLoading={this.state.cardLoading}
              message={this.state.message}
              hire={this.state.hire}
              handleMessage={this.handleMessage}
              handleAvailableNow={this.handleAvailableNow}
              handleFavourite={this.handleFavourite}
              handleInvite={this.handleInvite}
              handleHire={this.handleHire}
            />

          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              className="freelancer-search-load-more-grid"
            >
              {pageNumber < Math.ceil(totalCards / visibleCards) && !loading && (
                <Button
                  data-test-id="loadMoreId"
                  className="freelancer-search-secondary-button"
                  variant="contained"
                  color="default"
                  style={{
                    backgroundColor: "#F2F2F2",
                    borderRadius: "20px",
                    marginBottom: "10px"
                  }}
                  onClick={this.handleLoadMore}
                >
                  Show More
                  <ArrowForwardIosIcon className="freelancer-search-ArrowForwardIosIcon" />
                </Button>
              )}
              {loading && <CircularProgress />}
            </Grid>
          </Grid>
        </div>) : (<div>{error}</div>)}
        <DashboardFooter history={undefined} location={undefined} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
