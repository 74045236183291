// Customizable Area Start
import React from "react";
import {Container, Button, TextField,Grid,Typography,ThemeProvider,Box,InputLabel,createTheme,Paper} from  "@material-ui/core";
import {
  Props,
  configJSON,
} from "./FreelancerCertificationtrackingControllers.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import ArrowBack from '@material-ui/icons/ArrowBack';
import FrelancerRequestForCorrectionController from "./FrelancerRequestForCorrectionController.web";
import FullPageBackdropLoader from "../../../components/src/utill/FullPageBackdropLoader.web";
import CircularProgress from "@material-ui/core/CircularProgress";

const theme = createTheme({
    palette: {
      primary: {
        main: "#000",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });

class FreelancerRequestForCorrection extends FrelancerRequestForCorrectionController {
  constructor(props: Props) {
    super(props);
  }
  

  render() {
    const {inputData,errors,submitRequestLoading}=this.state;
    return(
    <div>
    <FreelancerHeader
                    navigation={this.props.navigation}
                    history={undefined}
                    location={undefined}
                    id={""}
                    route={""}
                />
      

    <ThemeProvider theme={theme}>
      <FullPageBackdropLoader
        open={this.state.fetchProject}
      />
    <Container>
        <Box style={{display:"flex",margin:"1rem 0 0 0"}}>
            <Button 
            type="submit" 
            variant="contained" 
            data-testid="BackTxtbtnFrc"
            onClick={this.goBack}
            style={{...webStyleRFC.backbutton,textTransform: "none",fontWeight:"bold",boxShadow:"none"}}
            >
            <ArrowBack 
              style={{...webStyleRFC.arrowbackrfc}}
               />
               {configJSON.BackTxt}
          </Button>
        <Typography component="h1" variant="h4" style={webStyleRFC.headingtypographystyle}>Request For Correction</Typography>
        </Box>
    
    
        <Paper variant="outlined" style={webStyleRFC.paperBoxStyle}>
            <Box sx={webStyleRFC.mainWrapper}>
       
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputLabel style={webStyleRFC.inputlabelstyle}>Full Name*</InputLabel>
          <TextField
            data-testid="name"
            name="name"
            fullWidth
            value={inputData.name}
            onChange={this.handleInputChange}
            style={webStyleRFC.inputStyle}
            error={Boolean(errors?.name)}
            helperText={errors?.name}
            placeholder="Enter your full name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <InputLabel style={webStyleRFC.inputlabelstyle}>Project Completion Date*</InputLabel>
            <TextField
              data-testid="completionDate"
              name="completionDate"
              fullWidth
              value={inputData.completionDate}
              onChange={this.handleInputChange}
              style={webStyleRFC.inputStyle}
              type="date"
              error={Boolean(errors?.completionDate)}
              helperText={errors?.completionDate}
              placeholder="Enter project completion date"
            />
         
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel style={webStyleRFC.inputlabelstyle}>Project Name*</InputLabel>
          <TextField
            data-testid="projectName"
            name="projectName"
            value={inputData.projectName}
            onChange={this.handleInputChange}
            fullWidth
            style={webStyleRFC.inputStyle}
            error={Boolean(errors?.projectName)}
            helperText={errors?.projectName}
            placeholder="Enter project name"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel style={webStyleRFC.inputlabelstyle}>Project Description*</InputLabel>
          <TextField
            data-testid="projectDescription"
            name="projectDescription"
            value={inputData.projectDescription}
            onChange={this.handleInputChange}
            fullWidth
            style={webStyleRFC.inputStyle}
            error={Boolean(errors?.projectDescription)}
            helperText={errors?.projectDescription}
            placeholder="Enter project description"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <InputLabel style={webStyleRFC.inputlabelstyle}>Lancerscape Profile URL*</InputLabel>
          <TextField
            data-testid="profileUrl"
            name="profileUrl"
            value={inputData.profileUrl}
            onChange={this.handleInputChange}
            type="url"
            fullWidth
            style={webStyleRFC.inputStyle}
            error={Boolean(errors?.profileUrl)}
            helperText={errors?.profileUrl}
            multiline={true}
            placeholder="Enter profile url"
          />
        </Grid>
       
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box style={{display:"flex",justifyContent: 'space-between'}}>
            <Button 
                type="submit"
                variant="contained" 
                data-testid="GoBackTxtbtnFrc" 
                onClick={this.goBack}
                style={
                  {
                   ...webStyleRFC.downbackbutton,backgroundColor:"#f2f2f2",color:"#222",textTransform: "none",fontWeight:"bold"
                  }
                }
              >
              {configJSON.BackTxt}
            </Button>
            <Button 
              disabled={submitRequestLoading}
              type="submit" 
              variant="contained"  
              data-testid="submitBtn"
              onClick={this.handleSubmit}
              style={
                  {
                  ...webStyleRFC.downbackbutton,backgroundColor:"#EC952D",color:"#fff",textTransform: "none",fontWeight:"bold",width:"123px"
                  }
              }
              >
                {submitRequestLoading?<CircularProgress size={18}/>:configJSON.continueBtnTxt}
            </Button>
          </Box>
        </Grid>
      </Grid>
      </Box>
      </Paper>
      </Container>
      </ThemeProvider>

      <DashboardFooter history={undefined} location={undefined} />
      </div>

      
    );
  }
}

export default FreelancerRequestForCorrection;

export const webStyleRFC = {
    arrowbackrfc:{
        fontSize:"20px",
    },

    backbutton:{
            color: "#222",
            background:"#f2f2f2",
            margin: "1rem 0 0 0rem",
            borderRadius: "26px",
            fontSize: "14px",
            height: "46px",
            width: "100px",
            display: "flex",
            padding:"0 15px 0 15px",
            alignItems: "center",
            cursor: "pointer",
            justifyContent:"space-evenly",
            
    },
    downbackbutton:{
        height: "46px",
        width: "100px",
        margin: "3rem 0 0 0rem",
        borderRadius: "26px",
        fontSize: "14px",
        cursor: "pointer",
        display: "flex",
        padding:"0 0 0 6px",
        alignItems: "center",
        fontWeight:800,
},
    headingtypographystyle:{
        color: "#222",
        fontFamily: "Inter",
        fontSize: "30px",
        fontStyle: "normal",
        margin:"1rem 0 0 2rem",
        fontWeight: 700,
    },
    inputlabelstyle:{
        margin:"2rem 0 0 0",
        color: "#8083A3",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle:"normal",
        fontWeight:600,
    },
    inputStyle:{
        color: "#222",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
    },

    paperBoxStyle:{
        padding: "20px",
        border: "1px solid #E4E6E8",
        borderRadius: "14px",
        boxShadow: "none",
        margin: "24px 0px 24px 0px"
    },
    mainWrapper: {
      display: "flex",
      fontFamily: "Roboto-Medium",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "30px",
      background: "#fff",
    },
    // inputStyle: {
    //   borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    //   width: "100%",
    //   height: "100px",
    //   display: "flex",
    //   flexDirection: "column",
    //   justifyContent: "space-between",
    // },
    buttonStyle: {
      width: "100%",
      height: "45px",
      marginTop: "40px",
      border: "none",
      backgroundColor: "rgb(98, 0, 238)",
    },
  };

// Customizable Area End