import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { WithStyles} from '@material-ui/core';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles{
  navigation:any;
  id:string;

}
export interface S {
  // Customizable Area Start

  msgData:any;
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class PrjMessagesController extends BlockComponent<
  Props, S, SS
> {

    // Customizable Area Start
 

  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

        this.state = {
            // Customizable Area Start
            msgData:
              {
                date:'10 Sep 2022',
                sender_msg:"Lorem Ipsum is simply dummy text.",
                receiver_msg:'Lorem Ipsum is simply dummy text.',
                time:'12:55 PM',
              }

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start


    // Customizable Area End
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
 
    // Customizable Area End
  }

 
  // Customizable Area End
}

