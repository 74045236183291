import React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    CircularProgress,
    IconButton,
    Menu,
    MenuItem,
    // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import '../assets/css/notification.web.css';
import { MoreVert, ClearAll } from '@material-ui/icons';
import { Link } from "react-router-dom";
// Customizable Area End
import NotificationController, { Props } from "./NotificationController.web";
import DashboardFooter from "../../dashboard/src/DashboardFooter.web";
import Sponserheader from "../../dashboard/src/Sponserheader.web";

export default class Notificationallsponser extends NotificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    
    getNavigationButtons = (notificationType: string | null, url: string | null) => {
        if (notificationType == "Updates") {
            return (
                  <Link to={url} >
                    <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "#00A7A2", cursor: "pointer" }}>
                        Go to Project
                    </span>
                </Link>)

        }
        if (notificationType == "Message") {
            return (
                  <Link to={url} >

                    <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "#00A7A2", cursor: "pointer" }}>
                        Message
                    </span>
                </Link>
            )
        }
        if (notificationType == "Proposals") {
            return (
                  <Link to={url} >

                    <span style={{ ...webStyle.acceptButton, color: "#ffffff", background: "#00A7A2", cursor: "pointer" }}>
                        View Proposal
                    </span>
                </Link>
            )
        }

    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { anchorEl, loading, notificationdata, totalNotifications } = this.state
        const ITEM_HEIGHT = 48;
        // Customizable Area End

        return (
            // Customizable Area Start
            <div className="notifications-page custom-header">

                <Sponserheader navigation={this.props.navigation} id={""} route={""} />
                <Container className="middle-container">
                    <Box style={webStyle.main_Box} id="bx" className="not_main_box">
                        <Box>
                            <Box style={webStyle.back_Wrapperr}>
                                <Button id="btnnon" style={webStyle.back_Button} 
                                onClick={() => { this.props.navigation.goBack(null); }} >
                                    <KeyboardBackspaceRoundedIcon width="10"  style={{ marginRight: "5px", transform: "scaleY(0.8)" }}/>Back</Button>
                                <Typography style={{ fontWeight: 'bold', marginLeft: '1.6rem', color: '#000' }}>Notifications</Typography>
                            </Box>
                            {loading &&
                                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                    <CircularProgress />
                                </Box>
                            }
                            {notificationdata.length > 0 && !loading ? (
                                <Box display={"flex"} flexDirection={"column"}>
                                    <Box
                                        style={webStyle.clearNotificationsWrapper}
                                        onClick={this.handleClearNotifications}
                                        data-test-id="clearAll">
                                        <ClearAll fontSize="small" />
                                        <Typography style={{ color: "rgb(22, 83, 221)", fontSize: "14px" }}>
                                            Clear All Notifications
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        flexDirection={"column"}
                                        style={{ border: '1px solid #dbd4d4', borderRadius: '20px', padding: '5px' }}>
                                        {this.state.notificationdata?.map((data: any, index: any) => {

                                            return (
                                                <Box key={index} id="kry" className="cardbottom" >
                                                    <Card square={true}>
                                                        <CardContent>
                                                            <Box className="resNotificationCardMain">
                                                                <Box className="resNotificationCardTop">
                                                                    <Box style={webStyle.image_Itemm}>
                                                                        <Avatar src={data.attributes.profile_photo?.url} />
                                                                    </Box>
                                                                    <Box display={"flex"} flexDirection={"column"} flexGrow={1}>

                                                                        <Typography variant="h6" id="sponser" 
                                                                             style={
                                                                                data?.attributes.is_read?{
                                                                                   marginBottom: "5px",
                                                                                  }:{
                                                                                    marginBottom: "5px",
                                                                                    fontWeight:"bolder"
                                                                                 }
                                                                            }
                                                                            dangerouslySetInnerHTML={{ __html: data.attributes.content }}
                                                                        />

                                                                            <Box display={"flex"} flex={"0 0 auto"} paddingTop={1} style={{ gap: "10px" }}>
                                                                                {this.getNavigationButtons(data.attributes.notification_for,data.attributes.link)}
                                                                            </Box>                                                                    
                                                                    </Box>
                                                                </Box>
                                                                <Box className="resNotificationCardBottom">
                                                                    <Typography className="hourtag">{data.attributes.created_at}</Typography>
                                                                    <IconButton
                                                                        aria-label="more"
                                                                        aria-controls="long-menu"
                                                                        aria-haspopup="true"
                                                                        onClick={(event) => this.handleClick(event, data.attributes.id)}
                                                                        data-test-id={data.id}
                                                                        style={{ padding: "0 12px" }}>
                                                                        <MoreVert />
                                                                    </IconButton>
                                                                </Box>

                                                            </Box>
                                                        </CardContent>
                                                    </Card>

                                                </Box>
                                            );
                                        })}
                                        {notificationdata.length < totalNotifications && (
                                            <Box p={1}
                                                display={"flex"}
                                                justifyContent={"center"}>
                                                <Button
                                                    style={webStyle.back_Button}
                                                    onClick={this.handleViewMore}
                                                    data-test-id="viewMore">
                                                    View More
                                                </Button>
                                            </Box>
                                        )}
                                        <Menu
                                            id="long-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(this.state.anchorEl)}
                                            onClose={this.handleClose}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: ITEM_HEIGHT * 4.5,
                                                    width: '18ch',
                                                },
                                            }}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <MenuItem onClick={this.handleDeleteNotification}
                                                style={{ color: "#DC2626", fontSize: "14px", margin: "4px 0px" }}
                                                data-test-id="menuItem">
                                                Delete Notification
                                            </MenuItem>

                                        </Menu>
                                    </Box>
                                </Box>
                            ) : <Box display="flex" alignItems="center" justifyContent="center">No notifications</Box>}
                        </Box>
                    </Box>


                </Container>
                <DashboardFooter history={undefined} location={undefined}  ></DashboardFooter>
            </div>
            // Cust>omizable Area End
        );

    }
}
// Customizable Area Start
const webStyle = {
    main_Box: {
        paddingTop: '10px',
        margin: '10px auto',
        maxWidth: "100%",
        width: "100%"
    },
    back_Wrapperr: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',

    },
    back_Button: {
        color: "#222",
        fontSize: "14px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#f2f2f2",
        height: "42px",
        borderRadius: "23px",
        width: "max-content",
        padding: "0 16px",
        gap: "10px",
        cursor: "pointer",
    } as const,
    image_Itemm: {
        marginRight: '14px',
        // flex:"1"
    },
    button_Wrapperr: {
        display: 'flex',
        paddingBottom: "20px",
        marginLeft: "60px",
        // margin:'auto'
    },
    button_design_onee: {
        color: 'white',
        backgroundColor: 'transparent',
        borderRadius: '20px',
        marginLeft: '10px',
        boxShadow: 'none'
    },
    button_design_twoo: {
        color: '#222',
        backgroundColor: 'transparent',
        borderRadius: '20px',
        boxShadow: 'none',
        fontFamily: "inter",
    },
    clearNotificationsWrapper: {
        paddingRight: "17px",
        paddingBottom: "14px",
        display: "flex",
        gap: "5px",
        alignSelf: "flex-end",
        cursor: "pointer",
    },
    acceptButton: {
        borderRadius: "18px",
        textTransform: "none",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        textAlign: "center",
        marginTop : "5px",  
        padding : "7px 13px"
    } as const,
    biggerButton: {

    },
    viewMoreButton: {
        padding: "0 5px",
        background: "rgb(242, 242, 242)",
        border: "1px solid rgba(228, 230, 232, 0.6)",
        borderRadius: "30px",
        fontSize: "14px",
        color: "#000"
    }

};
// Customizable Area End