export const successImage = require("../assets/images/image_success.png");
export const lancerlogo = require("../assets/logo.jpeg");
export const bellicon = require("../assets/bellicon.png");
export const buttonswitch = require("../assets/buttonswitch.png");
export const help = require("../assets/help.png");
export const messageicon = require("../assets/messageicon.png");
export const bannerimage = require("../assets/bannerimage.png");
export const slider1 = require("../assets/slider1.png");
export const slider2 = require("../assets/slider2.png");
export const slider3 = require("../assets/slider3.png");
export const slider4 = require("../assets/slider4.png");
export const location = require("../assets/location.png");
export const time = require("../assets/time.png");
export const copy = require("../assets/copy.png");
export const avtar = require("../assets/avtar.png");
export const newsavtar = require("../assets/newsavtar.png");
export const newsbanner = require("../assets/newsbanner.png");
export const telentbanner1 = require("../assets/telentbanner1.png");
export const telentbanner2 = require("../assets/telentbanner2.png");
export const telentbanner3 = require("../assets/telentbanner3.png");
export const telentbanner4 = require("../assets/telentbanner4.png");
export const dummyImage = require("../assets/images.jpeg");
export const leftArrow = require("../assets/leftArrow.png");
export const dislike = require("../assets/like.png");


export const parallaximage = require("../assets/lancerparalleximage.png");
export const lancerscapelogo = require("../assets/LANCERSCAPE Copy.png");
export const card_image = require("../assets/cardsimg.jpg");
export const profilepic = require("../assets/profile.png");
export const increase_img = require("../assets/increase.png");
export const location1 = require("../assets/location.png");
export const clock = require("../assets/clock.png");
export const slider_1= require("../assets/it.png");
export const slider_2 = require("../assets/web-design.png");
export const slider_3 = require("../assets/increase.png");
export const slider_4 = require("../assets/translation.png");
export const convertshape = require("../assets/convertshape.png");
export const wallersearch = require("../assets/wallet-search.png");
export const sms_image = require("../assets/sms.png");
export const tasksquare = require("../assets/task-square.png");
export const documenttext = require("../assets/document-text.png");
export const switchbtn = require("../assets/switchbtn.png");
export const bellicon_img = require("../assets/notification.png");
export const help_img_ = require("../assets/help.png");
export const usaimg = require("../assets/usa.png");
export const globe = require("../assets/globe.png");
export const oval = require("../assets/oval.png");
export const bidsprofile = require("../assets/bidsprofile.png");
export const cardimage = require("../assets/cardimage.png");
export const newsbanner_2 = require("../assets/newsbanner_2.png");
export const newsbanner_3 = require("../assets/newsbanner_3.png");
export const newsbanner_4= require("../assets/newsbanner_4.png");


export const browseAllProjectIcon= require("../assets/browseAllProjectIcon.png");
export const myBidsIcon= require("../assets/myBids.png");
export const completedProjectIcon= require("../assets/completedProjectIcon.png");
export const projectInProgressIcon= require("../assets/projectInProgressIcon.png");
export const chatIcon= require("../assets/chatIcon.png");
export const sponserProfile= require("../assets/sponserProfile.png");
export const filterIcon= require("../assets/filterIcon.png");

export const completedProject = require("../assets/completedProject.png");
export const graterthen = require("../assets/graterthen.png");
export const successImg = require("../assets/success.png");

export const ratingLogo =require("../assets/imagesCopy.jpeg")





