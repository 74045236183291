import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import {toast} from 'react-toastify'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  currentUser:any;
  IsLoading:boolean;
  SuppportCategory:any;
  number:any;
  initialvalue:any;
  isLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  getSubmitFormApiCallId:any
  getSupportCategoriesApiCallId:any
  getProfileDetailsAPICallId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LoginUserMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";
    this.getProfileDetailsAPICallId=""

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      currentUser:{},
      IsLoading:true,
      SuppportCategory:[],
      initialvalue:{
        email: "",
        name: '',
        phone_number: '',
        description: '',
        support_category_id: '',
      },
      number:{
        phone : "",
        country : 'in',
        state : ""
      },
      isLoading : false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.sendGetCurrentUserMessage();
    this.GetSupportCategories()
    this.getProfileDetails()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));

      if(user?.id){
        this.setState({ currentUser: user});
      }
    }
    
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = await getStorageData("loginSuccessToken");
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getContactUsList(token);
    }
     else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getSupportCategoriesApiCallId) {
        this.setState({SuppportCategory :responseJson })
      }
      if (apiRequestCallId === this.getSubmitFormApiCallId) {
        responseJson?.data && toast.success("Form submitted successfully !")
        this.setState({number:{
          phone:this.state.initialvalue.phone_number,
          state : this.state.initialvalue.support_category_id,
        },initialvalue :{
          email: this.state.initialvalue.email,
          name: this.state.initialvalue.name,
          phone_number: this.state.initialvalue.phone_number,
          description: '',
          support_category_id: '',
        }},()=>{
    this.setState({isLoading:false})
        })
      }
      if(apiRequestCallId === this.getProfileDetailsAPICallId){
        const user = responseJson.data.attributes
        
        this.setState({IsLoading:false,initialvalue:{
          email:user.account_detail.data.attributes.email,
          name:user.full_name,
          phone_number :user.mobile_number,
          support_category_id: '',
          description:""
        } , number:{
          phone : user.mobile_number,
          state : null
        } });



      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
 

  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  //contact-us API
  GetSupportCategories = async() =>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.GetSupportCategories}`,
      method: "GET",
      body: null,
    });
    this.getSupportCategoriesApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRole=()=>{
     const {currentUser}=this.state;
     let role="freelancer"
     if(currentUser?.currentState?.toLowerCase()?.includes("sponsor")){
      role="sponsor";
     }
     return role;
  }

  ContactFormSubmit = async(value:any) => {
    this.setState({isLoading:true})
    const token = await getStorageData("loginSuccessToken");
    const dataObject = {data : {
      email: value.email,
      name: value.name,
      phone_number: value.phone_number,
      description: value.description,
      support_category_id: value.support_category_id,
      role_type:this.getRole()
    }};
    
    const header = {
      token: token,
      "Content-Type": "application/json",
    };
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.submitform}`,
      method: "POST",
      body: JSON.stringify(dataObject),
    });
    this.getSubmitFormApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getProfileDetails = async() =>{
    const token = await getStorageData("loginSuccessToken");
    const header = {
      token: token,
    };
    const requestMessage = CreateRestApiMessage({
      header: header,
      apiUrl: `${configJSON.getCurrentUserApiUrl}`,
      method: "GET",
      body: null,
    });
    this.getProfileDetailsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sendGetCurrentUserMessage=()=>{
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );

    this.send(msg);
  }
  // Customizable Area End
}
