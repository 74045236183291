import React from "react";

// Customizable Area Start
import "../assets/CSS/index.css";

import Sponsorlistingcontroller, {
    Props
} from "./Sponsorlistingcontroller.web";
import ProjectDisplay from "../../../components/src/ProjectDisplay";
import ProjectFilterWeb from "../../dashboard/src/freelancer-projects/ProjectFilter.web";

// Customizable Area End
export default class Onholdproject extends Sponsorlistingcontroller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                <ProjectFilterWeb
                   onSearch={this.searchProjectHandler}
                   onFilter={this.filterProjectHandler}
                   onCancelFilter={this.cancelFilterProjectHandler}
                   data-testid="on-hold-project-filter"
                   activeTabColor="#00A7A2"
                />
                <ProjectDisplay
                    data-id="on-hold-project-listing"
                    type = "onhold"
                    loading={this.state.loading}
                    projectList={this.state.projectdata}
                    navigate={this.props.navigation?.navigate}
                    isShowMore={this.state.totalProjectsCount > this.state.projectdata?.length}
                    tabDataList={this.state.chipNames}
                    loadMore={this.handleProjectLoadMore}
                    onTabChange={this.handleChipClick}
                    copyURL={this.handleCopyClick}
                    />
            </div>
            // Customizable Area End
        );
    }
}
// Customizable Area End
