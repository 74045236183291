import React from 'react'
interface Props{
  email:string;
  onLogout:()=>void;
}
const ProfileSetupHeader:React.FC<Props>=({email})=>{
  const sortCode=email?.length>2 ?email.substring(0,2):email;
    return(
        <header className="header-right">
        <ul className="main-list">
          <li>
            <a href="#">Help and Support</a>
          </li>
          <li>
            <div className="mui-dropdown">
              <button
                className="mui-btn dropdown-btn mui-btn--raised"
                data-mui-toggle="dropdown"
              >
                <span className="id-text">{sortCode}</span>{email}
                <span className="mui-caret" />
              </button>
            </div>
          </li>
        </ul>
      </header>
    )
}


export default ProfileSetupHeader;