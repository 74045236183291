import React from "react";
// Customizable Area Start
// Customizable Area End

// Customizable Area Start
import Sponserheader from "../../dashboard/src/Sponserheader.web";
import Sponserfooter from "../../dashboard/src/Sponserfooter.web";
import ViewProfile from "../../../components/src/ViewProfile.web"
// Customizable Area End

import PublicProfileController, {
  Props,
  configJSON
} from "./PublicProfileController.web";
import FreelancerHeader from "../../dashboard/src/FreelancerHeader.web";
import { profileIcon } from "../../favourites/src/assets";

export default class LancerPublicProfile extends PublicProfileController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    // Customizable Area Start
    const { currentUser, profileInfo } = this.state;
    const isSponsor = currentUser?.currentState?.toLowerCase()?.includes("sponsor");

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {
          isSponsor ? (
            <Sponserheader navigation={this.props.navigation} />
          ) : (
            <FreelancerHeader navigation={this.props.navigation} />
          )
        }
        <ViewProfile isLoggedinUserSponsor={isSponsor} instance={this} isLoading={this.state.isLoading} profileInfo={profileInfo} profileLancer={profileIcon} />
        <Sponserfooter navigation={this.props.navigation} id={""} />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const webStyle = {
  loader: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-20%, -50%)',
  },
  currentDay: {
    color: "#8083A3",
    fontSize: "12px",
    fontWeight: 500
  },
  roleAndResponsibilites: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#222"
  },
  detailsExperience: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#222"
  },
  timeAndCountry: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#8083A3"
  },
  locationOnIcon: {
    fontSize: "1rem",
    color: "rgba(0, 0, 0, 0.87)!important"
  },
  boxLocation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    paddingTop: "10px"
  },
  skillsText: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#8083A3"
  },
  skillsBxs: {
    alignItems: "center",
    justifyContent: "left",
    marginTop: "5px"
  },
  lancerNameText: {
    color: "#333",
    fontSize: "16px",
    fontWeight: 600
  },
  ratingMainBox: {
    marginTop: "8px",
    display: "flex",
    gap: "10px"
  },
  ratingText: {
    color: "rgb(236, 149, 45)",
    fontSize: "1rem",
    marginRight: "2px"
  },
  smallText: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#8083A3"
  } as const,
  pastProjectownerName: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#8083A3"
  } as const,
  headerTextBtn: {
    color: "#222",
    fontSize: "28px",
    fontWeight: "bold"
  } as const,
  pastProjectDescription: {
    color: "#222",
    fontSize: "14px",
    fontStyle: "italic",
    fontWeight: 400
  } as const,
  projectNameText: {
    color: "#000",
    fontSize: "14px",
    fontWeight: 700
  } as const,
  projectAndReviewsText: {
    color: "#000",
    fontSize: "18px",
    fontWeight: 700
  } as const,
  freelancerSearchUserOnline: {
    position: "absolute",
    top: "60px",
    right: "3px",
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    backgroundColor: "green"
  } as const,
  avatarActive: {
    height: "80px",
    width: "80px !important",
    borderRadius: "50%",
    objectFit: "cover",

    position: "relative"
  } as const,
  frelancerDetails: {
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    alignItems: "flex-start"
  } as const,
  profileAvatar: {
    position: "relative",
    width: "80px",
    height: "80px",
    margin: "24px 0px 24px 24px"
  } as const,
  lancerName: {
    fontSize: "20px",
    fontWeight: "400",
    margin: "0"
  },

  mainprofilebox: {
    display: "flex",
    flexDrection: "column",
    gap: "30px",
    fontFamily: "inter",
    padding: " 32px 200px"
  },
  amtTextContent: {
    fontSize: "16px",
    color: "#222",
    fontWeight: 700,
    fontFamily: "Inter"
  },
  titleboxprofile: {
    display: "flex",
    alignItems: "center",
    gap: "20px"
  },
  amtTextHeader: {
    color: "8083A3",
    fontSize: "12px",
    fontFamily: "Inter"
  },
  progressDescriptionText: {
    fontSize: "14px",
    color: "#222",
    fontWeight: 400,
    fontFamily: "Inter"
  },
  progressDescriptionBoxContainer: {
    borderRadius: "15px",
    border: "1px solid #e4e6e8"
  },
  projectDescriptionBox: {
    borderRadius: "15px",
    marginBottom: "24px",
    border: "1px solid rgb(228, 230, 232)",
    cursor: "pointer",
    marginRight: "200px",
    marginLeft: "200px"
  },
  skillGreyBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "1vw",
    padding: "1vw"
  },
  skillGreyBoxCategory: {
    background: "#E4E6E8",
    color: "#8083A3",
    padding: "6px 20px",
    borderRadius: "20px",
    margin: "0 0.5vw"
  },
  progressDescriptionTextBox: {
    padding: "1vw 2vw"
  },
  amtDetailsContainer: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
    padding: "1vw 2vw"
  },
  backIconbutton: {
    color: "#222",
    fontSize: "14px",
    fontWeight: 700,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    height: "42px",
    borderRadius: "23px",
    width: "max-content",
    padding: "0 16px",
    gap: "10px",
    cursor: "pointer"
  } as const
};
// Customizable Area End
