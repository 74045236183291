import React from 'react';
import CustomSelect from './CustomSelect.web';
import {MenuItem} from '@material-ui/core'
import { ICurrencyInfo } from '../../../blocks/social-media-account-registration/src/AccountinfoController.web';
interface Props{
    countryList:string[];
    setFieldValue:any;
    onSelected:(countryName:string)=>void;
    countryLoading:boolean;
    tabIndex?:number;
    value:string;
    error?:string;
    currencyList:ICurrencyInfo[]
}
const CountryTextInput:React.FC<Props> = ({countryList,setFieldValue,onSelected,countryLoading,tabIndex,value,error,currencyList}) => {
    const getCurrencyIdForCountry=(selectedCountry:string)=>{
        const findCurrency=currencyList.find((item)=>item.attributes?.name===selectedCountry);
       return findCurrency?.id || "";
    }
    return(
        <CustomSelect
            value={value}
            onChange={(e:any)=>{
                setFieldValue("country",e.target.value);
                setFieldValue("state","");
                setFieldValue("city","");
                setFieldValue("time_zone","");
                setFieldValue("currency",getCurrencyIdForCountry(e.target.value));
                onSelected(e.target?.value)
            }}
            label="Country*"
            renderItem={(item,props)=>(
                <MenuItem
                key={item}
                value={item}
                {...props}
                >
                {item}
            </MenuItem>
            )}
            name="country"
            id="country"
            tabIndex={tabIndex}
            data={countryList}
            error={error}
            placeholder="Select your country"
            labelStyle={{
                marginBottom:"12px",
                marginTop:"0px"
              }}
            selectStyle={{
                paddingBottom:"2px"
              }}
      />
    )
}
 
export default CountryTextInput;