import React from 'react'

import {
   Typography,
   Box,
   Divider,
   Avatar,
   CardMedia,
   Grid,Tooltip
   } from "@material-ui/core";
   import {
    chatIcon,
} from '../../blocks/dashboard/src/assets'
import {configJSON} from '../../blocks/dashboard/src/freelancer-projects/FreelancerBrowseProjectController.web'
import { IProjectData } from '../../blocks/dashboard/src/types';
import { info_circle } from '../../blocks/auctionbidding/src/assets';

interface Props{
    card:IProjectData;
    param?:string;
    instance?:any
}

export const getProjectCostElement = (freelancerCost: any, sponsorCost: any, hourlyRated: boolean,costKey="amount") => {
  const freelancerAmount=freelancerCost[costKey] || 0;
  const sponsorAmount=sponsorCost[costKey] || 0;
  return (
    <>
      {sponsorCost?.currency_symbol} {sponsorAmount} {"   "}
    {  sponsorCost?.currency != freelancerCost?.currency && <>
            <span style={{ color: "#EC952D", fontWeight: 400, fontSize: "12px" }} >({freelancerCost?.currency_symbol}{freelancerAmount})</span>
            <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} title={`Converted from (${sponsorCost?.currency}) into (${freelancerCost?.currency}) based on today's conversion rate`}>
              <img style={{ height: "13px", width: "13px", marginLeft: "5px" }} src={info_circle} alt="info" />
            </Tooltip></>}
      {hourlyRated && " /hr"}
    </>
  )
} 

const ProjectCardProgress:React.FC<Props> = ({card,param, instance}) => {
  
  const handleNavigate=()=>{
    param == "closed" ? instance.props.navigation.navigate("ClosedPrjSummary", { projectid:card?.id}):
    param == "dispute" ? instance.props.navigation.navigate("DisputePrjSummary", { projectid:card?.id}):
    param == "onhold" ? instance.props.navigation.navigate("OnHoldPrjSummary", { projectid:card?.id}):
     instance.props.navigation.navigate("PrjSummary", { projectid:card?.id}
            )
    }

   
  
    const convertedCost=card.attributes?.currency_conversion;
    const freelancerConvertedCost=convertedCost?.freelancer;
    const sponsorConvertedCost=convertedCost?.sponsor;
    return (
        <Box onClick={handleNavigate} mb={3} style={webStyle.projectDescriptionBox}>
        <Box style={webStyle.progressDescriptionBoxContainer}>
          {/* ----------------- Project header start ---------------------------- */}
          <Box style={webStyle.progressProjectHeader}>
            <Box>
              <Typography style={webStyle.cardProjectName}>
                {card.attributes.project_name}
              </Typography>
              <Box style={webStyle.fb_projectSubInfo}>
                <Typography style={webStyle.projectSubInfoType}>
                {card?.attributes?.budget_type==="hourly_price"?"Hourly Rated":"Fixed Price"}
                </Typography><span style={{ color: 'gray' }}> • </span>
                <Typography style={webStyle.projectSubInfoType}>
                  {configJSON.estimatedTimeTxtLabel}{card.attributes.EST_time}
                </Typography><span style={{ color: 'gray' }}> • </span>
                <Typography style={webStyle.projectSubInfoType}>
                {configJSON.postedOnText}{card.attributes.posted_at}
                </Typography>
              </Box>
            </Box>
            <Box>

            </Box>
          </Box>
           {/* ----------------- Project header end ---------------------------- */}
          <Divider/>
            {/* ----------------- Avatar start ---------------------------- */}
           
          <Box style={webStyle.avatarBoxContainer}>
            <Typography style={webStyle.sponsorText}>Sponsor</Typography>
            <Box style={{display:"flex", alignItems:'center', justifyContent:'space-between'}}>
            <Box style={{display:"flex", alignItems:'center'}}>
                <Avatar src={card?.attributes?.profile_image != null ? card?.attributes?.profile_image[0]?.url:""} 
                style={webStyle.progressAvatar}/>
                <Typography style={webStyle.avatarText}>{card.attributes.profile_full_name}</Typography>
              </Box>
              <Box style={webStyle.messageGreyBox}>
              <CardMedia
                component="img"
                style={webStyle.messageImg}
                 image={chatIcon}
                 />
                <Typography style={webStyle.messageImgText}>10</Typography>
              </Box>
            </Box>
          </Box>
          {/* ----------------- Avatar end  ---------------------------- */}
          <Divider/>
            {/* ----------------- skills start  ---------------------------- */}
          <Box style={webStyle.skillGreyBox}>
            {card?.attributes?.skills.map((skill: any) => (
              <Box key={skill.name} style={webStyle.skillGreyBoxCategory}>{skill.name}</Box>
            ))}
          </Box>
            {/* ----------------- skills end  ---------------------------- */}
            {/* ----------------- desc start ---------------------------- */}
          <Box style={webStyle.progressDescriptionTextBox}>
            <Typography style={webStyle.progressDescriptionText}>
              {card.attributes.description}
            </Typography>
          </Box>
          {/* ----------------- desc end ---------------------------- */}
          {/* ----------------- amount details start ---------------------------- */}
          <Grid container style={webStyle.amtDetailsContainer}>
          {card?.attributes?.budget_type ==="fixed_price" &&
            <Grid item xs={12} md={2} style={{marginBottom:'1.5vw'}}>
              <Typography style={webStyle.amtTextHeader}>{configJSON.totalBudgetTxt}</Typography>
              <Typography style={webStyle.amtTextContent}>
                {getProjectCostElement(freelancerConvertedCost,sponsorConvertedCost,false)}
                </Typography>
            </Grid>
          }
             <Grid item xs={12} md={2} style={{marginBottom:'1.5vw'}}>
              <Typography style={webStyle.amtTextHeader}>{configJSON.totalAmtPaidTxt}</Typography>
              <Typography style={webStyle.amtTextContent}>{getProjectCostElement(freelancerConvertedCost,sponsorConvertedCost,false,"total_paid_amount")} </Typography>
            </Grid>
            <Grid item xs={12} md={2} style={{marginBottom:'1.5vw'}}>
            <Typography style={webStyle.amtTextHeader}> {configJSON.totalAmtEscrowTxt}</Typography>
            <Typography style={webStyle.amtTextContent}>{getProjectCostElement(freelancerConvertedCost,sponsorConvertedCost,false,"escrow_amount")}</Typography>
           </Grid>
             <Grid item xs={12} md={3} style={{marginBottom:'1.5vw'}}>
              <Typography style={webStyle.amtTextHeader}> {configJSON.totalMilestonesTxt} </Typography>
              <Typography style={webStyle.amtTextContent}> {card.attributes.No_of_milestone_completed} </Typography>
            </Grid>
             <Grid item xs={12} md={2} style={{marginBottom:'1.5vw'}}>
              <Typography style={webStyle.amtTextHeader}>{configJSON.experienceTxt}</Typography>
              <Typography style={webStyle.amtTextContent}>{card.attributes.experience_required} years</Typography>
            </Grid>
             <Grid item xs={12} md={2} style={{marginBottom:'1.5vw'}}>
              <Typography style={webStyle.amtTextHeader}>{configJSON.languageTxt}</Typography>
              <Typography style={webStyle.amtTextContent}> {card.attributes.language_of_converse}</Typography>
            </Grid>
           
          </Grid>

        </Box>
      </Box>
      );
}
 
export default ProjectCardProgress;

const webStyle = {
     amtTextContent: {
      fontSize: "16px",
      color: "#222",
      fontWeight: 700,
      fontFamily: 'Inter',
    },
    amtTextHeader: {
      color: "8083A3",
      fontSize: "12px",
      fontFamily: 'Inter',
    },
    progressDescriptionText: {
      fontSize: "14px",
      color: "#222",
      fontWeight: 400,
      fontFamily: 'Inter',
      whiteSpace: 'pre-wrap' as const,
      wordBreak: 'break-word' as const,
    },
    projectSubInfoType: {
      color: "#8083A3",
      fontSize: "12px",
    },
    progressDescriptionBoxContainer: {
      borderRadius: "15px",
      border: '1px solid #e4e6e8'
    },
    projectDescriptionBox: {
      borderRadius: "15px",
      marginBottom: "24px",
      border: "1px solid rgb(228, 230, 232)",
      cursor:'pointer',
    },
    cardProjectName: {
      fontSize: "18px",
      fontWeight: 600,
      color: "#000000",
    },
    avatarBoxContainer: {
      display: "flex",
      flexDirection:'column' as const,
      padding: "1vw 2vw",
    },
    fb_projectSubInfo: {
      display: "flex",
      alignItems: "center",
      marginTop: "12px",
      gap: "5px",
    },
    skillGreyBox: {
      display: "flex",
      alignItems: "center",
      marginTop:"1vw",
      padding: '1vw',
      flexWrap:'wrap' as const
     },

    skillGreyBoxCategory: {
      background: "#E4E6E8",
      color: "#8083A3",
      padding: "6px 20px",
      borderRadius: "20px",
      margin:"10px"
    },
    progressDescriptionTextBox: {
      padding:"1vw 2vw"
    },
    amtDetailsContainer: {
      display: "flex",
      justifyContent: " space-between",
      alignItems: "center",
      padding:'1vw 2vw',
     },
    progressProjectHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2vw",
    },
    sponsorText:{
      fontSize: "11x",
      fontWeight: 400,
      color: "#8083a3",
      fontFamily: 'Inter',
    },
    progressAvatar:{
      height:'30px',
      width:'30px'
    },
    avatarText:{
      fontSize: "16x",
      fontWeight: 700,
      color: "#000",
      fontFamily: 'Inter',
      marginLeft:'1vw',
    },
    messageGreyBox:{
      width: '82px',
      height: '46px',
      borderRadius: '23px',
      backgroundColor: '#E4E6E8',
      display:"flex", 
      alignItems:'center',
      justifyContent:'center',
      visibility: 'hidden' as const,
    },
    messageImg:{
      height:'19px',
      width:'19px'
    },
    messageImgText:{
      fontSize: "14x",
      fontWeight: 700,
      color: "#222",
      fontFamily: 'Inter',
      marginLeft:'8px',
    },
  };