import React from 'react';
import { Grid, Typography, Container, Tab, Tabs } from '@material-ui/core';
import { Link } from "react-router-dom";

import moment from "moment";
import AuctionBiddingController, { Props } from "./AuctionBiddingController.web";

// Customizable Area Start

import "../assets/styles/index.css";
import { notification } from "./assets";
// Customizable Area End

class AuctionBidding extends AuctionBiddingController {

  constructor(props: Props) {
    super(props);

    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  liveAuctionView = () => {
    return (
      <>
        {this.state.live_auction && this.state.live_auction.map((item) => (
          <Grid item xs={12} style={{ margin: "20px 0px",backgroundColor: "#d3dbe8", padding:"10px", borderRadius:"10px" }} key={item.id} >
            <Grid container item>
              <Grid item>
                <img
                  src={item?.attributes?.product_images[0]?.url}
                  alt="image" style={{ width: "120px", height: "120px", borderRadius: "20px" }}
                />
              </Grid>
              <Grid item xs style={{ paddingLeft: "20px" }}>
                 <Typography className="textStyle">Product Name : {item?.attributes?.product_name}</Typography>
                <Typography className="textStyle">Minimum Price : ${item?.attributes?.minimum_bid}</Typography>
                <Typography className="textStyle">Auction start date : {moment(item?.attributes?.start_time).format('DD/MM/YYYY')}</Typography>
                <Typography className="textStyle">Auction start date : {moment(item?.attributes?.end_time).format('DD/MM/YYYY')}</Typography>
                <Typography className="textStyle">Current Bid Price : ${item?.attributes?.maximum_bid_placed}</Typography>
              </Grid>
              <Link to={`AuctionBiddingDetail/${item.id}`}>Details</Link>
            </Grid>
          </Grid>
        ))}
        {this.state.live_auction == null &&
          <Grid item xs={12} style={{ margin: "20px 0px" }} >
            <Typography className={"textStyle"}>No Product Found</Typography>
          </Grid>
        }
      </>

    )
  }

  upcomingAuctionView = () => {
    return (
      <>
        {this.state.upcoming_auction && this.state.upcoming_auction.map((item) => (
          <Grid item xs={12} style={{ margin: "20px 0px",backgroundColor: "#d3dbe8", padding:"10px", borderRadius:"10px" }} key={item.id}>
            <Grid container item>
              <Grid item>
                <img
                  src={item?.attributes?.product_images[0].url}
                  alt="image" style={{ width: "120px", height: "120px", borderRadius: "20px" }}
                />
              </Grid>
              <Grid item xs style={{ paddingLeft: "20px" }}>
                <Typography className="textStyle">Product Name : {item?.attributes?.product_name}</Typography>
                <Typography className="textStyle">Minimum Price : ${item?.attributes?.minimum_bid}</Typography>
                <Typography className="textStyle">Auction start date : {moment(item?.attributes?.start_time).format('DD/MM/YYYY')}</Typography>
                <Typography className="textStyle">Auction start date : {moment(item?.attributes?.end_time).format('DD/MM/YYYY')}</Typography>
              </Grid>

              <Link to={`AuctionBiddingDetail/${item.id}`}>Details</Link>
            </Grid>
            
          </Grid>
        ))}
        {this.state.upcoming_auction == null &&
          <Grid item xs={12} style={{ margin: "20px 0px" }} >
            <Typography className="textStyle">No Product Found</Typography>
          </Grid>
        }
      </>
    )
  }

  header = () => {
    return (
      <Grid container xs={12} style={{ backgroundColor: "#e3dede",flexBasis: "0%"}} item>
          <Grid xs={11} item>
            <h2>Auction Bidding</h2>
          </Grid>
          <Grid xs={1} item>
            <Link to={`AuctionBiddingNotification`}>
              <img
                src={notification}
                alt="image" style={{ width: "40px", height: "40px", marginTop: "10px" }}
              />
            </Link>
            
          </Grid>
      </Grid>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start

    return (
      <>
        {this.header()}
        <Container maxWidth={"md"}>
          <Tabs
            value={this.state.selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onTabChange}
            className="tabStyle"
            classes={{
              indicator: "indicator"
            }}
            data-testid="auction_tab"
          >

            <Tab label="Live Auction" />
            <Tab label="Upcoming Auction" />
          </Tabs>
          <Grid container style={{ paddingLeft: "20px" }} item>
            {this.state.selectedTab == 0 && this.liveAuctionView()}
            {this.state.selectedTab == 1 && this.upcomingAuctionView()}
          </Grid>
        </Container>

      </>

    );
  }
  // Customizable Area End
}

export default AuctionBidding