
import React from "react";
import { IFreelancer } from "../../blocks/auctionbidding/src/SponsorInviteFreelancerController.web";
import { Box, Typography, Button, Grid, Avatar, IconButton } from '@material-ui/core'
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FavoriteFieldIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { Favorite, FavoriteBorderOutlined } from "@material-ui/icons";

import Rating from '@material-ui/lab/Rating';
import { message, location } from "../../blocks/auctionbidding/src/assets"
interface Props {
    freelancer: IFreelancer;
    handleSendMessage: (freelancerId: string) => void;
    handleLike: (data: any, index: number) => void;
    handleSelectInviteFreelancer: (item: IFreelancer, type: "invite" | "decline") => void;
    navigateToHireFreelancer: (item: IFreelancer) => void;
    index: number;
    loading?: boolean;
    loadingindex?:number | string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        freelancer_list_item: {
            display: "flex",
            gap: "15px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            border: "1px solid rgba(0,0,0,.1)",
            borderRadius: "20px",
            padding: "20px",
            "@media only screen and (max-width: 767px)": {
                justifyContent:"end" 
              },
        } as const,
        freelancer_avatar: {
            width: "70px",
            height: "70px",
        },
        freelancer_item_details_container: {
            display: "flex",
            gap: "15px",
            flexGrow: 1,
            "@media only screen and (max-width: 475px)": {
                justifyContent:"center",
                flexWrap:"wrap",
                "& .MuiAvatar-img":{
                    margin:"auto"
                }
            },
            "@media only screen and (max-width: 560px)": {
                justifyContent:"center",
                flexWrap:"wrap",
                "& .MuiAvatar-img":{
                    margin:"auto"
                }
            }

        } as const,
        freelancer_item_info: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            "@media only screen and (max-width: 475px)": {
                alignItems: "center",
            }

        } as const,
        properties_item_container: {
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            "@media only screen and (max-width: 475px)": {
                '&:nth-child(1)': {
                    paddingLeft: "0px"
                  }
            }
        } as const,
        skill_container: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
            marginTop: "15px",
        } as const,
        skillItem: {
            padding: "10px 20px",
            background: "#f5f6f5",
            borderRadius: "15px"
        },
        invite_button: {
            background: "#00A7A2",
            borderRadius: "20px",
            padding: "10px 20px",
            color: "white",
            textTransform: "capitalize",
            "&:hover": {
                background: "#00A7A2"
            },
            "@media only screen and (max-width: 600px)": {
              width:"50%"
              },
        } as const,
        available_button: {
            background: "white",
            borderRadius: "20px",
            padding: "3px 11px",
            textTransform: "capitalize",
            border: "1px solid #00A7A2",
            color: "#00A7A2",
            fontWeight: "bold",
            fontSize: "11px",
            "&:hover": {
                background: "white"
            }
        } as const,

        decline_button: {
            background: "red",
            color: "white",
            borderRadius: "20px",
            padding: "6px 20px",
            textTransform: "capitalize",
            "&:hover": {
                background: "red"
            }

        } as const,
        row: {
            display: "flex",
            alignItems: "center",
            gap: "10px",
            "@media only screen and (max-width: 475px)": {
            " & MuiRating-readOnly":{
                width: "100%"
            }}
        } as const,
        page_heading: {
            fontWeight: "bolder",

        } as const,
        subtitle: {
            color: "#96939c",
            fontWeight: "bold",
            fontSize: "13px",
            "@media only screen and (max-width: 475px)": {
            flexWrap: "wrap",
  justifyContent: "center",
  fontSize: "9px",
            },
            
        } as const,
        back_button: {
            background: "#f2f2f2",
            borderRadius: "20px",
            padding: "10px 20px",
            textTransform: "capitalize",
        } as const,
        invite : {
            "@media only screen and (max-width: 600px)": {
                justifyContent:"space-between" ,
                width : "100%"
              },
        }as const
    })
);


const InviteFreelancerItem: React.FC<Props> = ({loadingindex, loading, handleLike, freelancer, handleSendMessage, handleSelectInviteFreelancer, navigateToHireFreelancer, index }) => {
    const classes = useStyles();
    const freelancerPhoto = freelancer.attributes.photo;
    const photoLink = freelancerPhoto?.length > 0 ? freelancerPhoto[0]?.url : "";
    const skills = freelancer?.attributes?.skills?.map((item) => item?.name)?.slice(0, 3)?.join(" | ");
    const state = freelancer.attributes.state;
    const country = freelancer.attributes.country;
    const accountId = freelancer.attributes?.account_detail?.data?.id;
    const profileCategory = freelancer?.attributes?.category_of_interests;
    let experience: any = freelancer?.attributes?.experience_in_years || 0;
    const companies = freelancer?.attributes?.company_details;
    let companyCategory: string[] = [];
    if (companies?.data && companies?.data?.length > 1) {
        const secondaryCompany = companies.data.find((item) => item?.attributes?.company_type !== "primary_company");
        companyCategory = secondaryCompany ? secondaryCompany?.attributes?.category_of_interests?.map((item) => item?.name) as string[] : [];
        experience = secondaryCompany?.attributes?.freelancing_experience;
    } else if (companies?.data && companies?.data?.length > 0) {
        const secondaryCompany = companies.data[0];
        experience = secondaryCompany?.attributes?.freelancing_experience;
        companyCategory = secondaryCompany ? secondaryCompany?.attributes?.category_of_interests?.map((item) => item?.name) as string[] : [];
    } else {
        companyCategory = profileCategory?.map((item) => item.name);
        experience = freelancer?.attributes?.experience_in_years;
    }
    const invitationStatus = freelancer?.attributes?.invitation_status;
    const totalRatings = freelancer?.attributes?.total_ratings || 0;
    const hourlyRate = freelancer?.attributes?.hour_rate || 0;
    const rating = freelancer?.attributes?.rating || 0;
    const currencysymbol = freelancer?.attributes?.currency_symbol;
    return (
        <Box className={classes.freelancer_list_item}>
            <Box className={classes.freelancer_item_details_container}>
                <Avatar className={classes.freelancer_avatar} src={photoLink} />
                <Box className={classes.freelancer_item_info}>
                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Typography component={"h1"} variant="h6" className={classes.page_heading}>
                            {freelancer?.attributes?.first_name}{" "}{freelancer?.attributes?.last_name}
                        </Typography>
                        {
                            freelancer?.attributes?.availability && (
                                <Button className={classes.available_button}>
                                    Available Now
                                </Button>
                            )
                        }

                    </Box>
                    <Typography style={{ display: "flex", alignItems: "center" , marginTop:"10px" }} className={classes.subtitle} component={"p"} variant="subtitle1">
                        {skills} {skills && ". "}
                        <span
                        >
                            <img style={{ marginRight: "4px" }} src={location} alt="" />
                        </span>
                        {state},{country}
                    </Typography>
                    <Box className={classes.row}>
                        <Rating value={rating} readOnly />
                        <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                            <span style={{ color: "black" }}>{rating}/5</span>({totalRatings} Ratings)
                        </Typography>
                    </Box>
                    <Grid container style={{ marginTop: "15px" }}>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Box className={classes.properties_item_container}>
                                <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                                    Rate
                                </Typography>
                                <Typography className={classes.page_heading} component={"p"} variant="subtitle1">
                                    {currencysymbol}&nbsp;{hourlyRate}/hr
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Box className={classes.properties_item_container}>
                                <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                                    Total Amount of Time
                                </Typography>
                                <Typography className={classes.page_heading} component={"p"} variant="subtitle1">
                                    0 hrs
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Box className={classes.properties_item_container} style={{paddingLeft:"10px"}}>
                                <Typography className={classes.subtitle} component={"p"} variant="subtitle1">
                                    Total Experience
                                </Typography>
                                <Typography className={classes.page_heading} component={"p"} variant="subtitle1">
                                    {experience || 0} years
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className={classes.skill_container}>
                        {
                            companyCategory?.slice(0, 3)?.map((item) => (
                                <Box className={classes.skillItem}>
                                    <Typography style={{ fontWeight: "normal" }} className={classes.subtitle} component={"p"} variant="subtitle1">
                                        {item}
                                    </Typography>
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
            <Box style={{ display: "flex", gap: "10px", alignSelf: "start" }} className={classes.invite}>
                {/* <Box style={{display:"flex",gap:"10px"}}> */}
                <IconButton style={{ padding: "13px", borderRadius: "50%" }}
                    onClick={() => handleLike(freelancer.attributes, index)}
                >
                    {(index == loadingindex && loading) ? <CircularProgress
                        size={20} /> :
                        (freelancer?.attributes.is_favourite_freelancer ? (
                            <Favorite />
                        ) : (
                            <FavoriteBorderOutlined />
                        ))}
                </IconButton>
                <IconButton onClick={() => handleSendMessage(accountId)} className={classes.back_button} style={{ padding: "13px", borderRadius: "50%" }}>
                    <img style={{ height: "25px", width: "25px", cursor: "pointer" }} src={message} />
                </IconButton>
                {/* <IconButton className={classes.back_button} style={{padding:"13px",borderRadius:"50%"}}>
                            {index%2===0?<FavoriteBorderIcon/>:<FavoriteFieldIcon/>}
                       </IconButton> */}
                {
                    invitationStatus === "decline" && (
                        <Button className={classes.decline_button}>
                            Declined
                        </Button>
                    )
                }
                {
                    invitationStatus === "rejected" && (
                        <Button className={classes.decline_button}>
                            Rejected
                        </Button>
                    )
                }
                {
                    invitationStatus === "sent" && (
                        <Button onClick={() => handleSelectInviteFreelancer(freelancer, "decline")} className={classes.back_button}>
                            Decline
                        </Button>
                    )
                }
                {
                    invitationStatus === "accepted" && (
                        <Button onClick={() => navigateToHireFreelancer(freelancer)} className={classes.invite_button}>
                            Hire
                        </Button>
                    )
                }
                {
                    !freelancer.attributes?.invitation_sent && (
                        <Button onClick={() => handleSelectInviteFreelancer(freelancer, "invite")} className={classes.invite_button}>
                            Invite
                        </Button>
                    )
                }

                {/* </Box> */}
            </Box>
        </Box>
    )
}
export default InviteFreelancerItem;