
import React from "react";
import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start

    Paper, TextField,
    InputAdornment,
    IconButton,
    // Customizable Area End

} from "@material-ui/core";
const ResetImg = require("../assets/ResetImg.png");
const logoImg = require("../assets/logo.jpeg");
const success = require("../assets/success.png");
// Customizable Area Start
import { createTheme,ThemeProvider,makeStyles } from "@material-ui/core/styles";
import { contact } from "./assets";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const theme = createTheme({
    palette: {

    },
    overrides: {
        MuiFormLabel: {
          root: {
            lineHeight: "none",
            "&.Mui-focused":{
                color:"#8083a3",
                fontSize:"14px",
                fontWeight:400
            }
          }
        },
        MuiInputBase: {
          input: {
            padding: 0,
            fontSize:"14px",
            fontWeight:700,
            '&::placeholder': {
                color: "#8083a3", 
                fontSize:"14px",
                fontWeight:700,
              },            
          }
        },
        
        MuiButton:
            {
              contained :{
              color: '#fff'
            }
          },
      }
});

const useStyles = makeStyles((theme) => ({
    customPlaceholder: {
      '&::placeholder': {
        color: 'red', // Change this to your desired color
      },
    },
  }));

// Customizable Area End
import ForgetPasswordController, { Props, configJSON, } from "./ForgetPasswordController.web";

export default class ResetEmail extends ForgetPasswordController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box display='flex' justifyContent='end' style={{ marginTop: '6px', marginRight: '10px', display:"flex", alignItems:'center'}}>
                    <img style={{ height: '5vh' }} src={contact} alt="" />
                    <a style={{ marginTop: '5px', marginLeft: '5px', color: '#8083A3', textDecoration: 'none', fontWeight:700 }}
                        href="#">{configJSON.helpAndSupportTxt}
                    </a>
                </Box>
                <Box sx={{ alignSelf:'center'}}>
                    {this.state.isPasswordReset ? (
                        <>
                            <Box py={4} style={{
                                textAlign: "center",
                                display: "inline",
                            }}>
                                <img style={webStyle.logo_img} src={logoImg} alt="" />
                            </Box>
                            <Box width={450} component={Paper} mt={5} style={webStyle.main_box}>
                                <Box py={3} style={webStyle.text_box1}>
                                    <Typography style={{ fontWeight: 'bold' }}>{configJSON.createNewPasswordTxt}</Typography>
                                </Box>
                                <Box borderTop={1} style={webStyle.submain_box}>
                                    <Box p={3}>
                                        <Typography gutterBottom style={{
                                            textAlign: 'center',
                                            color: "black",
                                            fontSize: "14px"
                                        }}>
                                            {configJSON.passwordValidation}
                                        </Typography>
                                        <TextField
                                        InputProps={{
                                            endAdornment:(
                                            <InputAdornment position="end">
                                              <IconButton
                                              data-test-id="icon-click"
                                              tabIndex = {-1}
                                                onClick={this.handleResetVisibilityChange}
                                              >
                                                {this.state.resetVisible.visible ? <Visibility /> : <VisibilityOff />}
                                              </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                            autoFocus={true}
                                            tabIndex = {0}
                                            fullWidth
                                            style={webStyle.email_text}
                                            data-test-id="txtInputPassword"
                                            type={this.state.resetVisible.visible?"text":"password"}
                                            margin="normal"
                                            id="password"
                                            label="Password"
                                            name="password"
                                            placeholder="Enter your password"
                                            value={this.state.password}
                                            onChange={(e) => this.setState({ password: e.target.value })} />
                                        {<Typography tabIndex = {-1} style={webStyle.error_text}>{this.state.passwordErrors.passwordErr}</Typography>}

                                        <TextField
                                         InputProps={{

                                            endAdornment:(
                                                <InputAdornment position="end">
                                                  <IconButton
                                                  tabIndex = {-1}
                                                   onClick={this.handleConfirmVisibility}
                                                  >
                                                    {this.state.resetVisible.confirmVisible? <Visibility /> : <VisibilityOff />}
                                                  </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                            data-test-id="txtInputConfirmPassword"
                                            tabIndex = {1}
                                            fullWidth
                                            style={webStyle.email_text}
                                            type={this.state.resetVisible.confirmVisible?"text":"password"}
                                            margin="normal"
                                            id="don"
                                            label="Confirm Password"
                                            name="confirm_password"
                                            placeholder="Confirm your password"
                                            value={this.state.confirmpassword}
                                            onChange={(e) => this.setState({ confirmpassword: e.target.value })}
                                        />
                                        {<Typography tabIndex = {-1} style={webStyle.error_text}>{this.state.passwordErrors.passwordMatchErr}</Typography>}
                                        {this.state.resetPasswordLinkExpired && <Typography style={webStyle.error_text}>{configJSON.resetPasswordLinkExipired}</Typography>}
                                        <div tabIndex = {2}>
                                        <Button style={webStyle.savenewpassword_btn}
                                            variant="contained"
                                            disableFocusRipple
                                            onClick={this.Passwordreset}>
                                            <Typography style={webStyle.save_btn_text}>{configJSON.saveNewPasswordBtnTxt}</Typography>
                                        </Button>
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box >
                            <Box py={2} style={{
                                textAlign: "center",
                                display: "inline",
                            }}>
                                <img style={webStyle.logo_img} src={logoImg} alt="" />
                            </Box>

                            <Box width={450} component={Paper} mt={5} style={webStyle.main_box}>
                                <Box textAlign="center"><img src={success} alt="" /></Box>
                                <Box pt={4} style={{ textAlign: 'center' }}>
                                    <Typography variant="h5" style={{
                                        fontWeight: 'bold',
                                        textAlign: 'center'
                                    }}>
                                        {configJSON.passwordChanged} </Typography>
                                    <Typography style={{
                                        fontSize: "14px",
                                        color: "#8083A3",
                                        fontWeight: "bold",
                                        marginTop:"10px"
                                    }}>
                                        {configJSON.passwordChangedSuccessfully}</Typography>
                                </Box>
                                <Box py={3} style={{ textAlign: 'center' }}>
                                    <Button variant="contained"
                                        id="gotologinbtn"
                                        style={webStyle.gotologin_btn}
                                        onClick={this.cancelonClick}
                                    ><Typography style={webStyle.save_btn_text}>{configJSON.gotoLogin}</Typography></Button>
                                </Box>
                            </Box>
                        </Box>
                    )
                    }
                </Box>
                </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {

    logo_img: {
        height: "35px",
        width: "145.15px",
        marginLeft: "156px",
    },
    main_box: {
        border: '1px solid #E4E6E8',
        borderRadius: "18px",
    },
    text_box1: {
        display: 'flex',
        justifyContent: 'center',
    },
    submain_box: {
        color: "#E4E6E8",
    },

    email_text: {
        marginTop: '5px',
        marginBottom: '5px',
        
    },
    savenewpassword_btn: {
        backgroundColor: ' #EC952D',
        borderRadius: '19px',
        width: '390px',
        height: '46px',
        marginTop: 30,
        overflow:'hidden',
        padding:0
    },

    gotologin_btn: {        
        backgroundColor: '#EC952D',
        width: '338px',
        borderRadius: '23px',
        height:"47px"
        
    },
    error_text: {
        fontSize: '0.75rem',
        color: '#f44336',
    },
    save_btn_text: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        color: '#FFFFFF',
        textTransform: 'none' as const,
    },
    focusedLabel:{
        color: 'rgba(0, 0, 0, 0.54)',
    }
};

// Customizable Area End
