// Customizable Area Start
import React from "react";

import FreelancerBrowseProjectController, {
  Props,
  configJSON,
} from "./FreelancerBrowseProjectController.web";
import { Typography, Box,Paper } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProjectFilter from "./ProjectFilter.web";
import ProjectCardProgress from "../../../../components/src/ProjectCardProgress.web";
import { webStyle } from "./FreelancerBrowseProject.web";
import "../../assets/css/LancerDashboard.css"
class FreelancerProgressProject extends FreelancerBrowseProjectController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      projectList: progressProjectList,
      totalProjects,
      projectLoading: loadingProgressProjects,
    } = this.state;
    const enableProgressProjectsShowMore =
      totalProjects > progressProjectList?.length;
      let param = this.props.param
    return (
      <Box>
        <ProjectFilter
          onSearch={this.searchHandler}
          onFilter={this.filterHandler}
          onCancelFilter={this.cancelFilterHandler}
          isUserFreelancer={true}
        />
        <br />
        <br />

        { !loadingProgressProjects && progressProjectList?.length === 0 && (
                    <Paper className="border-block p-0 projectloading-main" > 
                      <Box className='projectloading'>
                      <Typography variant='h2'>{configJSON.loadingMsg}</Typography>
                    </Box>
                 </Paper>
)}

        {progressProjectList?.map((card: any, index: number) => (
          <ProjectCardProgress key={card?.id} card={card} param = {param} instance = {this}
          />
        ))}
        <Box style={webStyle.loadMoreBtn}>
          {loadingProgressProjects && (
            <Box>
              <CircularProgress />
            </Box>
          )}

          {enableProgressProjectsShowMore && !loadingProgressProjects && (
            <button
              data-testid="loadMore"
              style={webStyle.showMoreBtn}
              onClick={this.handleProjectLoadMore}
              hidden={loadingProgressProjects}
            >
              {configJSON.showMoreTxt}
              <ArrowForwardIosIcon style={webStyle.forwardIcon} />
            </button>
          )}
        </Box>
      </Box>
    );
  }
}

export default FreelancerProgressProject;

// Customizable Area End
